import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    marginRight: theme.spacing(0.5),
  },
}));
