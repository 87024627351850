import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { push } from 'connected-react-router';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import { Link as MLink } from '@material-ui/core';

import { selectConfigProperty, selectLoginPath } from 'redux/config';
import {
  createOrder,
  selectOrder,
  clearOrder,
  commitOrder,
} from 'redux/giftCards';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { PATHS } from 'constants/navigation';
import Loader from 'components/Loader';
import ErrorMessage from 'components/ErrorMessage';
import { useUserNavigation } from 'hooks/navigation';
import { useGiftCardDetails } from 'hooks/giftCards';
import { useEventTracker } from 'hooks/analytics';
import { useCardSelector } from 'hooks/wallet';
import { useIsLoggedIn } from 'hooks/user';
import { getFaceplatesUrl } from 'utils/giftCards';

import { ReactComponent as BatIcon } from 'assets/images/brave/bat-logo.svg';

import useStyles from './styles';

const maxDescriptionLength = 140;
const fetchInterval = 15 * 1000;

const GiftCardDetailsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { giftCardId, countryCode } = useParams();
  const [readMore, changeReadMore] = useState(false);
  const [showDisclaimer, changeShowDisclaimer] = useState(false);

  const isLoggedIn = useIsLoggedIn();
  const loginPath = useSelector(selectLoginPath);
  const order = useSelector(selectOrder);
  const isGiftCardEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.giftCardsEnabled)
  );
  const upholdAddFundsLink = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAddFundsLink)
  );
  const initialData = order?.id === +giftCardId ? order : {};
  const [activeOption, setActiveOption] = useState(
    initialData.activeOption || 0
  );
  const [otherAmount, setOtherAmount] = useState(initialData.otherAmount || '');
  const [otherAmountError, setOtherAmountError] = useState(null);
  const fetchIntervalRef = useRef(0);

  const [card, CardSelector] = useCardSelector(initialData.cardId);
  const { currency, balance } = card;

  const handleGAEvent = useEventTracker();

  useEffect(() => {
    if (order.id !== +giftCardId) {
      dispatch(clearOrder());
    }
  }, [dispatch, giftCardId, order.id]);

  useUserNavigation({
    backUrl: PATHS.giftCards,
  });

  const { giftCard, isLoading, error } = useGiftCardDetails(
    countryCode,
    giftCardId
  );

  useEffect(() => {
    const fetchRequest = () =>
      dispatch(
        createOrder({
          id: +giftCardId,
          amount: otherAmount || activeOption,
          cardId: card.id,
          countryCode,
          activeOption,
          otherAmount,
        })
      );
    clearInterval(fetchIntervalRef.current);
    if (!otherAmountError) {
      fetchRequest();
      fetchIntervalRef.current = setInterval(fetchRequest, fetchInterval);
    }

    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [
    otherAmount,
    activeOption,
    card,
    dispatch,
    giftCardId,
    countryCode,
    otherAmountError,
  ]);

  const handleReadMore = useCallback(() => changeReadMore(true), []);

  const handleDisclaimerClick = useCallback((_, isExpanded) => {
    changeShowDisclaimer(isExpanded);
  }, []);

  const handleOptionBtnClick = useCallback(
    (item) => {
      setActiveOption(item);
      setOtherAmount('');
      setOtherAmountError(null);
      handleGAEvent('gift-card', 'select-amount', item);
    },
    [handleGAEvent]
  );

  const handleOtherAmountChange = useCallback(
    ({ target: { value } }) => {
      if (value !== '' && (Number(value) < 1 || Number.isNaN(+value))) {
        return;
      }
      setActiveOption(null);
      setOtherAmount(value);
      setOtherAmountError(null);
      handleGAEvent('gift-card', 'select-amount', value);

      if (
        value &&
        giftCard?.denomination_type === 'Variable' &&
        (value < giftCard.denominations[0] || value > giftCard.denominations[1])
      ) {
        setOtherAmountError('Invalid amount, please try again.');
      }
    },
    [handleGAEvent, giftCard]
  );

  const handleBtnClick = useCallback(() => {
    dispatch(commitOrder());
    handleGAEvent('gift-card', 'submit-order', giftCardId);
  }, [dispatch, giftCardId, handleGAEvent]);

  const goToLogin = () => {
    dispatch(
      push(
        `${loginPath}?redirect_uri=${encodeURIComponent(window.location.href)}`
      )
    );
  };

  if (!isGiftCardEnabled) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty(giftCard)) {
    return <Typography variant="h3">Not found</Typography>;
  }
  const insufiFundsError = 'Insufficient funds, please choose another card.';

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.imgWrapper}>
          <img
            src={getFaceplatesUrl(giftCard)}
            alt={giftCard.name}
            className={classes.image}
          />
        </div>
        <Typography variant="h6" className={classes.description}>
          {readMore || giftCard.description.length < maxDescriptionLength ? (
            giftCard.description
          ) : (
            <>
              {giftCard.description.substring(0, maxDescriptionLength)}
              ...
              <button
                className={classes.readMore}
                type="button"
                onClick={handleReadMore}
              >
                Read More
              </button>
            </>
          )}
        </Typography>

        {isLoggedIn && (
          <>
            <div className={classes.optionBtnContainer}>
              {giftCard.denominations_to_show.map((item) => (
                <div key={item} className={classes.optionBtnWrapper}>
                  <Button
                    className={clsx(classes.optionBtn, {
                      [classes.activeOptionBtn]: activeOption === item,
                    })}
                    variant="contained"
                    onClick={() => handleOptionBtnClick(item)}
                    size="large"
                  >
                    ${item}
                  </Button>
                </div>
              ))}
            </div>
            {giftCard.denomination_type === 'Variable' && (
              <div className={classes.otherAmount}>
                <Typography className={classes.otherAmountLabel}>
                  Other Amount
                </Typography>
                <TextField
                  className={classes.otherAmountInput}
                  placeholder={`$${new Intl.NumberFormat().format(
                    giftCard.denominations[0]
                  )}-$${new Intl.NumberFormat().format(
                    giftCard.denominations[1]
                  )}`}
                  variant="outlined"
                  value={otherAmount}
                  size="small"
                  onChange={handleOtherAmountChange}
                  helperText={otherAmountError}
                  error={!!otherAmountError}
                />
              </div>
            )}
            <div className={classes.inputContainer}>
              <Typography className={classes.inputLabel}>
                Choose a card
              </Typography>
              <div className={classes.input}>{CardSelector}</div>
            </div>
            {currency && (
              <>
                <Typography className={classes.exchangeAmount}>
                  {currency === 'BAT' && (
                    <BatIcon className={classes.currencyIcon} />
                  )}
                  {currency} amount:&nbsp;{Number(balance)}
                  {balance >= 0 && upholdAddFundsLink && (
                    <MLink
                      className={classes.addFundsLink}
                      href={upholdAddFundsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Add funds
                    </MLink>
                  )}
                </Typography>
                <div className={classes.note}>
                  * {order.currencyName} value adjusts every{' '}
                  {fetchInterval / 1000} seconds
                </div>
              </>
            )}
            <Button
              className={classes.btn}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleBtnClick}
              disabled={
                !order.transactionId ||
                !!otherAmountError ||
                Number(order.amount) > Number(card.balance)
              }
            >
              {Number(order.amount) < Number(card.balance)
                ? 'Confirm'
                : 'Choose a card to continue'}
            </Button>
            <div className={classes.note}>
              All gift card purchases are final.
            </div>

            <ErrorMessage
              error={error !== insufiFundsError ? error : null}
              noContactUs
            />
          </>
        )}

        {giftCard?.terms && (
          <Accordion
            expanded={showDisclaimer}
            onChange={handleDisclaimerClick}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={showDisclaimer ? <RemoveIcon /> : <AddIcon />}
              classes={{
                root: classes.accordionSummary,
                content: classes.accordionSummaryContent,
              }}
            >
              Terms & Conditions
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                {giftCard.terms.length > 0 && parse(giftCard.terms)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        {!isLoggedIn && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.loginBtn}
            size="large"
            onClick={goToLogin}
          >
            Log in
          </Button>
        )}
      </div>
    </div>
  );
};

export default GiftCardDetailsPage;
