import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';

const getPath = (_, { path }) => path || '';

export const selectContent = (state) => _get(state, 'cms.content');
export const selectIsLoading = (state) => _get(state, 'cms.isLoading');
export const selectIsLoaded = (state) => _get(state, 'cms.isLoaded');
export const selectError = (state) => _get(state, 'cms.error');

export const selectCmsItems = (state) => state.cms.content;

export const _getCmsItem = (state, { key }) => {
  const items = selectCmsItems(state);
  return items ? items[key] : {};
};

export const selectCmsItem = createSelector(
  [_getCmsItem],
  (cmsItem) => cmsItem
);

export const selectEntryOptionsItems = (state) =>
  _get(state, 'cms.content.sweepEntryOptions.data.items', []);

export const selectDefaultEntryOption = (state) =>
  _get(state, 'cms.content.sweepEntryOptions.data.items', []).find(
    (option) => option.default
  );

export const selectItemData = createSelector(
  [selectCmsItem, getPath],
  (item, path) => _get(item, `data.${path}`)
);

export const selectCmsHtml = createSelector([selectCmsItem], (cmsItem) =>
  _get(cmsItem, 'html')
);
export const selectShouldFetchCms = createSelector(
  (state) => !state.cms.isLoaded && !state.cms.isLoading,
  (_shouldFetchCms) => _shouldFetchCms
);

export const selectCharityData = createSelector(
  [(state) => selectCmsItem(state, { key: 'charityUpsell' })],
  (charities) => charities
);

export const selectPopups = (state) => _get(state, 'cms.popups', []);

export const selectReferralContent = createSelector(
  [(state) => selectCmsItem(state, { key: 'referralPageContent' })],
  (content) => (content ? content.data : {})
);
