import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isAuthProcessing: false,
  isBidProcessing: false,
  bidError: null,
  order: null,
  authError: null,
  items: [],
  error: null,
};

const liveBidsSlice = createSlice({
  name: 'liveBids',
  initialState,
  reducers: {
    fetchLiveBids: (state) => {
      state.isLoading = true;
    },
    fetchLiveBidsSuccess: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
    fetchLiveBidsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchLiveBidDetails: (state, action) => {
      state.isLoading = action.payload.showLoader;
    },
    fetchLiveBidDetailsSuccess: (state, action) => {
      state.isLoading = false;
      const data = action.payload;
      const index = state.items.findIndex((i) => i.id === data.id);
      if (index !== -1) {
        Object.assign(state.items[index], data);
      } else {
        state.items.unshift(data);
      }
    },
    fetchLiveBidDetailsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    postAuctionUsername: (state) => {
      state.isAuthProcessing = true;
      state.authError = null;
    },
    postAuctionUsernameSuccess: (state) => {
      state.isAuthProcessing = false;
    },
    postAuctionUsernameFailure: (state, action) => {
      state.isAuthProcessing = false;
      state.authError = action.payload;
    },
    resetAuthError: (state) => {
      state.authError = null;
    },
    placeBidItem: (state) => {
      state.isBidProcessing = true;
      state.bidError = null;
    },
    placeBidItemSuccess: (state, action) => {
      state.isBidProcessing = false;
      state.order = action.payload;
    },
    placeBidItemFailure: (state, action) => {
      state.isBidProcessing = false;
      state.bidError = action.payload;
    },
    clearBidData: (state) => {
      state.order = null;
      state.bidError = null;
    },
    clearBidError: (state) => {
      state.bidError = null;
    },
  },
});

export const {
  fetchLiveBids,
  fetchLiveBidsFailure,
  fetchLiveBidsSuccess,
  fetchLiveBidDetails,
  fetchLiveBidDetailsSuccess,
  fetchLiveBidDetailsFailure,
  clearBidData,
  placeBidItem,
  placeBidItemSuccess,
  placeBidItemFailure,
  postAuctionUsername,
  postAuctionUsernameSuccess,
  postAuctionUsernameFailure,
  resetAuthError,
  clearBidError,
} = liveBidsSlice.actions;

export default liveBidsSlice.reducer;
