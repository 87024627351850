import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import { getIsDebugEnv } from 'utils/config';

import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

export const createStore = (history) => {
  const store = configureStore({
    reducer: createRootReducer(history),
    devTools: getIsDebugEnv(),
    middleware: [routerMiddleware(history), sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
};
