import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLiveBidDetails,
  clearBidData,
  selectBidDetails,
} from 'redux/liveBids';
import { selectUserInfo } from 'redux/user';

import LiveBidsIntroModal from 'components/LiveBidsIntroModal';
import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';

import Grid from '@material-ui/core/Grid';

import { useUserNavigation } from 'hooks/navigation';
import { useIsLoggedIn } from 'hooks/user';

import GetStartedPopUp from './components/GetStartedPopUp';
import BidDetails from './components/BidDetails';

import useStyles from './styles';

const LiveBidsDetailsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useUserNavigation();

  const [showIntroModal, changeShowIntroModal] = useState(false);
  const [showGetStartedPopUp, changeShowGetStartedPopUp] = useState(false);
  const detailsLoaded = useRef(false);

  const { id } = useParams();
  const isLoggedIn = useIsLoggedIn();

  const { isLoading, error, bidDetails } = useSelector(selectBidDetails)(+id);
  const { auction } = useSelector(selectUserInfo);

  const openIntroModal = useCallback(() => {
    changeShowIntroModal(true);
  }, []);

  const closeIntroModal = useCallback(() => {
    changeShowIntroModal(false);
  }, []);

  const handleShowGetStartedPopUp = useCallback(() => {
    changeShowGetStartedPopUp((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!detailsLoaded.current) {
      dispatch(
        fetchLiveBidDetails({
          id: +id,
          showLoader: !bidDetails.id,
        })
      );
      detailsLoaded.current = true;
    }
  }, [dispatch, id, bidDetails]);

  useEffect(() => {
    if (!auction?.user_name && isLoggedIn) {
      setTimeout(() => {
        changeShowGetStartedPopUp(true);
      }, 3000);
    }
  }, [auction, isLoggedIn]);

  useEffect(() => {
    return () => {
      dispatch(clearBidData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (auction?.user_name && showGetStartedPopUp) {
      changeShowGetStartedPopUp(false);
    }
  }, [auction, showGetStartedPopUp]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <LiveBidsIntroModal open={showIntroModal} onClose={closeIntroModal} />
      <GetStartedPopUp
        isOpened={showGetStartedPopUp}
        closeModal={handleShowGetStartedPopUp}
      />
      <Grid
        className={classes.container}
        container
        direction="column"
        alignItems="center"
      >
        <BidDetails
          bidDetails={bidDetails}
          toggleIntroModal={openIntroModal}
          isLoggedIn={isLoggedIn}
          handleShowGetStartedPopUp={handleShowGetStartedPopUp}
          auctionUsername={auction?.user_name}
          bidId={id}
        />
      </Grid>
    </>
  );
};

export default LiveBidsDetailsPage;
