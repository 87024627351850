/* eslint-disable */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserAccount, selectUserInfo } from 'redux/user';
import { storage } from 'services/storage';

//TODO: Rethink the use-case of this hook. Maybe the hook just retrieves the user from the api if user is not set in state?
export const useUserInfo = () => {
  const dispatch = useDispatch();
  const userToken = storage.local.get('isLoggedIn');
  const user = useSelector(selectUserInfo);

  useEffect(() => {
    if (userToken) dispatch(getUserAccount());
  }, [dispatch]);

  return {
    isValidUser: user.isValidUser,
    userToken,
    user,
  };
};

export const useIsLoggedIn = () => {
  return Boolean(storage.local.get('isLoggedIn'));
};

export const useUserCountry = (handleSuccess, handleError) => {
  const [countryCode, setCountryCode] = useState('US');
  const onSuccess = useCallback(async ({ coords: { latitude, longitude } }) => {
    try {
      const response = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
      const { countryCode } = await response.json();
      setCountryCode(countryCode);
      if (handleSuccess) {
        handleSuccess(countryCode);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onError = useCallback(() => {
    console.log("Unable to retrieve user's location");
    if (handleError) {
      handleError("Unable to retrieve user's location");
    }
  });

  useEffect(() => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      if (handleError) {
        handleError('Geolocation is not supported by your browser');
      }
    } else {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, []);

  return countryCode;
};
