import { Button, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { ReactComponent as SpotifyIcon } from 'assets/images/wmg/spotify.svg';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from './styles';

const FollowCard = ({
  name,
  image_url,
  id,
  isSpotify,
  setIsOpenModal,
  isFollowed,
  onSubmit,
  followText,
  followedText,
}) => {
  const classes = useStyles();
  const handleSubmit = () => {
    onSubmit(id);
  };

  return (
    <Grid item xs={6} sm={4} md={3} className={classes.card}>
      <div className={classes.cardImageWrapper}>
        <img src={image_url} alt={name} className={classes.image} />
        <Typography className={classes.acthorName}>{name}</Typography>
        {isFollowed ? (
          <div className={classes.success}>
            <Typography variant="subtitle1">{followedText}</Typography>
            <DoneIcon className={classes.doneIcon} />
          </div>
        ) : (
          <Button
            className={classes.button}
            startIcon={<SpotifyIcon />}
            onFocusVisible
            onClick={() => {
              if (isSpotify) {
                handleSubmit();
              } else {
                setIsOpenModal(true);
              }
            }}
          >
            {followText.toUpperCase()}
          </Button>
        )}
      </div>
    </Grid>
  );
};

export default FollowCard;
