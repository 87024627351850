import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLiveBidsList,
  fetchLiveBidDetails,
  fetchLiveBidDetailsSuccess,
} from 'redux/liveBids';
import { selectPennyAuctionSocketUrl } from 'redux/config';
import io from 'socket.io-client';

import { useUserInfo } from 'hooks/user';

import differenceInSeconds from 'date-fns/differenceInSeconds';

const useSocketEvent = (socketRef, eventName, callback) => {
  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on(eventName, callback);
    }

    return function socketEventCleanup() {
      if (socketRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        socketRef.current.off(eventName, callback);
      }
    };
  }, [socketRef, eventName, callback]);
};

const usePennyAuctionSockets = () => {
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  const [serverTime, setServerTime] = useState(null);
  const [auctionToFlash, setAuctionToFlash] = useState(null);
  const pennyAuctionSocketUrl = useSelector(selectPennyAuctionSocketUrl);

  const { user } = useUserInfo();

  const [wonAuctions, setWonAuctions] = useState([]);

  const { items } = useSelector(selectLiveBidsList);

  useEffect(() => {
    if (pennyAuctionSocketUrl) {
      socketRef.current = io(pennyAuctionSocketUrl, {
        transports: ['websocket'],
      });

      socketRef.current.on('connect', () => {
        console.log('connection established');
      });

      socketRef.current.on('disconnect', (error) => {
        console.log('Disconnected => ', error);
      });
    }
    return function socketCleanup() {
      socketRef.current.disconnect();
    };
  }, [pennyAuctionSocketUrl]);

  const resetWonAuction = useCallback(() => {
    setWonAuctions(wonAuctions.slice(1));
  }, [wonAuctions]);

  const sliservertime = useCallback((data) => {
    setServerTime(data.dTime);
  }, []);

  const bidPlaced = useCallback(
    (item, bidder) => {
      const { id, auctionStatus, date_closed: dateClosed, wprice } = item;
      const data = {
        id,
        auctionStatus,
        dateClosed,
        price: wprice.USD,
        bidder: bidder.username,
      };
      dispatch(fetchLiveBidDetailsSuccess(data));
      setAuctionToFlash(id);
      setTimeout(() => {
        setAuctionToFlash(null);
      }, 1000);
    },
    [dispatch]
  );

  const auctionEnd = useCallback(
    ({ project_id, winner_id }) => {
      dispatch(fetchLiveBidDetails({ id: project_id }));
      if (user?.auction?.user_id === winner_id) {
        const { title, price } = items.find((item) => item.id === project_id);
        setWonAuctions((prevState) => [...prevState, { title, price }]);
      }
    },
    [dispatch, items, user]
  );

  const goLive = useCallback(
    (id) => {
      setTimeout(() => {
        dispatch(fetchLiveBidDetails({ id }));
      }, 1000);
    },
    [dispatch]
  );

  useSocketEvent(socketRef, 'sliservertime', sliservertime);
  useSocketEvent(socketRef, 'bidPlaced', bidPlaced);
  useSocketEvent(socketRef, 'auction_end', auctionEnd);
  useSocketEvent(socketRef, 'goLive', goLive);

  const calculateDiff = (item) =>
    Math.abs(
      differenceInSeconds(
        serverTime ? new Date(serverTime) : new Date(),
        new Date(item.isFuture ? item.dateAdded : item.dateClosed)
      )
    );

  const shouldFlash = (item) => auctionToFlash === item.id;

  return { shouldFlash, calculateDiff, wonAuctions, resetWonAuction };
};

export default usePennyAuctionSockets;
