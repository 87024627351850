import React from 'react';
import { useSelector } from 'react-redux';
import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { PATHS } from 'constants/navigation';

import SocialLoginButton from 'components/SocialLoginButton';

const SpotifyButton = ({ isLoading }) => {
  const isSpotifyEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spotifyEnabled)
  );
  const spotifyClientId = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spotifyClientId)
  );
  const spotifyScopes = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spotifyScopes)
  );

  const spotifyRedirectUri = window.location.origin + PATHS.loginSpotify;

  if (!isSpotifyEnabled) {
    return null;
  }

  const handleClick = () => {
    localStorage.setItem('loginPage', 'true');
    window.location.href = `https://accounts.spotify.com/authorize?response_type=code&client_id=${spotifyClientId}&scope=${encodeURIComponent(
      spotifyScopes
    )}&redirect_uri=${encodeURIComponent(spotifyRedirectUri)}`;
  };

  return (
    <SocialLoginButton
      provider="Spotify"
      onClick={handleClick}
      disabled={isLoading}
    />
  );
};

export default SpotifyButton;
