import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  isLoaded: false,
  order: {},
};

const giftCardsSlice = createSlice({
  name: 'giftCards',
  initialState,
  reducers: {
    fetchGiftCards: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchGiftCardsSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = action.payload;
    },
    fetchGiftCardsFailure: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.payload;
    },
    redeemGiftCard: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    redeemGiftCardSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    redeemGiftCardFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createOrder: (state, action) => {
      Object.assign(state.order, action.payload);
      state.order.transactionId = null;
      state.error = null;
    },
    createOrderSuccess: (state, action) => {
      state.order.currencyAmount = +Number(
        action.payload.origin.amount
      ).toFixed(3);
      state.order.currencyName = action.payload.origin.currency;
      state.order.transactionId = action.payload.id;
    },
    createOrderFailure: (state, action) => {
      state.error = action.payload;
      state.order = {};
    },
    commitOrder: () => {},
    commitOrderSuccess: (state) => {
      state.order = {};
    },
    commitOrderFailure: (state, action) => {
      state.error = action.payload;
    },
    clearOrder: (state) => {
      state.order = {};
    },
  },
});

export const {
  fetchGiftCards,
  fetchGiftCardsFailure,
  fetchGiftCardsSuccess,
  redeemGiftCard,
  redeemGiftCardSuccess,
  redeemGiftCardFailure,
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  commitOrder,
  commitOrderSuccess,
  commitOrderFailure,
  clearOrder,
} = giftCardsSlice.actions;

export default giftCardsSlice.reducer;
