import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import RoundedOptionItem from 'components/RoundedOptionItem';
import Loader from 'components/Loader';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getCharities, selectCharitiesData } from 'redux/charities';

import { useUserNavigation } from 'hooks/navigation';
import { useEventTracker } from 'hooks/analytics';
import { PATHS } from 'constants/navigation';
import { ReactComponent as CharityIcon } from 'assets/images/icons/charity.svg';

import useStyles from './styles';

const CharitiesPage = () => {
  useUserNavigation();
  const classes = useStyles();
  const { isLoading, data } = useSelector(selectCharitiesData);

  const dispatch = useDispatch();

  const handleGAEvent = useEventTracker();

  const handleClick = useCallback(
    (id) => {
      handleGAEvent('charity', 'go-to-charity-payment', id);
      dispatch(push(`${PATHS.charities}/${id}/payment`));
    },
    [dispatch, handleGAEvent]
  );

  useEffect(() => {
    dispatch(getCharities());
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} variant="h4">
        Select a Charity
      </Typography>
      <Grid container className={classes.container}>
        {data.map(({ id, image_url }) => (
          <RoundedOptionItem
            key={id}
            imgSrc={image_url}
            onClick={() => handleClick(id)}
            data-cy="charity-item"
          />
        ))}
      </Grid>
      <div className={classes.poweredBy}>
        <CharityIcon />
        &nbsp; Powered by CharityChoice
      </div>
    </div>
  );
};

export default CharitiesPage;
