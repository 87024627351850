import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { selectUser, resetPasswordConfirm, clearUserError } from 'redux/user';
import { selectLoginPath } from 'redux/config';
import { getQryParams } from 'utils/url';

import useStyles from './styles';

const validatePasswords = (password, reEnterPassword) => {
  if (password !== reEnterPassword) {
    return 'Entries must match, please try again';
  }
  if (password.length < 8 || reEnterPassword < 8) {
    return 'Minimum 8 characters';
  }
  return null;
};

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const query = getQryParams(location);

  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [localError, setLocalError] = useState('');

  const { isLoading, error, isForgotPasswordSuccessful } = useSelector(
    selectUser
  );
  const loginPath = useSelector(selectLoginPath);
  const clearError = useCallback(() => dispatch(clearUserError()), [dispatch]);

  useEffect(() => clearError, [clearError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwordsError = validatePasswords(password, reEnterPassword);

    if (passwordsError) return setLocalError(passwordsError);

    if (!isForgotPasswordSuccessful)
      return dispatch(
        resetPasswordConfirm({
          reset_token: query.token,
          email: query.email,
          password,
          password_confirmation: reEnterPassword,
        })
      );

    return history.push(loginPath);
  };

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
      if (error) clearError();
      if (localError) setLocalError('');
    },
    [clearError, error, localError]
  );

  const handleReEnterPasswordChange = useCallback(
    (e) => {
      setReEnterPassword(e.target.value);
      if (error) clearError();
      if (localError) setLocalError('');
    },
    [clearError, error, localError]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <Typography variant="h4" className={classes.title}>
          Reset Password
        </Typography>
        <Typography variant="h5" className={classes.subTitle}>
          Enter your new password below
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item className={classes.inputContainer}>
              {!isForgotPasswordSuccessful && (
                <>
                  <TextField
                    className={classes.input}
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <TextField
                    className={classes.input}
                    label="Re-enter Password"
                    type="password"
                    variant="outlined"
                    value={reEnterPassword}
                    onChange={handleReEnterPasswordChange}
                  />
                </>
              )}
            </Grid>
            {isForgotPasswordSuccessful ? (
              <Typography variant="h6" className={classes.link}>
                Success!
              </Typography>
            ) : (
              <Typography variant="subtitle1" className={classes.note}>
                Minimum 8 characters
              </Typography>
            )}
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitBtn}
                disabled={isLoading}
                size="large"
              >
                {isLoading && (
                  <div className={classes.spinnerWrapper}>
                    <CircularProgress size={20} />
                  </div>
                )}
                {isForgotPasswordSuccessful ? 'Continue' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className={classes.errorContainer}>
          {error.length > 0 ? (
            <Typography
              variant="subtitle1"
              color="error"
              className={classes.error}
            >
              {error}
            </Typography>
          ) : (
            localError && (
              <Typography
                variant="subtitle1"
                color="error"
                className={classes.error}
              >
                {localError}
              </Typography>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
