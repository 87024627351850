import queryString from 'query-string';

export const getQryParams = (location) => queryString.parse(location.search);

export const replaceCouponCode = (location, coupon) => {
  const queryParams = getQryParams(location);
  queryParams.code = coupon;
  return queryString.stringify(queryParams);
};

export const isLocalRedirect = (location) => {
  return (
    _pathOnly(location) || _getUrl(location).hostname === _getLocalHostName()
  );
};

export const getLocalPath = (location) => {
  return _pathOnly(location) ? location : _getUrl(location).pathname;
};

const _getLocalHostName = () => window.location.hostname;

const _getUrl = (location) => new URL(location);

// Accounts for redirectUri like /attractions/1 which do not include the full hostname
const _pathOnly = (location) => location[0] === '/';
