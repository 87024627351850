/* eslint-disable no-restricted-globals */
/* eslint-disable radix */

import { PARTNER_DOMAIN_MAP, PARTNER_SECRET_MAP } from 'constants/config';
import { typeStringToBool } from './requests';

const DEFAULT_MAX_AGE = 90;
const DAY_IN_SECONDS = 60 * 60 * 24;

const getCookieMaxAge = () => {
  const maxCookieAge = parseInt(process.env.REACT_APP_COOKIE_MAX_AGE);
  return (
    (!isNaN(maxCookieAge) ? maxCookieAge : DEFAULT_MAX_AGE) * DAY_IN_SECONDS
  );
};

export const cookieOptions = {
  path: '/',
  httpOnly: true,
  secure: true,
  sameSite: 'strict',
  maxAge: getCookieMaxAge(),
};

export const getIsDebugEnv = () =>
  typeStringToBool(process.env.REACT_APP_DEBUG);

export const getGATrackingID = () => process.env.REACT_APP_GA_TRACKING_ID;

export const getGAArgs = () =>
  getIsDebugEnv()
    ? [getGATrackingID(), { cookieDomain: 'none' }]
    : [getGATrackingID()];

export const getRewardsHomeUrl = () => process.env.REACT_APP_REWARDS_HOME_URL;

export const getDefaultPartner = () =>
  process.env.REACT_APP_1TAP_DEFAULT_PARTNER;

export const getRecaptchaKey = () => process.env.REACT_APP_WMG_RECAPTCHA_KEY;

export const getIsMultiPartnerEnabled = () =>
  typeStringToBool(process.env.REACT_APP_1TAP_ENABLED);

export const getUseLandingPage = () => process.env.REACT_APP_USE_LANDING_PAGE;

export const getPhoneCountryCodesAllowed = () =>
  process.env.REACT_APP_PHONE_COUNTRY_CODES_ALLOWED.split(',');

export const getPartner = () => {
  const name = getIsMultiPartnerEnabled()
    ? PARTNER_DOMAIN_MAP[window.location.hostname]
    : getDefaultPartner();
  return { name, secret: PARTNER_SECRET_MAP[name] };
};
