import { PATHS, ALTNAMING } from 'constants/navigation';

const navigationTab = {
  sweepstakes: {
    currencyName: 'ust',
    items: [
      {
        label: 'sweeps',
        url: PATHS.sweepstakes,
      },
      {
        label: 'games',
        url: PATHS.games,
      },
      {
        label: 'gift cards',
        url: PATHS.giftCards,
      },
      {
        label: 'earn More',
        url: PATHS.moreTokens,
      },
      {
        label: '',
        url: PATHS.sweepstakesActivity,
      },
    ],
  },
  offers: {
    currencyName: 'ust',
    items: [
      {
        label: 'sweepstakes',
        url: PATHS.sweepstakes,
      },
      {
        label: 'Offers & Games',
        url: PATHS.offers,
      },
      {
        label: '',
        url: PATHS.sweepstakesActivity,
      },
    ],
  },
  profile: {
    items: [
      {
        label: '',
        url: PATHS.profile,
      },
    ],
  },
  activity: {
    currencyName: 'ust',
    items: [
      {
        label: '',
        url: PATHS.sweepstakesActivity,
      },
    ],
  },
};

// return specific nav tabs by navigation key from url
export const getNavigationTabs = (navigationKey) => {
  switch (navigationKey) {
    case PATHS.winners:
    case PATHS.activity:
    case PATHS.sweepstakes:
    case PATHS.privacy:
    case ALTNAMING.auctions:
    case ALTNAMING.affiliates:
    case ALTNAMING.awards:
    case PATHS.terms:
    case PATHS.spin:
    case PATHS.referral:
    case PATHS.moreTokens:
    case PATHS.charities:
    case PATHS.charityPayment:
    case PATHS.giftCards:
    case PATHS.donations:
    case PATHS.liveBids:
    case PATHS.onboarding:
    case PATHS.followArtists:
    case PATHS.followTracks:
    case PATHS.scratch:
    case PATHS.followed:
    case PATHS.games:
      return navigationTab.sweepstakes;
    case PATHS.charityUpsell:
      return navigationTab.activity;
    case PATHS.offers:
      return navigationTab.offers;
    case PATHS.profile:
      return navigationTab.profile;
    case '/sweeps/success':
      return navigationTab.sweepstakes;
    default:
      return {};
  }
};

export const pathsWithHiddenWallet = new RegExp(
  `(${PATHS.terms}|${PATHS.privacy})|${PATHS.onboarding}$/.+/.+$|${PATHS.sweepstakes}/w+/w.+$`,
  'g'
);

export const hiddenLoginPaths = [PATHS.exchange, PATHS.root];

export const excludeBannerPaths = [
  PATHS.signUp,
  PATHS.forgotPassword,
  PATHS.resetPassword,
  PATHS.profile,
];

export const excludeLoginBtnPaths = [
  PATHS.login,
  PATHS.signUp,
  PATHS.forgotPassword,
  PATHS.resetPassword,
  PATHS.profile,
];

export const staticPages = [
  PATHS.privacy,
  PATHS.terms,
  PATHS.support,
  PATHS.faq,
];
export const excludedRedirectPaths = [
  PATHS.exchange,
  PATHS.login,
  PATHS.signUp,
  PATHS.forgotPassword,
  PATHS.resetPassword,
];
