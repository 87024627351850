import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4, 2, 2, 2),
    flex: 1,
  },
  content: {
    width: '100%',
    maxWidth: 290,
    flex: 1,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));
