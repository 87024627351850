import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2.5),
  },
  searchContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  search: {
    width: '100%',
    maxWidth: 330,
  },
  emptyMessage: {
    width: '100%',
  },
  countrySelector: {
    marginLeft: theme.spacing(1),
    '& > button': {
      padding: theme.spacing(0.5, 1.1),
    },
    '& > ul': {
      maxHeight: 270,
    },
  },
}));
