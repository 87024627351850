/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  EmailShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share';

import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';

import { selectReferralContent } from 'redux/cms';

import isEmpty from 'lodash/isEmpty';
import copy from 'copy-to-clipboard';
import clsx from 'clsx';

import { useUserInfo } from 'hooks/user';
import { useEventTracker } from 'hooks/analytics';
import { useUserNavigation } from 'hooks/navigation';

import { PATHS } from 'constants/navigation';

import { ReactComponent as Checkmark } from 'assets/images/icons/checkmark-icon.svg';

import ShareButtonLabel from './components/ShareButtonLabel';

import useStyles from './styles';

const ReferralPage = () => {
  const [referralOptions, setReferralLink] = useState({});
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useUserNavigation();

  const handleGAEvent = useEventTracker();

  const {
    title,
    emailSubject,
    rewardsTitle,
    rewards,
    copyForShare,
    referralUrl,
  } = useSelector(selectReferralContent);
  const {
    user: { referral_code },
  } = useUserInfo();

  const handleReferralGAEvent = useCallback(
    (action) => {
      handleGAEvent('referral', action, referral_code);
    },
    [handleGAEvent, referral_code]
  );

  const classes = useStyles();

  const handleSnackbar = useCallback(() => {
    setIsLinkCopied((prev) => !prev);
  }, []);

  const copyToClipboard = useCallback(() => {
    copy(referralOptions.url);
    handleSnackbar();
    handleReferralGAEvent('clipboard');
  }, [handleSnackbar, handleReferralGAEvent, referralOptions.url]);

  useEffect(() => {
    if (referral_code && copyForShare) {
      const refLink =
        referralUrl ?? `${window.location.origin}${PATHS.sweepstakes}`;
      const { href } = new URL(`?ref_code=${referral_code}`, refLink);
      setReferralLink({
        message: `${copyForShare} ${href}`,
        url: href,
      });
    }
  }, [referral_code, copyForShare, setReferralLink, referralUrl]);

  if (isEmpty(referralOptions)) return null;

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {rewardsTitle}
        </Typography>
        {rewards && (
          <div className={classes.rewards}>
            {rewards.map((item) => (
              <div className={classes.reward} key={item}>
                <Checkmark />
                <Typography variant="h6" component="span">
                  {item}
                </Typography>
              </div>
            ))}
          </div>
        )}
        <div className={classes.buttons}>
          <div
            className={classes.referButton}
            onClick={() => handleReferralGAEvent('email')}
          >
            <EmailShareButton
              url={referralOptions.url}
              subject={emailSubject}
              body={copyForShare}
            >
              <ShareButtonLabel icon="email" label="Email" />
            </EmailShareButton>
          </div>
          <div
            className={clsx(classes.referButton, classes.mobileOnly)}
            onClick={() => handleReferralGAEvent('sms')}
          >
            <a href={`sms:?&body=${referralOptions.message}`}>
              <ShareButtonLabel icon="sms" label="SMS" />
            </a>
          </div>
          <div
            className={classes.referButton}
            onClick={() => handleReferralGAEvent('facebook')}
          >
            <FacebookShareButton url={referralOptions.url} quote={copyForShare}>
              <ShareButtonLabel icon="facebook" label="Facebook" />
            </FacebookShareButton>
          </div>
          <div
            className={classes.referButton}
            onClick={() => handleReferralGAEvent('twitter')}
          >
            <TwitterShareButton title={copyForShare} url={referralOptions.url}>
              <ShareButtonLabel icon="twitter" label="Twitter" />
            </TwitterShareButton>
          </div>
          <Button
            onClick={copyToClipboard}
            className={classes.referButton}
            data-cy="referral-copy-link"
            data-copy-url={referralOptions.url}
          >
            <ShareButtonLabel icon="clipboard" label="Copy Link" />
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isLinkCopied}
          onClose={handleSnackbar}
          autoHideDuration={2000}
          className={classes.snackbar}
        >
          <SnackbarContent
            className={classes.snackbarContent}
            message={<Typography variant="h6">Link copied!</Typography>}
          />
        </Snackbar>
      </div>
    </div>
  );
};

export default ReferralPage;
