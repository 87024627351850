import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    margin: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
  image: {
    margin: theme.spacing(2, 0),
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
}));
