import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  upsellTitle: {
    width: '100%',
    textAlign: 'center',
    margin: theme.spacing(3, 0, 2, 0),
    fontWeight: 'bold',
  },
  carousel: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    '& .BrainhubCarousel__arrows': {
      backgroundColor: theme.palette.white.main,
    },
    '& .BrainhubCarousel__arrows span': {
      borderColor: theme.palette.primary.main,
    },
    '& .BrainhubCarousel__arrows:hover:enabled': {
      backgroundColor: theme.palette.white.main,
    },
    '& .BrainhubCarousel__arrows:hover:enabled span': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
