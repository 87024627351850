import React from 'react';
import { useSelector } from 'react-redux';
import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import SocialLoginButton from 'components/SocialLoginButton';

const FacebookButton = ({ className, onBtnClick, isLoading }) => {
  const isFacebookEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.facebookEnabled)
  );
  const appId = useSelector(selectConfigProperty(CONFIG_KEYS_MAP.facebookId));
  const version = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.facebookApiVersion)
  );
  const redirectUri = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.facebookRedirectUri)
  );

  if (!isFacebookEnabled) {
    return null;
  }

  return (
    <FacebookLogin
      appId={appId}
      cookie
      fields="email"
      callback={onBtnClick}
      version={version}
      autoLoad={false}
      redirectUri={redirectUri}
      disableMobileRedirect
      render={({ onClick, isProcessing, isSdkLoaded }) => (
        <SocialLoginButton
          onClick={onClick}
          className={className}
          disabled={isProcessing || !isSdkLoaded || isLoading}
          provider="Facebook"
        />
      )}
    />
  );
};

export default FacebookButton;
