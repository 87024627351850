import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

const getNavigation = (state) => get(state, 'navigation', {});

export const selectBackButtonProperties = createSelector(
  [getNavigation],
  ({ backUrl, showBackButton, callback, hideAllItems }) => ({
    backUrl,
    showBackButton,
    callback,
    hideAllItems,
  })
);
