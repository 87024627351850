import React from 'react';

import Modal from 'components/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useCms } from 'hooks/cms';

import useStyles from './styles';

const SignUpPopup = ({ isModalOpened, toggleModal, popupType }) => {
  const classes = useStyles();
  const popup = useCms({ key: 'signUpPopups', path: popupType });

  return (
    <Modal open={isModalOpened} toggleModal={toggleModal}>
      <img src={popup.imgUrl} alt={popup.headline} className={classes.image} />
      <Typography variant="h5" className={classes.title}>
        {popup.headline}
      </Typography>
      <Typography component="div" variant="h6" className={classes.text}>
        {popup.copy}
      </Typography>
      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        size="large"
        onClick={toggleModal}
      >
        Let&apos;s go!
      </Button>
    </Modal>
  );
};

export default SignUpPopup;
