import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectConfigProperty } from 'redux/config';

import Grid from '@material-ui/core/Grid';

import spinImageBrave from 'assets/images/games/SpinBrave.png';
import scratchImageBrave from 'assets/images/games/ScratchBrave.png';
import spinImageWmg from 'assets/images/games/SpinWmg.png';
import scratchImageWmg from 'assets/images/games/ScratchWmg.png';

import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { getPartner } from 'utils/config';
import GamesItem from '../components/GamesItem';

import useStyles from './styles';

const GamesListPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentPartner = getPartner().name;
  const isWmgPartner = currentPartner === 'wmg';
  const isSpinPageEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spinPageEnabled)
  );

  const isScratchPageEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.scratchPageEnabled)
  );

  const goToSpin = () => {
    dispatch(push(PATHS.spin));
  };
  const goToScratch = () => {
    dispatch(push(PATHS.scratch));
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      {isSpinPageEnabled && (
        <GamesItem
          title="Spin & Win!"
          imgSrc={isWmgPartner ? spinImageWmg : spinImageBrave}
          onClick={goToSpin}
        />
      )}
      {isScratchPageEnabled && (
        <GamesItem
          title="Lucky Scratch!"
          imgSrc={isWmgPartner ? scratchImageWmg : scratchImageBrave}
          onClick={goToScratch}
        />
      )}
    </Grid>
  );
};

export default GamesListPage;
