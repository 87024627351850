import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  wrapper: {
    maxWidth: 300,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  text: {
    lineHeight: 1.8,
  },
  description: {
    margin: theme.spacing(3, 0),
    textAlign: 'left',
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  disclaimer: {
    marginTop: theme.spacing(2),
    lineHeight: '1.8em',
  },
  btn: {
    width: '100%',
    maxWidth: '300px',
    margin: theme.spacing(1, 0, 1),
    fontSize: '0.9rem',
    textDecoration: 'none',
  },
  btnAccent: {
    backgroundColor: theme.palette.accent.main,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
}));
