import React, { useCallback, useEffect, memo } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { placeBidItem, selectBidStatus, clearBidError } from 'redux/liveBids';
import { selectLoginPath, selectPennyAuctionBaseUrl } from 'redux/config';

import Grid from '@material-ui/core/Grid';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useWalletInfo } from 'hooks/wallet';
import usePennyAuctionSockets from 'hooks/usePennyAuctionSockets';

import { toHHMMSS } from 'utils/formatting';

import { PATHS } from 'constants/navigation';

import ProductDetails from '../ProductDetails';

import useStyles from './styles';

const BidDetails = memo(
  ({
    bidDetails,
    toggleIntroModal,
    isLoggedIn,
    handleShowGetStartedPopUp,
    auctionUsername,
    bidId,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { isBidProcessing, bidError } = useSelector(selectBidStatus);
    const pennyAuctionBaseURL = useSelector(selectPennyAuctionBaseUrl);
    const { shouldFlash, calculateDiff } = usePennyAuctionSockets();
    const [ust] = useWalletInfo('ust');
    const tokensAmount = Number(ust?.amount);

    const loginPath = useSelector(selectLoginPath);

    useEffect(() => {
      if (bidError) {
        setTimeout(() => {
          dispatch(clearBidError());
        }, 2500);
      }
    }, [dispatch, bidError]);

    const goToLogin = useCallback(() => {
      dispatch(push(`${loginPath}?redirect_uri=${PATHS.liveBids}/${bidId}`));
    }, [dispatch, loginPath, bidId]);

    const getButtonLabel = useCallback(() => {
      if (!isLoggedIn) return 'LOG IN';
      if (isBidProcessing) {
        return <CircularProgress size={20} />;
      }
      return 'BID NOW';
    }, [isLoggedIn, isBidProcessing]);

    const onBidNowButtonClick = useCallback(() => {
      if (!isLoggedIn) {
        goToLogin();
      } else if (!auctionUsername) {
        handleShowGetStartedPopUp();
      } else {
        dispatch(
          placeBidItem({
            auction_name: bidDetails.title,
            auction_id: Number(bidId),
          })
        );
      }
    }, [
      auctionUsername,
      handleShowGetStartedPopUp,
      goToLogin,
      isLoggedIn,
      bidId,
      dispatch,
      bidDetails.title,
    ]);

    const getBidderInfo = (isSold, bidder) => {
      if (isSold) {
        return `Winner: ${bidder}`;
      }
      if (!bidder) {
        return 'No current bids';
      }
      return `High bidder: ${bidDetails.bidder}`;
    };

    const getPriceInfo = (isSold, price, bidder) => {
      if (isSold) {
        return `Winning bid: $${price}`;
      }
      if (bidder) {
        return `Current bid: $${price}`;
      }
      return `Current Price: $${price}`;
    };

    const diff = calculateDiff(bidDetails);

    return (
      <Grid item container className={classes.wrapper} justify="center">
        <Grid item container className={classes.item} justify="center">
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6" component="p">
                {`Value: $${bidDetails.value}`}
              </Typography>
            </Grid>
            <Grid item onClick={toggleIntroModal}>
              <InfoOutlinedIcon className={classes.infoIcon} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              className={classes.imgContainer}
            >
              <img
                src={`${pennyAuctionBaseURL}/uploads/auction/${bidDetails.avatar}`}
                alt={bidDetails.title}
                className={clsx(classes.img, {
                  [classes.hiddenImg]: bidDetails.isSold,
                })}
              />
              {bidDetails.isSold && (
                <Typography color="error" className={classes.sold}>
                  SOLD
                </Typography>
              )}
            </Grid>
            {bidDetails.isEnded ? (
              <Typography variant="h6" component="p">
                Ended
              </Typography>
            ) : (
              <Typography
                variant="h4"
                component="p"
                className={clsx({
                  [classes.redTime]: diff > 0 && diff <= 15,
                  [classes.flashAnimation]: shouldFlash(bidDetails),
                })}
              >
                {toHHMMSS(diff)}
              </Typography>
            )}
            <Typography className={classes.currentBid}>
              {getPriceInfo(
                bidDetails.isSold,
                bidDetails.price,
                bidDetails.bidder
              )}
            </Typography>
            <Typography variant="h6" component="p">
              {getBidderInfo(bidDetails.isSold, bidDetails.bidder)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Typography className={classes.hint} color="error">
            {bidError}
          </Typography>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.bidBtn}
            disabled={bidDetails.isEnded || isBidProcessing || !tokensAmount}
            onClick={onBidNowButtonClick}
          >
            {getButtonLabel()}
          </Button>
          {isLoggedIn && (
            <Link
              to={PATHS.moreTokens}
              component={RouteLink}
              className={classes.tokensLink}
            >
              <Button
                size="large"
                variant={tokensAmount ? 'outlined' : 'contained'}
                color={tokensAmount ? 'default' : 'secondary'}
                className={classes.tokensBtn}
                fullWidth
              >
                Get More Tokens
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item className={classes.productDetails}>
          <ProductDetails details={bidDetails.productDetails} />
        </Grid>
      </Grid>
    );
  }
);

export default BidDetails;
