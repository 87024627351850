import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { storage } from 'services/storage';

import { PATHS } from 'constants/navigation';

import useStyles from './styles';

const CharityDonationInfo = ({ name, donation, tokens }) => {
  const classes = useStyles();
  const enteredSweepstake = storage.local.get('enteredSweepstake');
  const backLink = enteredSweepstake ? PATHS.charityUpsell : PATHS.donations;
  return (
    <div className={classes.paymentInfo}>
      <Typography variant="h6" component="p" data-cy="charity-donation">
        Donation to <span data-cy="charity-name">{name}</span>:&nbsp;
        <span data-cy="donation-amount">${donation}</span>
      </Typography>
      <Typography
        variant="h6"
        component="p"
        data-cy="tokens-reward"
      >{`Tokens: ${tokens}`}</Typography>
      <Typography component="p">
        To chage this amount,&nbsp;
        <Link component={RouterLink} to={backLink} className={classes.link}>
          go back
        </Link>
      </Typography>
    </div>
  );
};

export default CharityDonationInfo;
