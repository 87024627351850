import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './styles';

const CardSelector = ({
  cardId,
  disabled,
  availableCards,
  handleCardSelect,
}) => {
  const classes = useStyles();

  if (!availableCards) return null;

  return (
    <TextField
      select
      className={classes.cardSelectInput}
      fullWidth
      label="Available cards"
      variant="outlined"
      value={cardId}
      size="small"
      disabled={disabled}
      onChange={handleCardSelect}
      defaultValue=""
    >
      {availableCards.map((c) => (
        <MenuItem value={c.id} key={c.id}>
          {c.currency} - {+Number(c.balance).toFixed(4)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CardSelector;
