import { call, put, takeLatest, delay } from 'redux-saga/effects';

import {
  fetchScratchGame as _fetchScratchGame,
  fetchScratchGameReward as _fetchScratchGameReward,
} from 'services/api/scratchGame';
import { SCRATCH_PAUSE } from 'constants/scratchGame';
import {
  fetchScratchGame,
  fetchScratchGameSuccess,
  fetchScratchGameFailure,
  fetchScratchGameRewardSuccess,
  fetchScratchGameRewardFailure,
  fetchScratchGameReward,
} from './slice';
import { getWallet } from '../wallet';

function* getScratchGameSaga({ payload: walletId }) {
  try {
    const { data } = yield call(_fetchScratchGame, walletId);
    yield put(fetchScratchGameSuccess(data));
  } catch (error) {
    yield put(fetchScratchGameFailure(error));
  }
}

function* sendScratchGameSaga({ payload: orderId }) {
  try {
    const { data } = yield call(_fetchScratchGameReward, orderId);
    yield delay(SCRATCH_PAUSE);
    yield put(fetchScratchGameRewardSuccess(data.popup_data));
    yield put(getWallet());
  } catch (error) {
    yield put(fetchScratchGameRewardFailure(error));
  }
}

export function* scratchGameSaga() {
  yield takeLatest(fetchScratchGame, getScratchGameSaga);
  yield takeLatest(fetchScratchGameReward, sendScratchGameSaga);
}
