import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    letterSpacing: '0.05em',
    margin: theme.spacing(2, 0),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  submitBtn: {
    width: '100%',
    textTransform: 'initial',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));
