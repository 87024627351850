import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const Loader = ({ loaderText }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.root}
      alignItems="center"
      container
      justify="center"
      direction="column"
    >
      <Grid item className={classes.spinner}>
        <CircularProgress size={80} color="secondary" />
      </Grid>
      <Typography color="textSecondary">{loaderText}</Typography>
    </Grid>
  );
};

Loader.defaultProps = {
  loaderText: 'Loading...',
};

Loader.propTypes = {
  loaderText: PropTypes.string,
};

export default Loader;
