import { object, string } from 'yup';
import { defaultTopLevelDomains } from 'mailcheck';
import Filter from 'bad-words';

const filter = new Filter();

const phoneRegExp = /^(\([0-9]{3}\)|[0-9]{3})[0-9]{3}[0-9]{4}$/;

// added modern top level domain that mailcheck doesn't have
export const topLevelDomains = [...defaultTopLevelDomains, 'io'];

export const authShema = object().shape({
  email: string().required('Field is required.').email('Invalid email.'),
  password: string()
    .required('Field is required.')
    .min(8, 'Minimum 8 characters.'),
});

export const onboardingAuthShema = object().shape({
  first_name: string().required('Field is required.'),
  last_name: string().required('Field is required.'),
  email: string().required('Field is required.').email('Invalid email.'),
  password: string()
    .required('Field is required.')
    .min(8, 'Minimum 8 characters.'),
});

export const pennyAuctionUserNameShema = object().shape({
  name: string()
    .required('Field is required.')
    .test('name', 'Invalid name, try again', (val) => !filter.isProfane(val)),
});

export const phoneNumberShema = object().shape({
  number: string().test('phone_number', 'Invalid phone number', (value) =>
    phoneRegExp.test(value)
  ),
});
