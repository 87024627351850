import React from 'react';
import { useSelector } from 'react-redux';
import { selectDailyJackpotInfo } from 'redux/spinner';
import PropTypes from 'prop-types';

import parse from 'html-react-parser';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Modal from 'components/Modal';

import { useStyles } from './styles';

const JackpotInfoModal = ({ open, toggleModal, submit }) => {
  const classes = useStyles();

  const jackpotInfo = useSelector(selectDailyJackpotInfo);

  if (!jackpotInfo) return null;

  const {
    heading,
    image,
    background,
    secondary_text,
    button_text,
  } = jackpotInfo;

  return (
    <Modal
      open={open}
      toggleModal={toggleModal}
      showClose
      data-cy="jackpot-modal"
    >
      <div className={classes.wrapper}>
        <Typography variant="h4" className={classes.title}>
          {heading}
        </Typography>
        <img src={image} alt="Jackpot" className={classes.image} />
        <img src={background} alt="Jackpot BG" className={classes.bg} />
        <Typography variant="h5" className={classes.subTitle}>
          {secondary_text && parse(secondary_text)}
        </Typography>
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          onClick={submit}
        >
          {button_text}
        </Button>
      </div>
    </Modal>
  );
};

JackpotInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default JackpotInfoModal;
