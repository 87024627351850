import { call, takeEvery } from 'redux-saga/effects';

import { sendGAEvent, sendPageView } from 'services/analytics';
import { trackEvent, trackPageView } from './slice';

function* trackEventSaga({ payload: { event } }) {
  yield call(sendGAEvent, event);
}

function* trackPageViewSaga({ payload: { location } }) {
  yield call(sendPageView, location);
}

export function* analyticsSaga() {
  yield takeEvery(trackEvent, trackEventSaga);
  yield takeEvery(trackPageView, trackPageViewSaga);
}
