import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import { formatFinishSweepDate } from 'utils/formatting';

import Loader from 'components/Loader';

import { fetchSweeps, selectSweeps, selectSweepstakeById } from 'redux/sweeps';

import { useCmsHtml } from 'hooks/cms';
import { Title, Text, Link, StyledText } from './components/TextComponents';

import useStyle from './styles';

const defaultRules = {
  experience_type: 'Product Sweeps',
  sponsor_name: 'TAP NETWORK',
  sponsor_address: '340 S LEMON AVE #6858, WALNUT, CA 91789, UNITED STATES',
  prize_details:
    'For prize details, please refer to the sweepstake entry page.',
  promotional_partner_conditions: '',
  international: false,
  travel: false,
};

const RulesPage = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const {
    params: { sweepId, eventId },
  } = match;

  const sweepstake = useSelector(selectSweepstakeById(sweepId));

  const PromotionalPartnerConditions = useCmsHtml(
    'promotionalPartnerConditions'
  );

  const event = useMemo(() => {
    if (sweepstake) {
      const targetEvent = sweepstake.events.find(
        (item) => item.id === Number(eventId)
      );
      return {
        ...targetEvent,
        ...defaultRules,
        ...(!isEmpty(sweepstake.data.rules)
          ? sweepstake.data.rules
          : defaultRules),
      };
    }
    return {};
  }, [sweepstake, eventId]);

  const { isLoading } = useSelector(selectSweeps);

  useEffect(() => {
    dispatch(fetchSweeps());
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {sweepstake && (
          <>
            <Title variant="h4" centeredT>
              TAP NETWORK SWEEPSTAKES OFFICIAL RULES
            </Title>
            <div className={classes.textContainer}>
              <Text>
                By entering, I acknowledge that I have read and accept the
                following Sweepstakes Official Rules,{' '}
                <Link href={`${process.env.PUBLIC_URL}/terms`}>
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link href={`${process.env.PUBLIC_URL}/privacy`}>
                  Privacy Policy
                </Link>
                {sweepstake.terms && (
                  <>
                    {', '}
                    as well as the record label{' '}
                    <Link href={sweepstake.terms}>Privacy Policy</Link>
                  </>
                )}
                .
              </Text>
              <Text bold>
                NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN A PRIZE. A
                PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. SWEEPSTAKES
                ONLY OPEN TO PERSONS WHO ARE AT LEAST 18 YEARS OLD AT THE TIME
                OF ENTRY. VOID WHERE PROHIBITED.
              </Text>
              <Text>
                ODDS OF WINNING ARE DEPENDENT ON THE NUMBER OF ELIGIBLE ENTRIES
                RECEIVED. VOID WHERE PROHIBITED BY LAW. CERTIFICATE OF
                ELIGIBILITY / RELEASE OF LIABILITY / PRIZE ACCEPTANCE AGREEMENT
                WILL BE REQUIRED. ALL DISPUTES WILL BE RESOLVED SOLELY BY
                BINDING ARBITRATION AND ENTRANTS WAIVE THE ABILITY TO BRING
                CLAIMS IN A CLASS ACTION FORMAT.
              </Text>
              <Title>SWEEPSTAKES OFFER:</Title>
              <Title variant="h5" centered className={classes.sweepsName}>
                {sweepstake.name}
              </Title>
              <Title>ENTRY PERIODS:</Title>
              <Text>
                {`This TAP Network (“Administrator”) sweepstake (“Sweepstake” or “Sweepstakes”), sponsored by ${
                  event.sponsor_name
                }
              (“Sponsor”), and offered to the users of partner programs including Uber Technologies, Inc. (“Promotional Partner”) begins on ${format(
                new Date(event.entry_period_start),
                'MMMM d, y'
              )}
              (“Entry Start Date”) at 12:00:01
              a.m. Eastern Standard or Daylight Time (“ET”) and ends on
              ${formatFinishSweepDate(new Date(event.entry_period_end), {
                timeZone: 'UTC',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}

              (“Entry End Date”) at 11:59:59 p.m. Pacific Standard
              or Daylight Time (“PT”) (the “Sweepstakes Period”). Administrator's
              designated computer will serve as the official timekeeper for the
              Sweepstakes. Any attempted form of entry into the Sweepstakes
              other than as described herein is void.`}
              </Text>

              <Title>SWEEPSTAKE ENTITIES:</Title>
              <Text>
                Administrator, Sponsor, Promotional Partners, and their
                subsidiaries, and all of their respective partners, parent
                companies, divisions, subsidiaries, affiliates, trustees,
                advertising, promotion and public relations agencies, are
                collectively referred to as the “Sweepstakes Entities”.
              </Text>
              <Title>ELIGIBILITY:</Title>
              <Text>
                BY ENTERING, YOU REPRESENT THAT YOU ARE AT LEAST EIGHTEEN (18)
                YEARS OLD AT TIME OF ENTRY. By entering into the Promotion, you
                (“Entrant”) represent and warrant that you are age 18 and have
                the legal right to consent to these Official Rules;
              </Text>
              <Text>
                {`The Sweepstake is open to all natural persons who are residents of the United States, are 18 or older on  ${format(
                  new Date(),
                  'MMMM d, y'
                )} at 00:01 AM Eastern Daylight Time (EDT), and are registered users of the Promotional Partner’s platform and rewards program with the exception of employees, officers and directors of the Sweepstake Entities defined in these Official Rules, and members of their respective immediate families and/or persons with whom any of the foregoing are domiciled.`}
              </Text>
              {event.international && (
                <>
                  <Title>FOR INTERNATIONAL SWEEPS ONLY:</Title>
                  <Text>
                    <StyledText bold>Non U.S. Entries.</StyledText>
                    {`The Sweepstake is open to legal residents of the fifty (50) United States and the District of Columbia, and most countries around the world, but they are void in all countries on the United States list of embargoed countries, as well as in countries where local laws prohibit entry into sweepstakes. Any country where it is legal to participate in sweepstakes (but excluding: Austria, Belarus, Belgium, Brazil, China, Cuba, Czech Republic, France, Greece, Hong Kong, India, Iran, Iraq, Ireland, Italy, Japan, Lebanon, Mexico, Nicaragua, North Korea, Portugal, Russia, South Korea, Spain, Sweden, Syria, Taiwan, Venezuela, Yemen)  are 18 or older on
                ${format(
                  new Date(),
                  'MMMM d, y'
                )} at 00:01 AM Eastern Daylight Time (EDT), and are registered users of the Promotional Partner’s platform and rewards program, with the exception of employees, officers and directors of the Sweepstake Entities defined in these Official Rules, and members of their respective immediate families and/or persons with whom any of the foregoing are domiciled.`}
                  </Text>
                </>
              )}
              <Title>HOW TO ENTER:</Title>
              <Text>
                {`Visit the rewards program in the Promotional Partner’s App and/or website and follow instructions, or visit ${process.env.PUBLIC_URL} and follow the instructions. All users must create a MyTAPrewards (“MTR”) account (“Account” or “MTR Account”), or log-in. Creating an Account is free of charge. To enter the Sweepstakes using an MTR rewards token (“Token” or “Tokens”) you must have a verified Account and at least one valid Token, unless otherwise indicated in the description of the Sweepstake. You may redeem rewards points from Promotional Partners to Tokens. See ALTERNATE METHOD OF ENTRY for details on how to enter without redeeming rewards points or currency. All online entries must be received by Entry End Date.`}
              </Text>
              <Text>
                Administrator and Sponsor may, from time to time by various
                methods, offer additional opportunities to receive a Sweepstakes
                entry online without redeeming any rewards points or currency.
              </Text>
              <Text>
                Entry into one Sweepstake drawing pool does not carry over into
                entry into any other Sweepstakes drawing pool. Entrants may
                enter multiple times into the same Sweepstake by using multiple
                Tokens, however, multiple Entrants are not permitted to share
                the same Account. Any attempt by any Entrant to use
                multiple/different email addresses, identities, registrations
                and logins, or any other methods will void that Entrant&apos;s
                entries and Entrant may be disqualified.
              </Text>
              <Text>
                Use of any automated system to participate is prohibited and
                will result in disqualification. In the event of
                disqualification, any Sweepstake prize (“Prize” or “Prizes”)
                that Entrant might have been otherwise entitled to may be
                awarded to an alternate winner selected by the Administrator at
                the Administrator’s sole discretion.
              </Text>
              <Text>
                If you do not have Internet access via a personal computer, your
                local library or Internet café may be able to provide access to
                the Internet and a number of Internet service providers and
                other companies offer free email accounts.
              </Text>
              <Text>
                If you do not have Internet access via a personal computer, your
                local library or Internet café may be able to provide access to
                the Internet and a number of Internet service providers and
                other companies offer free email accounts.
              </Text>
              <Title>ALTERNATE METHOD OF ENTRY:</Title>
              <Text>
                {`To enter without purchase and without redeeming any rewards points or currency, you may obtain up to one entry (“Alternate Entry”) per eligible Sweepstakes by sending a 3 inch x 5 inch card or piece of paper in an envelope, on which you hand-print your full name, complete address, phone number with area code, date of birth, the email address associated with your MTR Account, and the name of the Sweepstakes which you wish to be entered into and mail it to: ${sweepstake.name}, c/o TAP Network, 340 S LEMON AVE #6858, WALNUT, CA 91789, UNITED STATES. Incomplete information on mailed cards shall render such entry ineligible.`}
              </Text>
              <Text>
                Requests must be postmarked by and received by Entry End Date.
              </Text>
              <Text>
                Only one (1) request per card or envelope allowed. Entries
                mechanically reproduced or made in any fashion other than as
                specified herein are void. Entries submitted by script, macro or
                other automated or mechanical means, entries by any means which
                subvert the entry process and entries made in any fashion other
                than as specified herein are void. All entries become property
                of the Administrator and Sponsor and will not be returned.
              </Text>
              <Title>CONDITIONS:</Title>
              <Text>
                Employees, officers and directors of the Sweepstake Entities,
                and their respective officers, directors, employees, agents,
                independent contractors, representatives and agencies are not
                eligible to participate in the Sweepstakes and/or win a Prize.
                Additionally, immediate family members of such employees and
                persons living in the same household as such employees (whether
                related or not) are not eligible to win. For the purposes of
                this Sweepstakes, “immediate family members” is defined as
                spouse, domestic partner, mother, father, grandmother,
                grandfather, children, siblings and their spouses and “persons
                living in the same household” shall mean people who share the
                same residence at least three (3) months a year, whether legally
                related or not.
              </Text>
              <Text>
                By entering this Sweepstakes, Entrants agree to be bound by
                these Official Rules and the decisions of the Administrator
                and/or Sponsor, which are final and binding in all respects and
                cannot be challenged or appealed.
              </Text>
              <Text>
                Unless specified as US only, Non-US residents may enter
                sweepstakes and acknowledge and agree that digital gift cards
                may be delivered internationally but can only be used with U.S.
                based stores or e-commerce sites, and other Prizes may be
                delivered internationally but the winner is responsible for
                shipping and handling costs, as well as any custom costs imposed
                by the destination country on arrival. Certain products may be
                prohibited or restricted from delivery into certain countries.
              </Text>
              <Text>
                If your country or local law prohibits sweepstakes entries,
                please do not redeem for sweepstake entries or participate in
                any sweepstakes. Any submission from residents of prohibited
                countries will be considered void. Residents of countries that
                may not participate in sweepstakes include, but are not limited
                to: Belgium, Brazil, Cuba, India, Ireland, Sweden, Australia,
                France, Germany, Italy, Mexico, Spain, Venezuela, Russia.
                Winners will be required to certify country of origin at the
                time of claiming a Prize.
              </Text>
              {event.experience_type === 'Live Experience' && (
                <>
                  <Title>
                    Conditions for entry into a Live Experience Sweeps:
                  </Title>
                  <Text>
                    Entrants acknowledge that travel to/from the live experience
                    is the sole responsibility of the winner. Administrator
                    and/or Sponsor does not provide transportation and lodging
                    to live events unless otherwise noted in the Prize
                    description. Instructions for your Prize delivery will be
                    provided after completing the winner’s affidavit.
                  </Text>
                </>
              )}
              {event.experience_type === 'Virtual Experience' && (
                <>
                  <Title>
                    Conditions for entry into a Virtual Experience Sweeps:
                  </Title>
                  <Text>
                    Entrants acknowledge that the winner is responsible for
                    providing internet connection in order to receive the
                    virtual experience as Prize and must download and install
                    any software required to participate in the virtual
                    experience. Administrator and/or Sponsor does not claim
                    responsibility for any 3rd party software, internet or
                    wireless service charges or equipment needed to receive the
                    Prize. Instructions for your Prize delivery will be provided
                    after completing the winner&apos;s affidavit.
                  </Text>
                </>
              )}
              {event.experience_type === 'Product Sweeps' && (
                <>
                  <Title>Conditions for entry into a Product Sweeps:</Title>
                  <Text>
                    Entrants acknowledge that Administrator and/or Sponsor will
                    ship the winner the product detailed in this sweep. Entrant
                    is responsible for providing a correct physical mailing
                    address in the winner’s affidavit and any errors or
                    omissions at no fault of the Administrator and/or Sponsor
                    will result in the cancellation of the Prize. Instructions
                    for your Prize delivery will be provided after completing
                    the winner’s affidavit.
                  </Text>
                </>
              )}
              <Title>ADDITIONAL CONDITIONS FOR ENTRY:</Title>
              <Text>When you enter:</Text>
              <ul>
                <li>
                  <Text>
                    You must log in to your MTR account at{' '}
                    <Link href={process.env.PUBLIC_URL}>
                      {' '}
                      {process.env.PUBLIC_URL}
                    </Link>{' '}
                  </Text>
                </li>
                <li>
                  <Text>
                    Select the corresponding Sweepstakes you want to enter and
                    select how many Tokens you want to use for entry.
                  </Text>
                </li>
              </ul>
              <Text>
                Those Entrants who do not fill in all the required fields on the
                Account page or agree to the Official Rules will be
                disqualified.
              </Text>
              <Text>
                Sponsor will accept only up to a maximum of one thousand (1,000)
                qualifying entries per Entrant in this Sweepstakes for each
                Entry Period during the Sweepstakes Period. Non-winning entries
                will not rollover to subsequent Entry Periods or any other
                Sweepstakes. Each qualifying entry will be entered into the
                drawing for a chance to win the Prize.
              </Text>
              <Text>
                {`Submission of false or misleading Personal Information of an Entrant will be grounds for disqualification of entry. Entries generated by a script, macro or other automated means will be disqualified. Illegible, damaged, lost, late or incomplete entries, as determined by the Sponsor in its sole discretion, will be disqualified. Sponsor will not deem proof of submission of an entry as proof of receipt of an entry. Entries submitted by entrants who do not meet the eligibility requirements (including all requirements with respect to age and residence) are void. Those who do not follow all of the instructions, provide the required information in their entry form, or abide by these Official Rules or other instructions of Sponsor may be disqualified. It is the sole responsibility of the Entrant to notify Sponsor in writing of any changes to Personal Information, including email addresses, by either updating Entrant’s Account online before the Entry End Date, or mailing such notice to: ${sweepstake.name}, c/o TAP Network, 340 S LEMON AVE #6858, WALNUT, CA 91789, UNITED STATES. All entries and related correspondence become property of Sponsor and will not be returned. Entrants are limited to nine hundred and ninety-nine (999) online entries and one mailing entry per sweepstakes. Additional entries will be disqualified as will incomplete entries and entries not received during the Sweepstakes Period.`}
              </Text>
              <Text>
                Administrator and Sponsor reserves the right to contact Entrants
                whose email address is submitted as part of this Sweepstakes
                with regard to their participation or promotional material.
                Entrants may choose to opt-out of future notifications and
                communications. Additional personally-identifying information of
                Entrants including but not limited to mailing address may be
                collected and used by Sponsor and Administrator only with
                respect to this Sweepstakes, and shall be periodically purged
                after the end of the Sweepstakes and the fulfillment of Prizes,
                as defined herein, except to the extent Sponsor and
                Administrator is required by law to maintain and/or disclose
                such information.
              </Text>
              <Text>
                SPONSOR DOES NOT SEEK TO USE, DISSEMINATE OR COLLECT PROHIBITED
                PERSONALLY IDENTIFIABLE INFORMATION THROUGH THE PROMOTION, AND
                ANY ENTRY THAT INCLUDES PROHIBITED PERSONALLY IDENTIFIABLE
                INFORMATION IS SUBJECT TO DISQUALIFICATION AND DESTRUCTION
                SHOULD SPONSOR OBTAIN ACTUAL KNOWLEDGE THAT SAID ENTRY INCLUDES
                PROHIBITED PERSONALLY IDENTIFIABLE INFORMATION.
              </Text>
              <Text>
                &quot;Prohibited Personally Identifying Information&quot;
                includes the following information of an individual under 18
                years of age: first and last names; home or physical addresses,
                including street name and city or town name; online contact
                information, such as email address or anything that permits
                direct contact with a person online; screen or user names;
                telephone numbers; social security numbers; photograph, video,
                or audio file that contains child’s image or voice; or
                geolocation information able to identify street name or city or
                town name.
              </Text>
              <Text>
                ENTRANTS ACKNOWLEDGE AND AGREE THAT THEY WILL NOT SUBMIT IN ANY
                FORM OR MANNER ANY PERSONALLY IDENTIFIABLE INFORMATION OF A
                MINOR CHILD YOUNGER THAN 18 YEARS OF AGE AT THE TIME OF ENTRY.
              </Text>
              <Title>ADDITIONAL PROMOTIONAL PARTNER CONDITIONS:</Title>
              <Text>
                <PromotionalPartnerConditions />
                {`${event.promotional_partner_conditions}`}
              </Text>
              <Title>SPONSOR:</Title>
              <Text>{`${event.sponsor_name}, ${event.sponsor_address}`}</Text>
              <Title>ADMINISTRATOR:</Title>
              <Text>
                TAP NETWORK, 340 S LEMON AVE #6858, WALNUT, CA 91789, UNITED
                STATES (“Administrator”).
              </Text>
              <Title>WINNER SELECTION PROCESS:</Title>
              <Text>
                Following the conclusion of the Sweepstakes Period,
                Administrator will select the Prize winners as defined below.
                Sponsor will conduct one (1) random drawing for one (1) Prize,
                with each random drawing consisting of all entries received with
                the correct information for each entry in accordance with these
                Official Rules. Sponsor will select the Prize winner
                automatically at the end of the Sweepstakes Entry Period.
              </Text>
              <Text>
                If a potential sweepstakes winner resides in Canada, in order to
                be declared a winner, selected entrant must first correctly
                answer an unaided mathematical skill-testing question, to be
                administered by email, and have his/her entry verified by
                Sponsor.
              </Text>
              <Text>
                If a potential Sweepstakes winner is found ineligible or fails
                to comply with these Official Rules, that Entrant’s claim to a
                Prize is forfeited and an alternate qualifying and eligible
                Entrant may be selected, at Sponsor’s sole discretion.
              </Text>
              <Text>All Prizes legitimately claimed will be awarded.</Text>
              <Title>PRIZES:</Title>
              <Text>
                {`Items referred to herein are collectively defined as the “Prize” or “Prizes”. The Approximate Retail Value (“ARV”) of each Prize is $${parseFloat(
                  event.prize_value
                ).toFixed(2)}. Total ARV of all Grand Prizes: $${parseFloat(
                  event.grand_prize_value
                ).toFixed(2)}`}
              </Text>
              <Title>ADDITIONAL PRIZE INFORMATION:</Title>
              <Text>{`${event.prize_details}`}</Text>
              <Text>
                The Prize consists only of the elements expressly set forth
                above, and no other elements or expenses are included in the
                Prize and all such expenses are the sole responsibility of the
                Prize winners All expenses not specifically mentioned herein are
                not included in any Prize and are solely the winner’s
                responsibility.
              </Text>
              <Text>
                Prize winners are solely responsible for the reporting and
                payment of any and all federal, state and local taxes as well as
                any other costs and expenses associated with acceptance and use
                of a Prize not specified herein as being awarded. Prize winners
                may be issued an IRS form 1099 based on the actual value of the
                Prize awarded.
              </Text>
              <Text bold>
                The value of each Prize set forth above represents the
                Administrator and Sponsor’s good faith determination of the ARV
                thereof and cannot be challenged or appealed. The Prize consists
                of only those items specifically listed as part of the Prize.
              </Text>
              <Title>PRIZE WINNER NOTIFICATION PROCESS:</Title>
              <Text>
                The Prize winners will be notified by Administrator via their
                MTR Account email address within a commercially reasonable time
                after the winner selection date, generally within forty-eight
                (48) hours If a Prize winner does not accept the Prize within
                forty-eight (48) hours following the date and time the Prize
                winner is notified by Administrator, then that Sweepstakes
                winner will be disqualified and an alternate winner will be
                selected at Administrator’s sole discretion. Non-compliance
                shall result in disqualification and award of the Prize to an
                alternate winner. If any potential Prize winner is found to be
                ineligible, or if he or she has not complied with these Official
                Rules, or if the potential Prize winner cannot attend or
                participate in any portion of the Prize, or declines a Prize for
                any reason prior to award, such potential Prize winner may be
                disqualified and an alternate potential Prize winner may be
                selected. The Sweepstakes Entities are not responsible for and
                shall not be liable for late, lost, damaged, intercepted,
                misdirected, or unsuccessful efforts to notify the potential
                Prize winner. The Sweepstakes Entities are not responsible for
                electronic communications that are undeliverable as a result of
                any form of active or passive filtering of any kind, or
                insufficient space in Entrant’s email account to receive email
                messages.
              </Text>
              <Text>
                The Administrator and Sponsor will require a tentative
                Sweepstakes winner to execute a Certificate of Eligibility,
                Liability Release and Publicity Release (collectively,
                “Certificate/Release”), where lawful. If travel is included as
                part of the Prize, the Sponsor will require a tentative Prize
                winner to also execute a Travel Release and Guest Travel
                Release. If a Prize winner fails to return any required
                documentation and signatures within twenty-four (24) hours
                following the date and time the Prize winner is notified by
                Administrator, then that Grand Prize winner will be disqualified
                and an alternate winner will be selected at Administrator’s sole
                discretion.
              </Text>
              <Title>PRIVACY STATEMENT:</Title>
              <Text>
                Entrants’ personal information will be utilized by the
                Administrator and Sponsor for fulfillment of the Prize and to
                comply with legal requirements.
              </Text>
              <Text>
                To review Administrator’s privacy policy, go to{' '}
                <Link href={`${process.env.PUBLIC_URL}/privacy`}>
                  {`${process.env.PUBLIC_URL}/privacy`}
                </Link>
                . To review Administrator’s terms of use, go to{' '}
                <Link href={`${process.env.PUBLIC_URL}/terms`}>
                  {`${process.env.PUBLIC_URL}/privacy`}
                </Link>
                .
              </Text>
              <Text>
                By entering this Sweepstakes, Entrants accept and agree to abide
                by these privacy policies and terms of use. As a condition of
                entering the Sweepstakes, each Entrant gives consent for
                Administrator and Sponsor to obtain and deliver his or her name,
                address and other information to third parties for the purpose
                of administering this Sweepstakes and to comply with applicable
                laws, regulations and rules. Any information Entrant provides to
                Sponsor may be used to communicate with Entrant in relation to
                this Sweepstakes or on a Sweepstakes winner’s list.
              </Text>
              <Title>OWNERSHIP AND LICENSE:</Title>
              <Text>
                All Sweepstakes related materials, including submission entries
                (“Submission”) becomes the property of the Administrator and
                Sponsor and will not be acknowledged or returned. The copyright
                in any Submission shall remain the property of the Entrant, but
                entry into this Sweepstakes constitutes Entrant’s irrevocable
                and perpetual permission and consent, without further
                compensation or attribution, to use, reproduce, print, publish,
                transmit, distribute, sell, perform, adapt, enhance, or display
                such Submission for any purpose, including, but not limited to,
                editorial, advertising, trade, commercial, and publicity
                purposes by the Sponsor and/or others authorized by the Sponsor,
                in any and all media now in existence or hereafter invented,
                throughout the universe, for the duration or the copyright in
                the Submission. Sponsor and/or others authorized by the Sponsor
                shall have the right to edit, adapt, and modify the Submission.
              </Text>
              <Text>
                Any use of any of the Sweepstakes Entities trademarks or
                copyright interest is strictly prohibited and may subject
                Entrant to civil and/or criminal liability.
              </Text>
              <Title>PUBLICITY GRANT:</Title>
              <Text>
                Except where prohibited by law, each Sweepstakes winner grants
                the Sweepstakes Entities, and those authorized by the
                Sweepstakes Entities, the perpetual and irrevocable right and
                license to use his/her first name, last initial, and location,
                for advertising, promotional and/or publicity purposes, on a
                worldwide basis, and in all forms of media now known or
                hereafter invented or discovered (including, without limitation,
                on the Sponsor’s web sites), in perpetuity, without further
                compensation, notice, review or approval except of the awarding
                of the Prize to the Prize winner.
              </Text>
              <Title>NO WARRANTIES:</Title>
              <Text>
                ALL PRIZES ARE PROVIDED “AS IS” AND “with all faults.”
                Sweepstake Entities make no warranties, express or implied, in
                fact or in law, relative to the use or enjoyment of any Prize,
                or any portion thereof, including, without limitation, its
                quality, merchantability or fitness for a particular purpose.
                Any and all warranties for a Prize, or any portion thereof, are
                contained in the manufacturers’ or service providers’
                warranties, as relevant.
              </Text>
              <Text>
                All trademarks utilized as part of the Sweepstakes or the Prize
                are the property of their respective owners. The use of certain
                companies’ products and services as Prizes does not imply
                participation in, approval of, or endorsement of this
                Sweepstakes by such companies. References to individuals are for
                reference and identification purposes only and not intended to
                suggest endorsement, sponsorship or affiliation with the
                Sweepstake Entities or the Sweepstakes.
              </Text>
              <Title>RELEASE, HOLD HARMLESS AND INDEMNIFICATION:</Title>
              <Text>
                By entering, all entrants in the sweepstakes, entrant’s heirs,
                executors, administrators, legal successors and/or assigns
                release and agree to defend, indemnify and hold harmless the
                sponsor and all sweepstakes entities from and against any and
                all claims, demands, losses and causes of action for any damages
                (including, without limitation, all consequential, punitive, and
                incidental damages), liability, loss, cost, fees (including
                attorneys’ fees), including, without limitation, damage to
                person (including death) or personal or real property, due, in
                whole or in part, directly or indirectly, by reason of the
                acceptance, possession, redemption, misdirection, use or misuse
                of any Prize or any portion of any Prize (including any activity
                or travel related thereto) or participation in the sweepstakes
                and the activities or for any and all claims based on rights of
                publicity and rights of privacy whether any of these things are
                caused, in whole or in part, by the acts, omissions, negligence
                or misconduct of the Sweepstakes Entities.
              </Text>
              <Title>Disclaimers / limitations on liability:</Title>
              <Text>
                By entering, all entrants in the sweepstakes agree that the
                sweepstakes entities assume no liability whatsoever for injuries
                or damages of any kind sustained in connection with the use,
                acceptance, possession or awarding of any Prize or while
                preparing for, participating in and/or traveling to or from any
                sweepstakes-related activity, including, without limitation, any
                injury, damage, death, loss or accident to person or property
                due to an intentional act, an act of negligence or default
                and/or any act of god.
              </Text>
              <Text>
                By entering the Sweepstakes, Entrants agree to waive any right
                to claim ambiguity with the Official Rules.
              </Text>
              <Text>
                The Sweepstakes Entities are not responsible for human errors or
                for any damaged, tampered with, delayed, illegible, incomplete,
                inaccurate, garbled, late, lost, forged, misaddressed,
                misdirected, mutilated, mass machine-generated, unintelligible,
                incomprehensible, stolen entries or entries otherwise not in
                compliance with these Official Rules. In addition, Sweepstakes
                Entities are not responsible for any typographical errors in
                these Official Rules, Sweepstakes content or any advertising
                materials for the Sweepstakes, or for any kind of computer,
                electronic, hardware, software, Internet, network, technical or
                telephone failures and/or problems caused by the Sponsor and/or
                the Sweepstakes Entities or the user, or by any of the equipment
                or programming associated with or utilized in the Sweepstakes,
                or by any human, typographical, electronic, network or other
                error that may occur in receiving and/or processing of the
                entries in the Sweepstakes, or by infection by computer viruses,
                bugs, tampering, unauthorized intervention, fraud or technical
                failure.
              </Text>
              <Text>
                If the Sweepstakes becomes compromised, hampered, interrupted,
                not capable of running as planned, rendered impossible of
                performance in any way or prevented, including, without
                limitation, because of any kind of computer viruses, bugs,
                electronic, hardware, software, Internet, network, technical or
                telephone failures, tampering, unauthorized intervention, fraud,
                an event of force majeure or any other cause created by Sponsor
                and/or the Sweepstakes Entities, the user or by any of the
                equipment or programming associated with or utilized in the
                Sweepstakes, Administrator and Sponsor reserves the right, at
                its sole discretion, to change the Official Rules, to cancel,
                modify, suspend or withdraw the Sweepstakes or any Sweepstakes
                offer and/or cancel, modify, suspend or withdraw any method of
                entry, without notice, and select Prize winners from all entries
                received for the Sweepstakes prior to or after the cancellation,
                modification, suspension or withdrawal. As used herein, an event
                of force majeure shall be deemed to include, without limitation,
                an act of God; an act of terrorism, war, riot or civil
                commotion, fire, casualties, equipment failure, boycott; labor
                dispute, strike or stoppage (including a strike by the members
                of any union), labor or material shortage, transportation
                interruption of any kind, an act, regulation, order or request
                of or by any federal, state or local authority or
                quasi-governmental entity (whether or not the act, regulation,
                order or request proves to be invalid); or any other similar or
                dissimilar act beyond the Sponsor’s control.
              </Text>
              <Title>GENERAL CONDITIONS OF ENTRY:</Title>
              <Text>
                All Entrants, as a condition of entry, agree that they have not
                purchased any kind of Internet service whatsoever in order to
                participate in and/or enter the Sweepstakes. Entrants agree not
                to knowingly damage or cause interruption of the Sweepstakes
                and/or prevent others from utilizing the Sweepstakes.
              </Text>
              <Text>
                CAUTION: ANY ATTEMPT TO DAMAGE ANY ONLINE OR WIRELESS SERVICE OR
                WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION OF THE SWEEPSTAKES
                OR CAMPAIGN VIOLATES CRIMINAL AND/OR CIVIL LAWS. IF SUCH AN
                ATTEMPT IS MADE, SPONSOR AND/OR SWEEPSTAKES ADMINISTERING AGENCY
                MAY, AT THEIR SOLE AND REASONABLE DISCRETION, DISQUALIFY ANY AND
                ALL ENTRANTS MAKING SUCH ATTEMPT AND SPONSOR MAY SEEK DAMAGES
                (INCLUDING ATTORNEYS’ FEES) TO THE FULLEST EXTENT PERMITTED BY
                LAW, INCLUDING CRIMINAL PROSECUTION. SPONSOR’S FAILURE TO
                ENFORCE ANY TERM OF THESE OFFICIAL RULES SHALL NOT CONSTITUTE A
                WAIVER OF THAT OR ANY OTHER PROVISION.
              </Text>
              <Title>GOVERNING LAW:</Title>
              <Text>
                The parties agree that the Sweepstakes and these Official Rules
                will be governed, construed and interpreted under the laws of
                the State of California without giving effect to any choice of
                law or conflict of law rules (whether of the State of California
                or any other jurisdiction) which would cause the application of
                the laws of any jurisdiction other than the State of California.
              </Text>
              <Title>DISPUTE RESOLUTION</Title>
              <Text>
                The parties each agree to finally settle all disputes only
                through arbitration; provided, however, the Sponsor shall be
                entitled to seek injunctive or equitable relief in the state and
                federal courts in Los Angeles County, California and any other
                court with jurisdiction over the parties. In arbitration, there
                is no judge or jury and review is limited. The arbitrator’s
                decision and award is final and binding, with limited
                exceptions, and judgment on the award may be entered in any
                court with jurisdiction. The parties agree that, except as set
                forth above, any claim, suit, action or proceeding arising out
                of or relating to this Sweepstakes shall be resolved solely by
                binding arbitration before a sole arbitrator under the
                streamlined Arbitration Rules Procedures of JAMS Inc. (“JAMS”)
                or any successor to JAMS. In the event JAMS is unwilling or
                unable to set a hearing date within fourteen (14) days of the
                filing of a “Demand for Arbitration”, then either party can
                elect to have the arbitration administered by the American
                Arbitration Association (“AAA”) or any other mutually agreeable
                arbitration administration service. If an in-person hearing is
                required, then it will take place in Los Angeles, California.
                The federal or state law that applies to these Official Rules
                will also apply during the arbitration. Disputes will be
                arbitrated only on an individual basis and will not be
                consolidated with any other proceedings that involve any claims
                or controversy of another party, including any class actions;
                provided, however, if for any reason any court or arbitrator
                holds that this restriction is unconscionable or unenforceable,
                then the agreement to arbitrate doesn’t apply and the dispute
                must be brought in a court of competent jurisdiction in Los
                Angeles County, California. Sponsor agrees to pay the
                administrative and arbitrator’s fees in order to conduct the
                arbitration (but specifically excluding any travel or other
                costs of Entrant to attend the arbitration hearing). Either
                party may, notwithstanding this provision, bring qualifying
                claims in small claims court.
              </Text>
              <Text>
                In Canada, with respect to Quebec residents, any litigation
                respecting the conduct or organization of a publicity contest
                may be submitted to the Régie des alcools, des courses et des
                jeux for a ruling. Any litigation respecting the awarding of a
                Prize may be submitted to the Régie only for the purpose of
                helping the parties reach a settlement.
              </Text>
              <Title>LIMITATION OF LIABILITY:</Title>
              <Text>
                BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES THAT TO THE EXTENT
                PERMITTED BY APPLICABLE LAW: (A) ANY AND ALL DISPUTES, CLAIMS
                AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE
                SWEEPSTAKES, OR ANY PRIZE AWARDED, WILL BE RESOLVED
                INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION; (B)
                ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS WILL BE LIMITED TO
                ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED (IF ANY) NOT TO
                EXCEED TEN DOLLARS ($10.00), BUT IN NO EVENT WILL ATTORNEYS’
                FEES BE AWARDED OR RECOVERABLE; AND (C) UNDER NO CIRCUMSTANCES
                WILL ANY ENTRANT BE PERMITTED TO OBTAIN ANY AWARD FOR, AND
                ENTRANT HEREBY KNOWINGLY AND EXPRESSLY WAIVES ALL RIGHTS TO
                SEEK, PUNITIVE, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES,
                LOST PROFITS AND/OR ANY OTHER DAMAGES, OTHER THAN ACTUAL OUT OF
                POCKET EXPENSES NOT TO EXCEED TEN DOLLARS ($10.00), AND/OR ANY
                RIGHTS TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED. SOME
                JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
                LIABILITY, SO THE ABOVE MAY NOT APPLY TO YOU.
              </Text>
              <Title>MISCELLANEOUS:</Title>
              <Text>
                The invalidity or unenforceability of any provision of these
                Official Rules or the Certificate/Release will not affect the
                validity or enforceability of any other provision. In the event
                that any provision of the Official Rules or the
                Certificate/Release is determined to be invalid or otherwise
                unenforceable or illegal, the other provisions will remain in
                effect and will be construed in accordance with their terms as
                if the invalid or illegal provision were not contained herein.
                Sponsor’s failure to enforce any term of these Official Rules
                will not constitute a waiver of that provision. Entrants agree
                to waive any rights to claim ambiguity of these Official Rules.
                Headings are solely for convenience of reference and will not be
                deemed to affect in any manner the meaning or intent of the
                documents or any provision hereof. In the event there is a
                discrepancy or inconsistency between disclosures or other
                statements contained in any Sweepstakes-related materials,
                privacy policy or terms of use on any website and/or the terms
                and conditions of the Official Rules, the Official Rules shall
                prevail, govern and control and the discrepancy will be resolved
                in Administrator&apos;s sole and absolute discretion.
              </Text>
              <Title>NAMES OF SWEEPSTAKES WINNERS:</Title>
              <Text>
                {`To receive the names of the winners, send a self-addressed, stamped envelope (excluding residents of VT) to: “Sweepstakes Winners List”, ${sweepstake.name}, c/o TAP Network, 340 S LEMON AVE #6858, WALNUT, CA 91789, UNITED STATES within 1 year of expiration of the Sweepstakes Period. Please indicate which Sweepstakes winners list you are requesting by referencing the name of the Sweepstakes in your request.`}
              </Text>
              <Text centered>© 2020 TAP Network. All rights reserved.</Text>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RulesPage;
