import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import isEmpty from 'lodash/isEmpty';

import { updateUser, selectUser } from 'redux/user';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ReCAPTCHA from 'react-google-recaptcha';
import { getPhoneCountryCodesAllowed, getRecaptchaKey } from 'utils/config';
import { storage } from 'services/storage';
import useStyles from './styles';

const EnterPhone = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const countryCodesAllowed = getPhoneCountryCodesAllowed();
  const [recaptchaSuccessToken, setRecaptchaSuccessToken] = useState(null);
  const inputRef = useRef();
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);

  const { isLoading, phone_number, error } = useSelector(selectUser);
  const recaptchaSiteKey = getRecaptchaKey();

  const { handleSubmit, errors, control } = useForm();

  const onCaptchaChange = (token) => {
    if (token) {
      setRecaptchaSuccessToken(token);
    }
  };

  const onSubmit = ({ number }) => {
    storage.local.set('formattedPhoneNumber', formattedPhoneNumber);
    dispatch(
      updateUser({
        phone_number: number,
        g_recaptcha_response: recaptchaSuccessToken,
      })
    );
  };

  return (
    <>
      <Typography
        variant="h4"
        className={classes.title}
        data-cy="enter-phone-title"
      >
        Get verified.
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        We’ll never call, we just need to make sure you’re human.
      </Typography>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item>
            <Controller
              control={control}
              name="number"
              defaultValue={phone_number || ''}
              render={({ onChange, ref, ...rest }) => (
                <PhoneInput
                  inputStyle={{
                    width: '300px',
                    height: '52px',
                    fontSize: '15px',
                    paddingLeft: '60px',
                    borderRadius: '5px',
                  }}
                  country="us"
                  onlyCountries={countryCodesAllowed}
                  placeholder="Enter Phone Number"
                  {...rest}
                  inputRef={ref}
                  className={classes.input}
                  specialLabel=""
                  label="Enter Phone Number"
                  variant="outlined"
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return `Invalid value: ${value}, ${country.name}`;
                    }
                    if (value.match(/1234/)) {
                      return false;
                    }
                    return true;
                  }}
                  inputProps={{
                    name: 'phone_number',
                    required: true,
                    autoFocus: true,
                    ref: (e) => {
                      inputRef.current = e;
                    },
                  }}
                  helperText={errors.number?.message}
                  error={!!errors.number?.message}
                  copyNumbersOnly={false}
                  onChange={(value) => {
                    setFormattedPhoneNumber(inputRef.current.value);
                    onChange(value);
                  }}
                  data-cy="phone-number-input"
                />
              )}
            />
          </Grid>
          <Grid>
            {recaptchaSiteKey && (
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                onChange={onCaptchaChange}
                size="normal"
                type="image"
              />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disabled={isLoading || !isEmpty(errors) || !recaptchaSuccessToken}
            size="large"
            data-cy="enter-phone-submit"
          >
            {isLoading ? <CircularProgress size={20} /> : 'Continue'}
          </Button>
          <Typography variant="h6" color="error" data-cy="phone-number-error">
            {error}
          </Typography>
        </Grid>
      </form>
    </>
  );
};

export default EnterPhone;
