import { all, call, takeLeading, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  fetchCoupon as _fetchCoupon,
  submitCoupon as _submitCoupon,
  verifyCaptchaRequest,
} from 'services/api/coupon';
import { getWallet } from 'redux/wallet';
import { storage } from 'services/storage';
import { selectConfigProperty } from 'redux/config';
import { replaceCouponCode } from 'utils/url';
import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';

import {
  fetchCoupon,
  fetchCouponSuccess,
  fetchCouponFailure,
  navigateUserToExperience,
  clearCouponData,
  toggleCouponModal,
  vefiryCaptcha,
  vefiryCaptchaSuccess,
  vefiryCaptchaFailure,
} from './slice';
import { getExperienceFromCoupon, handleRedirect, clearCoupon } from '../utils';

// IF userToken and redirectUri are provided THEN go to redirectUri
// IF veryDumbe is enabled, THEN go to experience directly
// IF veryDumb is disabled AND location is NOT /sign-up THEN
// IF userToken is provided THEN go to experience ELSE go to /sign-up
function* _setNavigation(redirectUri, localUrl, experience, search) {
  yield call(storage.local.set, 'experience', experience);
  const userToken = yield call(storage.local.get, 'isLoggedIn');
  const isUseVeryDumbCc = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.useVeryDumbCc)
  );
  if (redirectUri && userToken) {
    yield call(handleRedirect, redirectUri);
  } else if (isUseVeryDumbCc && localUrl !== PATHS.signUp) {
    yield put(push(`/${experience.toLowerCase()}/`));
  } else if (!isUseVeryDumbCc && localUrl !== PATHS.signUp) {
    if (userToken) {
      yield put(push(`/${experience.toLowerCase()}/`));
    } else {
      yield put(push({ pathname: PATHS.signUp, search }));
    }
  }
}

function* fetchCouponSaga({ payload: { code, createOrder, preventRedirect } }) {
  const location = yield select((state) => state.router.location);
  const userToken = yield call(storage.local.get, 'isLoggedIn');
  const recaptchaSiteKey = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.recaptchaSiteKey)
  );
  try {
    const { data } = yield call(_fetchCoupon, code);
    storage.local.removeItem('shouldShowCaptchaOnNextInvalidCode');
    yield put(fetchCouponSuccess(data.coupon_code));
    yield call(
      storage.local.set,
      'coupon',
      JSON.stringify({ ...data.coupon_code })
    );
    if (userToken && createOrder) {
      if (!location.pathname.match(PATHS.sweepstakes)) {
        yield put(toggleCouponModal(true));
      }
      yield call(_submitCoupon, { code });
      yield call(clearCoupon);
      yield put(getWallet());
    }

    if (preventRedirect) {
      return;
    }
    yield call(
      _setNavigation,
      location.query?.redirect_uri,
      location.pathname,
      getExperienceFromCoupon(data.coupon_code),
      replaceCouponCode(location, code)
    );
  } catch (error) {
    let shouldShowCaptcha = false;
    if (recaptchaSiteKey) {
      const shouldShowCaptchaOnNextInvalidCode = storage.local.get(
        'shouldShowCaptchaOnNextInvalidCode'
      );
      if (shouldShowCaptchaOnNextInvalidCode) {
        shouldShowCaptcha = true;
      } else {
        let errorAttempts = Number(storage.local.get('errorAttempts')) ?? 0;
        errorAttempts += 1;
        storage.local.set('errorAttempts', errorAttempts);
        shouldShowCaptcha = errorAttempts > 1;
      }
    }
    yield put(
      fetchCouponFailure({
        error: error.data,
        shouldShowCaptcha,
      })
    );
  }
}

function* handleNavigate() {
  const location = yield select((state) => state.router.location);
  const {
    coupon: { coupon },
  } = yield select();

  if (coupon) {
    yield call(
      _setNavigation,
      location.query?.redirect_uri,
      location.pathname,
      getExperienceFromCoupon(coupon),
      replaceCouponCode(location, coupon.code)
    );
    yield put(clearCouponData());
  }
}

function* vefiryCaptchaSaga({ payload: { token } }) {
  const secret = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.recaptchaSiteKey)
  );
  try {
    const {
      data: { success },
    } = yield call(verifyCaptchaRequest, { secret, token });
    if (success) {
      storage.local.removeItem('errorAttempts');
      storage.local.set('shouldShowCaptchaOnNextInvalidCode', true);
      yield put(vefiryCaptchaSuccess());
    } else {
      yield put(
        vefiryCaptchaFailure('Something went wrong. Please, try again')
      );
    }
  } catch (error) {
    yield put(vefiryCaptchaFailure(error?.data.errors[0] || error.message));
  }
}

export function* couponSaga() {
  yield all([
    takeLeading(fetchCoupon, fetchCouponSaga),
    takeLeading(navigateUserToExperience, handleNavigate),
    takeLeading(vefiryCaptcha, vefiryCaptchaSaga),
  ]);
}
