import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  title: {
    lineHeight: '1.25rem',
  },
  cardImageWrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardImage: {
    borderRadius: 10,
    display: 'block',
    maxWidth: '9.375rem',
    maxHeight: '9.375rem',
    margin: '-1px', // hack to avoid white border
  },
  grayedOut: {
    filter: 'grayscale(100%)',
  },
  comingSoon: {
    fontSize: '1.4rem',
    position: 'absolute',
    fontWeight: 'bold',
    transform: 'rotate(-30deg)',
  },
  noLink: {
    cursor: 'default',
  },
}));
