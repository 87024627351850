import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { PATHS } from '../constants/navigation';

export const useHideOnLandingPage = () => {
  const location = useLocation();

  const isUseLandingPage = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.useLandingPage)
  );

  return isUseLandingPage && location.pathname === PATHS.root;
};
