import React, { useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectConfigProperty, selectLoginPath } from 'redux/config';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import SidebarMenu from 'components/SidebarMenu';
import UserInfoBanner from 'components/UserInfoBanner';
import {
  excludeBannerPaths,
  excludeLoginBtnPaths,
  excludedRedirectPaths,
  hiddenLoginPaths,
} from 'utils/navigation';

import { PATHS } from 'constants/navigation';
import images from 'assets/images';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { useHideOnLandingPage } from 'hooks/display';
import { useIsLoggedIn, useUserInfo } from 'hooks/user';

import { useStyles } from './styles';
import { useProfile } from './useProfile';

const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const loginPath = useSelector(selectLoginPath);
  const {
    user: { onboarding_step },
  } = useUserInfo();

  const isLoggedIn = useIsLoggedIn();

  const hideOnLandingPage = useHideOnLandingPage();

  const isUseProfile = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.useProfile)
  );

  const logoRedirectUrl = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.logoRedirectUrl)
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  const showInfoBanner =
    ![...excludeBannerPaths, PATHS.support, PATHS.faq].includes(
      location.pathname
    ) && !location.pathname.includes('rules');
  const skipRedirect = excludedRedirectPaths.includes(location.pathname);
  const hideLoginButton = hiddenLoginPaths.includes(location.pathname);
  const Profile = useProfile(isUseProfile);

  return hideOnLandingPage ? (
    <></>
  ) : (
    <div>
      <AppBar position="static" color="inherit">
        <Toolbar>
          {!onboarding_step && location.pathname !== PATHS.onboarding && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              data-cy="header-menu-btn"
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.logo}>
            <Link to={logoRedirectUrl || '/'} className={classes.logoContainer}>
              <img
                src={images.logo}
                alt="header-logo"
                className={classes.image}
              />
            </Link>
          </div>
          {!excludeLoginBtnPaths.includes(location.pathname) &&
            (isLoggedIn
              ? Profile
              : !hideLoginButton && (
                  <Link
                    to={`${loginPath}${
                      skipRedirect
                        ? ''
                        : `?redirect_uri=${encodeURIComponent(
                            window.location.href
                          )}`
                    }`}
                    className={classes.loginLink}
                    data-cy="header_login_btn"
                  >
                    Log In
                  </Link>
                ))}
        </Toolbar>
      </AppBar>
      <SidebarMenu sidebarOpen={sidebarOpen} closeDrawer={toggleDrawer} />
      {showInfoBanner && <UserInfoBanner />}
    </div>
  );
};

export default Header;
