import React from 'react';
import { useSelector } from 'react-redux';

import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { PATHS } from 'constants/navigation';
import Modal from '../Modal';
import useStyles from './styles';

const PermissionLoginModal = ({ open }) => {
  const classes = useStyles();

  const spotifyClientId = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spotifyClientId)
  );

  const spotifyScopes = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spotifyScopes)
  );

  const spotifyRedirectUri = window.location.origin + PATHS.loginSpotify;

  const handleRedirectToSpotiy = () => {
    localStorage.removeItem('loginPage');
    window.location.href = `https://accounts.spotify.com/authorize?response_type=code&client_id=${spotifyClientId}&scope=${encodeURIComponent(
      spotifyScopes
    )}&redirect_uri=${encodeURIComponent(spotifyRedirectUri)}`;
  };

  return (
    <Modal open={open} disableBackdropClick>
      <Typography variant="h5" className={classes.title}>
        Log In Required
      </Typography>
      <Typography className={classes.rule}>
        In order to reward you, you’ll first need to log in to your Spotify
        account.
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        size="large"
        onClick={handleRedirectToSpotiy}
      >
        Let&apos;s Go!
      </Button>
    </Modal>
  );
};

export default PermissionLoginModal;
