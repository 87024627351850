import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  wrapper: {
    maxWidth: 300,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  btn: {
    width: '100%',
    maxWidth: '300px',
    margin: theme.spacing(5, 0, 1),
    fontSize: '0.9rem',
    textDecoration: 'none',
  },
  link: {
    marginTop: theme.spacing(2),
    color: theme.palette.accent.main,
    display: 'inline-block',
  },
}));
