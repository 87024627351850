import { getPartner } from 'utils/config';

const PartnerSpecificContent = ({
  hidden,
  partnerName,
  partnerNames = [],
  children,
}) => {
  const { name } = getPartner();
  if (hidden) {
    if (partnerName !== name && partnerNames.includes(name) === false) {
      return children;
    }
  } else if (partnerName === name || partnerNames.includes(name)) {
    return children ?? null;
  }
  return null;
};

export default PartnerSpecificContent;
