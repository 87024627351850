import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  search: {
    maxHeight: 36,
    maxWidth: 470,
    '& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));
