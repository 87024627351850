import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfig } from 'redux/config';

export const useConfig = () => {
  const config = useSelector((state) => state.config);
  const { isLoading, isLoaded, error } = config;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !isLoaded && !error) {
      dispatch(fetchConfig());
    }
  }, [dispatch, isLoading, isLoaded, error]);

  return {
    isLoading,
    isLoaded,
    error,
    config,
  };
};
