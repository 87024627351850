import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    padding: theme.spacing(1, 2),
    height: '100%',
  },
  title: {
    lineHeight: '1.25rem',
    marginBottom: theme.spacing(1),
  },
  image: {
    display: 'inline-block',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: 200,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 130,
    },
  },
  hiddenImg: {
    filter: 'grayscale(100%)',
  },
  time: {
    margin: theme.spacing(1, 0),
    minHeight: 18,
    marginTop: 'auto',
  },
  redTime: {
    color: theme.palette.info.main,
  },
  imgContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sold: {
    fontSize: '3rem',
    position: 'absolute',
    fontWeight: 'bold',
    transform: 'rotate(-30deg)',
  },
  price: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 0),
  },
  bidderName: {
    margin: theme.spacing(1, 0),
    minHeight: 18,
  },
  btn: {
    width: '100%',
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    borderColor: theme.palette.accent.main,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
  flashAnimation: {
    animation: '$flashAnimation 1s ease-in-out',
  },
  '@keyframes flashAnimation': {
    '0%': {
      backgroundColor: 'transparent',
    },
    '30%': {
      backgroundColor: 'rgba(246,185,23,0.4)',
    },
    '50%': {
      backgroundColor: 'rgba(246,185,23,0.4)',
    },
    '80%': {
      backgroundColor: 'rgba(246,185,23,0.4)',
    },
    '100%': {
      backgroundColor: 'transparent',
    },
  },
}));
