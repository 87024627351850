import { createSelector } from '@reduxjs/toolkit';
import { storage } from 'services/storage';

export const _getCoupon = (state) => state.coupon;

export const selectShouldFetchCoupon = createSelector(
  _getCoupon,
  ({ isLoading, coupon, error }) => Boolean(!isLoading && !coupon && !error)
);

export const selectCouponStatus = createSelector([_getCoupon], (coupon) => ({
  isLoading: coupon.isLoading,
  error: coupon.error,
  tokenAmount: coupon?.coupon?.product?.price,
  shouldShowSuccessModal: coupon?.shouldShowSuccessModal,
}));

export const selectCouponDetails = createSelector(
  [selectShouldFetchCoupon, (state) => state.coupon.coupon],
  (shouldFetchCoupon, coupon) => [coupon, shouldFetchCoupon]
);

export const selectCaptchaStatus = createSelector(
  [_getCoupon],
  ({ shouldShowCaptcha }) =>
    Number(storage.local.get('errorAttempts')) > 1 || shouldShowCaptcha
);

export const selectCouponError = (state) => state.coupon.error;
