import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchWinners } from 'services/api/winners';

import { getWinners, getWinnersSuccess, getWinnersFailure } from './slice';

function* getWinnersSaga() {
  try {
    const {
      data: { sweepstake_winners },
    } = yield call(fetchWinners);

    yield put(getWinnersSuccess(sweepstake_winners));
  } catch (error) {
    yield put(getWinnersFailure(error));
  }
}

export function* winnersSaga() {
  yield takeLatest(getWinners, getWinnersSaga);
}
