import { useSelector } from 'react-redux';
import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';

export const GamesRoutesGuard = ({ children }) => {
  const isGamesPageEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.gamesPageEnabled)
  );

  if (!isGamesPageEnabled) {
    return null;
  }
  return children;
};
