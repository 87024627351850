import { createSlice } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';

const initialState = {
  content: {
    sweepCategories: {},
    sweepEntryOptions: {},
  },
  popups: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    getCms: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getCmsSuccess: (state, action) => {
      state.isLoading = false;
      state.content = Object.assign(
        state.content,
        _keyBy(action.payload, 'key')
      );

      state.isLoaded = true;
      state.error = null;
    },
    getCmsFailure: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.payload;
    },
    getPopups: () => {},
    getPopupsSuccess: (state, action) => {
      state.popups = action.payload;
    },
  },
});

export const {
  getCms,
  getCmsFailure,
  getCmsSuccess,
  getPopups,
  getPopupsSuccess,
} = cmsSlice.actions;

export default cmsSlice.reducer;
