import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import isEmpty from 'lodash/isEmpty';

import { verifyPhone, resendCode, selectUserInfo } from 'redux/user';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

import { storage } from 'services/storage';
import useStyles from './styles';

const VerifyPhone = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);
  const { isLoading, isCodeResending, isCodeResent, error } = useSelector(
    selectUserInfo
  );
  const { handleSubmit, errors, control } = useForm();

  const formattedPhoneNumber = storage.local.get('formattedPhoneNumber');

  const onSubmit = (data) => {
    dispatch(verifyPhone(data));
  };

  const onResend = useCallback(() => {
    dispatch(resendCode());
    setIsResendButtonDisabled(true);
    setTimeout(() => setIsResendButtonDisabled(false), 60000);
  }, [dispatch]);

  const getIcon = useCallback(() => {
    if (!isResendButtonDisabled) return null;
    if (isCodeResending) {
      return <CircularProgress size={20} />;
    }
    if (isCodeResent) {
      return <CheckIcon />;
    }
    return null;
  }, [isCodeResent, isCodeResending, isResendButtonDisabled]);

  return (
    <>
      <Typography
        variant="h4"
        className={classes.title}
        data-cy="phone-verification-title"
      >
        Enter your code.
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        Check your phone for a special SMS.
      </Typography>
      <Typography variant="h6" className={classes.phoneMask}>
        {formattedPhoneNumber}
      </Typography>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item>
            <Controller
              control={control}
              name="code"
              defaultValue=""
              render={({ onChange, ref, ...rest }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  className={classes.input}
                  label="Enter code"
                  variant="outlined"
                  helperText={errors.email?.message}
                  error={!!errors.email?.message}
                  onChange={({ target: { value } }) => {
                    const onlyNums = value.replace(/[^0-9]/g, '');
                    if (onlyNums.length < 11) {
                      onChange(onlyNums);
                    }
                  }}
                  data-cy="verification-code"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              disabled={isResendButtonDisabled}
              onClick={onResend}
              className={classes.resendButton}
              endIcon={getIcon()}
              data-cy="resend-code-button"
            >
              Resend code
            </Button>
            {isResendButtonDisabled && (
              <div>This may take up to 60 seconds</div>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disabled={isLoading || !isEmpty(errors)}
            size="large"
            data-cy="verificaton-submit"
          >
            {isLoading ? <CircularProgress size={20} /> : 'Continue'}
          </Button>
          <Typography variant="h6" color="error" data-cy="verification-error">
            {error}
          </Typography>
        </Grid>
      </form>
    </>
  );
};

export default VerifyPhone;
