import { createSelector } from '@reduxjs/toolkit';
import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';

const selectConfig = (state) => state.config;

export const selectConfigProperty = (key) =>
  createSelector([selectConfig], (config) => config[key]);

export const selectLoginPath = createSelector(
  [selectConfigProperty(CONFIG_KEYS_MAP.externalPartnerLoginEnabled)],
  (isExternalPartnerLoginEnabled) =>
    isExternalPartnerLoginEnabled ? PATHS.externalLogin : PATHS.login
);

export const selectExternalSignUpPath = createSelector(
  [
    selectConfigProperty(CONFIG_KEYS_MAP.externalPartnerLoginEnabled),
    selectConfigProperty(CONFIG_KEYS_MAP.externalPartnerSignUpUrl),
  ],
  (isExternalPartnerLoginEnabled, externalPartnerSignUpUrl) =>
    isExternalPartnerLoginEnabled ? externalPartnerSignUpUrl : PATHS.signUp
);

export const selectSignUpPath = createSelector(
  [selectConfigProperty(CONFIG_KEYS_MAP.signupUrl)],
  (signupUrl) => signupUrl
);

export const selectPennyAuctionBaseUrl = createSelector(
  [selectConfigProperty(CONFIG_KEYS_MAP.pennyAuctionBaseURL)],
  (pennyAuctionBaseURL) => pennyAuctionBaseURL
);

export const selectPennyAuctionSocketUrl = createSelector(
  [selectConfigProperty(CONFIG_KEYS_MAP.pennyAuctionBaseURL)],
  (pennyAuctionBaseURL) =>
    pennyAuctionBaseURL ? pennyAuctionBaseURL.replace('https', 'wss') : ''
);
