import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 2, 3, 2),
    width: '100%',
    margin: 0,
  },
  content: {
    maxWidth: 315,
  },
  title: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  body: {
    fontWeight: 500,
    color: theme.palette.dark.secondary,
    textAlign: 'center',
    '&:first-child': {
      marginBottom: theme.spacing(2),
      alignSelf: 'center',
    },
  },
  helper: {
    margin: theme.spacing(3.5, 0, 3, 0),
  },
  helperText: {
    color: theme.palette.dark.main,
    fontWeight: 'bold',
  },
  link: {
    color: theme.palette.accent.main,
  },
  button: {
    maxWidth: 315,
    borderRadius: 5,
    marginBottom: theme.spacing(3),
  },
}));
