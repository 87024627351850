import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Link from '@material-ui/core/Link';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';

import { selectConfigProperty } from 'redux/config';
import { selectAvailableCards } from 'redux/wallet';
import { selectScratchGame } from 'redux/scratchGame';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { ReactComponent as BatIcon } from 'assets/images/brave/bat-logo.svg';

import { useStyles } from './styles';

export const UpholdCardSelection = ({ card, cardSelector, handleOnclick }) => {
  const [cardBalance, setCardBalance] = useState(0);
  const classes = useStyles();
  const { currency } = card;

  const availableCards = useSelector(selectAvailableCards);
  const { isLoading, isButtonDisabled, isModalLoading } = useSelector(
    selectScratchGame
  );
  const upholdAddFundsLink = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAddFundsLink)
  );

  const isBraveButtonEnabled =
    currency && Number(cardBalance) > 0 && !isButtonDisabled;

  useEffect(() => {
    if (availableCards && !_.isEmpty(card)) {
      setCardBalance(availableCards.find((c) => c.id === card.id)?.balance);
    }
  }, [availableCards, card]);

  const showButtonText = () => {
    if (isButtonDisabled) {
      return 'You’re ready to scratch!';
    }
    if (currency) {
      return 'Scratch again!';
    }
    return 'Choose a card to scratch again';
  };

  return (
    <>
      <Box className={classes.cardSelectWrapper}>{cardSelector}</Box>
      {currency && (
        <>
          <Box className={classes.exchangeAmount}>
            {currency === 'BAT' && <BatIcon className={classes.currencyIcon} />}
            <Typography className={classes.currencyText}>
              {currency} amount:&nbsp;
              {+Number(cardBalance).toFixed(4)}
            </Typography>
            <Link
              className={classes.addFundsLink}
              href={upholdAddFundsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Add funds
            </Link>
          </Box>
          <Typography className={classes.note}>
            * {currency} value adjusts every 30 seconds
          </Typography>
        </>
      )}
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        className={classes.buttonReload}
        onClick={() => handleOnclick(Number(cardBalance) > 0)}
        size="large"
        data-cy="scratch_button"
        disabled={!isBraveButtonEnabled}
      >
        {isLoading || isModalLoading ? (
          <CircularProgress size={20} color="white" />
        ) : (
          showButtonText()
        )}
      </Button>
    </>
  );
};
