import { createSelector } from '@reduxjs/toolkit';

const getCharities = (state) => state.charities;

export const selectCharitiesData = createSelector(
  [getCharities],
  ({ isLoading, error, data, isDonationProcessing, processingError }) => ({
    isLoading,
    error,
    data,
    isDonationProcessing,
    processingError,
  })
);

export const selectPaymentIntent = createSelector(
  [getCharities],
  ({ paymentIntent }) => paymentIntent
);

export const selectPaymentIntentSecret = createSelector(
  [getCharities],
  ({ paymentIntentSecret }) => paymentIntentSecret
);

export const selectCharityReceiptData = createSelector(
  [getCharities],
  ({ receipt, isLoading }) => ({ receipt, isLoading })
);
