import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  items: [],
  wall: {},
  offerIds: [],
  error: null,
};

const adgemSlice = createSlice({
  name: 'adgem',
  initialState,
  reducers: {
    getAdGemData: (state) => {
      state.isLoading = true;
    },
    getAdGemDataSuccess: (state, action) => {
      state.items = action.payload.data;
      state.wall = action.payload.wall;
      state.isLoading = false;
    },
    getAdGemDataFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getActiveOffersSuccess: (state, action) => {
      state.offerIds = action.payload;
    },
  },
});

export const {
  getAdGemData,
  getAdGemDataSuccess,
  getAdGemDataFailure,
  getActiveOffersSuccess,
} = adgemSlice.actions;

export default adgemSlice.reducer;
