import { call, put, takeLatest, select, fork } from 'redux-saga/effects';

import { fetchAdGemData, fetchActiveOfferIds } from 'services/api/adgem';

import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { selectUserInfo } from 'redux/user';

import {
  getAdGemData,
  getAdGemDataFailure,
  getAdGemDataSuccess,
  getActiveOffersSuccess,
} from './slice';

function* getAdGemDataSaga() {
  const user = yield select(selectUserInfo);
  const adGemAppId = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.adGemAppId)
  );

  yield fork(getActiveOffersSaga);
  try {
    const { data } = yield call(fetchAdGemData, adGemAppId, user.referral_code);

    yield put(getAdGemDataSuccess(data.data[0]));
  } catch (error) {
    yield put(getAdGemDataFailure(error));
  }
}
function* getActiveOffersSaga() {
  try {
    const { data } = yield call(fetchActiveOfferIds);

    yield put(getActiveOffersSuccess(data.offers.ids));
  } catch (error) {
    console.log('error', error);
  }
}
export function* adgemSaga() {
  yield takeLatest(getAdGemData, getAdGemDataSaga);
}
