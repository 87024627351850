import React from 'react';

import Typography from '@material-ui/core/Typography';

import email from 'assets/images/icons/email.svg';
import sms from 'assets/images/icons/sms.svg';
import facebook from 'assets/images/icons/facebook.svg';
import twitter from 'assets/images/icons/twitter.svg';
import clipboard from 'assets/images/icons/clipboard.svg';

import useStyles from './styles';

const imagesMap = {
  email,
  sms,
  facebook,
  twitter,
  clipboard,
};

const ShareButtonLabel = ({ icon, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        src={imagesMap[icon]}
        className={classes.img}
        alt={`Share via ${icon}`}
      />
      <Typography variant="h6">{label}</Typography>
    </div>
  );
};

export default ShareButtonLabel;
