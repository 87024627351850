import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchCharityList,
  postCharityDonation,
  fetchCharityReceipt,
} from 'services/api/charity';
import { PATHS } from 'constants/navigation';
import { trackEvent } from 'redux/analytics';

import { getWallet, refetchActivitiesList } from 'redux/wallet';
import {
  getCharities,
  getCharitiesFailure,
  getCharitiesSuccess,
  sendCharityDonation,
  sendCharityDonationSuccess,
  sendCharityDonationFailure,
  getCharityReceipt,
  getCharityReceiptSuccess,
  getCharityReceiptFailure,
  setPaymentIntentSecret,
  clearPaymentIntentSecret,
  setPaymentIntent,
  clearPaymentIntent,
} from './slice';

function* loadCharityList() {
  try {
    const { data } = yield call(fetchCharityList);
    yield put(getCharitiesSuccess(data.charities));
  } catch (error) {
    yield put(getCharitiesFailure(error?.data?.message));
  }
}

function* sendCharityDonationSaga({
  payload: { tokens, id, paymentMethod, intent },
}) {
  try {
    const { data } = yield call(postCharityDonation, {
      id,
      value: tokens,
      payment_method: paymentMethod,
      payment_intent: intent,
    });

    if (data.requires_action) {
      if (data.payment_method_invalid) {
        yield put(clearPaymentIntentSecret());
        yield put(setPaymentIntent(data.payment_intent));
        yield put(sendCharityDonationSuccess());
        yield put(sendCharityDonationFailure(data.error));
      } else {
        yield put(setPaymentIntentSecret(data.payment_intent_client_secret));
        yield put(sendCharityDonationSuccess());
      }
    } else {
      const { order_number, amount, created_at, line_items } = data;
      const {
        data: { buyer_info },
        product: { display_name },
      } = line_items[0];

      yield put(
        sendCharityDonationSuccess({
          amount,
          charity: display_name,
          date: created_at,
          donatedBy: buyer_info,
        })
      );
      yield put(getWallet());
      yield put(refetchActivitiesList({ category: 'awards' }));
      yield put(clearPaymentIntent());
      yield put(clearPaymentIntentSecret());
      yield put(push(`${PATHS.charities}/${order_number}/success`));
      yield put(
        trackEvent({
          event: {
            eventCategory: 'charity',
            eventAction: 'payment-success',
            eventLabel: id,
          },
        })
      );
    }
  } catch (error) {
    yield put(
      sendCharityDonationFailure(error?.data?.error || error?.data?.errors[0])
    );
  }
}

function* loadCharityReceipt({ payload: { id } }) {
  try {
    const { data } = yield call(fetchCharityReceipt, id);
    yield put(getCharityReceiptSuccess(data));
  } catch (error) {
    yield put(getCharityReceiptFailure(error?.data?.message));
  }
}

export function* charitiesSaga() {
  yield takeLatest(getCharities, loadCharityList);
  yield takeLeading(sendCharityDonation, sendCharityDonationSaga);
  yield takeLatest(getCharityReceipt, loadCharityReceipt);
}
