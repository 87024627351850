import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    padding: theme.spacing(2),
    maxWidth: 640,
  },
  textContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  sweepsName: {
    textTransform: 'uppercase',
  },
}));
