import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { useArtists } from 'hooks/follow/artists';
import { useUserNavigation } from 'hooks/navigation';
import ConnectErrorModal from 'components/ConnectErrorModal/index';
import PermissionLoginModal from 'components/SpotifyPermissionLoginModal';
import { selectFollowed, followArtist } from 'redux/follow/artists';
import { selectIsSpotify, selectSpotifyConnectError } from 'redux/user';
import { storage } from 'services/storage';
import FollowCard from '../components/FollowCard';

import useStyles from './styles';

const FollowArtists = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { artists } = useArtists();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isSpotify = useSelector(selectIsSpotify);
  const spotifyConnectError = useSelector(selectSpotifyConnectError);
  const [isConnectErrorModalOpen, setIsConnectErrorModalOpen] = useState(false);
  const followedArtists = useSelector(selectFollowed);
  const [products, setProducts] = useState([]);
  const followText = 'Follow';
  const followedText = 'Followed';

  useEffect(() => {
    storage.local.set('follow', 'artists');
  }, []);

  useEffect(() => {
    if (artists && artists.length) {
      const sortedProducts = [...artists].sort(
        (a, b) => a.followed - b.followed
      );
      setProducts(sortedProducts);
    }
  }, [artists]);

  const onSubmit = (product_id) => {
    dispatch(followArtist({ product_id }));
  };

  useUserNavigation();

  return (
    <div className={classes.root}>
      <PermissionLoginModal open={isOpenModal} />
      <ConnectErrorModal
        toggleModal={() => setIsConnectErrorModalOpen(false)}
        open={isConnectErrorModalOpen}
        spotifyConnectError={spotifyConnectError}
      />
      <div className={classes.head}>
        <Typography variant="h5" className={classes.title}>
          Follow an Artist, Get Rewarded.
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          Earn 5 tokens instantly for every Warner artist you follow on Spotify.
        </Typography>
      </div>
      {products.length ? (
        <Grid item container spacing={2} className={classes.content}>
          {products.map(({ id, name, image_url, followed }) => (
            <FollowCard
              key={id}
              id={id}
              name={name}
              image_url={image_url}
              isSpotify={isSpotify}
              isFollowed={followed || !!followedArtists.find((el) => el === id)}
              followText={followText}
              followedText={followedText}
              setIsOpenModal={setIsOpenModal}
              onSubmit={onSubmit}
            />
          ))}
        </Grid>
      ) : (
        <Typography variant="subtitle1" className={classes.subText}>
          Congrats, you&apos;ve followed all available artists. Check back soon
          for more!
        </Typography>
      )}
    </div>
  );
};

export default FollowArtists;
