import queryString from 'query-string';
import { Api } from './index';

export const loadWallets = () => Api.get('/wallets');
export const fetchExternalWalletsRequest = () => Api.get('/wallets/external');

export const loadActivities = ({ category, page, perPage }) =>
  Api.get(
    queryString.stringifyUrl({
      url: `/orders/${category}`,
      query: { page, items: perPage },
    })
  );

export const loadPendingActivities = () =>
  Api.get(`/orders/affiliates/pending`);

export const fetchUpholdConversionRate = (from, to) =>
  Api.get(`/uphold/tickers?from=${from}&to=${to}`);
