import { useEffect, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = forwardRef((_, ref) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [pathname, ref]);

  return null;
});

export default ScrollToTop;
