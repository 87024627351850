import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { selectLoginPath, selectSignUpPath } from 'redux/config';

import PartnerSpecificContent from 'components/PartnerSpecificContent';

import Button from '@material-ui/core/Button';

import useStyles from './styles';

const LoggedOutContent = () => {
  const signUpPath = useSelector(selectSignUpPath);
  const loginPath = useSelector(selectLoginPath);
  const dispatch = useDispatch();

  const classes = useStyles();

  const goToLogin = useCallback(() => {
    dispatch(
      push(
        `${loginPath}?redirect_uri=${encodeURIComponent(window.location.href)}`
      )
    );
  }, [dispatch, loginPath]);

  return (
    <div className={classes.buttonsContainer}>
      <PartnerSpecificContent partnerName="uber">
        <Button
          className={`${classes.enterNowBtn} ${classes.getCodeBtn}`}
          variant="contained"
          disableElevation
          size="large"
          color="primary"
          onClick={goToLogin}
        >
          Log in
        </Button>
      </PartnerSpecificContent>
      <PartnerSpecificContent partnerName="uphold">
        <Button
          className={`${classes.enterNowBtn} ${classes.getCodeBtn}`}
          variant="contained"
          disableElevation
          size="large"
          color="primary"
          onClick={goToLogin}
        >
          Log in
        </Button>
      </PartnerSpecificContent>
      <PartnerSpecificContent partnerName="brave">
        <Button
          className={`${classes.enterNowBtn} ${classes.getCodeBtn}`}
          variant="contained"
          disableElevation
          size="large"
          color="primary"
          onClick={goToLogin}
        >
          Log in
        </Button>
      </PartnerSpecificContent>
      <PartnerSpecificContent partnerName="wmg">
        <Button
          className={`${classes.enterNowBtn} ${classes.getCodeBtn}`}
          variant="contained"
          disableElevation
          size="large"
          color="primary"
          onClick={goToLogin}
        >
          Log in
        </Button>
        <a
          href={`${signUpPath}?redirect_uri=${encodeURIComponent(
            window.location.href
          )}`}
          className={classes.loginLink}
        >
          Not yet a member? Sign up!
        </a>
      </PartnerSpecificContent>
    </div>
  );
};

export default LoggedOutContent;
