import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  logoContainer: {
    position: 'relative',
    width: '100%',
  },
  logo: {
    height: 'auto',
    maxHeight: '22px',
  },
}));
