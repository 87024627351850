import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  head: {
    width: '96%',
    marginTop: '16px',
    fontSize: '0.9375rem',
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    marginTop: '16px',
  },
  subText: {
    marginTop: '40px',
  },
  content: {
    marginBottom: '40px',
  },
}));
