import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import {
  selectUser,
  forgotPassword,
  clearUserError,
  resetForgotPassword,
} from 'redux/user';
import { PATHS } from 'constants/navigation';

import useStyles from './styles';

const ForgotPasswordPage = () => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { isLoading, error, isForgotPasswordSuccessful } = useSelector(
    selectUser
  );

  const clearError = useCallback(() => dispatch(clearUserError()), [dispatch]);
  const reset = useCallback(() => dispatch(resetForgotPassword()), [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      forgotPassword({
        email,
      })
    );
  };

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
      if (error) clearError();
      if (isForgotPasswordSuccessful) reset();
    },
    [clearError, error, isForgotPasswordSuccessful, reset]
  );

  useEffect(() => reset, [reset]);

  useEffect(() => {
    if (isForgotPasswordSuccessful) {
      setEmail('');
    }
  }, [isForgotPasswordSuccessful]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <Typography variant="h4" className={classes.title}>
          Reset Password
        </Typography>
        <Typography variant="h5" className={classes.subTitle}>
          Enter your email below
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <TextField
                className={classes.input}
                label="Email Address"
                type="email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>
            {isForgotPasswordSuccessful && (
              <Typography variant="h6" className={classes.successText}>
                An email has been sent to this address. Please follow
                instructions to reset your password.
              </Typography>
            )}

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={clsx(classes.submitBtn, {
                  [classes.successBtn]: isForgotPasswordSuccessful,
                })}
                disabled={isLoading}
                size="large"
              >
                {isLoading && (
                  <div className={classes.spinnerWrapper}>
                    <CircularProgress size={20} />
                  </div>
                )}
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className={classes.errorContainer}>
          {error.length > 0 && (
            <Typography
              variant="subtitle1"
              color="error"
              className={classes.error}
            >
              {error}
            </Typography>
          )}
        </div>
      </div>
      <div>
        <Typography variant="h6">
          <Link
            to={{ ...location, pathname: PATHS.exchange }}
            onClick={clearError}
            className={classes.link}
          >
            Not an existing user?
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
