import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectConfigProperty } from 'redux/config/selectors';

import { useUserNavigation } from 'hooks/navigation';
import { useOnboardingSteps } from 'hooks/onboarding';

import { PATHS } from 'constants/navigation';
import { STEPS } from 'constants/onboarding';
import { CONFIG_KEYS_MAP } from 'constants/config';

import SignUp from './components/SignUp';
import SelectGenres from './components/SelectGenres';
import VerifyPhone from './components/VerifyPhone';
import EnterPhone from './components/EnterPhone';

import useStyles from './styles';

const steps = {
  [STEPS.signUp]: <SignUp />,
  [STEPS.enterPhone]: <EnterPhone />,
  [STEPS.verifyPhone]: <VerifyPhone />,
  [STEPS.selectGenre]: <SelectGenres />,
};

const Onboarding = () => {
  const classes = useStyles();
  const {
    step,
    completedOnboarding,
    backButtonCallback,
    isAllItemsHidden,
  } = useOnboardingSteps();

  const isOnboardingEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.onboardingEnabled)
  );

  useUserNavigation({
    callback: backButtonCallback,
    hideAllItems: isAllItemsHidden,
  });

  if (!isOnboardingEnabled || completedOnboarding) {
    return <Redirect to={PATHS.sweepstakes} />;
  }

  if (!step) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>{steps[step]}</div>
    </div>
  );
};

export default Onboarding;
