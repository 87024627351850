import React from 'react';

import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import parse from 'html-react-parser';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import useStyle from './styles';

const SponsorContent = ({ sponsorData, title }) => {
  const classes = useStyle();

  if (isEmpty(sponsorData)) {
    return null;
  }
  const {
    imageLink,
    youtubeLink,
    copy,
    websiteCopy,
    name,
    buttonCopy,
    buttonIsEnabled,
    buttonUrl,
  } = sponsorData;

  return (
    <div>
      {name && (
        <Grid item>
          <Typography variant="h6" className={classes.title}>
            {`More from ${name}`}
          </Typography>
        </Grid>
      )}
      <Grid item>
        {youtubeLink ? (
          <iframe
            title="Sponsor's video"
            frameBorder="0"
            className={classes.videoFrame}
            src={youtubeLink}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <div className={classes.cardImageWrapper}>
            <img
              className={classes.cardImage}
              src={imageLink.replace(
                'image/upload/v',
                'image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v'
              )}
              alt={title}
            />
          </div>
        )}
      </Grid>
      {copy && (
        <Grid item style={{ maxWidth: 315 }}>
          {parse(copy)}
        </Grid>
      )}
      <Typography variant="h6" className={classes.link}>
        {(websiteCopy && parse(websiteCopy)) ||
          'Visit the Official Artist Website'}
      </Typography>
      {buttonIsEnabled && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          href={buttonUrl}
          target="_blank"
        >
          {buttonCopy}
        </Button>
      )}
    </div>
  );
};

SponsorContent.defaultProps = {
  sponsorData: {
    imageLink: '',
    youtubeLink: '',
    copy: '',
    websiteCopy: '',
  },
};

SponsorContent.propTypes = {
  sponsorData: PropTypes.shape({
    imageLink: PropTypes.string,
    youtubeLink: PropTypes.string,
    copy: PropTypes.string,
    websiteCopy: PropTypes.string,
  }),
};

export default SponsorContent;
