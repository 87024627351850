import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  backUrl: '',
  showBackButton: false,
  callback: null,
  hideAllItems: false,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setBackUrl: (state, action) => {
      state.callback = action.payload.callback;
      state.hideAllItems = action.payload.hideAllItems;
      state.backUrl = action.payload.backUrl;
      state.showBackButton = true;
    },
    clearBackUrl: () => initialState,
  },
});

export const { setBackUrl, clearBackUrl } = navigationSlice.actions;

export default navigationSlice.reducer;
