import { useState, useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import _isEqual from 'lodash/isEqual';
import queryString from 'query-string';

import { setBackUrl, clearBackUrl } from 'redux/navigation';

import { getNavigationTabs, pathsWithHiddenWallet } from 'utils/navigation';
import { getQryParams } from 'utils/url';
import { storage } from 'services/storage';
import { selectConfigProperty } from 'redux/config';

import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { getPartner, getRewardsHomeUrl } from 'utils/config';
import { useHideOnLandingPage } from 'hooks/display';
import { useUserInfo } from './user';

export const useNavigationTabs = () => {
  const [data, setNavigationTabs] = useState({
    navigationTabs: {},
    hideWallet: false,
  });
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: '/activity/:activityType',
  });

  const activityType = match?.params?.activityType;

  useEffect(() => {
    const isSweepsSuccess = matchPath(location.pathname, {
      path: `${PATHS.sweepstakes}/:sweepId/:eventId/success`,
      exact: true,
    });

    const navigationKey = activityType || location.pathname.split('/')[1];
    const navigationTabs = getNavigationTabs(
      `/${isSweepsSuccess ? 'sweeps/success' : navigationKey}`
    );
    const hideWallet = !!location.pathname.match(pathsWithHiddenWallet);
    setNavigationTabs({ navigationTabs, hideWallet });
  }, [location, activityType]);
  return { ...data };
};

export const useUserNavigation = ({
  backUrl = '',
  callback,
  hideAllItems = false,
} = {}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBackUrl({ backUrl, callback, hideAllItems }));
    return () => dispatch(clearBackUrl());
  }, [backUrl, dispatch, callback, hideAllItems]);
};

export const useToggleActiveTabStyle = () => {
  const { pathname } = useLocation();
  const [activeTab, toggleActiveTab] = useState(true);

  useEffect(() => {
    const shouldLinkHaveActiveStyle = !pathname.match(/(success|wellness)/);
    if (!_isEqual(activeTab, shouldLinkHaveActiveStyle)) {
      toggleActiveTab(shouldLinkHaveActiveStyle);
    }
  }, [pathname, activeTab]);

  return activeTab;
};

export const usePartnerLandingPage = () => {
  const location = useLocation();
  const hideOnLandingPage = useHideOnLandingPage();

  useEffect(() => {
    const params = getQryParams(location);

    if (hideOnLandingPage) {
      window.location.replace(
        queryString.stringifyUrl({ url: getRewardsHomeUrl(), query: params })
      );
    }
  });
};

export const useNavigationRedirect = () => {
  // Better to use window.location instead of other ones
  // to have the latest location updates to avoid any mess/desync
  // between react-router-dom history and connected-react-router history because of many redirects here
  const { location } = window;
  const history = useHistory();
  const dispatch = useDispatch();
  const isChooseExperienceEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.chooseExperienceEnabled)
  );

  const partnerLoginUrl = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.externalPartnerLoginUrl)
  );

  const partnerSignUpUrl = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.externalPartnerSignUpUrl)
  );

  const isOnboardingEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.onboardingEnabled)
  );

  const { user } = useUserInfo();
  const partner = getPartner().name;

  useEffect(() => {
    const isLoggedIn = Boolean(storage.local.get('isLoggedIn'));
    const params = getQryParams(location);
    // IF /sign-up or /login AND code is present AND user has an authentication token THEN navigate to /exchange
    // ELSE IF /sign-up AND code is NOT present AND referral code is NOT presented THEN
    //  IF user has an authentication token THEN navigate to /sweeps ELSE navigate to /exchange
    // ELSE IF /sign-up AND referral code is present AND user has an authentication token THEN
    //  IF choose experience is enabled THEN navigate to /choose-experience ELSE navigate to /sweeps
    // ELSE IF /login AND code is NOT present AND user has an authentication token THEN
    //  IF choose experience is enabled THEN navigate to /choose-experience ELSE navigate to /sweeps
    if (
      partner === 'wmg' &&
      user?.isLoaded &&
      !user.completed_onboarding &&
      location.pathname !== PATHS.onboarding
    ) {
      if (isOnboardingEnabled) {
        dispatch(push({ pathname: PATHS.onboarding }));
      } else {
        window.location.replace(
          queryString.stringifyUrl({ url: partnerSignUpUrl })
        );
      }
    } else if (location.pathname === PATHS.externalLogin) {
      window.location.replace(
        queryString.stringifyUrl({ url: partnerLoginUrl, query: params })
      );
    } else if (
      (location.pathname === PATHS.signUp ||
        location.pathname === PATHS.login) &&
      params.code &&
      isLoggedIn
    ) {
      dispatch(push({ ...location, pathname: PATHS.exchange }));
    } else if (
      location.pathname === PATHS.signUp &&
      !params.code &&
      !params.ref_code
    ) {
      dispatch(
        push({
          ...location,
          pathname: isLoggedIn ? PATHS.sweepstakes : PATHS.exchange,
        })
      );
    } else if (
      location.pathname === PATHS.signUp &&
      params.ref_code &&
      isLoggedIn
    ) {
      dispatch(
        push({
          ...location,
          pathname: isChooseExperienceEnabled
            ? PATHS.chooseExperience
            : PATHS.sweepstakes,
        })
      );
    } else if (
      location.pathname === PATHS.login &&
      isLoggedIn &&
      !params.code
    ) {
      dispatch(
        push({
          ...location,
          pathname: isChooseExperienceEnabled
            ? PATHS.chooseExperience
            : PATHS.sweepstakes,
        })
      );
    }
  }, [
    dispatch,
    location,
    location.pathname,
    history,
    isChooseExperienceEnabled,
    partnerLoginUrl,
    partner,
    user,
    isOnboardingEnabled,
    partnerSignUpUrl,
  ]);
};
