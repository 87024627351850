import React from 'react';
import { Link } from 'react-router-dom';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { PATHS } from 'constants/navigation';

export const useProfile = (isUseProfile) =>
  isUseProfile ? (
    <Link to={PATHS.profile}>
      <PermIdentityIcon color="secondary" fontSize="large" />
    </Link>
  ) : (
    <></>
  );
