/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCoupon,
  selectCouponDetails,
  selectCouponError,
} from 'redux/coupon';

export const useCoupon = (code) => {
  const dispatch = useDispatch();
  const [coupon, shouldFetchCoupon] = useSelector(selectCouponDetails);
  const couponError = useSelector(selectCouponError);

  useEffect(() => {
    if (shouldFetchCoupon && code) {
      dispatch(fetchCoupon({ code }));
    }
  }, [dispatch]);
  return [coupon, couponError];
};
