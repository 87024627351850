export const PARTNER_DOMAIN_MAP = {
  [process.env.REACT_APP_UBER_URL]: 'uber',
  [process.env.REACT_APP_WMG_URL]: 'wmg',
  [process.env.REACT_APP_SYW_URL]: 'syw',
  [process.env.REACT_APP_BRAVE_URL]: 'brave',
  [process.env.REACT_APP_UPHOLD_URL]: 'uphold',
};

export const PARTNER_SECRET_MAP = {
  uber: process.env.REACT_APP_UBER_TAP_KEY,
  wmg: process.env.REACT_APP_WMG_TAP_KEY,
  syw: process.env.REACT_APP_SYW_TAP_KEY,
  brave: process.env.REACT_APP_BRAVE_TAP_KEY,
  uphold: process.env.REACT_APP_UPHOLD_TAP_KEY,
};

export const MAX_REQUEST_TIMEOUT = 2000;

export const CONFIG_KEYS_MAP = {
  useVeryDumbCc: 'coupon_connector_very_dumb',
  sweetRewardsBaseUrl: 'wellness_sweet_rewards_base_url',
  showCategoriesFilter: 'sweeps_show_categories_filter',
  chooseExperienceEnabled: 'choose_experience_enabled',
  useProfile: 'user_profile_enabled',
  spinPageEnabled: 'spin_page_enabled',
  showOtherAmount: 'sweeps_show_other_amount',
  appStoreLink: 'app_store_link',
  playMarketLink: 'play_market_link',
  htmlMetaTags: 'html_meta_tags',
  customerService: 'customer_service',
  externalPartnerLoginEnabled: 'external_partner_login_enabled',
  externalPartnerLoginUrl: 'external_partner_login_url',
  externalPartnerSignUpUrl: 'external_partner_sign_up_url',
  logoutRedirectUrl: 'logout_redirect_url',
  logoRedirectUrl: 'header_image_redirect_url',
  signupUrl: 'signup_url',
  adGemEnabled: 'adgem_enabled',
  useAdgemIframe: 'use_adgem_iframe',
  adGemAppId: 'adgem_app_id',
  facebookEnabled: 'facebook_enabled',
  facebookId: 'facebook_id',
  facebookApiVersion: 'facebook_api_version',
  facebookRedirectUri: 'facebook_redirect_uri',
  useLandingPage: 'use_landing_page',
  charityEnabled: 'charity_enabled',
  giftCardsEnabled: 'gift_cards_enabled',
  spotifyEnabled: 'spotify_enabled',
  spotifyClientId: 'spotify_client_id',
  spotifyScopes: 'spotify_scopes',
  pennyAuctionBaseURL: 'penny_auction_base_url',
  pennyAuctionAppId: 'penny_auction_app_id',
  pennyAuctionAppSecret: 'penny_auction_app_secret',
  pennyAuctionSearchLimit: 'penny_auction_search_limit',
  hotjarId: 'hotjar_id',
  recaptchaSiteKey: 'recaptcha_site_key',
  upholdAuthLink: 'uphold_auth_link',
  upholdAppState: 'uphold_app_state',
  upholdAddFundsLink: 'uphold_add_funds_link',
  upholdVerificationLink: 'uphold_verification_link',
  onboardingEnabled: 'onboarding_enabled',
  showUberPointsOption: 'show_uber_points_option',
  gtmId: 'gtm_id',
  useExternalWallets: 'use_external_wallets',
  followArtistEnabled: 'follow_artist_enabled',
  scratchOffGameName: 'scratch_off_game_name',
  scratchOffCoverImage: 'scratch_off_cover_image',
  gamesPageEnabled: 'games_page_enabled',
  scratchPageEnabled: 'scratch_page_enabled',
  earnMoreEnabled: 'earn_more_enabled',
  followTrackEnabled: 'follow_track_enabled',
  revUAppId: 'revu_app_id',
  revUEnabled: 'revu_enabled',
};
