import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    margin: theme.spacing(0, -2, -2),
    padding: theme.spacing(0, 2, 2),
    height: 560,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(376)]: {
      height: 510,
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: '500',
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(2),
  },
  bg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: -1,
    height: '100%',
    objectFit: 'cover',
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.white.main,
  },
  button: {
    margin: theme.spacing(4, 0, 2),
    width: '100%',
    background: theme.palette.white.main,
    '&:hover': {
      background: theme.palette.white.main,
    },
    [theme.breakpoints.down(376)]: {
      margin: theme.spacing(3, 0, 1),
    },
  },
  footerLink: {
    textDecoration: 'underline',
    textAlign: 'center',
    color: theme.palette.white.main,
    cursor: 'pointer',
  },
}));
