import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardSelectWrapper: {
    marginTop: theme.spacing(2),
    '& div': {
      marginBottom: 0,
    },
  },
  exchangeAmount: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  currencyIcon: {
    height: '1em',
    marginRight: theme.spacing(0.6),
  },
  currencyText: {
    fontSize: '14px',
    fontWeight: 700,
  },
  addFundsLink: {
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: theme.spacing(5),
    textDecoration: 'none',
  },
  note: {
    fontSize: '10px',
    marginTop: theme.spacing(0.5),
    color: theme.palette.greyDark.main,
  },
  buttonReload: {
    marginTop: '20px',
    width: '100%',
  },
}));
