import React from 'react';

import PropTypes from 'prop-types';

import parse from 'html-react-parser';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

const ResultModal = ({
  heading,
  secondaryText,
  background,
  buttonLabel,
  handleBtnClick,
  footerLink,
  handleFooterLinkClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h4" className={classes.title}>
        {heading}
      </Typography>
      <div />
      <img src={background} alt="spin-result-bg" className={classes.bg} />
      <div>
        <Typography
          variant="h5"
          className={classes.subTitle}
          data-cy="result-modal-secondary-text"
        >
          {secondaryText && parse(secondaryText)}
        </Typography>
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          onClick={handleBtnClick}
          data-cy="result-modal-submit-button"
        >
          {buttonLabel}
        </Button>
        {footerLink && (
          <Typography
            variant="h6"
            className={classes.footerLink}
            onClick={handleFooterLinkClick}
          >
            {footerLink}
          </Typography>
        )}
      </div>
    </div>
  );
};

ResultModal.defaultProps = {
  secondaryText: '',
};

ResultModal.propTypes = {
  heading: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  background: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  handleBtnClick: PropTypes.func.isRequired,
};

export default ResultModal;
