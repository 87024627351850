import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';

const CircleIcon = ({ className, ...rest }) => {
  const classes = useStyles();

  return <span className={clsx(classes.circle, className)} {...rest} />;
};

export default CircleIcon;
