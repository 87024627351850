import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    boxSizing: 'border-box',
    padding: theme.spacing(2, 0, 1),
    [theme.breakpoints.up(1024)]: {
      padding: theme.spacing(3.25, 0, 3.75),
    },
  },
  text: {
    margin: theme.spacing(0, 1, 0, 0),
    color: theme.palette.greyDark.main,
  },
  container: {
    maxWidth: 1295,
    padding: theme.spacing(0, 2),
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  powerInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    order: 1,
    maxHeight: 40,
    [theme.breakpoints.up(768)]: {
      width: '50%',
      order: 0,
      justifyContent: 'flex-start',
    },
  },
  logo: {
    width: 155,
    margin: 0,
    textAlign: 'left',
    '& img': {
      height: 'auto',
    },
    '& a': {
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footerList: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    order: 0,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    [theme.breakpoints.up(768)]: {
      justifyContent: 'flex-end',
      width: '50%',
      order: 1,
    },

    '& > li': {
      margin: theme.spacing(0, 0, 1),
      color: theme.palette.greyDark.main,
      [theme.breakpoints.up(768)]: {
        margin: 0,
      },
      '& a': {
        color: theme.palette.greyDark.main,
      },
      '&::before': {
        content: '"|"',
        margin: theme.spacing(0, 1.25, 0, 1.5),
        display: 'inline-block',
        verticalAlign: 'top',
        fontSize: '0.9rem',
        [theme.breakpoints.up(768)]: {
          margin: theme.spacing(0, 1.75, 0, 2),
          fontSize: '1rem',
        },
      },

      '&:first-child': {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
}));
