import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    padding: theme.spacing(3, 0),
    fontWeight: 'bold',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    flex: 1,
    padding: theme.spacing(0, 1, 2),
  },
  poweredBy: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    fontSize: '0.75rem',
    padding: theme.spacing(2, 0),
  },
}));
