import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  authButton: {
    width: '100%',
    maxWidth: 300,
    textTransform: 'initial',
    background: theme.palette.grey.third,
    borderRadius: 5,
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.grey.third,
      boxShadow: 'none',
    },
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(6),
  },
  icon: {
    position: 'absolute',
    left: theme.spacing(2),
    maxHeight: 28,
  },
}));
