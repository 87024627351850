import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  data: [],
  error: null,
  isLoaded: false,
};

const winnersSlice = createSlice({
  name: 'winners',
  initialState,
  reducers: {
    getWinners: (state) => {
      state.isLoading = true;
    },
    getWinnersSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = action.payload;
    },
    getWinnersFailure: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.payload;
    },
  },
});

export const {
  getWinners,
  getWinnersFailure,
  getWinnersSuccess,
} = winnersSlice.actions;

export default winnersSlice.reducer;
