import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { selectCmsItem } from 'redux/cms';
import { SWEEPS_CATEGORIES_KEY } from 'constants/sweeps';

export const selectSweeps = (state) => _get(state, 'sweeps', {});

export const selectFilteredSweeps = (activeCategory) =>
  createSelector([selectSweeps], ({ sweepstakes }) => {
    if (activeCategory === 'all') {
      return [...sweepstakes].sort((a, b) => a.priority - b.priority);
    }
    return sweepstakes
      .filter((sweepstake) => sweepstake.categories.includes(activeCategory))
      .sort((a, b) => a.priority - b.priority);
  });

export const selectSweepstakesCategories = createSelector(
  [(state) => selectCmsItem(state, { key: SWEEPS_CATEGORIES_KEY })],
  (sweepstakesCategories) => {
    const items = _get(sweepstakesCategories, 'data.items', []);
    return [
      ...items.slice().sort((a, b) => (a.priority > b.priority ? 1 : -1)),
      { displayName: 'All', key: 'all' },
      { displayName: 'Winners', key: 'winners' },
    ];
  }
);

export const selectDefaultActiveCategory = createSelector(
  [(state) => selectCmsItem(state, { key: SWEEPS_CATEGORIES_KEY })],
  (sweepstakesCategories) => {
    const items = _get(sweepstakesCategories, 'data.items', []);
    const activeCategory = items.find((item) => item.default === 'true');
    return activeCategory?.key || 'all';
  }
);

export const selectSweepstakeById = (sweepId) =>
  createSelector([selectSweeps], ({ sweepstakes }) =>
    sweepstakes?.find((sweep) => sweep.id === +sweepId)
  );

export const selectEventByEventId = ({ sweepId, eventId }) =>
  createSelector(
    [(state) => selectSweepstakeById(sweepId)(state)],
    (sweepstake) => sweepstake?.events?.find((event) => event.id === +eventId)
  );

export const selectSponsorData = (sweepId) =>
  createSelector(
    [(state) => selectSweepstakeById(sweepId)(state)],
    (sweepstake) => {
      const data = _get(sweepstake, 'data', {});
      if (!sweepstake?.sponsored || !data.sponsor) {
        return {};
      }
      const {
        sponsor: {
          image_link,
          youtube_link,
          copy_html,
          official_website_copy,
          name,
          success_page_button_copy,
          success_page_button_enabled,
          success_page_button_url,
        },
      } = data;

      return {
        imageLink: image_link || sweepstake?.profile_image,
        youtubeLink: youtube_link,
        copy: copy_html,
        websiteCopy: official_website_copy,
        name,
        buttonCopy: success_page_button_copy,
        buttonIsEnabled: success_page_button_enabled,
        buttonUrl: success_page_button_url,
      };
    }
  );

export const selectOrderDetails = createSelector(
  [selectSweeps],
  ({ orderDetails, sendingEntriesError, isLoading }) => ({
    orderDetails,
    sendingEntriesError,
    isLoading,
  })
);
