export default {
  typography: {
    fontSize: 10.5,
    color: '#FFFFFF',
    fontFamily: 'Poppins, sans-serif',
  },
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontSize: '0.82rem',
        lineHeight: '15px',
      },
    },
    MuiFormHelperText: {
      contained: {
        fontSize: '0.7rem',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        pointerEvents: 'initial !important',
      },
      contained: {
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#000000',
        },
      },
      containedSizeLarge: {
        fontSize: '1rem',
        lineHeight: '20px',
        paddingTop: 16,
        paddingBottom: 16,
      },
      outlinedSizeLarge: {
        fontSize: '1rem',
        lineHeight: '20px',
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: 'rgb(132, 142, 151)',
      },
      vertical: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
  },
  palette: {
    info: {
      main: 'rgb(235,87,87)', // #EB5757
    },
    success: {
      main: '#05A357',
    },
    error: {
      main: 'rgb(255,0,0)', // #FF0000
    },
    white: {
      main: 'rgb(255,255,255)', // #FFFFFF
    },
    black: {
      main: 'rgb(0,0,0)', // #000000
    },
    grey: {
      main: 'rgb(224,224,224)', // #E0E0E0
      withOpacity: 'rgba(224,224,224, .8)',
      secondary: '#D0CCD0',
      third: '#F9F9F9',
    },
    greyDark: {
      main: '#757575',
      secondary: '#545454',
    },
    border: {
      main: 'rgba(16,19,20,0.1)', // #101314 + opacity 0.12
    },
    dark: {
      main: '#000000',
      secondary: '#101314',
      withOpacity: 'rgba(0, 0, 0, 0.6)',
    },
    selection: {
      main: 'rgba(255,255,255,0.3)',
    },
    transparent: {
      main: 'transparent',
    },
  },
};
