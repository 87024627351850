import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  activeLink: {
    backgroundColor: theme.palette.accentLight.main,

    '&:hover': {
      backgroundColor: theme.palette.accentLight.main,
    },
  },
  listItemText: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    minWidth: 0,
  },
  logoutButton: {
    paddingTop: 0,
  },
  logo: {
    maxHeight: '36px',
    margin: theme.spacing(2, 'auto', 0),
  },
  poweredBy: {
    maxHeight: '30px',
    margin: theme.spacing('auto', 0, 2),
  },
}));
