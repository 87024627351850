import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { compareAsc } from 'date-fns';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { fetchSweeps, selectSweeps, selectSweepstakeById } from 'redux/sweeps';

import Loader from 'components/Loader';

import { PATHS } from 'constants/navigation';

import { useUserNavigation } from 'hooks/navigation';

import EventsCard from '../components/EventsCard';

import useStyle from './styles';

const EventsPage = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const { isLoading } = useSelector(selectSweeps);
  const sweepstake = useSelector(selectSweepstakeById(match.params.sweepId));

  useEffect(() => {
    dispatch(fetchSweeps());
  }, [dispatch]);

  useUserNavigation({ backUrl: PATHS.sweepstakes });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {sweepstake && (
        <>
          <div className={classes.header}>
            <Typography className={classes.title} variant="h4">
              {sweepstake.name}
            </Typography>
            <Typography variant="h4">Select an event:</Typography>
          </div>
          <Grid container>
            {sweepstake.events
              .slice()
              .sort((a, b) =>
                compareAsc(new Date(a.event_date), new Date(b.event_date))
              )
              .map((event) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={event.id}>
                  <EventsCard {...event} sweepId={sweepstake.id} />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default EventsPage;
