import React, { useCallback, useState } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Modal from 'components/Modal';
import { clearCouponData } from 'redux/coupon';
import { COUPON_EXPERIENCE } from 'constants/coupons';
import { PATHS } from 'constants/navigation';

import CouponImage from 'assets/images/coupon-image.png';

import { useStyles } from './styles';

export const CouponSuccess = ({ coupon }) => {
  const classes = useStyles();
  const type = COUPON_EXPERIENCE[coupon.product.destination_currency_type.name];

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        You’ve unlocked
      </Typography>
      <Typography
        variant="h4"
        className={classes.subTitle}
        data-cy="coupon-denomination"
      >
        {Number(coupon.denomination)} {type} tokens!
      </Typography>
    </>
  );
};

export const ReferralCode = () => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h4"
        className={classes.title}
        data-cy="referral-bonus"
      >
        Welcome, 1st token on us!
      </Typography>
      <Grid
        container
        item
        xs={12}
        justify="center"
        className={classes.imgContainer}
      >
        <img src={CouponImage} alt="Sweepstakes" className={classes.img} />
      </Grid>
    </>
  );
};

export const CouponError = ({ error }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpened, changeIsOpened] = useState(!!error.message);

  const handleLetsGo = useCallback(() => {
    dispatch(clearCouponData());
    dispatch(push({ ...location, pathname: PATHS.exchange }));
  }, [dispatch, location]);

  const toggleModal = useCallback(() => {
    changeIsOpened(!isOpened);
    handleLetsGo();
  }, [handleLetsGo, isOpened]);

  return (
    <Modal open={isOpened} toggleModal={toggleModal} showClose={false}>
      <Typography className={classes.couponError} data-cy="coupon-error-modal">
        Code error! Please try again.
      </Typography>
      <Button
        variant="contained"
        className={classes.modalButton}
        color="primary"
        size="large"
        onClick={handleLetsGo}
        data-cy="coupon-error-modal-submit"
      >
        Let`s go!
      </Button>
    </Modal>
  );
};
