import React, { useCallback } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import DotTab from 'components/DotTab';

import { logoutUser } from 'redux/user';
import { storage } from 'services/storage';
import PoweredByTap from 'assets/images/my-tap-rewards-logo.svg';
import images from 'assets/images';
import { PATHS, supportItems } from 'constants/navigation';
import { getSidebarItems } from 'constants/menu';
import { excludedRedirectPaths } from 'utils/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { selectConfigProperty, selectLoginPath } from 'redux/config';

import { useIsLoggedIn } from 'hooks/user';
import { useGamesAvailability } from 'hooks/games';

import { useStyles } from './styles';

const SidebarMenu = ({ sidebarOpen, closeDrawer }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const activeCategory = storage.local.get('activeCategory');

  const isGamesPageEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.gamesPageEnabled)
  );
  const isChooseExperienceEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.chooseExperienceEnabled)
  );

  const loginPath = useSelector(selectLoginPath);

  const shouldShowGamesDot = useGamesAvailability();

  const { loggedInRoutesList, loggedOutRoutesList } = getSidebarItems(
    isChooseExperienceEnabled,
    isGamesPageEnabled
  );

  const toggleDrawer = (toggle) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    closeDrawer(toggle);
  };

  const handleLogOut = useCallback(() => dispatch(logoutUser()), [dispatch]);

  const skipRedirect = excludedRedirectPaths.includes(location.pathname);

  const handleLogIn = useCallback(
    () =>
      dispatch(
        push(
          `${loginPath}${
            skipRedirect
              ? ''
              : `?redirect_uri=${encodeURIComponent(window.location.href)}`
          }`
        )
      ),
    [dispatch, skipRedirect, loginPath]
  );

  const handleListItemClick = (item) => {
    // Such stuff needs for the winners section which includes
    // as a part of sweepstakes and doesn't exist on a separate route,
    // but also should be highlighted as active in the sidebar menu
    if (item === 'Winners') {
      storage.local.set('activeCategory', item.toLowerCase());
    } else if (item === 'Sweepstakes' && activeCategory === 'winners') {
      storage.local.removeItem('activeCategory');
    }
    if (supportItems.includes(item)) {
      window.ZohoHCAsapReady(() => {
        window.ZohoHCAsap.Action('open');
      });
    }
  };

  const getSidebar = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <img src={images.logo} alt="header-logo" className={classes.logo} />
      <List>
        {(isLoggedIn ? loggedInRoutesList : loggedOutRoutesList).map(
          (item) =>
            item && (
              <ListItem
                button
                component={item.link ? 'a' : NavLink}
                key={item.name}
                to={{
                  ...location,
                  pathname: !supportItems.includes(item.name)
                    ? item.path
                    : undefined,
                }}
                href={item.link}
                target={item.link ? '_blank' : '_self'}
                className={classes.link}
                activeClassName={classes.activeLink}
                isActive={() => {
                  const match = matchPath(location.pathname, {
                    path: item.path,
                  });
                  // Such stuff needs for the winners section which includes
                  // as a part of sweepstakes and doesn't exist on a separate route,
                  // but also should be highlighted as active in the sidebar menu
                  if (
                    activeCategory === 'winners' &&
                    location.pathname === PATHS.sweepstakes
                  ) {
                    return activeCategory === item.name.toLowerCase();
                  }
                  return !!match && item.name.toLocaleLowerCase() !== 'winners';
                }}
                data-cy={item['data-cy']}
                onClick={() => handleListItemClick(item.name)}
              >
                <ListItemText
                  primary={
                    item.path === PATHS.games ? (
                      <DotTab
                        text={item.name}
                        showDot={shouldShowGamesDot}
                        className={classes.listItemText}
                      />
                    ) : (
                      <Typography
                        component="div"
                        variant="body1"
                        className={classes.listItemText}
                      >
                        {item.name}
                      </Typography>
                    )
                  }
                />
              </ListItem>
            )
        )}
        <ListItem
          button
          className={classes.logoutButton}
          onClick={isLoggedIn ? handleLogOut : handleLogIn}
          data-cy={isLoggedIn ? 'menu-log-out' : 'menu-log-in'}
        >
          <ListItemText
            primary={
              <Typography variant="body1" className={classes.listItemText}>
                {isLoggedIn ? 'Log Out' : 'Log In'}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <img
        src={PoweredByTap}
        alt="Powered By Tap"
        className={classes.poweredBy}
      />
    </div>
  );

  return (
    <Drawer anchor="left" open={sidebarOpen} onClose={toggleDrawer(false)}>
      {getSidebar()}
    </Drawer>
  );
};

export default SidebarMenu;
