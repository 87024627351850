import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  videoFrame: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  link: {
    margin: theme.spacing(3, 0, 2),
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
  },
  cardImageWrapper: {
    display: 'inline-block',
    borderRadius: 10,
    overflow: 'hidden',
  },
  cardImage: {
    display: 'block',
    maxWidth: '9.375rem',
    maxHeight: '9.375rem',
    margin: '-1px', // hack to avoid white border
  },
  button: {
    maxWidth: 315,
    borderRadius: 5,
    marginBottom: theme.spacing(3),
  },
}));
