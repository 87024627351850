import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  empty: {
    lineHeight: '22px',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    color: theme.palette.dark.main,
  },
}));
