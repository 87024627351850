import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  content: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
}));
