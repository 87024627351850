import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    maxWidth: 350,
  },
  item: {
    marginBottom: theme.spacing(5),
  },
  infoIcon: {
    width: '1.1em',
    height: '1.1em',
  },
  imgContainer: {
    margin: theme.spacing(1, 0, 2, 0),
    position: 'relative',
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: 200,
  },
  hiddenImg: {
    filter: 'grayscale(100%)',
  },
  sold: {
    fontSize: '4rem',
    position: 'absolute',
    fontWeight: 'bold',
    transform: 'rotate(-30deg)',
  },
  currentBid: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    letterSpacing: '1px',
    margin: theme.spacing(0.5, 0),
  },
  productDetails: {
    width: '100%',
  },
  accordion: {
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  accordionSummary: {
    minHeight: 16,
    '&.Mui-expanded': {
      minHeight: 16,
    },
  },
  accordionSummaryContent: {
    justifyContent: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    wordBreak: 'break-word',
    textAlign: 'left',
    padding: theme.spacing(0, 2, 2, 2),
  },
  hint: {
    minHeight: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
  bidBtn: {
    padding: theme.spacing(2, 2.75),
    marginBottom: theme.spacing(2),
  },
  tokensLink: {
    marginBottom: theme.spacing(3.5),
  },
  tokensBtn: {
    padding: theme.spacing(1.5, 2.75),
    fontSize: '1rem',
  },
  redTime: {
    color: theme.palette.info.main,
  },
  flashAnimation: {
    animation: '$flashAnimation 1s ease-in-out',
  },
  '@keyframes flashAnimation': {
    '0%': {
      backgroundColor: 'transparent',
    },
    '30%': {
      backgroundColor: 'rgba(246,185,23,0.4)',
    },
    '50%': {
      backgroundColor: 'rgba(246,185,23,0.4)',
    },
    '80%': {
      backgroundColor: 'rgba(246,185,23,0.4)',
    },
    '100%': {
      backgroundColor: 'transparent',
    },
  },
}));
