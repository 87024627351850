import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  referral_code: '',
  email: '',
  error: '',
  completed_onboarding: false,
  isValidUser: false,
  isLoaded: false,
  isLoading: false,
  first_name: '',
  last_name: '',
  onboarding_step: null,
  isSpotifyLogin: false,
  isForgotPasswordSuccessful: false,
  isCodeResending: false,
  isCodeResent: false,
  resendCodeError: null,
  spotifyConnectError: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, actions) => {
      Object.assign(state, actions.payload);
      if (actions.payload.completed_onboarding) {
        state.onboarding_step = null;
      }
      state.isValidUser = true;
      state.isLoaded = true;
      state.error = '';
    },
    setLoadingStart: (state) => {
      state.isLoading = true;
    },
    setLoadingEnd: (state) => {
      state.isLoading = false;
    },
    signupUser: () => {},
    signupWithOnboarding: () => {},
    verifyPhone: () => {},
    resendCode: (state) => {
      state.isCodeResending = true;
      state.resendCodeError = null;
      state.isCodeResent = false;
    },
    resendCodeSuccess: (state) => {
      state.isCodeResending = false;
      state.isCodeResent = true;
    },
    resendCodeFailure: (state, action) => {
      state.isCodeResending = false;
      state.resendCodeError = action.payload.error;
    },
    logoutUser: () => initialState,
    getUserAccount: () => {},
    facebookLogin: () => {},
    spotifyLogin: (state) => {
      state.isSpotifyLogin = true;
    },
    spotifyConnect: () => {},
    spotifyConnectError: (state, action) => {
      state.spotifyConnectError = {
        title: action.payload?.title
          ? action.payload.title
          : 'Spotify connect error',
        description: action.payload?.description,
      };
    },
    removeConnectError: (state) => {
      state.spotifyConnectError = null;
    },
    upholdLogin: () => {},
    loginUser: () => {},
    clearUserError: (state) => {
      state.error = '';
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.onboarding_step = state.onboarding_step
        ? null
        : state.onboarding_step;
    },

    updateUserProfile: () => {},
    updateUser: () => {},
    forgotPassword: () => {},
    forgotPasswordSuccess: (state) => {
      state.isForgotPasswordSuccessful = true;
    },
    resetForgotPassword: (state) => {
      state.isForgotPasswordSuccessful = false;
    },
    resetPasswordConfirm: () => {},
    resetPasswordConfirmSuccess: (state) => {
      state.isForgotPasswordSuccessful = true;
    },
  },
});

export const {
  setUserData,
  setLoadingStart,
  setLoadingEnd,
  signupUser,
  signupWithOnboarding,
  logoutUser,
  getUserAccount,
  facebookLogin,
  spotifyLogin,
  upholdLogin,
  loginUser,
  clearUserError,
  setError,
  forgotPassword,
  forgotPasswordSuccess,
  updateUserProfile,
  updateUser,
  resetForgotPassword,
  resetPasswordConfirm,
  resetPasswordConfirmSuccess,
  verifyPhone,
  resendCode,
  resendCodeSuccess,
  resendCodeFailure,
  spotifyConnect,
  spotifyConnectError,
  removeConnectError,
} = userSlice.actions;

export default userSlice.reducer;
