import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isModalLoading: false,
  isModalOpen: false,
  isWinner: false,
  hasFeatured: false,
  iconsList: [],
  isButtonDisabled: false,
  orderId: null,
  modalHeadingText: '',
  modalSecondaryText: '',
  modalButtonText: '',
  modalPopupImage: '',
  errors: [],
};

const scratchGameSlice = createSlice({
  name: 'scratchGame',
  initialState,
  reducers: {
    fetchScratchGame: (state) => {
      state.isLoading = true;
      state.isButtonDisabled = true;
      state.errors = [];
    },
    fetchScratchGameSuccess: (state, action) => {
      state.isLoading = false;
      state.isWinner = action.payload.is_winner;
      state.hasFeatured = action.payload.has_featured;
      state.iconsList = action.payload.icons_list;
      state.orderId = action.payload.order.id;
    },
    fetchScratchGameFailure: (state, action) => {
      state.isLoading = false;
      state.isButtonDisabled = false;
      state.errors = action.payload.data.errors;
    },

    fetchScratchGameReward: (state) => {
      state.isModalLoading = true;
      state.errors = [];
    },
    fetchScratchGameRewardSuccess: (state, action) => {
      state.isModalLoading = false;
      state.isModalOpen = true;
      state.isButtonDisabled = false;
      state.modalHeadingText = action.payload.heading;
      state.modalSecondaryText = action.payload.secondary_text;
      state.modalButtonText = action.payload.button_text;
      state.modalPopupImage = action.payload.popup_image;
    },
    fetchScratchGameRewardFailure: (state, action) => {
      state.isModalLoading = false;
      state.errors = action.payload.data.errors;
    },

    handleOpenModal: (state) => {
      state.isModalOpen = true;
    },
    handleCloseModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const {
  fetchScratchGame,
  fetchScratchGameSuccess,
  fetchScratchGameFailure,
  fetchScratchGameReward,
  fetchScratchGameRewardSuccess,
  fetchScratchGameRewardFailure,
  handleOpenModal,
  handleCloseModal,
} = scratchGameSlice.actions;

export default scratchGameSlice.reducer;
