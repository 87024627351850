import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { GamesRoutesGuard } from 'routes/gamesRoutes';

// Sweepstakes pages
import SweepstakesList from 'pages/Sweepstakes/List';
import SweepstakesDetails from 'pages/Sweepstakes/Details';
import SweepstakesSuccess from 'pages/Sweepstakes/Success';
import EventsPage from 'pages/Sweepstakes/Events';

// Auth pages
import Login from 'pages/LoginPage';
import SignupPage from 'pages/Signup';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import SpotifyLogin from 'pages/SpotifyLogin';
import ResetPassword from 'pages/ResetPasswordPage';
import UpholdLogin from 'pages/UpholdLogin';

// Gift cards pages
import GiftCardsListPage from 'pages/GiftCards/List';
import GiftCardDetailsPage from 'pages/GiftCards/Details';
import GiftCardSuccessPage from 'pages/GiftCards/Success';

// Charity donation pages
import CharityUpsellPage from 'pages/Charity/Upsell';
import DonationPage from 'pages/Charity/Donation';
import CharitiesPage from 'pages/Charity/List';
import CharityPayment from 'pages/Charity/Payment';
import CharityConfirmationPage from 'pages/Charity/Confirmation';

import SpinnerPage from 'pages/SpinnerPage';
import CouponPage from 'pages/CouponPage';
import RulesPage from 'pages/RulesPage';
import LegalPage from 'pages/LegalPage';
import ChooseExperiencePage from 'pages/ChooseExperiencePage';
import Wallet from 'pages/WalletPage';
import Support from 'pages/SupportPage';
import ReferralPage from 'pages/ReferralPage';
import GetMoreTokensPage from 'pages/GetMoreTokensPage';
import LiveBidsDetailsPage from 'pages/LiveBidsDetailsPage';
import Onboarding from 'pages/Onboarding';
import GamesListPage from 'pages/Games/List';
import ScratchPage from 'pages/ScratchPage';
import RevU from 'pages/RevU';
// Follow Pages
import FollowArtists from 'pages/Follow/Artists';
import FollowTracks from 'pages/Follow/Tracks';

import { useTrackAllPageViews } from 'hooks/analytics';
import { useNavigationRedirect, usePartnerLandingPage } from 'hooks/navigation';
import { getIsDebugEnv, getPartner } from 'utils/config';
import { PATHS } from 'constants/navigation';

import ProtectedRoute from './protectedRoute';

const Profile = lazy(() => import('../pages/ProfilePage'));
const ComponentIndex = lazy(() => import('../components/ComponentIndex'));

export const Routes = () => {
  usePartnerLandingPage();
  useTrackAllPageViews();
  useNavigationRedirect();

  const partner = getPartner().name;

  return (
    <Switch>
      {/* Auth & Coupon Connector */}
      {partner === 'uber' ? (
        <Route exact path={PATHS.root} component={CouponPage} />
      ) : (
        <Route exact path={PATHS.root}>
          <Redirect to={PATHS.sweepstakes} />
        </Route>
      )}
      <Route exact path={PATHS.exchange} component={CouponPage} />
      <Route exact path={PATHS.login} component={Login} />
      <Route exact path={PATHS.signUp} component={SignupPage} />
      <Route exact path={PATHS.forgotPassword} component={ForgotPasswordPage} />
      <Route exact path={PATHS.resetPassword} component={ResetPassword} />
      <Route exact path={PATHS.loginSpotify} component={SpotifyLogin} />
      <Route exact path={PATHS.loginUphold} component={UpholdLogin} />
      {/* Onboarding */}
      <Route exact path={PATHS.onboarding} component={Onboarding} />

      <Route
        exact
        path={PATHS.chooseExperience}
        component={ChooseExperiencePage}
      />
      {/* Get more tokens page */}
      <ProtectedRoute
        exact
        path={PATHS.moreTokens}
        component={GetMoreTokensPage}
      />
      {/* Referral Page */}
      <ProtectedRoute exact path={PATHS.referral} component={ReferralPage} />
      {/* Charity Upsell */}
      <ProtectedRoute exact path={PATHS.charities} component={CharitiesPage} />
      {/* Donation Page  */}
      <ProtectedRoute exact path={PATHS.donations} component={DonationPage} />
      <ProtectedRoute
        exact
        path={PATHS.charityUpsell}
        component={CharityUpsellPage}
      />
      <ProtectedRoute
        exact
        path={`${PATHS.charities}/:id/payment`}
        component={CharityPayment}
      />
      <ProtectedRoute
        exact
        path={`${PATHS.charities}/:receiptId/success`}
        component={CharityConfirmationPage}
      />
      {/* Sweepstakes */}
      <Route exact path={PATHS.sweepstakes} component={SweepstakesList} />
      <Route exact path={PATHS.terms} component={LegalPage} />
      <Route exact path={PATHS.privacy} component={LegalPage} />

      <Route exact path="/sweepstakes">
        <Redirect to={PATHS.sweepstakes} />
      </Route>
      <Route
        exact
        path={`${PATHS.sweepstakes}/:sweepId`}
        component={EventsPage}
      />
      <Route
        exact
        path={`${PATHS.sweepstakes}/:sweepId/:eventId/success`}
        component={SweepstakesSuccess}
      />
      <Route
        exact
        path={`${PATHS.sweepstakes}/:sweepId/:eventId`}
        component={SweepstakesDetails}
      />
      <Route
        exact
        path={`${PATHS.sweepstakes}/:sweepId/:eventId/rules`}
        component={RulesPage}
      />
      {/* Wallet */}
      <ProtectedRoute exact path={PATHS.activity}>
        <Redirect to={PATHS.sweepstakesActivity} />
      </ProtectedRoute>

      <Route exact path={PATHS.followTracks} component={FollowTracks} />
      <Route exact path={PATHS.followArtists} component={FollowArtists} />

      <ProtectedRoute
        exact
        path={`${PATHS.activity}/:activityType`}
        component={Wallet}
      />
      {/* Gift cards  */}
      <Route exact path={PATHS.giftCards} component={GiftCardsListPage} />
      <Route
        exact
        path={`${PATHS.giftCards}/:orderId/success`}
        component={GiftCardSuccessPage}
      />
      <Route
        exact
        path={`${PATHS.giftCards}/:countryCode/:giftCardId`}
        component={GiftCardDetailsPage}
      />
      {/* Penny Auction live bids */}
      <Route
        exact
        path={`${PATHS.liveBids}/:id`}
        component={LiveBidsDetailsPage}
      />
      {/* Support */}
      <Route exact path={PATHS.support} component={Support} />
      {/* Profile */}
      <ProtectedRoute exact path={PATHS.profile} component={Profile} />
      <ProtectedRoute exact path={PATHS.offers} component={RevU} />
      {/* Debug */}
      {getIsDebugEnv() && (
        <Route exact path="/components" component={ComponentIndex} />
      )}

      {/* Games */}
      <GamesRoutesGuard>
        <Route exact path={PATHS.games} component={GamesListPage} />

        <ProtectedRoute exact path={PATHS.scratch} component={ScratchPage} />
        <Route exact path={PATHS.spin} component={SpinnerPage} />

        {/* Games rules */}
        <Route exact path={PATHS.spinnerRules} component={LegalPage} />
        <Route exact path={PATHS.scratchRules} component={LegalPage} />
      </GamesRoutesGuard>

      {/* Error Pages */}
      <Route component={() => <div>Not found</div>} />
    </Switch>
  );
};
