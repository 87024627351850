import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(4, 0),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.grey.third,
    border: '1px solid rgba(16, 19, 20, 0.1)',
    borderRadius: '10px',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 'auto', 2),
    color: theme.palette.greyDark.secondary,
    textDecoration: 'none',
    maxWidth: '350px',
  },
  itemTitle: {
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    marginLeft: theme.spacing(4),
  },
  icon: {
    fontSize: '5.5rem',
  },
}));
