import { takeLeading, call, put, select, debounce } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { PATHS } from 'constants/navigation';
import {
  fetchGiftCardsRequest,
  redeemGiftCardRequest,
  createOrderRequest,
  commitOrderRequest,
} from 'services/api/giftCards';

import {
  fetchGiftCards,
  fetchGiftCardsSuccess,
  fetchGiftCardsFailure,
  redeemGiftCard,
  redeemGiftCardSuccess,
  redeemGiftCardFailure,
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  commitOrder,
  commitOrderSuccess,
  commitOrderFailure,
} from './slice';
import { selectOrder } from './selectors';

function* handleFetchGiftCards({ payload }) {
  try {
    const {
      data: { gift_cards },
    } = yield call(fetchGiftCardsRequest, payload);
    yield put(fetchGiftCardsSuccess(gift_cards));
  } catch (error) {
    yield put(
      fetchGiftCardsFailure(error?.data?.error || error?.data?.errors[0])
    );
  }
}

function* handleRedeemGiftCard({ payload }) {
  try {
    const { data } = yield call(redeemGiftCardRequest, payload);
    yield put(redeemGiftCardSuccess());
    yield put(push(`${PATHS.giftCards}/${data.order.id}/success`));
  } catch (error) {
    yield put(
      redeemGiftCardFailure(error?.data?.error || error?.data?.errors[0])
    );
  }
}

function* handleCreateOrder({ payload }) {
  try {
    const order = yield select(selectOrder);
    const params = {
      id: payload?.id || order.id,
      amount: payload?.amount || order.amount,
      cardId: payload?.cardId || order.cardId,
      countryCode: payload?.countryCode || order.countryCode,
    };
    if (!params.id || !params.amount || !params.cardId || !params.countryCode) {
      return;
    }
    const { data } = yield call(createOrderRequest, params);
    yield put(createOrderSuccess(data.data));
  } catch (error) {
    yield put(createOrderFailure(error?.data?.errors));
  }
}
function* handleCommitOrder() {
  try {
    const order = yield select(selectOrder);
    const { data } = yield call(commitOrderRequest, {
      transactionId: order.transactionId,
      countryCode: order.countryCode,
    });
    yield put(push(`${PATHS.giftCards}/${data.id}/success`));
    yield put(commitOrderSuccess());
  } catch (error) {
    yield put(commitOrderFailure(error?.data?.errors));
  }
}

export function* giftCardsSaga() {
  yield takeLeading(fetchGiftCards, handleFetchGiftCards);
  yield takeLeading(redeemGiftCard, handleRedeemGiftCard);
  yield debounce(300, createOrder.type, handleCreateOrder);
  yield takeLeading(commitOrder, handleCommitOrder);
}
