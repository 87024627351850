import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import { getPopups, selectPopups } from 'redux/cms';
import { storage } from 'services/storage';
import { selectUserInfo } from 'redux/user';
import { selectConfigProperty } from 'redux/config';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { fetchAdGemData } from 'services/api/adgem';

import Modal from '../Modal';
import { useStyles } from './styles';

const GenericPopups = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector(selectPopups);
  const user = useSelector(selectUserInfo);
  const adGemEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.adGemEnabled)
  );
  const adGemAppId = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.adGemAppId)
  );

  const [currentPopups, setCurrentPopups] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [addGemOffer, setAddGemOffer] = useState(null);

  const getVisiblePopups = () =>
    JSON.parse(storage.local.get('visiblePopups') || '{}');

  const getCache = () => JSON.parse(storage.local.get('popupStorage') || '[]');

  useEffect(() => {
    dispatch(getPopups());
  }, [dispatch]);

  useEffect(() => {
    if (data.length) {
      const popups = data
        .filter((i) => {
          const regexp = new RegExp(
            `${i.location_route.replaceAll('*', '[^/]+')}$`
          );
          return regexp.test(location.pathname) && !getCache().includes(i.id);
        })
        .map((item) =>
          getVisiblePopups()[item.id]
            ? { ...item, ...getVisiblePopups()[item.id] }
            : item
        );
      setCurrentPopups(popups);
    }
  }, [location.pathname, data]);

  const current = currentPopups[0];

  const closeModal = () => {
    setIsOpened(false);
    if (current.times_to_display) {
      const nextTimeToDisplay = current.times_to_display - 1;
      if (nextTimeToDisplay) {
        updateVisiblePopups({
          id: current.id,
          times_to_display: nextTimeToDisplay,
        });
      } else {
        updateCache(current.id);
      }
    }
    setAddGemOffer(null);
    setCurrentPopups(currentPopups.slice(1));
  };

  const updateCache = (id) => {
    const newCache = [...getCache(), id];
    storage.local.set('popupStorage', JSON.stringify(newCache));
  };

  const updateVisiblePopups = ({ id, times_to_display }) => {
    const newCache = { ...getVisiblePopups(), [id]: { times_to_display } };
    storage.local.set('visiblePopups', JSON.stringify(newCache));
  };

  const handleBtnPress = () => {
    if (addGemOffer) {
      window.open(addGemOffer.url, '_blank');
    } else if (current.button_link) {
      if (current.button_link_external) {
        window.open(current.button_link, '_blank');
      } else {
        history.push(current.button_link);
      }
    }
    closeModal();
  };

  useEffect(() => {
    if (currentPopups.length) {
      if (adGemEnabled && currentPopups[0].is_adgem_offer && user.isValidUser) {
        const campaignId = +currentPopups[0].adgem_campaign_id;

        fetchAdGemData(adGemAppId, user.referral_code).then((res) => {
          const items = res.data.data[0].data;
          const offer = items.find((i) => i.campaign_id === campaignId);
          if (offer) {
            setIsOpened(true);
            setAddGemOffer(offer);
          } else {
            setCurrentPopups(currentPopups.slice(1));
          }
        });
      } else {
        setIsOpened(true);
      }
    }
  }, [currentPopups, adGemAppId, adGemEnabled, user]);

  if (!current) return null;

  if (current.is_sticky) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpened}
        ContentProps={{
          className: classes.snackbarContent,
          classes: {
            action: classes.snackbarAction,
            message: classes.snackbarMessage,
          },
        }}
        classes={{
          root: classes.snackbarRoot,
        }}
        action={
          <CloseIcon onClick={closeModal} className={classes.closeModal} />
        }
        message={
          <div className={classes.wrapper}>
            <Typography variant="h5" className={classes.snackbarTitle}>
              {current.headline}
            </Typography>
            <Typography variant="h6" className={classes.subTitle}>
              {current.body_html.length > 0 && parse(current.body_html)}
            </Typography>
            <Button
              size="large"
              className={classes.button}
              onClick={handleBtnPress}
            >
              {current.button_text}
            </Button>
            <Typography variant="h5">
              {current.below_button_html?.length > 0 &&
                parse(current.below_button_html)}
            </Typography>
          </div>
        }
      />
    );
  }

  return (
    <Modal open={isOpened} toggleModal={closeModal} showClose>
      <div className={classes.wrapper}>
        <Typography variant="h4" className={classes.title}>
          {current.headline}
        </Typography>
        {current.image_url?.length > 0 && (
          <img
            src={current.image_url}
            alt="Jackpot"
            className={classes.image}
          />
        )}
        <Typography variant="h5" className={classes.subTitle}>
          {current.body_html.length > 0 && parse(current.body_html)}
        </Typography>
        <Button
          size="large"
          className={classes.button}
          onClick={handleBtnPress}
        >
          {current.button_text}
        </Button>
        <Typography variant="h5">
          {current.below_button_html?.length > 0 &&
            parse(current.below_button_html)}
        </Typography>
      </div>
    </Modal>
  );
};

export default GenericPopups;
