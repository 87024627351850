import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    letterSpacing: '0.05em',
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > span': {
      textAlign: 'left',
    },
  },
  checkbox: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  link: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
  submitBtn: {
    width: '100%',
    textTransform: 'initial',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  hintContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hint: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
    color: theme.palette.greyDark.main,
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    '& hr': {
      flex: 1,
      border: 'none',
      borderBottom: '1px solid',
    },
    '& span': {
      margin: theme.spacing(0, 2),
    },
  },
  info: {
    textAlign: 'left',
  },
  legal: {
    marginTop: '16px',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));
