import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import clsx from 'clsx';

import { selectCharityData } from 'redux/cms';

import Button from '@material-ui/core/Button';
import { Link as MLink } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useEventTracker } from 'hooks/analytics';
import { storage } from 'services/storage';
import { PATHS } from 'constants/navigation';
import { ReactComponent as WinnerIcon } from 'assets/images/icons/winner-icon.svg';

import useStyle from './styles';

const CharityUpsell = () => {
  const classes = useStyle();

  const dispatch = useDispatch();
  const charities = useSelector(selectCharityData);
  const exchangeRates = charities?.data.exchangeRates;
  const [activeOption, setActiveOption] = useState(0);
  const enteredSweepstake = storage.local.get('enteredSweepstake');
  const handleGAEvent = useEventTracker();

  useEffect(() => {
    const savedValue = Number(storage.local.get('charityTokensAmount'));
    if (exchangeRates) {
      const value =
        exchangeRates.find((i) => i.default)?.value || exchangeRates[0].value;
      setActiveOption(savedValue || value);
    }
  }, [exchangeRates]);

  const handleBtnClick = useCallback(() => {
    storage.local.set('charityTokensAmount', activeOption);

    handleGAEvent('charity', 'go-to-charity-selection', activeOption);
    dispatch(push(PATHS.charities));
  }, [dispatch, activeOption, handleGAEvent]);

  const handleOptionBtnClick = useCallback((item) => {
    setActiveOption(item);
  }, []);

  const handleSkip = () => {
    handleGAEvent('charity', 'skip-charity-upsell', '');
    const { sweepId, eventId } = JSON.parse(enteredSweepstake);
    dispatch(push(`${PATHS.sweepstakes}/${sweepId}/${eventId}/success`));
  };

  if (!exchangeRates) return null;

  const rate = exchangeRates.find((i) => i.value === activeOption)?.rate;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography
          variant="h4"
          className={classes.title}
          data-cy="sweep-success-title"
        >
          You&apos;re In! <WinnerIcon />
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Want to increase your chances to win? Support a great cause and get up
          to 100 additional tokens!
        </Typography>
        <div className={classes.imgWrapper}>
          <img
            src={charities?.data.collage.replace(
              'image/upload/v',
              'image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v'
            )}
            alt="Collage uploaded"
            className={classes.image}
          />
        </div>
        <Typography variant="h6" className={classes.pickOptionTitle}>
          How many chances to win would you like?
        </Typography>
        <div className={classes.optionBtnContainer}>
          {exchangeRates.map((item) => (
            <div key={item.value} className={classes.optionBtnWrapper}>
              <div className={clsx(classes.optionBtnText, classes.textAbove)}>
                {item.textAbove}
              </div>
              <Button
                className={clsx(classes.optionBtn, {
                  [classes.activeOptionBtn]: activeOption === item.value,
                })}
                variant="contained"
                onClick={() => handleOptionBtnClick(item.value)}
                size="large"
                data-cy="donation-option"
              >
                {item.value}
              </Button>
              <div className={clsx(classes.optionBtnText, classes.textBelow)}>
                {item.textBelow}
              </div>
            </div>
          ))}
        </div>
        {rate && (
          <Typography variant="h6" data-cy="donation-amount">
            ${rate} donation
          </Typography>
        )}
        <Button
          className={classes.btn}
          variant="contained"
          size="large"
          color="primary"
          onClick={handleBtnClick}
          data-cy="select-charity-button"
        >
          Select a Charity
        </Button>
        <MLink className={classes.skipLink} to="#" onClick={handleSkip}>
          No thanks, finish my entry
        </MLink>
      </div>
    </div>
  );
};

export default CharityUpsell;
