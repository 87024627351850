import { put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { storage } from 'services/storage';
import { isLocalRedirect, getLocalPath } from 'utils/url';
import { COUPON_EXPERIENCE } from 'constants/coupons';

export const getExperienceFromCoupon = (coupon) => {
  return COUPON_EXPERIENCE[coupon.product.destination_currency_type.name];
};

export function* handleRedirect(redirectUri) {
  if (isLocalRedirect(decodeURIComponent(redirectUri))) {
    yield put(push(getLocalPath(decodeURIComponent(redirectUri))));
  } else {
    window.location.href = decodeURIComponent(redirectUri);
  }
}

export function* handleReferrer(referrer) {
  yield call(handleRedirect, referrer);
  yield call(storage.local.removeItem, 'referrer');
}

export function* clearCoupon() {
  yield call(storage.local.removeItem, 'coupon');
}
