import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    // eslint-disable-next-line no-dupe-keys
    height: 'calc(var(--vh, 1vh) * 100)',
    textAlign: 'center',
    backgroundColor: theme.palette.white.main,
  },
  contentWrapper: {
    flex: 1,
    overflow: 'auto',
  },
  contentHeight: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100% - 104px)`,
    [theme.breakpoints.down('sm')]: {
      minHeight: `calc(100% - 100px)`,
    },
  },
  '@global': {
    html: {
      backgroundColor: theme.palette.white.main,
    },
  },
  banner: {
    backgroundColor: '#f00',
    padding: theme.spacing(1, 2),
    color: theme.palette.white.main,
    fontWeight: 'bold',
    '& a': {
      color: theme.palette.white.main,
    },
  },
}));
