import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import format from 'date-fns/format';

import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import { PATHS } from 'constants/navigation';
import { selectCharityReceiptData, getCharityReceipt } from 'redux/charities';
import { selectUserInfo } from 'redux/user';

import { useEventTracker } from 'hooks/analytics';

import { ReactComponent as WinnerIcon } from 'assets/images/icons/winner-icon.svg';

import { Typography } from '@material-ui/core';

import { storage } from 'services/storage';

import useStyle from './styles';

const CharityConfirmationPage = () => {
  const { receiptId } = useParams();
  const classes = useStyle();
  const dispatch = useDispatch();
  const handleGAEvent = useEventTracker();

  useEffect(() => {
    dispatch(getCharityReceipt({ id: receiptId }));
  }, [dispatch, receiptId]);

  useEffect(() => {
    storage.local.removeItem('enteredSweepstake');
  }, []);

  const { receipt, isLoading } = useSelector(selectCharityReceiptData);
  const user = useSelector(selectUserInfo);

  const goToSweeps = () => {
    handleGAEvent('charity', 'finish-charity', 'sweeps');
    dispatch(push(PATHS.sweepstakes));
  };
  const goToSpin = () => {
    handleGAEvent('charity', 'finish-charity', 'spinner');
    dispatch(push(PATHS.spin));
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty(receipt)) {
    return <Typography variant="h3">Not found</Typography>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography
          variant="h3"
          className={classes.title}
          data-cy="success-title"
        >
          Success <WinnerIcon />
        </Typography>
        <Typography variant="h6" className={classes.text}>
          Thank you for your donation! Your tokens are available for immediate
          use on Sweepstakes or Daily Spins, and your receipt is below.
        </Typography>
        <div className={classes.description}>
          <Typography variant="h6" data-cy="donated-charity">
            <span className={classes.infoLabel}>Organization: </span>
            {receipt.line_items?.[0]?.product?.display_name}
          </Typography>
          <Typography variant="h6" data-cy="donated-amount">
            <span className={classes.infoLabel}>Amount: </span>${receipt.amount}
          </Typography>
          <Typography variant="h6">
            <span className={classes.infoLabel}>Donated by: </span>
            {user.email}
          </Typography>
          <Typography variant="h6" data-cy="donation-date">
            <span className={classes.infoLabel}>Date: </span>
            {format(new Date(receipt.created_at), 'MMMM d, y')}
          </Typography>
          <div className={classes.disclaimer}>
            {receipt.line_items?.[0]?.product?.display_name} is a registered
            501(c)(3) non-profit organization. No goods or services were
            provided by {receipt.line_items?.[0]?.product?.display_name} in
            return for this contribution.
          </div>
        </div>
        <Button
          className={classes.btn}
          variant="contained"
          size="large"
          color="primary"
          onClick={goToSweeps}
        >
          Sweepstakes
        </Button>
        <Button
          className={clsx(classes.btn, classes.btnAccent)}
          variant="contained"
          size="large"
          color="primary"
          onClick={goToSpin}
        >
          Daily Spin
        </Button>
      </div>
    </div>
  );
};

export default CharityConfirmationPage;
