import React from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { useUserInfo } from 'hooks/user';
import { selectConfigProperty } from 'redux/config';
import { useUserNavigation } from 'hooks/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';

import useStyle from './styles';

const RevU = () => {
  const classes = useStyle();
  const { user } = useUserInfo();
  const appId = useSelector(selectConfigProperty(CONFIG_KEYS_MAP.revUAppId));

  useUserNavigation();

  return (
    <div className={classes.contentWrapper}>
      {parse(
        `<iframe
          src="https://publishers.revenueuniverse.com/wallresp/${appId}/offers?uid=${user.referral_code}&sid3=${appId}"
          style="width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:1199; flex: 1 1 auto" scrolling="yes">
          Your browser doesn't support iframes
        </iframe>`
      )}
    </div>
  );
};

export default RevU;
