import { call, put, takeLatest, delay } from 'redux-saga/effects';

import {
  fetchSpinnerPrizes,
  spinSpinner as _spinSpinner,
} from 'services/api/spinner';
import { SPINNER_DURATION } from 'constants/spinner';

import { getWallet, refetchActivitiesList } from 'redux/wallet';
import {
  getSpinnerData,
  getSpinnerDataSuccess,
  getSpinnerDataFailure,
  spinSpinner,
  spinSpinnerSuccess,
  spinSpinnerFailure,
} from './slice';

function* getSpinnerDataSaga() {
  try {
    const { data } = yield call(fetchSpinnerPrizes);
    const jackpotInfo = data.awards.find((item) => item.sub_type === 'jackpot')
      ?.jackpot_info;
    yield put(
      getSpinnerDataSuccess({
        jackpotInfo,
        prizes: data.awards.map((i) => ({
          ...i,
          clickable: i.sub_type === 'jackpot',
        })),
      })
    );
  } catch (error) {
    let errorMsg = error.data?.message;
    if (error?.data.errors[0]) {
      errorMsg = error?.data.errors[0];
    }
    yield put(getSpinnerDataFailure(errorMsg));
  }
}

function* spinSpinnerSaga({ payload: { options } }) {
  try {
    const { data } = yield call(_spinSpinner, options);

    yield put(spinSpinnerSuccess(data.spinner_award));
    yield delay(SPINNER_DURATION);
    yield put(getWallet());
    yield put(refetchActivitiesList({ category: 'spin' }));
  } catch (error) {
    yield put(
      spinSpinnerFailure(error.data?.errors?.[0] || error.data?.message)
    );
  }
}

export function* spinnerSaga() {
  yield takeLatest(getSpinnerData, getSpinnerDataSaga);
  yield takeLatest(spinSpinner, spinSpinnerSaga);
}
