import { Api } from './index';

export const fetchGiftCardsRequest = (selectedCountry) =>
  Api.get(`offers/gift_cards/${selectedCountry}`);
export const redeemGiftCardRequest = ({ id, value }) =>
  Api.post('orders/gift-cards', { id, value });
export const createOrderRequest = ({ id, amount, cardId, countryCode }) =>
  Api.post('orders/gift-cards/create-order', {
    id,
    amount,
    uphold_card_id: cardId,
    country_code: countryCode,
  });
export const commitOrderRequest = ({ transactionId, countryCode }) =>
  Api.post('orders/gift-cards/commit-order', {
    transaction_id: transactionId,
    country_code: countryCode,
  });
