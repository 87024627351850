import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  artists: [],
  isLoaded: false,
  followed: [],
};

const followArtistSlice = createSlice({
  name: 'follows',
  initialState,
  reducers: {
    fetchFollowArtists: (state) => {
      state.isLoading = true;
    },
    fetchFollowArtistsSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.artists = action.payload.artists;
    },
    fetchFollowArtistsFailure: (state) => {
      state.isLoading = false;
      state.isLoaded = true;
    },
    followArtist: (state) => {
      state.isLoading = true;
    },
    followArtistSuccess: (state, action) => {
      state.isLoading = false;
      state.followed = [...state.followed, action.payload];
    },
    followArtistFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  fetchFollowArtists,
  fetchFollowArtistsSuccess,
  fetchFollowArtistsFailure,
  followArtist,
  followArtistSuccess,
  followArtistFailure,
} = followArtistSlice.actions;

export default followArtistSlice.reducer;
