import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CONFIG_KEYS_MAP } from 'constants/config';
import Loader from 'components/Loader';
import { selectConfigProperty } from 'redux/config';
import { upholdLogin } from 'redux/user';
import { getQryParams } from 'utils/url';

const UpholdLogin = () => {
  const location = useLocation();
  const params = getQryParams(location);
  const dispatch = useDispatch();
  const upholdAppState = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAppState)
  );

  useEffect(() => {
    const { code, state } = params;
    if (upholdAppState !== state) {
      throw new Error(`Invalid uphold auth state '${state}' returned`);
    }

    if (code) {
      dispatch(upholdLogin({ code }));
    }
  }, [dispatch, params, upholdAppState]);

  return <Loader />;
};

export default UpholdLogin;
