import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import facebookIcon from 'assets/images/facebook.png';
import spotifyIcon from 'assets/images/spotify.png';

import useStyles from './styles';

const icons = {
  facebook: facebookIcon,
  spotify: spotifyIcon,
};

const SocialLoginButton = ({ onClick, className, provider, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      size="large"
      variant="contained"
      color="inherit"
      type="button"
      className={clsx(classes.authButton, className)}
      onClick={onClick}
      disabled={disabled}
    >
      <img
        src={icons[provider.toLowerCase()]}
        className={classes.icon}
        alt={provider}
      />
      {`Continue with ${provider}`}
    </Button>
  );
};

export default SocialLoginButton;
