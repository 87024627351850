import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import FacebookButton from 'components/FacebookButton';
import SpotifyButton from 'components/SpotifyButton';
import PartnerSpecificContent from 'components/PartnerSpecificContent';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getQryParams } from 'utils/url';
import {
  selectUser,
  loginUser,
  clearUserError,
  facebookLogin,
} from 'redux/user';
import { selectSignUpPath, selectConfigProperty } from 'redux/config';
import { storage } from 'services/storage';
import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { useUserNavigation } from 'hooks/navigation';

import upholdLogo from 'assets/images/uphold.png';

import useStyles from './styles';

const LoginPage = () => {
  const location = useLocation();
  const params = getQryParams(location);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLoading, error } = useSelector(selectUser);
  useUserNavigation();

  const signUpPath = useSelector(selectSignUpPath);
  const facebookEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.facebookEnabled)
  );
  const spotifyEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.spotifyEnabled)
  );
  const upholdAuthLink = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAuthLink)
  );
  const upholdAppState = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAppState)
  );

  const clearError = useCallback(() => dispatch(clearUserError()), [dispatch]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(
      loginUser({
        email,
        password,
        coupon_code:
          params.code || JSON.parse(storage.local.get('coupon') || null)?.code,
        redirectUri: params.redirect_uri,
      })
    );
  };

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
      if (error) clearError();
    },
    [clearError, error]
  );

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
      if (error) clearError();
    },
    [clearError, error]
  );

  const onFacebookLogin = useCallback(
    (response) => {
      const { accessToken } = response;
      if (accessToken) {
        dispatch(
          facebookLogin({
            accessToken,
            type: 'auth',
          })
        );
      }
    },
    [dispatch]
  );

  const upholdLogin = () => {
    storage.local.set('referrer', params.redirect_uri);
    window.location.href = `${upholdAuthLink}&state=${upholdAppState}`;
  };

  const upholdSignUp = () => {
    storage.local.set('referrer', params.redirect_uri);
    window.location.href = `${upholdAuthLink}&intention=kyc&state=${upholdAppState}`;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <PartnerSpecificContent hidden='true' partnerNames={['brave', 'uphold']}>
          <Typography variant="h4" className={classes.title}>
            Welcome back!
          </Typography>
          <Typography variant="h5" className={classes.subTitle}>
            Enter your information below
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              className={classes.input}
              label="Email Address"
              type="email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              size="small"
              data-cy="login_email"
            />
            <TextField
              className={classes.input}
              label="Password"
              variant="outlined"
              value={password}
              type="password"
              onChange={handlePasswordChange}
              size="small"
              data-cy="login_password"
            />
            {error.length > 0 && (
              <Typography
                variant="subtitle1"
                color="error"
                className={classes.error}
                data-cy="login_error"
              >
                {error}
              </Typography>
            )}
            <Link
              to={PATHS.forgotPassword}
              className={classes.link}
              data-cy="login_forgot_pass_link"
            >
              Forgot Password
            </Link>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              disabled={isLoading}
              size="large"
              data-cy="login_submit"
            >
              {isLoading && (
                <div className={classes.spinnerWrapper}>
                  <CircularProgress size={20} />
                </div>
              )}
              Continue
            </Button>
            {(facebookEnabled || spotifyEnabled) && (
              <div className={classes.divider}>
                <hr />
                <span>Or</span>
                <hr />
              </div>
            )}
            <FacebookButton
              onBtnClick={onFacebookLogin}
              isLoading={isLoading}
            />
            <SpotifyButton isLoading={isLoading} />
          </form>
          <Link
            to={{ ...location, pathname: signUpPath }}
            onClick={clearError}
            className={classes.link}
            data-cy="login_non_exists_link"
          >
            Not an existing user?
          </Link>
        </PartnerSpecificContent>
        <PartnerSpecificContent partnerNames={['brave', 'uphold']}>
          <Typography variant="h4" className={classes.title}>
            Welcome!
          </Typography>
          <Typography className={classes.subTitle}>
            <PartnerSpecificContent partnerName="brave">
              An Uphold account is required to join the Rewards Market for Brave
              users
            </PartnerSpecificContent>
            <PartnerSpecificContent partnerName="uphold">
              An Uphold account is required to enter this experience.
            </PartnerSpecificContent>
          </Typography>
          <Button
            type="submit"
            variant="outlined"
            className={classes.submitBtn}
            size="large"
            onClick={upholdLogin}
          >
            <img
              src={upholdLogo}
              className={classes.upholdIcon}
              alt="Uphold Logo"
            />
            <div className={classes.flex}>Continue with Uphold</div>
          </Button>
          <Button
            type="submit"
            variant="outlined"
            className={classes.submitBtn}
            size="large"
            onClick={upholdSignUp}
          >
            <img
              src={upholdLogo}
              className={classes.upholdIcon}
              alt="Uphold Logo"
            />
            <div className={classes.flex}>Get an Uphold Account</div>
          </Button>
        </PartnerSpecificContent>
      </div>
    </div>
  );
};

export default LoginPage;
