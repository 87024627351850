import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUser, selectUserInfo } from 'redux/user';
import { selectItemData } from 'redux/cms';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';

import useStyles from './styles';

const SelectGenres = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [interests, setSelectedGenres] = useState([]);
  const { isLoading, error } = useSelector(selectUserInfo);
  const genres = useSelector((state) =>
    selectItemData(state, { key: 'onboarding', path: 'genres' })
  );

  const onSubmit = () => {
    dispatch(updateUser({ interests }));
  };

  const onSelect = (genre) => {
    const arr = interests.includes(genre)
      ? interests.filter((item) => item !== genre)
      : [...interests, genre];
    setSelectedGenres(arr);
  };

  if (!genres) {
    return <Loader />;
  }

  return (
    <>
      <Typography
        variant="h4"
        className={classes.title}
        data-cy="select-genres-title"
      >
        Tell us what you love.
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        This will help optimize your experience. Please choose at least one.
      </Typography>
      <div className={classes.content}>
        <div className={classes.list}>
          {genres.map((item) => (
            <Button
              key={item}
              variant="contained"
              color={interests.includes(item) ? 'primary' : 'default'}
              className={classes.optionButton}
              onClick={() => onSelect(item)}
              data-cy="genre-button"
              data-selected={interests.includes(item)}
            >
              {item}
            </Button>
          ))}
        </div>
        <div style={{ width: '100%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disabled={isLoading || !interests.length}
            size="large"
            onClick={onSubmit}
            fullWidth
            data-cy="select-genres-submit"
          >
            {isLoading ? <CircularProgress size={20} /> : 'Finish'}
          </Button>
          <ErrorMessage error={error} />
        </div>
      </div>
    </>
  );
};

export default SelectGenres;
