import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 0),
  },
  empty: {
    fontWeight: 500,
    padding: theme.spacing(0, 2),
  },
}));
