import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  pickOptionTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  pickOptionDesc: {
    color: theme.palette.greyDark.main,
  },
  optionBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  optionBtn: {
    backgroundColor: theme.palette.transparent.main,
    borderRadius: '0.625rem',
    transitionProperty: 'background-color, box-shadow, border, color',

    '&:hover': {
      backgroundColor: theme.palette.transparent.main,
    },
  },
  activeOptionBtn: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    borderRadius: '0.625rem',

    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  codeTokensBtn: {
    textTransform: 'none',
    width: '12rem',
    height: '2.2rem',
    borderRadius: '0.625rem',
    fontWeight: 'bold',
    alignSelf: 'center',
    marginBottom: theme.spacing(3),
    border: 'none',
    background: theme.palette.grey.main,
  },
  cardListLabel: {
    width: '100%',
    maxWidth: 60,
  },
  cardSelect: {
    width: '100%',
  },
  linkWrapper: {
    height: '3.375rem',
    width: '100%',
    maxWidth: '25rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
    textDecoration: 'none',
  },
  enterNowBtn: {
    textTransform: 'none',
    height: '3.375rem',
    width: '100%',
    maxWidth: '25rem',
    alignSelf: 'center',
  },
  getCodeBtn: {
    marginTop: theme.spacing(4),
  },
  errorMessage: {
    color: theme.palette.error.main,
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
    height: '1.5rem',
    whiteSpace: 'pre-line',
  },

  exchangeAmount: {
    fontSize: '1rem',
    fontWeight: 600,
    padding: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  note: {
    textAlign: 'center',
    fontSize: '0.7rem',
    color: theme.palette.greyDark.main,
    marginBottom: theme.spacing(3),
  },
  currencyIcon: {
    height: '1em',
    marginRight: theme.spacing(0.6),
  },
  addFundsLink: {
    fontSize: '0.7rem',
    marginLeft: theme.spacing(2),
    textDecoration: 'none',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginLeft: 'auto',
    width: '65%',
  },
}));
