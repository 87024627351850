import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoaded: false,
  prizes: [],
  jackpotInfo: null,
  error: null,
  result: null,
};

const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    getSpinnerData: (state) => {
      state.isLoading = true;
    },
    getSpinnerDataSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.prizes = action.payload.prizes;
      state.jackpotInfo = action.payload.jackpotInfo;
    },
    getSpinnerDataFailure: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.payload;
    },
    spinSpinner: (state) => {
      state.result = null;
    },
    spinSpinnerSuccess: (state, action) => {
      state.result = action.payload;
    },
    spinSpinnerFailure: (state, action) => {
      state.error = action.payload;
    },
    resetSpinResult: (state) => {
      state.result = null;
    },
  },
});

export const {
  getSpinnerData,
  getSpinnerDataFailure,
  getSpinnerDataSuccess,
  spinSpinner,
  spinSpinnerSuccess,
  spinSpinnerFailure,
  resetSpinResult,
} = spinnerSlice.actions;

export default spinnerSlice.reducer;
