import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  spinBtn: {
    width: '100%',
    maxWidth: '300px',
    textTransform: 'initial',
    backgroundColor: theme.palette.accent.main,
    margin: theme.spacing(1, 0),
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  addFundsLink: {
    fontSize: '0.7rem',
    marginLeft: theme.spacing(2),
    textDecoration: 'none',
  },
  description: {
    color: theme.palette.greyDark.main,
    padding: theme.spacing(1, 4),
  },
  note: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
  spinnerWrapper: {
    margin: theme.spacing(2),
  },
  spinnerHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.accent.main,
  },
  spinsLeft: {
    textAlign: 'left',
  },
  jackpotButton: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.accent.main,
    padding: 0,
    height: '1rem',
    textDecoration: 'underline',
  },
  gemIcon: {
    width: '1rem',
    height: '1rem',
    margin: theme.spacing(0, 0.2, 0.2, 0),
  },
  rulesLink: {
    textDecoration: 'underline',
  },
  confirmText: {
    textAlign: 'center',
    margin: theme.spacing(4, 1, 5),
    fontWeight: 'bold',
  },
  confirmBtn: {
    backgroundColor: theme.palette.accent.main,
    marginBottom: theme.spacing(1),
  },
  cardSelectWrapper: {
    margin: theme.spacing(1, 'auto'),
    maxWidth: '300px',
  },
  currencyIcon: {
    height: '1em',
    marginRight: theme.spacing(0.6),
  },
  exchangeAmount: {
    fontSize: '1rem',
    fontWeight: 600,
    padding: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
