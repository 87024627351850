import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearUserError } from 'redux/user';
import { STEPS } from 'constants/onboarding';
import { useUserInfo, useIsLoggedIn } from 'hooks/user';

export const useOnboardingSteps = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(null);
  const [isAllItemsHidden, setIsAllItemsHidden] = useState(true);

  const isLoggedIn = useIsLoggedIn();
  const {
    user: { isLoading, onboarding_step, error, completed_onboarding },
  } = useUserInfo();

  const backButtonCallback = useCallback(() => {
    if (error) {
      dispatch(clearUserError());
    }
    setCurrentStep(STEPS.enterPhone);
  }, [error, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      setCurrentStep(STEPS.signUp);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (onboarding_step && !isLoading) {
      setCurrentStep(onboarding_step);
    }
  }, [onboarding_step, isLoading]);

  useEffect(() => {
    if (!onboarding_step && !isLoading && currentStep) {
      setCurrentStep(currentStep);
    }
  }, [onboarding_step, isLoading, currentStep]);

  useEffect(() => {
    if (currentStep === STEPS.verifyPhone) {
      setIsAllItemsHidden(false);
    } else if (!isAllItemsHidden) {
      setIsAllItemsHidden(true);
    }
  }, [currentStep, isAllItemsHidden]);

  return {
    step: currentStep,
    backButtonCallback,
    completedOnboarding: completed_onboarding,
    isAllItemsHidden,
  };
};
