import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    lineHeight: '1.25rem',
    color: theme.palette.black.main,
    fontWeight: 'bold',
    margin: theme.spacing(2, 0, 1.5),
  },
  cardImageWrapper: {
    display: 'inline-block',
    borderRadius: 10,
    overflow: 'hidden',
    width: '345px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardImage: {
    display: 'block',
    width: '100%',
    margin: '-1px',
  },
}));
