import { getPartner } from 'utils/config';
import brave from './brave';
import syw from './syw';
import uber from './uber';
import uphold from './uphold';
import wmg from './wmg';

const configs = {
  brave,
  syw,
  uber,
  uphold,
  wmg,
};

const currentPartner = getPartner().name;
const currentConfig = configs[currentPartner];

if (!currentConfig) {
  // eslint-disable-next-line no-console
  console.error(
    `!!! You've forgotten to specify config for ${currentPartner} partner`
  );
}

export default currentConfig || {};
