import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    marginBottom: theme.spacing(6),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    maxWidth: '18.75rem',
    marginBottom: theme.spacing(3),
  },
  submitBtn: {
    width: '100%',
    maxWidth: '18.75rem',
    textTransform: 'initial',
    marginBottom: theme.spacing(2),
  },
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    height: '1.3rem',
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  note: {
    color: theme.palette.greyDark.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
}));
