import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import Grid from '@material-ui/core/Grid';

import { selectConfigProperty } from 'redux/config';
import { PATHS } from 'constants/navigation';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { warnerStoreRoute } from 'constants/menu/wmg-menu';

import PartnerSpecificContent from 'components/PartnerSpecificContent';
import RoundedOptionItem from 'components/RoundedOptionItem';
import CmsModal from 'components/CmsModal';
import { storage } from 'services/storage';

import useStyles from './styles';

const GetMoreTokensPage = () => {
  const dispatch = useDispatch();
  const revUEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.revUEnabled)
  );
  const donationsEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.charityEnabled)
  );
  const followArtistEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.followArtistEnabled)
  );
  const followTrackEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.followTrackEnabled)
  );

  const showUberPointsOption = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.showUberPointsOption)
  );

  const [modalsOpenStatus, toggleModalsOpenStatus] = useState({
    howItWorks: false,
    travel: false,
  });

  const toggleModalsStatus = useCallback((key) => {
    toggleModalsOpenStatus((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  }, []);

  const goToReferral = useCallback(() => {
    dispatch(push(PATHS.referral));
  }, [dispatch]);

  const goToOffers = useCallback(() => {
    dispatch(push(PATHS.offers));
  }, [dispatch]);

  const goToCharity = useCallback(() => {
    storage.local.removeItem('enteredSweepstake');
    dispatch(push(PATHS.donations));
  }, [dispatch]);

  const goToFollowArtist = useCallback(() => {
    dispatch(push(PATHS.followArtists));
  }, [dispatch]);

  const goToFollowTrack = useCallback(() => {
    dispatch(push(PATHS.followTracks));
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {followTrackEnabled && (
        <RoundedOptionItem
          title="Save a Track"
          imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1647748595/uber/getMoreTokens/Follow_Icon.png"
          onClick={goToFollowTrack}
        />
      )}
      {followArtistEnabled && (
        <RoundedOptionItem
          title="Follow an Artist"
          imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1647748595/uber/getMoreTokens/Follow_Icon.png"
          onClick={goToFollowArtist}
        />
      )}
      {revUEnabled && (
        <RoundedOptionItem
          title="Offers"
          imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1611833180/uber/getMoreTokens/Offers.png"
          onClick={goToOffers}
        />
      )}
      <RoundedOptionItem
        title="Refer a Friend"
        imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1611833177/uber/getMoreTokens/Refer_a_friend.png"
        onClick={goToReferral}
        data-cy="refer-a-friend"
      />
      <PartnerSpecificContent partnerName="wmg">
        <RoundedOptionItem
          title="Book Travel & Save"
          imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1619075394/uber/getMoreTokens/Book_Travel.png"
          onClick={() => toggleModalsStatus('travel')}
        />
      </PartnerSpecificContent>
      {donationsEnabled && (
        <RoundedOptionItem
          title="Donate to Charity"
          imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1616664230/uber/getMoreTokens/Charity.png"
          onClick={goToCharity}
        />
      )}
      <RoundedOptionItem
        title={warnerStoreRoute.name}
        imgSrc="https://sgi.offerscdn.net/i/zdcs-merchants/02LRGnefC3gOshp5BG38X1l.h200.w200.flpad.v94.bffffff.jpg"
        onClick={() => window.open(warnerStoreRoute.link, '_blank')}
      />
      {showUberPointsOption && (
        <RoundedOptionItem
          title="Use Uber Rewards Points"
          imgSrc="https://res.cloudinary.com/hooch/image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v1611833183/uber/getMoreTokens/Uber_Rewards.png"
          onClick={() => toggleModalsStatus('howItWorks')}
        />
      )}
      <CmsModal
        cmsKey="howItWorks"
        isModalOpened={modalsOpenStatus.howItWorks}
        toggleModal={() => toggleModalsStatus('howItWorks')}
        showClose
      />
      <CmsModal
        cmsKey="bookTravel&Save"
        isModalOpened={modalsOpenStatus.travel}
        toggleModal={() => toggleModalsStatus('travel')}
        showClose
      />
    </Grid>
  );
};

export default GetMoreTokensPage;
