import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    letterSpacing: '0.05em',
    marginTop: theme.spacing(2),
  },
  optionButton: {
    width: `calc(50% - ${theme.spacing(0.6)}px)`,
    height: 60,
    fontSize: '0.8rem',
    marginTop: theme.spacing(1.2),
    whiteSpace: 'break-spaces',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: 300,
    marginTop: theme.spacing(2),
  },
  submitBtn: {
    width: '100%',
    maxWidth: 300,
    textTransform: 'initial',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));
