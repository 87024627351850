import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    background: theme.palette.grey.third,
    borderRadius: 5,
    cursor: 'pointer',
    padding: theme.spacing(2),
  },
  img: {
    position: 'absolute',
    left: theme.spacing(2),
  },
}));
