/* eslint-disable no-nested-ternary */
import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

import { Props, DefaultProps } from 'models/sweeps';

import { PATHS } from 'constants/navigation';

import useStyles from './styles';

const SweepstakeItem = ({ item, className }) => {
  const classes = useStyles();

  const link = item.coming_soon
    ? '#'
    : item.events.length > 1
    ? `${PATHS.sweepstakes}/${item.id}`
    : `${PATHS.sweepstakes}/${item.id}/${item.events[0].id}`;

  return (
    <Grid item xs={6} sm={4} md={3} className={className}>
      <Link
        color="inherit"
        component={RouterLink}
        data-cy={`sweep-link-${item.id}`}
        underline="none"
        className={clsx({ [classes.noLink]: item.coming_soon })}
        to={link}
      >
        <div className={classes.cardImageWrapper}>
          <img
            src={item.profile_image.replace(
              'image/upload/v',
              'image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v'
            )}
            alt="Sweepstake"
            className={clsx(classes.cardImage, {
              [classes.grayedOut]: item.coming_soon,
            })}
          />
          {item.coming_soon && (
            <Typography color="error" className={classes.comingSoon}>
              COMING SOON
            </Typography>
          )}
        </div>
        <Typography className={classes.title}>{item.name}</Typography>
      </Link>
    </Grid>
  );
};

SweepstakeItem.defaultProps = DefaultProps;

SweepstakeItem.propTypes = Props;

export default SweepstakeItem;
