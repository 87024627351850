import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getWallet,
  selectWalletInfo,
  selectShouldFetchWallet,
  selectNumberOfAvailableSpins,
  selectAvailableCards,
} from 'redux/wallet';
import { selectConfigProperty } from 'redux/config';
import { selectScratchGame } from 'redux/scratchGame';
import CardSelector from 'components/CardSelector';
import { fetchUpholdConversionRate } from 'services/api/wallet';
import { CONFIG_KEYS_MAP } from 'constants/config';

import { useIsLoggedIn } from './user';

export const useWalletInfo = (currencyName) => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();

  const shouldFetchWallet = useSelector(selectShouldFetchWallet);
  const walletData = useSelector(selectWalletInfo(currencyName));

  useEffect(() => {
    if (isLoggedIn && shouldFetchWallet) {
      dispatch(getWallet());
    }
  }, [dispatch, isLoggedIn, shouldFetchWallet]);

  return [walletData];
};

export const useSpinsInfo = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();

  const shouldFetchWallet = useSelector(selectShouldFetchWallet);

  useEffect(() => {
    if (isLoggedIn && shouldFetchWallet) {
      dispatch(getWallet());
    }
  }, [dispatch, isLoggedIn, shouldFetchWallet]);

  return useSelector(selectNumberOfAvailableSpins);
};

const fetchInterval = 30 * 1000;

export const useCurrencyConversionRate = (currency, shouldRefetch = false) => {
  const [rate, setRate] = useState(0);
  const [showedCurrency, setShowedCurrency] = useState(currency);
  const fetchIntervalRef = useRef(0);

  const useExternalWallets = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.useExternalWallets)
  );

  const requestRate = useCallback(async () => {
    if (currency) {
      const { data } = await fetchUpholdConversionRate(
        'USD',
        currency
      ).catch((e) => console.log(e));
      setRate(data?.ask || 0);
      setShowedCurrency(currency);
    }
  }, [currency]);

  useEffect(() => {
    if (useExternalWallets) {
      requestRate();
      clearInterval(fetchIntervalRef.current);
      if (shouldRefetch) {
        fetchIntervalRef.current = setInterval(requestRate, fetchInterval);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [useExternalWallets, shouldRefetch, requestRate]);

  return [(amount) => +(amount * rate).toFixed(2), showedCurrency];
};

export const useCardSelector = (cardId) => {
  const [card, setCard] = useState(cardId ? { id: cardId } : {});
  const availableCards = useSelector(selectAvailableCards);
  const { isButtonDisabled } = useSelector(selectScratchGame);

  const handleCardSelect = useCallback(
    ({ target: { value } }) => {
      setCard(availableCards.find((c) => c.id === value));
    },
    [availableCards]
  );

  return [
    card,
    <CardSelector
      cardId={card.id}
      disabled={isButtonDisabled}
      availableCards={availableCards}
      handleCardSelect={handleCardSelect}
    />,
  ];
};
