import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchFollowArtists,
  selectArtists,
  followArtist,
} from 'redux/follow/artists';

export const useArtists = () => {
  const dispatch = useDispatch();
  const { isLoading, artists, isLoaded } = useSelector(selectArtists);

  useEffect(() => {
    if (!isLoading && !artists.length && !isLoaded) {
      dispatch(fetchFollowArtists());
    }
  }, [dispatch, isLoading, artists, isLoaded]);

  return {
    isLoading,
    artists,
    isLoaded,
  };
};

export const useFollowArtists = () => {
  const [id, setId] = useState(0);

  const subscribeArtist = (artistId) => {
    setId(artistId);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== 0) {
      dispatch(followArtist({ product_id: id }));
    }
  }, [id, dispatch]);

  return { subscribeArtist };
};
