import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  bottomInputsContainer: {
    width: '100%',
  },
  bottomInput: {
    flex: 1,
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  submitBtn: {
    marginBottom: theme.spacing(2),
  },
  paymentError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(1),
  },
  errorLink: {
    color: theme.palette.error.main,
    textDecoration: 'underline',
  },
  notice: {
    textAlign: 'left',
    lineHeight: 1.8,
  },
}));
