import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSweeps, selectSweepstakeById, selectSweeps } from 'redux/sweeps';

const useUpsells = (sweepId) => {
  const dispatch = useDispatch();
  const [upsellsWithEvents, setUpsells] = useState([]);

  const { sweepstakes, isLoading, isLoaded } = useSelector(selectSweeps);
  const sweepstake = useSelector(selectSweepstakeById(sweepId));

  useEffect(() => {
    if (!sweepstakes.length && !isLoaded) {
      dispatch(fetchSweeps());
    }
  }, [sweepstakes, isLoaded, dispatch]);

  useEffect(() => {
    if (sweepstake && sweepstake.upsells) {
      const upsells = sweepstake.upsells
        ?.map((upsell) => {
          const { events } = sweepstakes.find((item) => item.id === upsell.id);
          return { ...upsell, events };
        })
        .sort((a, b) => a.priority - b.priority);
      setUpsells(upsells);
    }
  }, [sweepId, sweepstake, sweepstakes]);

  return {
    upsellsWithEvents,
    isSweepstakesLoading: isLoading,
    isSweepstakesLoaded: isLoaded,
  };
};

export default useUpsells;
