import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import CircleIcon from 'components/CircleIcon';

import clsx from 'clsx';

import { useStyles } from './styles';

const HorizontalFilter = ({ filterOptions, activeFilter, setActiveFilter }) => {
  const classes = useStyles();
  const activeFilterRef = useRef(null);

  useEffect(() => {
    if (activeFilterRef.current) {
      activeFilterRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [activeFilter]);

  return (
    <Grid
      className={classes.root}
      container
      justify={filterOptions?.length > 3 ? 'flex-start' : 'center'}
      alignItems="center"
      wrap="nowrap"
    >
      {filterOptions.map(({ displayName, disabled, showRedDot, key }) => {
        const active = !!activeFilter && activeFilter === key;
        return (
          !disabled && (
            <Button
              variant="outlined"
              className={clsx(classes.button, active && 'active')}
              ref={active ? activeFilterRef : null}
              key={key}
              size="medium"
              onClick={() => setActiveFilter(key)}
            >
              {displayName}
              {showRedDot && <CircleIcon />}
            </Button>
          )
        );
      })}
    </Grid>
  );
};

HorizontalFilter.defaultProps = {
  filterOptions: [],
  activeFilter: null,
  setActiveFilter: () => null,
};

HorizontalFilter.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.exact({
      displayName: PropTypes.string,
      key: PropTypes.string,
      id: PropTypes.number,
      priority: PropTypes.number,
      default: PropTypes.string,
      disabled: PropTypes.bool,
      showRedDot: PropTypes.bool,
    })
  ),
  activeFilter: PropTypes.string,
  setActiveFilter: PropTypes.func,
};

export default HorizontalFilter;
