import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import { Link as MLink } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { clearPaymentIntentSecret } from 'redux/charities';
import StripeInput from '../StripeInput';

import useStyles from './styles';

const CheckoutForm = ({
  children,
  onSubmit,
  isDonationProcessing,
  processingError,
  paymentIntentSecret,
  paymentIntent,
  donationNotice,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [
    paymentAuthorizationProcessing,
    setPaymentAuthorizationProcessing,
  ] = useState(false);

  // Need to check Stripe validation errors manually
  // since Stripe has a specific internal implementation of entering values
  // because of ensuring compliance with PCI SAQ A requirements
  // and it's impossible to use standard validation libraries since they doesn't able to receive values from inputs
  const [stripeErrors, setErrors] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });

  const [paymentMethodError, setPaymentMethodError] = useState(null);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);

  const [cardName, setCardName] = useState('');

  const handlePaymentAuthentication = useCallback(async () => {
    const handleCardResponse = await stripe.handleCardAction(
      paymentIntentSecret
    );
    if (handleCardResponse.error) {
      setPaymentMethodError(handleCardResponse.error.message);
      dispatch(clearPaymentIntentSecret());
    } else {
      setPaymentMethodError(null);
      onSubmit({ intent: handleCardResponse.paymentIntent.id });
    }
    setPaymentAuthorizationProcessing(false);
  }, [onSubmit, paymentIntentSecret, stripe, dispatch]);

  useEffect(() => {
    if (paymentIntentSecret) {
      setPaymentAuthorizationProcessing(true);
      handlePaymentAuthentication();
    }
  }, [paymentIntentSecret, stripe, onSubmit, handlePaymentAuthentication]);

  const handleCardName = useCallback(({ target: { value } }) => {
    setCardName(value);
  }, []);

  const handleStripeInputChange = useCallback(
    (event) => {
      if (paymentMethodError) {
        setPaymentMethodError(null);
      }
      const { elementType, error } = event;
      if (!error && stripeErrors[elementType]) {
        setErrors((prev) => ({ ...prev, [elementType]: null }));
      }
      if (error) {
        setErrors((prev) => ({ ...prev, [elementType]: error.message }));
      }
    },
    [stripeErrors, paymentMethodError]
  );

  const isButtonDisabled = useMemo(
    () => Object.keys(stripeErrors).some((value) => !!stripeErrors[value]),
    [stripeErrors]
  );

  const handleSupportButtonClick = useCallback(() => {
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open');
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitButtonPressed(true);
    const cardNumber = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      ...(cardName.length && {
        billing_details: {
          name: cardName,
        },
      }),
    });
    if (error) {
      setPaymentMethodError(error.message);
    } else {
      onSubmit({ paymentMethod: paymentMethod.id, intent: paymentIntent });
    }
    setIsSubmitButtonPressed(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.form}
      data-cy="checkout-form"
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        className={classes.container}
      >
        <Grid item>
          <TextField
            className={classes.input}
            label="Name on Card"
            name="cardName"
            variant="outlined"
            value={cardName}
            required
            InputLabelProps={{
              shrink: true,
            }}
            data-cy="card-name"
            onChange={handleCardName}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Card Number"
            name="cardNumber"
            className={classes.input}
            onChange={handleStripeInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
              },
            }}
            helperText={stripeErrors.cardNumber}
            error={!!stripeErrors.cardNumber}
          />
        </Grid>
        <Grid
          item
          container
          justify="space-between"
          className={classes.bottomInputsContainer}
        >
          <Grid item className={classes.bottomInput}>
            <TextField
              variant="outlined"
              label="Expiration Date"
              name="cardExpiry"
              className={classes.input}
              onChange={handleStripeInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                },
              }}
              helperText={stripeErrors.cardExpiry}
              error={!!stripeErrors.cardExpiry}
            />
          </Grid>
          <Grid item className={classes.bottomInput}>
            <TextField
              variant="outlined"
              label="CVC"
              name="cvc"
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleStripeInputChange}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                },
              }}
              helperText={stripeErrors.cardCvc}
              error={!!stripeErrors.cardCvc}
            />
          </Grid>
        </Grid>
      </Grid>
      {children}
      <div>
        <Typography className={classes.paymentError}>
          {paymentMethodError}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          size="large"
          fullWidth
          data-cy="submit-button"
          disabled={
            isButtonDisabled ||
            !!paymentMethodError ||
            isDonationProcessing ||
            paymentAuthorizationProcessing ||
            isSubmitButtonPressed
          }
        >
          {isDonationProcessing ? <CircularProgress size={20} /> : 'Donate Now'}
        </Button>
        {processingError && (
          <Typography className={classes.paymentError} color="error">
            {`${processingError}. Please `}
            <MLink
              href="#"
              className={classes.errorLink}
              onClick={handleSupportButtonClick}
            >
              contact Support
            </MLink>{' '}
            if you feel this message is in error.
          </Typography>
        )}
        <Typography>Payment secured by Stripe</Typography>
        <div className={classes.notice}>{donationNotice}</div>
      </div>
    </form>
  );
};

export default CheckoutForm;
