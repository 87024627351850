import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  getCodeBtn: {
    marginTop: theme.spacing(4),
  },
  loginLink: {
    fontSize: '0.85rem',
    color: theme.palette.accent.main,
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  howItWorks: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
  howItWorksList: {
    '& ol': {
      marginTop: '0.2em',
      paddingLeft: '1em',
      fontSize: '0.85rem',
      '& > li': {
        marginTop: theme.spacing(1),
      },
      '& a': {
        color: 'inherit',
      },
    },
  },
}));
