import React from 'react';

import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { selectWinnersFromCMS } from 'redux/winners';
import { selectIsLoading } from 'redux/cms';

import Loader from 'components/Loader';
import WinnerCard from '../WinnerCard';

import { useStyles } from './styles';

const WinnersList = () => {
  const winners = useSelector(selectWinnersFromCMS);
  const isLoading = useSelector(selectIsLoading);
  const classes = useStyles();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems="stretch" spacing={3}>
        {getWinners(winners, classes)}
      </Grid>
    </>
  );
};

const getWinners = (winners, classes) =>
  winners.length ? (
    winners.map((item) => (
      <Grid item xs={12} xl={3} md={4} lg={4} key={item.name}>
        <WinnerCard {...item} />
      </Grid>
    ))
  ) : (
    <Typography variant="h6" className={classes.empty}>
      Winner announcements starting December 20th!
    </Typography>
  );

export default WinnersList;
