import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 1.875),
    boxShadow:
      '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 18px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(1),
    textAlign: 'left',
  },
  avatar: {
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
  userInfo: {
    flex: 1,
  },
  text: {
    fontWeight: 'bold',
  },
  title: {
    textAlign: 'left',
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  event: {
    maxWidth: 185,
  },
  winner: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  winners: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
}));
