import { Api } from './index';

export const loginUserRequest = (data) => Api.post('/users/login', data);

export const logoutUserRequest = () => Api.post('/logout');

export const signupUserRequest = (data) => Api.post('/users/sign_up', data);

export const updateUserRequest = (data) => Api.put('/users/account', data);

export const getAccountRequest = () => Api.get('/users/account');

export const forgotPasswordRequest = (data) =>
  Api.post('/password_reset', data);

export const resetPasswordConfirmRequest = (data) =>
  Api.post('/password_reset/confirm', data);

export const completeOnboardingRequest = () =>
  Api.post('/users/onboarding/complete');

export const verifyPhoneNumberRequest = (data) =>
  Api.post('/phone_numbers/verify', data);

export const resendCodeRequest = () => Api.post('/phone_numbers/send_code');

export const facebookLoginRequest = (data) => Api.post('/users/fb_login', data);

export const spotifyLoginRequest = (data) => Api.post('/login/spotify', data);

export const spotifyConnectRequest = (data) => {
  return Api.post('/connect/spotify', data, true);
};

export const upholdLoginRequest = (data) =>
  Api.post('/users/login/uphold', data);
