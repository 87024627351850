import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { selectSponsorData } from 'redux/sweeps';

import { PATHS } from 'constants/navigation';
import Loader from 'components/Loader';

import { storage } from 'services/storage';
import useUpsells from 'hooks/upsells';
import { useSweepOrderInfo } from 'hooks/sweepstakes';

// import Typography from '@material-ui/core/Typography';

import Upsells from '../components/Upsells';
import SponsorContent from '../components/SponsorContent';
import SuccessContent from '../components/SuccessContent';

const SweepstakesConfirmationPage = () => {
  const dispatch = useDispatch();
  const { sweepId, eventId } = useParams();

  const { announcementDate, amount } = useSweepOrderInfo();
  const sponsorData = useSelector(selectSponsorData(sweepId));
  const {
    upsellsWithEvents,
    isSweepstakesLoading,
    isSweepstakesLoaded,
  } = useUpsells(sweepId);

  useEffect(() => {
    storage.local.removeItem('enteredSweepstake');
  }, [dispatch]);

  if (isSweepstakesLoading || !isSweepstakesLoaded) {
    return <Loader />;
  }

  if (!announcementDate) {
    dispatch(push(PATHS.sweepstakes));
    return <></>;
  }

  return (
    <SuccessContent
      announcementDate={announcementDate}
      amount={amount}
      sponsorContent={<SponsorContent sponsorData={sponsorData} />}
      sweepId={sweepId}
      eventId={eventId}
      upsells={<Upsells upsells={upsellsWithEvents} />}
    />
  );
};

export default SweepstakesConfirmationPage;
