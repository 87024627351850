import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getPartner } from 'utils/config';
import { selectConfigProperty } from 'redux/config';
import images from 'assets/images';
import { CONFIG_KEYS_MAP } from 'constants/config';

const fonts = {
  base: [
    <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
      key="https://fonts.gstatic.com"
    />,
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400&display=swap"
      rel="stylesheet"
      key="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400&display=swap"
    />,
  ],
  syw: [
    <link rel="preconnect" href="https://fonts.gstatic.com" />,
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap"
      rel="stylesheet"
    />,
  ],
  uphold: [
    <link rel="preconnect" href="https://fonts.gstatic.com" />,
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap"
      rel="stylesheet"
    />,
  ],
  uber: null,
};

const Metadata = () => {
  const { title, description, color } = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.htmlMetaTags)
  );
  const { zoho_id, zoho_org_id } = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.customerService)
  );
  const { name } = getPartner();

  return (
    <Helmet>
      <title>{title || 'My TAP Rewards'}</title>
      <link rel="icon" href={images.favicon} />
      <link
        rel="apple-touch-icon"
        href={`${process.env.PUBLIC_URL}/static/${name}/logo192.png`}
      />
      <meta name="description" content={`${description || 'My TAP Rewards'}`} />
      <meta name="theme-color" content={`${color}`} />
      {/* manifest.json provides metadata used when your web app is installed on a
      user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/ */}
      <link
        rel="manifest"
        href={`${process.env.PUBLIC_URL}/static/${name}/manifest.json`}
      />
      {/* Zoho widget setup */}
      {zoho_id && zoho_org_id && (
        <script
          type="text/javascript"
          defer=""
          src={`https://desk.zoho.com/portal/api/web/inapp/${zoho_id}?orgId=${zoho_org_id}`}
        />
      )}
      {fonts[name] || fonts.base}
    </Helmet>
  );
};

export default Metadata;
