import { getPartner } from 'utils/config';

import braveImages from './brave';
import sywImages from './syw';
import uberImages from './uber';
import upholdImages from './uphold';
import wmgImages from './wmg';

const imageMap = {
  brave: braveImages,
  syw: sywImages,
  uber: uberImages,
  uphold: upholdImages,
  wmg: wmgImages,
};

export default imageMap[getPartner().name];
