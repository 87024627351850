/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from 'react';
import parse from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux';

import {
  getCms,
  selectShouldFetchCms,
  selectCmsItem,
  selectItemData,
  selectCmsHtml,
} from 'redux/cms';

export const useCms = (fromCms = { key: null, path: null }) => {
  const { key, path } = fromCms;
  const dispatch = useDispatch();

  const shouldFetchCms = useSelector(selectShouldFetchCms);
  const cmsItem = useSelector((state) => selectCmsItem(state, { key }));
  const itemAtPath = useSelector((state) =>
    selectItemData(state, { key, path })
  );

  useEffect(() => {
    if (shouldFetchCms) {
      dispatch(getCms());
    }
  }, [shouldFetchCms]);

  return path ? itemAtPath : cmsItem;
};

export const useCmsHtml = (key) => {
  useCms();

  const htmlToParse = useSelector((state) => selectCmsHtml(state, { key }));
  return key && htmlToParse ? () => parse(htmlToParse) : Fragment;
};
