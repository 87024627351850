import { createSelector } from '@reduxjs/toolkit';

const selectSpinner = (state) => state.spinner;

export const selectSpinnerPrizesData = createSelector(
  [selectSpinner],
  ({ prizes, isLoading, isLoaded }) => ({ prizes, isLoading, isLoaded })
);

export const selectDailyJackpotInfo = createSelector(
  [selectSpinner],
  (spinner) => spinner.jackpotInfo
);

export const selectSpinnerResult = createSelector(
  [selectSpinner],
  (spinner) => ({ result: spinner.result, error: spinner.error })
);
