import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { useTracks } from 'hooks/follow/tracks';
import { useUserNavigation } from 'hooks/navigation';
import ConnectErrorModal from 'components/ConnectErrorModal/index';
import PermissionLoginModal from 'components/SpotifyPermissionLoginModal';
import { selectFollowed, followTrack } from 'redux/follow/tracks';
import { selectIsSpotify, selectSpotifyConnectError } from 'redux/user';
import { storage } from 'services/storage';
import FollowCard from '../components/FollowCard';

import useStyles from './styles';

const FollowTracks = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tracks } = useTracks();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isSpotify = useSelector(selectIsSpotify);
  const spotifyConnectError = useSelector(selectSpotifyConnectError);
  const [isConnectErrorModalOpen, setIsConnectErrorModalOpen] = useState(false);
  const followedTracks = useSelector(selectFollowed);
  const [products, setProducts] = useState([]);
  const followText = 'Save';
  const followedText = 'Saved';

  useEffect(() => {
    storage.local.set('follow', 'tracks');
  }, []);

  useEffect(() => {
    if (tracks && tracks.length) {
      const sortedProducts = [...tracks].sort(
        (a, b) => a.followed - b.followed
      );
      setProducts(sortedProducts);
    }
  }, [tracks]);

  const onSubmit = (product_id) => {
    dispatch(followTrack({ product_id }));
  };

  useUserNavigation();

  return (
    <div className={classes.root}>
      <PermissionLoginModal open={isOpenModal} />
      <ConnectErrorModal
        toggleModal={() => setIsConnectErrorModalOpen(false)}
        open={isConnectErrorModalOpen}
        spotifyConnectError={spotifyConnectError}
      />
      <div className={classes.head}>
        <Typography variant="h5" className={classes.title}>
          Save a Track, Get Rewarded.
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          Earn 5 tokens instantly for every Warner Track you save on Spotify.
        </Typography>
      </div>
      {products.length ? (
        <Grid item container spacing={2} className={classes.content}>
          {products.map(({ id, name, image_url, followed }) => (
            <FollowCard
              key={id}
              id={id}
              name={name}
              image_url={image_url}
              isSpotify={isSpotify}
              isFollowed={followed || !!followedTracks.find((el) => el === id)}
              followText={followText}
              followedText={followedText}
              setIsOpenModal={setIsOpenModal}
              onSubmit={onSubmit}
            />
          ))}
        </Grid>
      ) : (
        <Typography variant="subtitle1" className={classes.subText}>
          Congrats, you&apos;ve saved all available Tracks. Check back soon for
          more!
        </Typography>
      )}
    </div>
  );
};

export default FollowTracks;
