import { PATHS } from '../navigation';

const staticInfoRoutes = [
  { name: 'FAQ', path: PATHS.faq },
  { name: 'Terms', path: PATHS.terms },
  { name: 'Privacy', path: PATHS.privacy },
  { name: 'Support', path: PATHS.support },
];

const winnerRoute = {
  name: 'Winners',
  path: PATHS.sweepstakes,
};

export const getRoutesList = ({
  isChooseExperienceEnabled,
  isGamesPageEnabled,
}) => {
  const sweepsRoute = {
    name: isChooseExperienceEnabled ? 'Choose experience' : 'Sweepstakes',
    path: isChooseExperienceEnabled
      ? PATHS.chooseExperience
      : PATHS.sweepstakes,
    'data-cy': 'sweepstakes-link',
  };
  const gamesRoute = isGamesPageEnabled && { name: 'Games', path: PATHS.games };

  const defaultRoutes = [sweepsRoute, gamesRoute, winnerRoute];

  const loggedOutRoutesList = [...defaultRoutes, ...staticInfoRoutes];

  const loggedInRoutesList = [
    {
      name: 'Get More Tokens',
      path: PATHS.moreTokens,
      'data-cy': 'get-more-tokens-link',
    },
    ...defaultRoutes,
    { name: 'Activity', path: PATHS.activity },
    ...staticInfoRoutes,
  ];

  return {
    loggedOutRoutesList,
    loggedInRoutesList,
  };
};
