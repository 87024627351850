import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    margin: theme.spacing(1.5, 0),
    color: theme.palette.primary.main,
  },
  sutTitle: {
    marginBottom: theme.spacing(2),
  },
  rule: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  ruleIcon: {
    width: 16,
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
  skipLink: {
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '0.85rem',
    textAlign: 'center',
    padding: theme.spacing(1, 1.4),
    textDecoration: 'underline',
  },
}));
