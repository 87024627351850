import React, { useRef, useEffect, useCallback } from 'react';
import * as d3 from 'd3';

import { SPINNER_DURATION } from 'constants/spinner';

const originalSize = 372;

const SpinnerWheel = ({ pieces, winItem, onSpinEnd, size, onSectorClick }) => {
  const svgRef = useRef(null);
  const wheelRef = useRef(null);
  const oldRotation = useRef(-(360 / pieces.length / 2));

  const drawWheel = useCallback(() => {
    const paddingTop = size / 15;
    const w = size;
    const h = size - paddingTop;
    const r = Math.min(w, h) / 2;

    const svg = d3
      .select(svgRef.current)
      .data([pieces])
      .attr('width', w)
      .attr('height', h + paddingTop)
      .attr('data-cy', 'spinner-wheel');

    svg.selectAll('g').remove();

    const container = svg
      .append('g')
      .attr('transform', `translate(${w / 2},${h / 2 + paddingTop})`);

    wheelRef.current = container
      .append('g')
      .attr('transform', `rotate(${oldRotation.current})`);

    const pie = d3
      .pie()
      .sort(null)
      .value(() => 1);

    // declare an arc generator function
    const arc = d3.arc().innerRadius(0).outerRadius(r);

    // select paths, use arc generator to draw
    const arcs = wheelRef.current
      .selectAll('g.slice')
      .data(pie)
      .enter()
      .append('g')
      .attr('class', 'slice')
      .attr('data-cy', (d) => `spinner-wheel-slice-${d.data.sub_type}`);

    // fill sectors color
    arcs
      .append('path')
      .attr('fill', (d) => d.data.sector_color)
      .attr('d', (d) => arc(d))
      .on('click', onSectorClick)
      .style('cursor', (d) => (d.data.clickable ? 'pointer' : 'default'));

    // add images to sectors
    arcs
      .append('image')
      .attr('xlink:href', (d) => d.data.sector_image)
      .attr('width', size / 2 - paddingTop / 2)
      .attr(
        'transform',
        (d) =>
          `rotate(${
            (((d.startAngle + d.endAngle) / 2) * 180) / Math.PI - 90
          })translate(0,-${(70 * size) / originalSize - paddingTop * 0.18})`
      )
      .style('pointer-events', 'none');

    // draw arrow
    const arrowScale = size / originalSize;
    svg
      .append('g')
      .append('path')
      .attr(
        'transform',
        `translate(${
          size / 2 - (63.3 / 2) * arrowScale
        },-5)scale(${arrowScale})`
      )
      .attr(
        'd',
        'M1.8,0.1H62c1.2,0,2,1.3,1.3,2.4l-30.1,47c-0.6,0.9-2,1-2.6,0L0.6,2.6C-0.2,1.6,0.5,0.1,1.8,0.1z'
      )
      .attr('fill', '#ffc043');

    // draw spin circle
    container
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', size / 25)
      .attr('stroke', '#fff')
      .attr('stroke-width', size / 40)
      .attr('fill', '#000');
  }, [pieces, size, onSectorClick]);

  const spin = useCallback(
    async (index) => {
      const ps = 360 / pieces.length;
      const rotation = 9 * 360 - index * ps - 10 - (ps - 10) * Math.random();

      return new Promise((resolve) => {
        wheelRef.current
          .transition()
          .duration(SPINNER_DURATION)
          .attrTween('transform', () => rotTween(rotation))
          .on('end', () => {
            oldRotation.current = rotation;
            resolve();
          });
      });
    },
    [pieces.length]
  );

  const rotTween = (to) => (t) =>
    `rotate(${d3.interpolate(oldRotation.current % 360, to)(t)})`;

  useEffect(() => {
    if (pieces.length) {
      drawWheel();
    }
  }, [drawWheel, pieces, size]);

  useEffect(() => {
    if (winItem) {
      const index = pieces.findIndex((i) => i.id === winItem);
      spin(index).then(onSpinEnd);
    }
  }, [winItem, pieces, onSpinEnd, spin]);

  return <svg ref={svgRef} />;
};

export default SpinnerWheel;
