import { call, all, takeEvery, put } from 'redux-saga/effects';
import { getWallet } from 'redux/wallet';
import {
  fetchFollowArtists as _fetchFollowArtists,
  followArtist as _followArtist,
} from 'services/api/follow/artists';

import {
  fetchFollowArtists,
  fetchFollowArtistsSuccess,
  fetchFollowArtistsFailure,
  followArtist,
  followArtistSuccess,
  followArtistFailure,
} from './slice';

function* fetchFollowArtistsSaga() {
  try {
    const { data } = yield call(_fetchFollowArtists);
    yield put(fetchFollowArtistsSuccess(data));
  } catch (err) {
    yield put(fetchFollowArtistsFailure());
  }
}

function* postFollowArtistSaga(id) {
  try {
    const { data } = yield call(_followArtist, id);
    yield put(
      followArtistSuccess(data.order.line_items[0].data.followed_artist_id)
    );
    yield put(getWallet());
  } catch (error) {
    yield put(followArtistFailure(error.message));
  }
}

export function* followArtistsSaga() {
  yield all([
    takeEvery(fetchFollowArtists, fetchFollowArtistsSaga),
    takeEvery(followArtist, postFollowArtistSaga),
  ]);
}
