import React from 'react';
import { useSelector } from 'react-redux';

import { selectScratchGame } from 'redux/scratchGame';
import { Button, CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

export const WmgButton = ({ handleOnclick, hasUserTokens }) => {
  const classes = useStyles();

  const { isLoading, isButtonDisabled, isModalLoading } = useSelector(
    selectScratchGame
  );

  const showButtonText = () => {
    if (isButtonDisabled) {
      return 'You’re ready to scratch!';
    }
    if (hasUserTokens) {
      return 'Scratch again for 1 token!';
    }
    return 'Get more tokens!';
  };

  return (
    <Button
      fullWidth
      type="submit"
      variant="contained"
      color="primary"
      className={classes.buttonReload}
      onClick={() => handleOnclick(hasUserTokens)}
      size="large"
      data-cy="scratch_button"
      disabled={isButtonDisabled}
    >
      {isLoading || isModalLoading ? (
        <CircularProgress size={20} color="white" />
      ) : (
        showButtonText()
      )}
    </Button>
  );
};
