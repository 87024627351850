import {
  call,
  all,
  takeEvery,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { fetchSweeps as _fetchSweeps, postEntries } from 'services/api/sweeps';
import { storage } from 'services/storage';

import { push } from 'connected-react-router';

import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';

import { selectConfigProperty } from 'redux/config';
import { trackEvent } from 'redux/analytics';
import { getWallet, getActivities, selectWalletInfo } from 'redux/wallet';
import {
  fetchSweeps,
  fetchSweepsFailure,
  fetchSweepsSuccess,
  enterSweeps,
  enterSweepsSuccess,
  enterSweepsFailure,
} from './slice';

const defaultNotEnoughEntriesMessages = [
  'number_of_entries: must be greater than 0',
  'Not enough Entries and Points to process the request',
];

const requiredNotEnoughEntriesMessage =
  'Insufficient tokens, please choose a different entry amount.';

function* fetchSweepsSaga() {
  try {
    const {
      data: { sweepstakes },
    } = yield call(_fetchSweeps);
    yield put(fetchSweepsSuccess(sweepstakes));
  } catch (err) {
    yield put(fetchSweepsFailure());
  }
}

function* postSweepstakesEntries({
  payload: { event_id, value, sweep_newsletter_opt_in, wallet_id, sweepId },
}) {
  try {
    const { amount } = yield select(selectWalletInfo('ust'));

    const number_of_entries = value === 'ALL' ? amount : value;

    const { data } = yield call(postEntries, {
      event_id,
      number_of_entries,
      sweep_newsletter_opt_in,
      wallet_id,
    });

    yield put(
      trackEvent({
        event: {
          eventCategory: 'sweepstakes',
          eventAction: 'sweepstake-entry',
          eventLabel: number_of_entries,
        },
        location: window.location,
      })
    );

    yield put(enterSweepsSuccess(data));
    yield put(getWallet());
    yield put(getActivities({ category: 'sweepstakes' }));

    const isCharityEnabled = yield select(
      selectConfigProperty(CONFIG_KEYS_MAP.charityEnabled)
    );

    if (isCharityEnabled) {
      yield call(
        storage.local.set,
        'enteredSweepstake',
        JSON.stringify({ sweepId, eventId: event_id })
      );
      yield put(push(PATHS.charityUpsell));
    } else {
      yield put(push(`${PATHS.sweepstakes}/${sweepId}/${event_id}/success`));
    }
  } catch (error) {
    let errorMsg = 'Processing error, please try again.';
    if (error?.data.errors[0]) {
      errorMsg = error?.data.errors[0];
    }
    if (defaultNotEnoughEntriesMessages.includes(error?.data.errors[0])) {
      errorMsg = requiredNotEnoughEntriesMessage;
    }
    yield put(enterSweepsFailure(errorMsg));
  }
}

export function* sweepsSaga() {
  yield all([
    takeEvery(fetchSweeps, fetchSweepsSaga),
    takeLatest(enterSweeps, postSweepstakesEntries),
  ]);
}
