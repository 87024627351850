import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  checkboxContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  checkboxIcon: {
    fontSize: '1.625rem',
    color: 'black',
  },
  rulesContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
  },
  newsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  newsCheckboxLabel: {
    '& p': {
      padding: 0,
      margin: 0,
    },
    '& a': {
      color: 'inherit',
    },
  },
  rulesMessageLinks: {
    textDecoration: 'underline',
    display: 'inline-block',
  },
}));
