import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Typography, Link } from '@material-ui/core';

import { useConfig } from 'hooks/config';
import { useUserNavigation } from 'hooks/navigation';
import Loader from 'components/Loader';
import PartnerSpecificContent from 'components/PartnerSpecificContent';
import { PATHS } from 'constants/navigation';

import { useStyles } from './styles';
import { ScratchGame } from './components';

const ScratchPage = () => {
  const classes = useStyles();
  const { isLoading, config } = useConfig();

  useUserNavigation();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.scratchRoot}>
      <div className={classes.scratchCard}>
        <div className={classes.scratchCardDescription}>
          <Typography className={classes.scratchTitle}>
            {config.scratch_off_game_name}
          </Typography>
          <PartnerSpecificContent partnerNames="brave">
            <Typography className={classes.scratchRules}>
              Reveal 3 Brave lions and win a $10 Gift Card. Find the secret
              treasure chest and win a bonus $25 Gift Card!
            </Typography>
          </PartnerSpecificContent>
          <PartnerSpecificContent partnerNames="uphold">
            <Typography className={classes.scratchRules}>
              Reveal 3 Uphold logos and win a $10 Gift Card. Find the secret
              treasure chest and win a bonus $25 Gift Card!
            </Typography>
          </PartnerSpecificContent>
          <PartnerSpecificContent partnerNames="wmg">
            <Typography className={classes.scratchRules}>
              Reveal 3 Warner Music logos and win 5 tokens. Find the secret
              treasure chest and win a $25 Warner Megastore gift card!
            </Typography>
          </PartnerSpecificContent>
        </div>
        <Box className={classes.scratchContainerGame}>
          <ScratchGame />
        </Box>
        <div>
          <Typography className={classes.scratchInfoGameFooter}>
            Game resets each day at 12am PT. Daily free play is a limited time
            offer and will not accrue. See{' '}
            <Link
              color="inherit"
              component={RouterLink}
              className={classes.rulesLink}
              to={PATHS.scratchRules}
              target="_blank"
              onClick={(event) => {
                event.preventDefault();
                window.open(`${PATHS.scratchRules}`);
              }}
            >
              Official Rules
            </Link>{' '}
            for details.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ScratchPage;
