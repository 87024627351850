import PropTypes from 'prop-types';

const Event = PropTypes.shape({
  id: PropTypes.number,
  location_date: PropTypes.string,
  event_date: PropTypes.string,
  entry_period_start: PropTypes.string,
  entry_period_end: PropTypes.string,
  winner_announcement_date: PropTypes.string,
  prize_value: PropTypes.string,
  active: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      customSliderImage: PropTypes.string,
    }),
  ]),
});

export const Upsell = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  profile_image: PropTypes.string,
  priority: PropTypes.number,
});

export const DefaultProps = {
  className: '',
};

export const Props = {
  item: PropTypes.shape({
    id: PropTypes.number,
    events: PropTypes.arrayOf(Event),
    profile_image: PropTypes.string,
    name: PropTypes.string,
    upsells: PropTypes.arrayOf(Upsell),
  }).isRequired,
  className: PropTypes.string,
};
