import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  innerContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    flexWrap: 'nowrap',
    height: '48px',
    '& > div': {
      height: '100%',
      width: 'fit-content',
    },
  },
  hiddenItems: {
    minHeight: 44,
  },
  svg: {
    fontSize: '1rem',
    fill: theme.palette.white,
  },
  icon: {
    fontSize: '1rem',
    margin: 0,
    marginLeft: theme.spacing(-2),
  },
  text: {
    fontWeight: 'bold',
  },
  button: {
    color: theme.palette.white.main,
    textTransform: 'uppercase',
    padding: theme.spacing(1.5, 0),
    marginLeft: '16px',
  },
  link: {
    color: theme.palette.white.main,
    textDecoration: 'none',
    display: 'block',
    whiteSpace: 'nowrap',
    borderBottom: '2px solid transparent',
  },
  paddings: {
    padding: theme.spacing(1.5, 0),
  },
  walletLink: {
    maxWidth: 'none',
  },
  active: (shouldLinkHaveActiveStyle) =>
    shouldLinkHaveActiveStyle && {
      color: theme.palette.white.main,
      borderBottom: `2px solid ${theme.palette.white.main}`,
    },
  // use a separate style for wallet nav link since it should be active everywhere
  walletActiveLink: {
    color: theme.palette.white.main,
    borderBottom: `2px solid ${theme.palette.white.main}`,
  },
}));
