import React from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'constants/navigation';

import { useUserNavigation } from 'hooks/navigation';
import { useCmsHtml } from 'hooks/cms';

import useStyle from './styles';

const legalInfoKeys = {
  [PATHS.privacy]: 'uberPrivacyPolicy',
  [PATHS.terms]: 'uberTerms',
  [PATHS.spinnerRules]: 'spinnerRules',
  [PATHS.scratchRules]: 'scratchRules',
};

const LegalPage = () => {
  const location = useLocation();
  const Content = useCmsHtml(legalInfoKeys[location.pathname]);

  useUserNavigation();

  const classes = useStyle();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.contentWrapper}>
          <Content />
        </div>
      </div>
    </>
  );
};

export default LegalPage;
