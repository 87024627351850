import React from 'react';

import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { toPlural } from 'utils/formatting';

import { ReactComponent as TokenIcon } from 'assets/images/icons/token-icon.svg';

import useStyles from './styles';

const WalletTab = ({ tokens, showBackButton }) => {
  const classes = useStyles();

  return tokens ? (
    <Grid
      item
      container
      alignItems="center"
      className={classes.root}
      style={{ marginRight: showBackButton ? '16px' : null }}
    >
      <TokenIcon className={classes.svg} />
      <Typography className={classes.text} data-cy="ribbon-wallet-tab">
        {toPlural(Number(tokens), 'token')}
      </Typography>
    </Grid>
  ) : null;
};

WalletTab.defaultProps = {
  tokens: '',
};

WalletTab.propTypes = {
  tokens: PropTypes.string,
};

export default WalletTab;
