import sub from 'date-fns/sub';

export const formatFinishSweepDate = (date, options) =>
  sub(date, {
    hours: 9,
  }).toLocaleDateString('en-US', options);

export const toPlural = (number, alias) =>
  `${number} ${Math.abs(number) === 1 ? `${alias}` : `${alias}s`}`;

export const toHHMMSS = (secs) => {
  const sec_num = parseInt(secs, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  return [hours, minutes, seconds].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};
