import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2.5),
    boxShadow:
      '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 18px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(1),
    textAlign: 'left',
    margin: theme.spacing(1, 2, 2),
    minHeight: `calc(100% - ${theme.spacing(2)}px)`,
  },
  content: {
    minHeight: '2.875rem',
  },
  amountWrapper: {
    width: 'max-content',
    maxWidth: '60%',
    textAlign: 'right',
  },
  itemWapper: {
    width: 'max-content',
    flex: 1,
  },
  text: {
    fontWeight: 'bold',
  },
  name: {
    maxWidth: '14rem',
    wordBreak: 'break-word',
  },
  tiny: {
    color: theme.palette.greyDark.main,
    marginTop: theme.spacing(1),
  },
  amount: {
    color: theme.palette.greyDark.main,
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.accent.main,
    marginTop: theme.spacing(1.5),
  },
}));
