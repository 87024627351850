import { call, all, takeEvery, put } from 'redux-saga/effects';
import { getWallet } from 'redux/wallet';
import {
  fetchFollowTracks as _fetchFollowTracks,
  followTrack as _followTrack,
} from 'services/api/follow/tracks';

import {
  fetchFollowTracks,
  fetchFollowTracksSuccess,
  fetchFollowTracksFailure,
  followTrack,
  followTrackSuccess,
  followTrackFailure,
} from './slice';

function* fetchFollowTracksSaga() {
  try {
    const { data } = yield call(_fetchFollowTracks);
    yield put(fetchFollowTracksSuccess(data));
  } catch (err) {
    yield put(fetchFollowTracksFailure());
  }
}

function* postFollowTrackSaga(id) {
  try {
    const { data } = yield call(_followTrack, id);
    yield put(
      followTrackSuccess(data.order.line_items[0].data.followed_track_id)
    );
    yield put(getWallet());
  } catch (error) {
    yield put(followTrackFailure(error.message));
  }
}

export function* followTracksSaga() {
  yield all([
    takeEvery(fetchFollowTracks, fetchFollowTracksSaga),
    takeEvery(followTrack, postFollowTrackSaga),
  ]);
}
