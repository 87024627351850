import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    margin: theme.spacing(0, -2, -2),
    padding: theme.spacing(0, 2),
    height: 580,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(376)]: {
      height: 530,
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: '500',
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(2),
  },
  image: {
    maxWidth: '70%',
    margin: theme.spacing(0, 'auto', 1),
  },
  bg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '85%',
    objectFit: 'cover',
    zIndex: -1,
  },
  subTitle: {
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    backdropFilter: 'blur(1px)',
    boxShadow: '0 0 3px 1px rgba(255,255,255,0.5)',
    padding: theme.spacing(1),
    borderRadius: 10,
  },
  button: {
    margin: theme.spacing('auto', 0, 4, 0),
    width: '100%',
    background: theme.palette.white.main,
    '&:hover': {
      background: theme.palette.white.main,
    },
    [theme.breakpoints.down(376)]: {
      margin: theme.spacing('auto', 0, 3, 0),
    },
  },
}));
