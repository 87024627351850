import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  wrapper: {
    maxWidth: 310,
    width: '100%',
  },
  subtitle: {
    marginTop: theme.spacing(3),
  },
  rewards: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 0),
    paddingLeft: theme.spacing(1.25),
  },
  reward: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
    '& span': {
      marginLeft: theme.spacing(1),
    },
  },
  referButton: {
    marginBottom: theme.spacing(2),
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
    },
    '& button': {
      width: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileOnly: {
    display: 'none',
    '@media (pointer: coarse)': {
      display: 'block',
    },
  },
  snackbarContent: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.success.main,
  },
  snackbarMessage: {
    textAlign: 'center',
  },
}));
