import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Link as RouterLink } from 'react-router-dom';
import differenceInDays from 'date-fns/differenceInDays';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { ReactComponent as BatIcon } from 'assets/images/brave/bat-logo.svg';

import { selectDefaultActiveCategory } from 'redux/sweeps';
import {
  getSpinnerData,
  spinSpinner,
  resetSpinResult,
  selectSpinnerResult,
  selectSpinnerPrizesData,
} from 'redux/spinner';

import { ReactComponent as Diamond } from 'assets/images/icons/wmg-diamond.svg';

import Modal from 'components/Modal';
import ResultModal from 'components/ResultModal';
import Loader from 'components/Loader';
import ErrorMessage from 'components/ErrorMessage';
import { PATHS } from 'constants/navigation';
import { useIsLoggedIn } from 'hooks/user';
import {
  useCurrencyConversionRate,
  useCardSelector,
  useSpinsInfo,
  useWalletInfo,
} from 'hooks/wallet';
import { useEventTracker } from 'hooks/analytics';
import { useCms } from 'hooks/cms';
import { storage } from 'services/storage';
import { selectTokensAmount } from 'redux/wallet';
import { selectConfigProperty, selectLoginPath } from 'redux/config';
import { SPINS_PER_TOKEN } from 'constants/spinner';
import { useUserNavigation } from 'hooks/navigation';

import JackpotInfoModal from './components/JackpotInfoModal';
import SpinnerWheel from './components/SpinnerWheel';

import useStyles from './styles';
import { CONFIG_KEYS_MAP } from '../../constants/config';

const maxWheelSize = 375;
const getWheelSize = () => Math.min(maxWheelSize, window.innerWidth - 70);

const shouldShowJackpotInfoModal = () => {
  const lastDate = storage.local.get('jackpotInfoDate');
  if (!lastDate) return true;
  return differenceInDays(new Date(), new Date(lastDate)) >= 1;
};

const SpinnerPage = () => {
  const classes = useStyles();
  const [isSpinning, setIsSpinning] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [wheelSize, setWheelSize] = useState(getWheelSize());
  const [showJackpotInfoModal, setShowJackpotInfoModal] = useState(false);
  const dispatch = useDispatch();

  const { prizes, isLoading, isLoaded } = useSelector(selectSpinnerPrizesData);
  const { result, error } = useSelector(selectSpinnerResult);

  const defaultActiveCategory = useSelector(selectDefaultActiveCategory);
  const useExternalWallets = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.useExternalWallets)
  );

  const availableSpins = useSpinsInfo();

  const [ust] = useWalletInfo('ust');
  const [wmp] = useWalletInfo('wmp');
  const isLoggedIn = useIsLoggedIn();
  const spinnerButtonLink = useCms({ key: 'spinnerMainButton', path: 'link' });
  const shouldUseCard = isLoggedIn && useExternalWallets && !availableSpins;

  const dailySpins = useSelector(selectTokensAmount('dspn'));
  const freeSpins = useSelector(selectTokensAmount('spn'));
  const loginPath = useSelector(selectLoginPath);

  const [card, CardSelector] = useCardSelector();

  const [convertUsdToCurrency, currencyName] = useCurrencyConversionRate(
    card.currency,
    true
  );

  const hasEnoughFunds = +card.balance > convertUsdToCurrency(0.25);

  useUserNavigation({
    backUrl: PATHS.games,
  });

  const upholdAddFundsLink = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAddFundsLink)
  );

  const handleWindowResize = useCallback(() => {
    const newSize = getWheelSize();
    if (Math.abs(wheelSize - newSize) >= 10) {
      setWheelSize(newSize);
    }
  }, [wheelSize]);

  const handleGAEvent = useEventTracker();

  useEffect(() => {
    setTimeout(() => {
      const shouldShow = shouldShowJackpotInfoModal();
      if (shouldShow) {
        handleGAEvent('pop-up', 'open', 'spinner-jackpot-info');
        setShowJackpotInfoModal(shouldShow);
      }
    }, 500);
  }, [handleGAEvent]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  useEffect(() => {
    if (!prizes.length && !isLoaded) {
      dispatch(getSpinnerData());
    }
  }, [dispatch, prizes, isLoaded]);

  const onSpinEnd = useCallback(() => {
    setIsSpinning(false);

    setShowResultModal(true);
  }, []);

  useEffect(() => {
    return () => {
      if (result) {
        dispatch(resetSpinResult());
      }
    };
  }, [dispatch, result]);

  const goToLogin = () => {
    dispatch(
      push(
        `${loginPath}?redirect_uri=${encodeURIComponent(window.location.href)}`
      )
    );
  };

  const startSpin = (options = {}) => {
    if (isSpinning) return;

    setIsSpinning(true);

    dispatch(spinSpinner({ options }));
  };

  const handleSectorClick = useCallback(
    (e, d) => {
      if (d.data.sub_type === 'jackpot') {
        handleGAEvent('pop-up', 'open', 'spinner-jackpot-info');
        setShowJackpotInfoModal(true);
      }
    },
    [handleGAEvent]
  );

  const handleCloseJackpotInfoModal = useCallback(
    (action) => {
      storage.local.set('jackpotInfoDate', new Date());
      handleGAEvent('pop-up', action, 'spinner-jackpot-info');

      setShowJackpotInfoModal(false);
    },
    [handleGAEvent]
  );

  const closeJackpotInfoModal = useCallback(() => {
    handleCloseJackpotInfoModal('close');
  }, [handleCloseJackpotInfoModal]);

  const confirmJackpotInfoModal = useCallback(() => {
    handleCloseJackpotInfoModal('confirm');
  }, [handleCloseJackpotInfoModal]);

  const handleMainBtnClick = () => {
    if (availableSpins) {
      startSpin();
      handleGAEvent('spinner', 'spin-wheel', 'spin');
    } else if (shouldUseCard) {
      startSpin({ wallet_id: card.id });
      handleGAEvent('spinner', 'spin-wheel', 'card');
    } else if (
      +ust?.amount ||
      (+wmp?.amount && +wmp?.amount >= SPINS_PER_TOKEN)
    ) {
      startSpin({ using_tokens: +ust?.amount ? 'ust' : 'wmp' });
      handleGAEvent('spinner', 'spin-wheel', +ust?.amount ? 'token' : 'point');
    } else if (spinnerButtonLink) {
      dispatch(push(spinnerButtonLink));
    } else {
      dispatch(push(PATHS.moreTokens));
    }
  };

  const toggleResultModal = () => setShowResultModal(!showResultModal);

  const handleResultBtnClick = () => {
    toggleResultModal();
    if (result.button_link) {
      if (result.button_link === PATHS.sweepstakes) {
        storage.local.set('activeCategory', defaultActiveCategory);
      }
      dispatch(push(result.button_link));
    }
    handleGAEvent('spinner', 'spin-result', result.button_text);
  };

  const handleResultFooterLinkClick = () => {
    handleGAEvent('spinner', 'spin-result', result.button_text);
    toggleResultModal();
  };

  const renderResultModal = () => (
    <Modal
      open={showResultModal}
      toggleModal={toggleResultModal}
      showClose={result.sub_type === 'token' || result.sub_type === 'no_prize'}
      data-cy="spinner-result-modal"
    >
      <ResultModal
        heading={result.heading}
        secondaryText={result.secondary_text}
        background={result.popup_image}
        buttonLabel={result.button_text}
        handleBtnClick={handleResultBtnClick}
        footerLink={
          result.sub_type === 'token' &&
          result.currency_type === 'fst' &&
          result.token_reward > 0 &&
          'Spin Again'
        }
        handleFooterLinkClick={handleResultFooterLinkClick}
      />
    </Modal>
  );

  if (isLoading) return <Loader />;

  let description =
    'Spin daily for a chance to win the jackpot, sweepstakes tokens and additional spins!';

  if (isLoggedIn) {
    if (dailySpins || freeSpins) {
      description =
        'Spin now for a chance to win the jackpot, winner’s choice gift cards and free spins!';
    } else if (+ust?.amount || shouldUseCard) {
      description = null;
    } else if (+wmp?.amount && +wmp?.amount >= SPINS_PER_TOKEN) {
      description = `No spins available! ${SPINS_PER_TOKEN} points = 1 spin. You have ${+wmp?.amount} points.`;
    } else {
      description =
        'No spins available! Get more tokens to spin again or come back tomorrow.';
    }
  }

  let btnTitle = '1 Free Daily Spin!';

  if (freeSpins) {
    btnTitle = '1 Free Spin Available!';
  }

  if (!availableSpins) {
    if (shouldUseCard) {
      if (card.id) {
        if (hasEnoughFunds) {
          btnTitle = 'Spin again!';
        } else {
          btnTitle = 'Choose a different card!';
        }
      } else {
        btnTitle = 'Choose a card to spin again!';
      }
    } else if (+ust?.amount) {
      btnTitle = 'Use 1 Token to Spin Again!';
    } else if (+wmp.amount && +wmp.amount >= SPINS_PER_TOKEN) {
      btnTitle = `${SPINS_PER_TOKEN} Points to Spin Again!`;
    } else {
      btnTitle = 'Get more tokens!';
    }
  }
  const insufiFundsError = 'Insufficient funds, please choose another card.';
  return (
    <div>
      <div className={classes.spinnerHeader}>
        <Button
          size="large"
          onClick={() => setShowJackpotInfoModal(true)}
          className={classes.jackpotButton}
          data-cy="jackpot-btn"
        >
          <Diamond className={classes.gemIcon} />
          JACKPOT
        </Button>
      </div>
      <div className={classes.spinnerWrapper}>
        <SpinnerWheel
          size={wheelSize}
          pieces={prizes}
          winItem={result?.id}
          onSpinEnd={onSpinEnd}
          onSectorClick={handleSectorClick}
        />
      </div>
      {shouldUseCard && (
        <div className={classes.cardSelectWrapper}>
          {!availableSpins && CardSelector}
        </div>
      )}
      {currencyName && (
        <>
          <Typography className={classes.exchangeAmount}>
            {currencyName === 'BAT' && (
              <BatIcon className={classes.currencyIcon} />
            )}
            {currencyName} amount:&nbsp;
            {convertUsdToCurrency(0.25)}
            <Link
              className={classes.addFundsLink}
              href={upholdAddFundsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Add funds
            </Link>
          </Typography>
          <div className={classes.note}>
            * {currencyName} value adjusts every 15 seconds
          </div>
        </>
      )}
      {isLoggedIn ? (
        <>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.spinBtn}
            size="large"
            disabled={
              isSpinning || (shouldUseCard && (!card.id || !hasEnoughFunds))
            }
            onClick={handleMainBtnClick}
            data-cy="spin_button"
          >
            {btnTitle}
          </Button>
        </>
      ) : (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.spinBtn}
          size="large"
          onClick={goToLogin}
        >
          Log in
        </Button>
      )}
      <ErrorMessage error={error !== insufiFundsError ? error : null} />
      {description && !isSpinning && (
        <Typography variant="h6" className={classes.description}>
          {description}
        </Typography>
      )}

      {isLoggedIn && (
        <Typography
          variant="body1"
          className={clsx(classes.description, classes.note)}
        >
          All spins will reset each day at 12AM PT. Any unused spins will expire
          at this time! See{' '}
          <Link
            color="inherit"
            component={RouterLink}
            className={classes.rulesLink}
            to={PATHS.spinnerRules}
            target="_blank"
            onClick={(event) => {
              event.preventDefault();
              window.open(`${PATHS.spinnerRules}`);
            }}
          >
            Official Rules
          </Link>{' '}
          for details.
        </Typography>
      )}

      {!!result && renderResultModal()}
      <JackpotInfoModal
        open={showJackpotInfoModal}
        toggleModal={closeJackpotInfoModal}
        submit={confirmJackpotInfoModal}
      />
    </div>
  );
};

export default SpinnerPage;
