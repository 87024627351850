import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: 'none',
    pointerEvents: 'none',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '350px',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white.main,
    borderRadius: '8px',
    boxShadow:
      '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2)',
    pointerEvents: 'initial',
    overflow: 'hidden',
    zIndex: 0,
  },
  closeModal: {
    fontSize: '1.5rem',
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
}));
