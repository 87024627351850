import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  sweepstakes: [],
  sweepstakesCategories: [],
  isEntriesSending: false,
  sendingEntriesError: null,
  isLoaded: false,
  currencyAmount: 0,
  orderDetails: {},
};

const sweepsSlice = createSlice({
  name: 'sweeps',
  initialState,
  reducers: {
    fetchSweeps: (state) => {
      state.isLoading = true;
    },
    fetchSweepsSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.sweepstakes = action.payload;
    },
    fetchSweepsFailure: (state) => {
      state.isLoading = false;
      state.isLoaded = true;
    },
    enterSweeps: (state) => {
      state.isEntriesSending = true;
      state.orderDetails = {};
    },
    enterSweepsSuccess: (state, action) => {
      state.isEntriesSending = false;
      state.sendingEntriesError = null;
      state.orderDetails = action.payload;
    },
    enterSweepsFailure: (state, action) => {
      state.isEntriesSending = false;
      state.sendingEntriesError = action.payload;
    },
    clearEnterSweepsErrors: (state) => {
      state.sendingEntriesError = null;
    },
  },
});

export const {
  fetchSweeps,
  fetchSweepsFailure,
  fetchSweepsSuccess,
  enterSweeps,
  enterSweepsSuccess,
  enterSweepsFailure,
  clearEnterSweepsErrors,
} = sweepsSlice.actions;

export default sweepsSlice.reducer;
