import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    width: '100%',
  },
  wrapper: {
    maxWidth: 300,
    width: '100%',
  },
  imgWrapper: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    borderRadius: 10,
    width: '100%',
    maxHeight: 300,
  },
  image: {
    width: '100%',
  },
  description: {
    marginBottom: theme.spacing(3),
    whiteSpace: 'pre-line',
    textAlign: 'left',
  },
  readMore: {
    border: '0',
    margin: '0 auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
    },
  },
  accordion: {
    marginTop: theme.spacing(2),
    borderRadius: '0 !important',
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    minHeight: 16,
    '&.Mui-expanded': {
      minHeight: 16,
    },
  },
  accordionSummaryContent: {
    justifyContent: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    wordBreak: 'break-word',
    textAlign: 'left',
    padding: theme.spacing(0, 0, 2, 0),
  },
  optionBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  optionBtnWrapper: {
    position: 'relative',
    margin: theme.spacing(1, 0),
  },
  optionBtn: {
    backgroundColor: theme.palette.transparent.main,
    borderRadius: '0.625rem',
    transitionProperty: 'background-color, box-shadow, border,color',
    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.transparent.main,
    },
  },
  activeOptionBtn: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    borderRadius: '0.625rem',

    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
  exchangeAmount: {
    fontSize: '1rem',
    fontWeight: 600,
    padding: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    width: '100%',
    margin: theme.spacing(2, 0, 1),
    fontSize: '0.9rem',
  },
  note: {
    fontSize: '0.7rem',
    color: theme.palette.greyDark.main,
    marginBottom: theme.spacing(1),
  },
  otherAmount: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  otherAmountInput: {
    marginLeft: 'auto',
    width: '65%',
  },
  otherAmountLabel: {
    marginTop: 10,
  },
  currencyIcon: {
    height: '1em',
    marginRight: theme.spacing(0.6),
  },
  addFundsLink: {
    fontSize: '0.7rem',
    marginLeft: theme.spacing(2),
    textDecoration: 'none',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginLeft: 'auto',
    width: '65%',
  },
  loginBtn: {
    width: '100%',
    maxWidth: '300px',
    textTransform: 'initial',
    backgroundColor: theme.palette.accent.main,
    margin: theme.spacing(2, 0),
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
}));
