import { createSlice } from '@reduxjs/toolkit';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { typeStringToBool } from 'utils/requests';
import { storage } from 'services/storage';

const arrayBoolToString = ['true', 'false'];

const getProperty = (key) => {
  const property = process.env[key];

  return arrayBoolToString.includes(property)
    ? typeStringToBool(property)
    : property;
};

const defaultConfig = {
  [CONFIG_KEYS_MAP.useLandingPage]: getProperty('REACT_APP_USE_LANDING_PAGE'),
  [CONFIG_KEYS_MAP.sweetRewardsBaseUrl]: getProperty(
    'REACT_APP_SWEET_REWARDS_BASE_URL'
  ),
};

const initialState = {
  ...defaultConfig,
  isLoading: false,
  isLoaded: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    fetchConfig: (state) => {
      state.isLoading = true;
      Object.assign(state, JSON.parse(storage.local.get('cachedConfig')));
    },
    fetchConfigSuccess: (state, action) => {
      Object.assign(state, action.payload);
      state.isLoading = false;
      state.isLoaded = true;
    },
    fetchConfigFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoaded = false;
    },
  },
});

export const {
  fetchConfig,
  fetchConfigSuccess,
  fetchConfigFailure,
} = configSlice.actions;

export default configSlice.reducer;
