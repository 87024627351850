import { createSelector } from '@reduxjs/toolkit';
import { selectCmsItem } from 'redux/cms';
import { SWEEPS_CATEGORIES_KEY } from 'constants/sweeps';
import _get from 'lodash/get';

const selectLiveBids = (state) => _get(state, 'liveBids', {});

export const selectAuctionAuthStatus = createSelector(
  [selectLiveBids],
  ({ authError, isAuthProcessing }) => ({ authError, isAuthProcessing })
);

export const selectBidDetails = createSelector(
  [selectLiveBids],
  ({ items, isLoading, error }) => (id) => ({
    bidDetails: items.find((i) => i.id === id) || {},
    isLoading,
    error,
  })
);

export const selectLiveBidsList = createSelector(
  selectLiveBids,
  ({ items, isLoading }) => {
    const filteredItems = items.filter((i) => !i.isEnded || i.isSold);
    return { items: filteredItems, isLoading };
  }
);

export const selectIsLiveActive = createSelector(
  [(state) => selectCmsItem(state, { key: SWEEPS_CATEGORIES_KEY })],
  (sweepstakesCategories) => {
    const items = _get(sweepstakesCategories, 'data.items', []);
    const liveCategory = items.find((item) => item.key === 'live');
    return !!liveCategory && !liveCategory?.disabled;
  }
);

export const selectBidStatus = createSelector(
  [selectLiveBids],
  ({ isBidProcessing, bidError, order }) => ({
    isBidProcessing,
    bidError,
    order,
  })
);
