import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Routes } from 'routes';
import { createBrowserHistory } from 'history';
import App from 'components/App';
import * as serviceWorker from 'services/serviceWorker';
import { getThemeByName } from 'theme';
import 'utils/viewPortHeight';
import { getPartner } from 'utils/config';
import { installInterceptors } from 'services/api';
import { createStore } from './redux';

import './index.css';

const history = createBrowserHistory();
const store = createStore(history);

installInterceptors(store);

const theme = getThemeByName(getPartner().name);

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <App history={history}>
          <Routes />
        </App>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
