import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },
  title: {
    margin: theme.spacing(1, 0, 2),
  },
  subTitle: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
}));
