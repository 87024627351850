import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}));
