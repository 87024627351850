// tmp
const successStatuses = [200, 201, 202, 203, 204, 205, 206];

export const handleResponse = (response) => {
  if (response.ok && successStatuses.includes(response.status)) {
    return response;
  }
  throw response;
};

export const handleSweetRewardsResponse = (response) => {
  if (
    response.ok &&
    successStatuses.includes(response.status) &&
    response.data.success
  ) {
    return response;
  }
  throw response;
};

export const typeStringToBool = (args) => {
  return !!args && args.toLowerCase() === 'true';
};
