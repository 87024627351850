import { useSelector } from 'react-redux';
import {
  selectAvailableCards,
  selectTapCurrencyAvailability,
} from 'redux/wallet';
import { useCurrencyConversionRate } from './wallet';

export const useGamesAvailability = () => {
  const isTapCurrencyAvailable = useSelector(selectTapCurrencyAvailability);
  const availableCards = useSelector(selectAvailableCards);
  const firstCard = availableCards?.[0];
  const [convertUsdToCurrency] = useCurrencyConversionRate(firstCard?.currency);

  const hasEnoughFunds = +firstCard?.balance > convertUsdToCurrency(0.25);

  return isTapCurrencyAvailable || hasEnoughFunds;
};
