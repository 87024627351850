import React from 'react';
import { Link } from 'react-router-dom';

import MTR from 'assets/images/my-tap-rewards-logo.svg';

import Typography from '@material-ui/core/Typography';

import { useHideOnLandingPage } from 'hooks/display';

import useStyle from './styles';

const Footer = () => {
  const handleListItemClick = () => {
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open');
    });
  };

  const hideOnLandingPage = useHideOnLandingPage();

  const classes = useStyle();
  return hideOnLandingPage ? (
    <></>
  ) : (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.powerInfo}>
          <Typography component="div" variant="body1" className={classes.text}>
            Powered by
          </Typography>
          <div className={classes.logo}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="#">
              <img src={MTR} alt="tap network" width="161" height="50" />
            </Link>
          </div>
        </div>
        <ul className={classes.footerList}>
          <li>
            <Typography component="span" variant="body1">
              <Link
                to="/terms"
                target="_blank"
                onClick={(event) => {
                  event.preventDefault();
                  window.open('/terms');
                }}
                className={classes.link}
              >
                Terms of Use
              </Link>
            </Typography>
          </li>

          <li>
            <Typography component="span" variant="body1">
              <Link
                to="/privacy"
                target="_blank"
                onClick={(event) => {
                  event.preventDefault();
                  window.open('/privacy');
                }}
                className={classes.link}
              >
                Privacy Policy
              </Link>
            </Typography>
          </li>
          <li>
            <Typography
              component="span"
              variant="body1"
              onClick={handleListItemClick}
              className={classes.supportItem}
            >
              Support
            </Typography>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
