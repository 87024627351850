import { create } from 'apisauce';
import { getPartner } from 'utils/config';
import { handleResponse, handleSweetRewardsResponse } from 'utils/requests';
import { logoutUser } from 'redux/user';
// import { storage } from '../storage';

// export const getToken = () => {
//   const userToken = storage.local.get('userToken');
//   return userToken || null;
// };

const api = create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token',
  headers: {
    'Content-Type': 'application/json',
    'TAP-SECRET': getPartner().secret,
    accept: 'application/json',
    'X-Application-ID': getPartner().secret,
    // Authorization: `Token token=${getToken()}`,
  },
});

export const installInterceptors = (store) => {
  api.addMonitor((response) => {
    if (response.status === 401) {
      store.dispatch(logoutUser());
    }
  });
};

export const Api = {
  post: (url, data, withoutHandleError) =>
    api.post(url, JSON.stringify(data)).then((response) => {
      return withoutHandleError ? response : handleResponse(response);
    }),
  get: (url) => api.get(url).then(handleResponse),
  put: (url, data) => api.put(url, JSON.stringify(data)).then(handleResponse),
};

const sweetRewardApi = create({
  baseURL: process.env.REACT_APP_SWEET_REWARDS_API_URL,
  // headers: {
  //   'Content-Type': 'multipart/form-data',
  // },
});

export const SweetRewardsApi = {
  post: (url, data) =>
    sweetRewardApi.post(url, data).then(handleSweetRewardsResponse),
};
