import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from '../config';

export default {
  [CONFIG_KEYS_MAP.useVeryDumbCc]: false,
  [CONFIG_KEYS_MAP.showCategoriesFilter]: true,
  [CONFIG_KEYS_MAP.chooseExperienceEnabled]: false,
  [CONFIG_KEYS_MAP.useProfile]: false,
  [CONFIG_KEYS_MAP.spinPageEnabled]: true,
  [CONFIG_KEYS_MAP.showOtherAmount]: false,
  [CONFIG_KEYS_MAP.appStoreLink]:
    'https://apps.apple.com/si/app/uber/id368677368',
  [CONFIG_KEYS_MAP.playMarketLink]:
    'https://play.google.com/store/apps/details?id=com.ubercab',
  [CONFIG_KEYS_MAP.htmlMetaTags]: {
    color: '#000000',
    description: 'WMG Rewards by Tap Network',
    title: 'WMG | My TAP Rewards',
  },
  [CONFIG_KEYS_MAP.customerService]: {
    zoho_id: '441484000006378001',
    zoho_org_id: '698264665',
  },
  [CONFIG_KEYS_MAP.externalPartnerLoginEnabled]: false,
  [CONFIG_KEYS_MAP.externalPartnerLoginUrl]: null,
  [CONFIG_KEYS_MAP.externalPartnerSignUpUrl]: null,
  [CONFIG_KEYS_MAP.logoutRedirectUrl]: '/login',
  [CONFIG_KEYS_MAP.logoRedirectUrl]: '/sweepstakes',
  [CONFIG_KEYS_MAP.adGemEnabled]: true,
  [CONFIG_KEYS_MAP.useAdgemIframe]: false,
  [CONFIG_KEYS_MAP.adGemAppId]: 3472,
  [CONFIG_KEYS_MAP.facebookEnabled]: true,
  [CONFIG_KEYS_MAP.facebookId]: '2145188379119925',
  [CONFIG_KEYS_MAP.facebookApiVersion]: '5.0',
  [CONFIG_KEYS_MAP.facebookRedirectUri]:
    'http://wmg.dev.mytaprewards.com:3051/sweepstakes',
  [CONFIG_KEYS_MAP.useLandingPage]: true,
  [CONFIG_KEYS_MAP.charityEnabled]: true,
  [CONFIG_KEYS_MAP.giftCardsEnabled]: false,
  [CONFIG_KEYS_MAP.spotifyEnabled]: true,
  [CONFIG_KEYS_MAP.spotifyClientId]: '8af24235ff3f445b938035537d13f379',
  [CONFIG_KEYS_MAP.spotifyScopes]:
    'user-read-email,playlist-read-private,user-read-recently-played,playlist-read-collaborative,user-follow-read,user-library-read,user-top-read,user-follow-modify,user-library-modify',
  [CONFIG_KEYS_MAP.pennyAuctionBaseURL]: 'https://penny.auctionsoftware.com',
  [CONFIG_KEYS_MAP.pennyAuctionAppId]: 'Calling_Mazzady_backend_APIs',
  [CONFIG_KEYS_MAP.pennyAuctionAppSecret]:
    '1d30ea1fc1e6d655987ad7ba67a225827c23b2e98755ecfe051405938710c89c8bffaa5bc6ceafadb6781d355e',
  [CONFIG_KEYS_MAP.pennyAuctionSearchLimit]: '10',
  [CONFIG_KEYS_MAP.signupUrl]: PATHS.onboarding,
  [CONFIG_KEYS_MAP.hotjarId]: '2394733',
  [CONFIG_KEYS_MAP.onboardingEnabled]: true,
  [CONFIG_KEYS_MAP.showUberPointsOption]: false,
  [CONFIG_KEYS_MAP.gtmId]: 'GTM-PPBWH9S',
  [CONFIG_KEYS_MAP.followArtistEnabled]: true,
  [CONFIG_KEYS_MAP.scratchOffGameName]: 'Lucky Scratch',
  [CONFIG_KEYS_MAP.scratchOffCoverImage]:
    'https://res.cloudinary.com/hooch/image/upload/v1653063199/Warner_image_xq6akc.png',
  [CONFIG_KEYS_MAP.gamesPageEnabled]: true,
  [CONFIG_KEYS_MAP.scratchPageEnabled]: true,
  [CONFIG_KEYS_MAP.revUAppId]: 574,
  [CONFIG_KEYS_MAP.revUEnabled]: true,
  [CONFIG_KEYS_MAP.earnMoreEnabled]: true,
  [CONFIG_KEYS_MAP.followTrackEnabled]: true,
};
