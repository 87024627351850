import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },
  title: {
    margin: theme.spacing(1, 0, 2),
  },
  image: {
    maxWidth: '100%',
  },
  button: {
    width: '100%',
    maxWidth: '300px',
    textTransform: 'initial',
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    margin: theme.spacing(1, 0),
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  snackbarTitle: {
    fontWeight: 'bold',
  },
  closeModal: {
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
  snackbarRoot: {
    bottom: 16,
  },
  snackbarContent: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.black.main,
    borderRadius: 8,
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap',
    maxWidth: 335,
    maxHeight: 210,
  },
  snackbarMessage: {
    padding: 0,
  },
}));
