import React, { useState, useCallback, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import parse from 'html-react-parser';

import useStyles from './styles';

const ProductDetails = memo(({ details }) => {
  const classes = useStyles();
  const [showProductDetails, changeShowProductDetails] = useState(false);

  const handleShowProductDetails = useCallback((_, isExpanded) => {
    changeShowProductDetails(isExpanded);
  }, []);

  return (
    <Accordion
      expanded={showProductDetails}
      onChange={handleShowProductDetails}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={showProductDetails ? <RemoveIcon /> : <AddIcon />}
        classes={{
          root: classes.accordionSummary,
          content: classes.accordionSummaryContent,
        }}
      >
        <Typography variant="subtitle1">Product Details</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography>{details && parse(details)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
});

export default ProductDetails;
