import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  otherAmountContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  otherAmountInput: {
    marginLeft: 'auto',
    width: '65%',
    '& fieldset': {
      borderRadius: 5,
    },
  },
  otherAmountInputActive: {
    '& fieldset': {
      borderColor: theme.palette.accent.main,
      borderWidth: 2,
    },
  },
  rulesLink: {
    marginBottom: theme.spacing(4),
  },
}));
