import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { selectBackButtonProperties } from 'redux/navigation';
import { selectConfigProperty } from 'redux/config';

import WalletTab from 'components/WalletTab';
import DotTab from 'components/DotTab';

import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';

import { useWalletInfo } from 'hooks/wallet';
import { useIsLoggedIn } from 'hooks/user';
import { useGamesAvailability } from 'hooks/games';
import { useNavigationTabs, useToggleActiveTabStyle } from 'hooks/navigation';

import { ReactComponent as WalletIcon } from 'assets/images/icons/wallet.svg';

import { useStyles } from './styles';

const UserInfoBanner = () => {
  const { navigationTabs, hideWallet } = useNavigationTabs();
  const shouldLinkHaveActiveStyle = useToggleActiveTabStyle();
  const classes = useStyles(shouldLinkHaveActiveStyle);
  const dispatch = useDispatch();

  const isLoggedIn = useIsLoggedIn();

  const { showBackButton, backUrl, callback, hideAllItems } = useSelector(
    selectBackButtonProperties
  );
  const isGamesPageEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.gamesPageEnabled)
  );
  const isGiftCardEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.giftCardsEnabled)
  );
  const isEarnMoreEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.earnMoreEnabled)
  );
  const useExternalWallets = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.useExternalWallets)
  );

  const isOnBoarding = window.location.pathname === '/onboarding';
  const handleBack = useCallback(() => dispatch(goBack()), [dispatch]);
  const handleBackUrl = useCallback(() => dispatch(push(backUrl)), [
    dispatch,
    backUrl,
  ]);

  const onBackButtonClick = useCallback(() => {
    if (callback) {
      callback();
    } else if (backUrl) {
      handleBackUrl(backUrl);
    } else {
      handleBack();
    }
  }, [callback, backUrl, handleBack, handleBackUrl]);

  const { currencyName, items } = navigationTabs;

  const [walletData] = useWalletInfo(currencyName);
  const shouldShowGamesDot = useGamesAvailability();

  // Filter tabs that can be toggled based on partner configuration
  const filteredItems = useMemo(
    () =>
      items?.filter((item) => {
        return !(
          (item.url.includes(PATHS.games) && !isGamesPageEnabled) ||
          (item.url.includes(PATHS.giftCards) && !isGiftCardEnabled) ||
          (item.url.includes(PATHS.activity) && !hideWallet && !isLoggedIn) ||
          (item.url.includes(PATHS.moreTokens) &&
            (!isLoggedIn || !isEarnMoreEnabled))
        );
      }) || [],
    [
      items,
      isGamesPageEnabled,
      isLoggedIn,
      isGiftCardEnabled,
      hideWallet,
      isEarnMoreEnabled,
    ]
  );
  return (
    <div className={classes.root}>
      <Box boxShadow={0}>
        <Grid
          container
          alignItems="center"
          justify={showBackButton ? 'space-between' : 'space-around'}
          className={clsx(classes.innerContainer, {
            [classes.hiddenItems]: hideAllItems,
          })}
        >
          {showBackButton && !hideAllItems && (
            <Grid item container>
              <Button
                variant="text"
                onClick={onBackButtonClick}
                className={classes.button}
                classes={{ startIcon: classes.icon }}
                startIcon={
                  <Icon
                    component={ArrowBackIosIcon}
                    className={clsx(classes.svg)}
                  />
                }
              >
                <Typography className={classes.text}>Back</Typography>
              </Button>
            </Grid>
          )}
          {filteredItems.map(({ label, url }) => {
            if (url.includes(PATHS.activity)) {
              return (
                <Grid
                  item
                  container
                  md={showBackButton ? 0 : 4}
                  key={url}
                  className={classes.walletLink}
                  justify="space-around"
                >
                  <NavLink
                    to={url}
                    className={clsx(classes.link, classes.paddings)}
                    activeClassName={classes.walletActiveLink}
                  >
                    {useExternalWallets ? (
                      <Typography
                        className={classes.text}
                        style={{ marginRight: showBackButton ? '16px' : null }}
                      >
                        <WalletIcon /> Activity
                      </Typography>
                    ) : (
                      !isOnBoarding && (
                        <WalletTab
                          tokens={walletData.amount}
                          showBackButton={showBackButton}
                        />
                      )
                    )}
                  </NavLink>
                </Grid>
              );
            }
            return (
              !showBackButton && (
                <Grid item container key={url} md={4} justify="space-around">
                  <NavLink
                    to={url}
                    className={clsx(classes.link, classes.paddings)}
                    activeClassName={classes.active}
                  >
                    {url.includes(PATHS.games) ? (
                      <DotTab
                        text={label}
                        showDot={shouldShowGamesDot}
                        className={classes.text}
                      />
                    ) : (
                      <Typography className={classes.text}>{label}</Typography>
                    )}
                  </NavLink>
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default UserInfoBanner;
