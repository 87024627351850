import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  tracks: [],
  isLoaded: false,
  followed: [],
};

const followTrackSlice = createSlice({
  name: 'follows',
  initialState,
  reducers: {
    fetchFollowTracks: (state) => {
      state.isLoading = true;
    },
    fetchFollowTracksSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.tracks = action.payload.tracks;
    },
    fetchFollowTracksFailure: (state) => {
      state.isLoading = false;
      state.isLoaded = true;
    },
    followTrack: (state) => {
      state.isLoading = true;
    },
    followTrackSuccess: (state, action) => {
      state.isLoading = false;
      state.followed = [...state.followed, action.payload];
    },
    followTrackFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  fetchFollowTracks,
  fetchFollowTracksSuccess,
  fetchFollowTracksFailure,
  followTrack,
  followTrackSuccess,
  followTrackFailure,
} = followTrackSlice.actions;

export default followTrackSlice.reducer;
