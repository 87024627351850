import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  card: {
    margin: '36px 0 0 0',
  },
  cardImageWrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    objectFit: 'cover',
    width: '150px',
    height: '150px',
    borderRadius: '10px',
  },
  acthorName: {
    margin: '10px 0 10px 0 ',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#1BD760',
    fontSize: '0.8rem',
    color: 'white',
    height: '23px',
    borderRadius: '50px',
    '& > span': {
      marginTop: '1px',
    },
    '&:hover': {
      backgroundColor: '#1BD760',
    },
  },
  success: {
    display: 'flex',
    color: '#015BB0',
  },
  doneIcon: {
    marginLeft: '4px',
    fontSize: 16,
    fontWeight: 20,
  },
}));
