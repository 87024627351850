import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modalTitle: {
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    margin: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
  howItWorks: {
    padding: theme.spacing(0, 2.5),
    '& ol': {
      padding: theme.spacing(0, 0, 0, 2),
    },
    '& li:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& li:last-child': {
      marginBottom: theme.spacing(3),
    },
    '& a': {
      color: 'inherit',
    },
  },
  modalButton: {
    margin: theme.spacing(1, 0),
  },
  textUnderButton: {
    textAlign: 'center',
  },
}));
