import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  text: {
    fontSize: '0.9375rem',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  centered: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  underline: {
    textDecoration: 'underline',
  },
}));
