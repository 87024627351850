import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 2),
    padding: theme.spacing(3, 2.5),
    boxShadow:
      '0 0.1875rem 0.375rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.125rem rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(1),
    textAlign: 'left',
  },
  eventInfo: {
    width: 'max-content',
    minHeight: '3.125rem',
    maxWidth: '16.5rem',
  },
  text: {
    lineHeight: '1rem',
    fontWeight: 'bold',
  },
  textLocation: {
    marginBottom: theme.spacing(1),
  },
  eventLink: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
  secondRowContainer: {
    display: 'flex',
  },
  city: {
    paddingRight: theme.spacing(0.5),
  },
}));
