import { createSlice } from '@reduxjs/toolkit';

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {},
  reducers: {
    trackEvent: () => {},
    trackPageView: () => {},
  },
});

export const { trackEvent, trackPageView } = analyticsSlice.actions;

export default analyticsSlice.reducer;
