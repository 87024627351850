import axios from 'axios';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { Api } from './index';

const pennyAuctionApi = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const updateAuctionApiConfig = (config) => {
  pennyAuctionApi.defaults.baseURL =
    config[CONFIG_KEYS_MAP.pennyAuctionBaseURL];
  pennyAuctionApi.defaults.headers.app_id =
    config[CONFIG_KEYS_MAP.pennyAuctionAppId];
  pennyAuctionApi.defaults.headers.app_secret =
    config[CONFIG_KEYS_MAP.pennyAuctionAppSecret];
};

export const fetchLiveBidsList = ({ limit }) =>
  pennyAuctionApi.get(
    `/search?liveauction=1&futureauction=1&closedauction=1&limit=${limit}`
  );
export const postAuctUsername = (auctionUsername) =>
  Api.post('/users/auction', auctionUsername);

export const fetchLiveBidDetailsRequest = (id) =>
  pennyAuctionApi.get(`/product/view/${id}`);

export const placeBid = (data) => Api.post('/orders/auctions', data);
