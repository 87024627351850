import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Link as MLink } from '@material-ui/core';

import {
  fetchCoupon,
  clearCouponData,
  navigateUserToExperience,
  toggleCouponModal,
  selectCouponStatus,
  selectCaptchaStatus,
  vefiryCaptcha,
} from 'redux/coupon';
import { selectConfigProperty } from 'redux/config';

import Modal from 'components/Modal';
import ResultModal from 'components/ResultModal';
import CmsModal from 'components/CmsModal';

import CouponImage from 'assets/images/coupon-image.png';
import { getQryParams } from 'utils/url';
import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';

import { useIsLoggedIn } from 'hooks/user';

import Tokens from 'assets/images/tokens.png';

import useStyles from './styles';

const CouponPage = () => {
  const location = useLocation();
  const params = getQryParams(location);
  const classes = useStyles();
  const dispatch = useDispatch();

  const clearError = useCallback(() => dispatch(clearCouponData()), [dispatch]);

  const isLoggedIn = useIsLoggedIn();

  const { isLoading, error, tokenAmount, shouldShowSuccessModal } = useSelector(
    selectCouponStatus
  );
  const shouldShowCaptcha = useSelector(selectCaptchaStatus);
  const isChooseExperienceEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.chooseExperienceEnabled)
  );
  const recaptchaSiteKey = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.recaptchaSiteKey)
  );
  const [code, setCode] = useState(params.code || '');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

  useEffect(() => {
    if (tokenAmount && isLoggedIn && shouldShowSuccessModal) {
      setIsSuccessModalOpened(true);
    }
  }, [tokenAmount, isLoggedIn, shouldShowSuccessModal]);

  useEffect(() => {
    return () => {
      dispatch(toggleCouponModal(false));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (error) {
        clearError();
      }
    };
  }, [error, clearError]);

  const handleChange = (couponCode) => {
    setCode(couponCode);
    if (error) clearError();
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(
      fetchCoupon({
        code,
        createOrder: true,
        preventRedirect: !!isLoggedIn,
      })
    );
  };

  const toggleSuccessModal = useCallback(() => {
    setIsSuccessModalOpened(!isSuccessModalOpened);
  }, [isSuccessModalOpened]);

  const toggleModal = useCallback(() => {
    setIsModalOpened(!isModalOpened);
  }, [isModalOpened]);

  const handleSupportButtonClick = (e) => {
    e.preventDefault();
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open');
    });
  };

  const onCaptchaChange = (token) => {
    dispatch(vefiryCaptcha({ token }));
  };

  const handleSuccessClick = useCallback(() => {
    toggleSuccessModal();
    dispatch(navigateUserToExperience());
  }, [dispatch, toggleSuccessModal]);

  const renderSuccessModal = () => {
    const heading = `You've unlocked ${tokenAmount} sweepstakes ${
      tokenAmount > 1 ? 'tokens' : 'token'
    }!`;
    return (
      <Modal open={isSuccessModalOpened} toggleModal={toggleSuccessModal}>
        <ResultModal
          heading={heading}
          secondaryText="You're ready to enter sweepstakes!"
          background={Tokens}
          handleBtnClick={handleSuccessClick}
          buttonLabel="Let's go!"
        />
      </Modal>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <Typography variant="h3" className={classes.title}>
          You’re steps away from entering sweepstakes!
        </Typography>
        <Grid container alignItems="center" className={classes.list}>
          <Grid item xs={12}>
            <img
              src={CouponImage}
              alt="Sweepstakes"
              className={classes.couponImage}
            />
          </Grid>
        </Grid>
        <Typography variant="h4" className={classes.subTitle}>
          Enter your code to get started
        </Typography>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            className={classes.input}
            label="Enter Code"
            variant="outlined"
            value={code}
            onChange={(evt) => handleChange(evt.target.value)}
            data-cy="coupon_code_input"
          />
          <Typography variant="h6">
            <MLink
              href="#"
              onClick={toggleModal}
              className={classes.howGetACode}
            >
              How do I get a code?
            </MLink>
          </Typography>
          <CmsModal
            cmsKey="howItWorks"
            isModalOpened={isModalOpened}
            toggleModal={toggleModal}
          />
          {renderSuccessModal()}
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading || shouldShowCaptcha}
            className={classes.submitBtn}
            color="primary"
            size="large"
            data-cy="coupon_code_submit"
          >
            Submit
          </Button>
          {error && (
            <Typography
              variant="subtitle1"
              className={classes.error}
              color="error"
              data-cy="coupon_error"
            >
              {`${error.message || error.error}. Try again or `}
              <MLink
                href="#"
                className={classes.errorLink}
                onClick={handleSupportButtonClick}
              >
                contact Support
              </MLink>
              .
            </Typography>
          )}
          {shouldShowCaptcha && recaptchaSiteKey && (
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              onChange={onCaptchaChange}
              size="normal"
              type="image"
              className={classes.captcha}
            />
          )}
        </form>
        <Typography variant="h6">
          <RouterLink
            to={
              isChooseExperienceEnabled
                ? PATHS.chooseExperience
                : PATHS.sweepstakes
            }
            className={classes.link}
            replace
          >
            Explore
          </RouterLink>
        </Typography>
      </div>
    </div>
  );
};

export default CouponPage;
