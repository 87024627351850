import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paymentInfo: {
    textAlign: 'left',
    '& > p': {
      marginBottom: theme.spacing(1),
    },
  },
  link: {
    textDecoration: 'underline',
  },
}));
