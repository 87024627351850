import { createSelector } from '@reduxjs/toolkit';
import { selectItemData } from 'redux/cms';

const selectWinners = (state) => state.winners;

export const selectWinnersData = createSelector(
  [selectWinners],
  (winners) => winners.data
);

export const selectIsDataLoading = createSelector(
  [selectWinners],
  (winners) => ({
    isLoading: winners.isLoading,
    isLoaded: winners.isLoaded,
  })
);

export const selectWinnersFromCMS = createSelector(
  [(state) => selectItemData(state, { key: 'winners', path: 'winners' })],
  (winners) => winners?.slice()?.sort((a, b) => a.priority - b.priority) || []
);
