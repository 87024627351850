import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectConfigProperty } from 'redux/config';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';

import { useSweepstakes } from 'hooks/sweepstakes';

import HorizontalFilter from 'components/HorizontalFilter';
import PartnerSpecificContent from 'components/PartnerSpecificContent';
import Loader from 'components/Loader';

import { ReactComponent as TokenIcon } from 'assets/images/more-tokens-button.svg';

import { useUserInfo } from 'hooks/user';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { PATHS } from 'constants/navigation';

import SweepstakeItem from '../components/SweepstakeItem';
import LiveBidsList from '../components/LiveBidsList';
import WinnersList from '../components/WinnersList';

import useStyle from './styles';

const SweepstakesListPage = () => {
  const classes = useStyle();
  const { isValidUser } = useUserInfo();

  const {
    isLoading,
    sweepstakesCategories,
    filteredSweeps,
    activeCategory,
    handleActiveCatagory,
  } = useSweepstakes();

  const isShowCategoriesFilter = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.showCategoriesFilter)
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container className={classes.container}>
        {isShowCategoriesFilter && (
          <HorizontalFilter
            filterOptions={sweepstakesCategories}
            activeFilter={activeCategory}
            setActiveFilter={handleActiveCatagory}
          />
        )}
        <Grid item container spacing={2} className={classes.content}>
          {filteredSweeps.map((sweep) => (
            <SweepstakeItem
              item={sweep}
              key={sweep.id}
              className={classes.card}
            />
          ))}
          {activeCategory === 'winners' && <WinnersList />}
          {activeCategory === 'live' && <LiveBidsList />}
        </Grid>
      </Grid>
      {isValidUser && (
        <PartnerSpecificContent partnerNames={['brave', 'uphold']} hidden>
          <div className={classes.fabContainer}>
            <Link to={PATHS.moreTokens} className={classes.fab}>
              <Fab color="primary" aria-label="add">
                <TokenIcon className={classes.svg} />
              </Fab>
            </Link>
          </div>
        </PartnerSpecificContent>
      )}
    </>
  );
};

export default SweepstakesListPage;
