import React from 'react';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const OtherAmountInput = ({
  isNoTokens,
  otherAmountValue,
  handleOtherAmountValueChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.otherAmountContainer}>
      <Typography>Other amount</Typography>
      <TextField
        variant="outlined"
        size="small"
        color="secondary"
        className={clsx(classes.otherAmountInput, {
          [classes.otherAmountInputActive]: !!otherAmountValue,
        })}
        value={otherAmountValue}
        onChange={handleOtherAmountValueChange}
        disabled={isNoTokens}
      />
    </div>
  );
};

export default OtherAmountInput;
