import React from 'react';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

import { PATHS } from 'constants/navigation';

import { ReactComponent as WinnerIcon } from 'assets/images/icons/winner-icon.svg';

import { Typography } from '@material-ui/core';

import useStyle from './styles';

const GiftCardSuccessPage = () => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const goToActivity = () => {
    dispatch(
      push({
        pathname: `${PATHS.activity}/gift-cards`,
        state: {
          from: `${PATHS.giftCards}/:id/success`,
        },
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h3" className={classes.title}>
          Success <WinnerIcon />
        </Typography>
        <Typography variant="h6">
          Your gift card is available for immediate use and is located in the
          Activity section.
        </Typography>
        <Button
          className={classes.btn}
          variant="contained"
          size="large"
          color="primary"
          onClick={goToActivity}
        >
          Go to Activity
        </Button>
        <Link className={classes.link} to={PATHS.sweepstakes}>
          <Typography variant="h6" component="p">
            Return to homepage
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default GiftCardSuccessPage;
