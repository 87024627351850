import React from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Modal from '../Modal';
import useStyles from './styles';
import { logoutUser, removeConnectError } from '../../redux/user';

const ConnectErrorModal = ({ open, toggleModal, spotifyConnectError }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogOut = () => {
    toggleModal();
    dispatch(logoutUser());
  };

  const handleClose = () => {
    dispatch(removeConnectError());
    toggleModal();
  };

  return (
    <Modal open={open} disableBackdropClick showClose toggleModal={handleClose}>
      <Typography variant="h5" className={classes.title}>
        <div>{spotifyConnectError?.title}</div>
      </Typography>
      <Typography className={classes.rule}>
        {spotifyConnectError?.description}
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        size="large"
        onClick={handleLogOut}
      >
        Log out
      </Button>
    </Modal>
  );
};

export default ConnectErrorModal;
