import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modalStatusContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0px',
    left: '0px',
    background: 'rgba(16, 19, 20, 0.5)',
    color: 'black',
    zIndex: '10',
    userSelect: 'none',
  },

  progressContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalStatusContentContainer: {
    width: '335px',
    height: '511px',
    borderRadius: '8px',
    background: 'white',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  modalStatusButton: {
    display: 'flex',
    justifyContent: 'right',
  },

  modalStatusTextClose: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '5px',
    marginRight: '5px',
    maxWidth: '20px',
    maxHeight: '20px',
    minWidth: '20px',
    minHeight: '20px',
    fontSize: '1rem',
    fontWeight: 'normal',
  },

  modalStatusHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0px 20px',
    marginTop: '20px',
    minHeight: '430px',
  },

  modalStatusTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
  },

  modalStatusFooter: {
    width: '100%',
  },

  modalStatusFooterText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },

  modalStatusFooterButton: {
    padding: '17px',
    marginTop: '20px',
    fontWeight: '500',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'rgba(224,224,224, .8)',
    },
  },
}));
