import React from 'react';

import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import format from 'date-fns/format';

import { ReactComponent as WinnerIcon } from 'assets/images/icons/winner-icon.svg';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import { PATHS } from 'constants/navigation';
import PartnerSpecificContent from 'components/PartnerSpecificContent';

import useStyles from './styles';

const SuccessContent = ({
  announcementDate,
  amount,
  sweepId,
  eventId,
  upsells,
  sponsorContent,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const tokens = Math.abs(amount);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid item container className={classes.content}>
        <Grid item container justify="center">
          <Typography
            variant="h3"
            className={classes.title}
            data-cy="sweep-success-title"
          >
            You&apos;re In! <WinnerIcon />
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" direction="column">
          <Typography variant="h6" className={classes.body}>
            <PartnerSpecificContent partnerNames={['brave', 'uphold']} hidden>
              You&apos;ve submitted {tokens}&nbsp;
              {tokens > 1 ? 'tokens' : 'token'}, good luck!
            </PartnerSpecificContent>
            <PartnerSpecificContent partnerNames={['brave', 'uphold']}>
              You have {`${tokens} ${tokens > 1 ? 'chances' : 'chance'}`} to
              win, good luck!
            </PartnerSpecificContent>
          </Typography>
          <Typography variant="h6" className={classes.body}>
            Winners will be selected at random on{' '}
            {format(new Date(announcementDate), 'MMMM d, y')} and notified via{' '}
            <PartnerSpecificContent partnerNames={['brave', 'uphold']} hidden>
              their Rewards by Warner Music
            </PartnerSpecificContent>
            <PartnerSpecificContent partnerNames={['brave', 'uphold']}>
              their Uphold
            </PartnerSpecificContent>
            &nbsp;account email.
          </Typography>
        </Grid>
        <Grid item container justify="center" className={classes.helper}>
          <Typography variant="h6" className={classes.helperText}>
            Increase your chances to win!
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justify="center">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() =>
            history.push(`${PATHS.sweepstakes}/${sweepId}/${eventId}`)
          }
        >
          Enter Again
        </Button>
      </Grid>
      {sponsorContent}
      {upsells}

      <Link className={classes.link} to={PATHS.sweepstakes}>
        <Typography variant="h6" component="p">
          Return to homepage
        </Typography>
      </Link>
    </Grid>
  );
};

SuccessContent.propTypes = {
  announcementDate: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  eventId: PropTypes.string.isRequired,
  sweepId: PropTypes.string.isRequired,
  sponsorContent: PropTypes.node.isRequired,
  upsells: PropTypes.node.isRequired,
};

export default SuccessContent;
