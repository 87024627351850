import braveTheme from './brave.theme';
import sywTheme from './syw.theme';
import uberTheme from './uber.theme';
import upholdTheme from './uphold.theme';
import wmgTheme from './wmg.theme';

const themeMap = {
  brave: braveTheme,
  syw: sywTheme,
  uber: uberTheme,
  uphold: upholdTheme,
  wmg: wmgTheme,
};

export function getThemeByName(name) {
  return themeMap[name];
}
