import { createSlice } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { logoutUser } from 'redux/user';
import { spinSpinner } from 'redux/spinner';

const initialState = {
  data: {},
  isLoading: false,
  isLoaded: false,
  error: null,
  isLoadingActivities: false,
  activities: [],
  activitiesMeta: {
    category: null,
    totalPages: 0,
    page: 1,
    perPage: 20,
  },
  loadActivitiesError: null,
  isActivityLoaded: false,
  cards: [],
  isAccountVerified: true,
};

const spinTokenAmount = {
  ust: 1,
  wmp: 250,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    getWallet: (state) => {
      state.isLoading = true;
    },
    getWalletSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = keyBy(action.payload, 'currency_type.name');
    },
    getWalletFailure: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.payload;
    },
    getExternalWallet: (state) => {
      state.isLoading = true;
    },
    getExternalWalletSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isAccountVerified = !!payload.memberAt;
      state.cards = payload.balance.map((c) => ({
        name: c.label,
        balance: c.balance,
        id: c.id,
        currency: c.currency,
      }));
    },
    getExternalWalletFailure: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.payload;
    },
    getActivities: (state) => {
      state.isLoadingActivities = true;
      state.activities = [];
    },
    getActivitiesSuccess: (state, action) => {
      state.isLoadingActivities = false;
      state.isActivityLoaded = true;
      state.activities.push(...action.payload.orders);
      state.activitiesMeta.totalPages = action.payload.totalPages;
    },
    getActivitiesFailure: (state, action) => {
      state.activities = [];
      state.isLoadingActivities = false;
      state.isActivityLoaded = true;
      state.loadActivitiesError = action.payload;
    },
    setActivityCategory: (state, action) => {
      state.activitiesMeta.category = action.payload;
      state.isLoadingActivities = true;
      state.activitiesMeta.page = 1;
      state.activities = [];
    },
    getMoreActivities: (state) => {
      state.activitiesMeta.page += 1;
    },
    refetchActivitiesList: (state) => {
      state.activitiesMeta.page = 1;
      state.activities = [];
    },
  },
  extraReducers: {
    [logoutUser]: () => initialState,
    [spinSpinner]: (state, action) => {
      if (action.payload?.options?.using_tokens) {
        const type = action.payload?.options?.using_tokens;
        state.data[type].amount -= spinTokenAmount[type];
      }
    },
  },
});

export const {
  getActivities,
  getActivitiesSuccess,
  getActivitiesFailure,
  getWallet,
  getWalletSuccess,
  getWalletFailure,
  getExternalWallet,
  getExternalWalletSuccess,
  getExternalWalletFailure,
  getMoreActivities,
  setActivityCategory,
  refetchActivitiesList,
} = walletSlice.actions;

export default walletSlice.reducer;
