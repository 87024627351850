import { call, put, select, takeLatest, fork } from 'redux-saga/effects';

import {
  loadActivities,
  loadWallets,
  loadPendingActivities,
  fetchExternalWalletsRequest,
} from 'services/api/wallet';

import { getIsShowSweepLink, getDisplayedAmount } from 'utils/wallet';
import { toPlural } from 'utils/formatting';

import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { selectConfigProperty } from 'redux/config';

import {
  getWallet,
  getWalletSuccess,
  getWalletFailure,
  getActivities,
  getActivitiesSuccess,
  getActivitiesFailure,
  getMoreActivities,
  setActivityCategory,
  refetchActivitiesList,
  getExternalWallet,
  getExternalWalletSuccess,
  getExternalWalletFailure,
} from './slice';
import { selectActivities } from './selectors';

function* onFetchWallets() {
  try {
    const {
      data: { purses },
    } = yield call(loadWallets);
    yield put(getWalletSuccess(purses));
    const useExternalWallets = yield select(
      selectConfigProperty(CONFIG_KEYS_MAP.useExternalWallets)
    );
    if (useExternalWallets) {
      yield fork(onFetchExternalWallets);
    }
  } catch (errorMsg) {
    const error = errorMsg.data.errors[0];
    yield put(getWalletFailure(error));
  }
}

function* onFetchExternalWallets() {
  try {
    const {
      data: { purses },
    } = yield call(fetchExternalWalletsRequest);
    yield put(getExternalWalletSuccess(purses));
  } catch (errorMsg) {
    const error = errorMsg.data.errors[0];
    yield put(getExternalWalletFailure(error));
  }
}

const getOrderDetails = (product, data, order) => {
  const displayedAmount = getDisplayedAmount(order);

  if (product.type === 'AuctionBid') {
    const { auction_name } = data;
    const { display_name } = product;
    return {
      displayedAmount,
      displayName: `${display_name}: ${auction_name}`,
      link: {},
    };
  }

  if (product.public_id === 'wmg-artist-follow-spotify') {
    return {
      displayedAmount,
      displayName: `Artist - ${product.display_name}`,
      link: {},
    };
  }

  if (product.public_id === 'wmg-track-follow-spotify') {
    return {
      displayedAmount,
      displayName: `Track - ${product.display_name}`,
      link: {},
    };
  }

  // If it is charity order
  if (product.public_id === 'donation-reward') {
    const { donated_amount, charity_name, donation_order_number } = data;
    return {
      displayedAmount,
      displayName: `$${donated_amount}, ${charity_name}`,
      link: {
        label: 'Donation Receipt',
        url: `${PATHS.charities}/${donation_order_number}/success`,
      },
    };
  }

  // If it is a gift card
  if (product.type === 'EGiftCard') {
    const { name } = product;
    const { amount, url } = data;
    return {
      displayedAmount,
      displayName: `${name} $${amount}`,
      link: {
        label: 'View Code',
        url,
        isExternal: true,
      },
    };
  }

  const { sweepstake_id, event_id, entry_period_end } = data;

  const isShowSweepLink = getIsShowSweepLink(
    sweepstake_id,
    event_id,
    entry_period_end,
    order.amount
  );
  return {
    displayedAmount,
    displayName: product.display_name,
    link: isShowSweepLink
      ? {
          label: 'Enter Again',
          url: `${PATHS.sweepstakes}/${sweepstake_id}/${event_id}`,
        }
      : null,
  };
};

function* onFetchActivities({ payload }) {
  const { meta } = yield select(selectActivities);
  const category = payload?.category || meta.category;
  if (!category) return;
  try {
    const {
      data: { orders, pages },
    } = yield call(loadActivities, {
      category,
      page: meta.page,
      perPage: meta.perPage,
    });

    let pendingActivities = [];
    if (category === 'affiliates') {
      const {
        data: { orders: pendingOrders },
      } = yield call(loadPendingActivities);

      pendingActivities = pendingOrders.map((order) => ({
        id: order.created_at,
        displayName: order.product.display_name,
        displayedAmount: toPlural(order.amount, 'Token'),
        amount: order.amount,
        createdAt: order.created_at,
        productType: order.product.type,
        pending: true,
      }));
    }
    const activities = orders.map((order) => {
      const { product, data } = order.line_items[0];
      const { type } = product;
      const { created_at, id } = order;
      const {
        referral_email,
        winner_announcement_date,
        event_id,
        sweepstake_id,
      } = data;

      const { displayedAmount, displayName, link } = getOrderDetails(
        product,
        data,
        order
      );

      return {
        id,
        displayName,
        link,
        displayedAmount,
        amount: order.amount,
        createdAt: created_at,
        productType: type,
        referralEmail: referral_email,
        announcementDate: winner_announcement_date,
        sweepstakeId: sweepstake_id,
        eventId: event_id,
      };
    });
    yield put(
      getActivitiesSuccess({
        orders: [...pendingActivities, ...activities],
        totalPages: pages,
      })
    );
  } catch (error) {
    yield put(getActivitiesFailure(error?.data.error));
  }
}

function* onRefetchActivities({ payload }) {
  const { meta } = yield select(selectActivities);
  if (payload.category === meta.category) {
    yield fork(onFetchActivities);
  }
}

export function* walletSaga() {
  yield takeLatest(getWallet, onFetchWallets);
  yield takeLatest(getExternalWallet, onFetchExternalWallets);
  yield takeLatest(getActivities, onFetchActivities);
  yield takeLatest(getMoreActivities, onFetchActivities);
  yield takeLatest(setActivityCategory, onFetchActivities);
  yield takeLatest(refetchActivitiesList, onRefetchActivities);
}
