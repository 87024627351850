import { call, all, select, takeLeading, put } from 'redux-saga/effects';

import { fetchCms, fetchPopups } from 'services/api/cms';

import { selectIsLoaded } from './selectors';
import {
  getCms,
  getCmsFailure,
  getCmsSuccess,
  getPopups,
  getPopupsSuccess,
} from './slice';

function* loadCms() {
  const hasContent = yield select(selectIsLoaded);
  if (!hasContent) {
    try {
      const { data } = yield call(fetchCms);
      yield put(getCmsSuccess(data.items));
    } catch (error) {
      yield put(getCmsFailure(error?.data?.message));
    }
  }
}

function* loadPopups() {
  try {
    const { data } = yield call(fetchPopups);
    yield put(getPopupsSuccess(data.popups));
  } catch (e) {
    console.log(e);
  }
}

export function* cmsSaga() {
  yield all([takeLeading(getCms, loadCms)]);
  yield all([takeLeading(getPopups, loadPopups)]);
}
