import React from 'react';
import clsx from 'clsx';

import useStyles from './styles';

const KeyValueText = ({ boldKey, className, keyProp, value }) => {
  const classes = useStyles();
  return (
    <span className={clsx(className)}>
      <span className={clsx({ [classes.bold]: boldKey })}>{keyProp}</span>
      <span>{value}</span>
    </span>
  );
};

export default KeyValueText;
