import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& li': {
      fontSize: '0.9375rem',
    },
  },
  contentWrapper: {
    maxWidth: '40rem',
    '& h4': {
      fontSize: '1.3rem',
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    '& h6, p': {
      fontSize: '0.94rem',
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    '& a': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
}));
