import { toPlural } from 'utils/formatting';
import isPast from 'date-fns/isPast';

export const getIsShowSweepLink = (
  sweepstakeId,
  eventId,
  entryPeriodEnd,
  orderAmount
) =>
  !!sweepstakeId &&
  !!eventId &&
  !isPast(new Date(entryPeriodEnd)) &&
  orderAmount < 0;

const currencyNameMapping = {
  bat: 'BAT',
};

export const getDisplayedAmount = (order) => {
  const orderAmountName = Math.abs(order.amount) > 1 ? 'Tokens' : 'Token';
  const displayName =
    order.currency_type.display_name === 'Warner Music Entries'
      ? orderAmountName
      : order.currency_type.display_name;
  if (order.amount) {
    const currency = order.currency_type;
    let formattedValue = '';
    if (['spn', 'dspn', 'token'].includes(currency.name)) {
      formattedValue = toPlural(order.amount, displayName);
    } else {
      formattedValue = `${order.amount} ${
        currencyNameMapping[order.currency_type.name] || displayName
      }`;
    }
    return order.amount > 0 ? `+${formattedValue}` : formattedValue;
  }
  return '';
};
