import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  coupon: null,
  shouldShowSuccessModal: false,
  shouldShowCaptcha: false,
  verifyCaptchaError: null,
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    fetchCoupon: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCouponSuccess: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.coupon = action.payload;
    },
    fetchCouponFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
      state.shouldShowCaptcha = payload.shouldShowCaptcha;
    },
    vefiryCaptcha: (state) => {
      state.isLoading = true;
      state.verifyCaptchaError = null;
    },
    vefiryCaptchaSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    vefiryCaptchaFailure: (state, action) => {
      state.isLoading = false;
      state.verifyCaptchaError = action.payload;
    },
    navigateUserToExperience: () => {},
    clearCouponData: () => initialState,
    toggleCouponModal: (state, action) => {
      state.shouldShowSuccessModal = action.payload;
    },
  },
});

export const {
  fetchCoupon,
  fetchCouponFailure,
  fetchCouponSuccess,
  navigateUserToExperience,
  clearCouponData,
  toggleCouponModal,
  vefiryCaptcha,
  vefiryCaptchaSuccess,
  vefiryCaptchaFailure,
} = couponSlice.actions;

export default couponSlice.reducer;
