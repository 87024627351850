import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  isDonationProcessing: false,
  processingError: null,
  donatedCharity: null,
  receipt: {},
  paymentIntentSecret: null,
  paymentIntent: null,
};

const charitiesSlice = createSlice({
  name: 'charities',
  initialState,
  reducers: {
    getCharities: (state) => {
      state.isLoading = true;
    },
    getCharitiesSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    getCharitiesFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    sendCharityDonation: (state) => {
      state.isDonationProcessing = true;
      state.processingError = null;
    },
    sendCharityDonationSuccess: (state, action) => {
      state.donatedCharity = action.payload;
      state.isDonationProcessing = false;
    },
    sendCharityDonationFailure: (state, action) => {
      state.isDonationProcessing = false;
      state.processingError = action.payload;
    },
    getCharityReceipt: (state) => {
      state.receipt = {};
      state.isLoading = true;
    },
    getCharityReceiptSuccess: (state, action) => {
      state.receipt = action.payload;
      state.isLoading = false;
    },
    getCharityReceiptFailure: (state) => {
      state.sLoading = false;
    },
    setPaymentIntentSecret: (state, action) => {
      state.paymentIntentSecret = action.payload;
    },
    clearPaymentIntentSecret: (state) => {
      state.paymentIntentSecret = null;
    },
    setPaymentIntent: (state, action) => {
      state.paymentIntent = action.payload;
    },
    clearPaymentIntent: (state) => {
      state.paymentIntent = null;
    },
  },
});

export const {
  getCharities,
  getCharitiesSuccess,
  getCharitiesFailure,
  sendCharityDonation,
  sendCharityDonationSuccess,
  sendCharityDonationFailure,
  getCharityReceipt,
  getCharityReceiptSuccess,
  getCharityReceiptFailure,
  setPaymentIntentSecret,
  clearPaymentIntentSecret,
  setPaymentIntent,
  clearPaymentIntent,
} = charitiesSlice.actions;

export default charitiesSlice.reducer;
