import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGiftCards,
  selectShouldFetchGiftCards,
  selectGiftCardsData,
  selectFilteredGiftCards,
  selectGiftCardsCategories,
  selectDefaultActiveCategory,
  selectGiftCardById,
} from 'redux/giftCards';

import { storage } from 'services/storage';

export const useGiftCards = (selectedCountry) => {
  const dispatch = useDispatch();
  const shouldFetchGiftCards = useSelector(selectShouldFetchGiftCards);
  const { isLoading, error } = useSelector(selectGiftCardsData);

  const giftCardsCategories = useSelector(selectGiftCardsCategories);
  const defaultActiveCategory = useSelector(selectDefaultActiveCategory);

  const savedActiveCategory = storage.local.get('activeGiftCardCategory');

  const [activeCategory, setActiveCategory] = useState(
    () => savedActiveCategory || defaultActiveCategory
  );

  const filteredGiftCards = useSelector(
    selectFilteredGiftCards(activeCategory)
  );

  const handleActiveCatagory = useCallback((key) => {
    storage.local.set('activeGiftCardCategory', key);
    setActiveCategory(key);
  }, []);

  useEffect(() => {
    if (selectedCountry.length) {
      dispatch(fetchGiftCards(selectedCountry));
    }
  }, [dispatch, selectedCountry]);

  return {
    isLoading,
    shouldFetchGiftCards,
    activeCategory,
    error,
    giftCardsCategories,
    filteredGiftCards,
    handleActiveCatagory,
  };
};

export const useGiftCardDetails = (countryCode, giftCardId) => {
  const dispatch = useDispatch();
  const shouldFetchGiftCards = useSelector(selectShouldFetchGiftCards);

  useEffect(() => {
    if (shouldFetchGiftCards) {
      dispatch(fetchGiftCards(countryCode));
    }
  }, [dispatch, shouldFetchGiftCards, countryCode]);

  return useSelector(selectGiftCardById(+giftCardId));
};
