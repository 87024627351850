import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { selectScratchGame, handleCloseModal } from 'redux/scratchGame';

import { useStyles } from './style';

export const WinLoseModal = ({
  cardBalance,
  hasUserTokens,
  currentPartner,
  setIsLessThanFourPercent,
  handleOnclick,
}) => {
  const dispatch = useDispatch();

  const {
    modalHeadingText,
    modalSecondaryText,
    modalButtonText,
    modalPopupImage,
    isModalLoading,
    isButtonDisabled,
  } = useSelector(selectScratchGame);

  const classes = useStyles();

  const closeXModal = () => {
    dispatch(handleCloseModal());
    setIsLessThanFourPercent(true);
  };

  const handleButtonModal = () => {
    dispatch(handleCloseModal());
    if (currentPartner === 'wmg') {
      handleOnclick(hasUserTokens);
    } else {
      handleOnclick(Number(cardBalance) > 0);
    }
  };

  return (
    <div className={classes.modalStatusContainer}>
      <div
        className={classes.modalStatusContentContainer}
        style={{
          backgroundImage: isModalLoading ? '' : `url(${modalPopupImage})`,
        }}
      >
        {isModalLoading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={80} color="secondary" />
          </div>
        ) : (
          <>
            <div className={classes.modalStatusButton}>
              <Button
                className={classes.modalStatusTextClose}
                size="medium"
                variant="text"
                onClick={closeXModal}
              >
                &times;
              </Button>
            </div>
            <div className={classes.modalStatusHeader}>
              <Typography className={classes.modalStatusTitle}>
                {modalHeadingText}
              </Typography>

              <div className={classes.modalStatusFooter}>
                <Typography className={classes.modalStatusFooterText}>
                  {modalSecondaryText}
                </Typography>
                <Button
                  fullWidth
                  disabled={isButtonDisabled}
                  className={classes.modalStatusFooterButton}
                  variant="contained"
                  onClick={handleButtonModal}
                >
                  {modalButtonText}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
