import { call, put, takeLatest, takeLeading, select } from 'redux-saga/effects';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { getUserAccount } from 'redux/user';
import {
  fetchLiveBidsList,
  fetchLiveBidDetailsRequest,
  postAuctUsername,
  placeBid,
} from 'services/api/liveBids';
import { getWallet, refetchActivitiesList } from 'redux/wallet';
import { selectConfigProperty } from 'redux/config';
import {
  fetchLiveBids,
  fetchLiveBidsFailure,
  fetchLiveBidsSuccess,
  fetchLiveBidDetails,
  fetchLiveBidDetailsSuccess,
  fetchLiveBidDetailsFailure,
  placeBidItem,
  placeBidItemSuccess,
  placeBidItemFailure,
  postAuctionUsername,
  postAuctionUsernameSuccess,
  postAuctionUsernameFailure,
} from './slice';

function* fetchLiveBidsSaga() {
  const limit = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.pennyAuctionSearchLimit)
  ) || 10;
  try {
    const { data } = yield call(fetchLiveBidsList, { limit });
    const items = data.data.products.map((i) => {
      const {
        auction_state,
        auctionStatus,
        avatar,
        date_added,
        date_closed,
        description,
        id,
        mprice,
        status,
        title,
        wprice,
      } = i;
      return {
        auctionState: auction_state,
        auctionStatus,
        avatar,
        bidder: i.highest_bidder_username,
        dateAdded: date_added,
        dateClosed: date_closed,
        id,
        isEnded: auctionStatus === 'closed',
        isFuture: auctionStatus === 'upcoming',
        isSold: status === 'sold',
        price: wprice.USD,
        productDetails: description,
        title,
        value: mprice.USD,
      };
    });
    yield put(fetchLiveBidsSuccess(items));
  } catch (error) {
    yield put(fetchLiveBidsFailure(error));
  }
}

function* fetchBidDetails({ payload: { id } }) {
  try {
    const {
      data: {
        data: { auctionDetail, highBidder },
      },
    } = yield call(fetchLiveBidDetailsRequest, id);
    const {
      auction_state,
      auctionStatus,
      avatar,
      date_added,
      date_closed,
      description,
      mprice,
      status,
      title,
      wprice,
    } = auctionDetail;
    const data = {
      auctionState: auction_state,
      auctionStatus,
      avatar,
      bidder: highBidder[0]?.username,
      dateAdded: date_added,
      dateClosed: date_closed,
      id,
      isEnded: auctionStatus === 'closed',
      isFuture: auctionStatus === 'upcoming',
      isSold: status === 'sold',
      price: wprice.USD,
      productDetails: description,
      title,
      value: mprice.USD,
    };
    yield put(fetchLiveBidDetailsSuccess(data));
  } catch (error) {
    yield put(fetchLiveBidDetailsFailure(error?.message));
  }
}

function* auctionAuth({ payload: { name } }) {
  try {
    yield call(postAuctUsername, { auction_username: name });
    yield put(getUserAccount());
    yield put(postAuctionUsernameSuccess());
  } catch (error) {
    yield put(postAuctionUsernameFailure(error?.data?.message || ''));
  }
}

function* placeBidItemSaga({ payload: { auction_name, auction_id } }) {
  try {
    const {
      data: { order_number: order },
    } = yield call(placeBid, {
      auction_name,
      auction_id,
    });
    yield put(placeBidItemSuccess(order));
    yield put(getWallet());
    yield put(refetchActivitiesList({ category: 'auctions' }));
  } catch (error) {
    yield put(
      placeBidItemFailure(error?.data?.message || error?.data?.errors[0])
    );
  }
}

export function* liveBidsSaga() {
  yield takeLatest(fetchLiveBids, fetchLiveBidsSaga);
  yield takeLeading(postAuctionUsername, auctionAuth);
  yield takeLatest(fetchLiveBidDetails, fetchBidDetails);
  yield takeLeading(placeBidItem, placeBidItemSaga);
}
