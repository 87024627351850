import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import Loader from 'components/Loader';
import { PATHS } from 'constants/navigation';
import { setError, spotifyConnect, spotifyLogin } from 'redux/user';
import { getQryParams } from 'utils/url';

const SpotifyLogin = () => {
  const location = useLocation();
  const params = getQryParams(location);
  const dispatch = useDispatch();
  const spotifyRedirectUri = window.location.origin + PATHS.loginSpotify;
  const isLoginPage = localStorage.getItem('loginPage');

  useEffect(() => {
    const { code, error } = params;
    if (code) {
      if (isLoginPage) {
        dispatch(spotifyLogin({ code, redirectUri: spotifyRedirectUri }));
      } else {
        dispatch(spotifyConnect({ code, redirectUri: spotifyRedirectUri }));
      }
    }
    if (error) {
      dispatch(setError(error));
      dispatch(replace(PATHS.login));
    }
  }, [dispatch, params, spotifyRedirectUri, isLoginPage]);

  return <Loader />;
};

export default SpotifyLogin;
