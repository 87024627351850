import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as SweepsIcon } from 'assets/images/icons/sweeps-icon.svg';

import { PATHS } from 'constants/navigation';

import { useStyles } from './styles';

const ChooseExperiencePage = () => {
  const classes = useStyles();

  const items = [
    {
      icon: SweepsIcon,
      iconViewBox: '0 0 83 86',
      title: 'Sweepstakes',
      link: PATHS.sweepstakes,
    },
  ];

  return (
    <div className={classes.wrapper}>
      <Typography variant="h4" className={classes.title}>
        Choose an Experience:
      </Typography>
      {items.map((item) => (
        <Link to={item.link} className={classes.item} key={item.link}>
          <SvgIcon
            component={item.icon}
            viewBox={item.iconViewBox}
            className={classes.icon}
          />
          <Typography variant="h6" className={classes.itemTitle}>
            {item.title}
          </Typography>
        </Link>
      ))}
    </div>
  );
};

export default ChooseExperiencePage;
