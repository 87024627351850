import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    position: 'relative',
    width: '100%',
  },
  logo: {
    display: 'block',
    height: 'auto',
    maxHeight: '36px',
    margin: '0 auto',
  },
  menuButton: {
    position: 'absolute',
    zIndex: 2,
  },
  loginLink: {
    position: 'absolute',
    right: theme.spacing(2),
    padding: theme.spacing(1),
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: theme.palette.accent.main,
    textDecoration: 'none',
  },
  image: {
    width: '100%',
    maxHeight: '36px',
  },
}));
