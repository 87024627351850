/* eslint-disable  react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import TagManager from 'react-gtm-module';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { trackEvent, trackPageView } from 'redux/analytics';
import { selectConfigProperty } from 'redux/config';

export const useGTM = () => {
  const gtmId = useSelector(selectConfigProperty(CONFIG_KEYS_MAP.gtmId));
  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId, dataLayerName: 'onboarding' });
    }
  }, [gtmId]);
};

export const useEventTracker = () => {
  const dispatch = useDispatch();
  return useCallback(
    (eventCategory, eventAction, eventLabel) => {
      dispatch(
        trackEvent({ event: { eventCategory, eventAction, eventLabel } })
      );
    },
    [dispatch]
  );
};

export const useTrackAllPageViews = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trackPageView({ location }));
  }, [dispatch, location.pathname]);
};
