import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import parse from 'html-react-parser';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

import PartnerSpecificContent from 'components/PartnerSpecificContent';

import { PATHS } from 'constants/navigation';

import useStyles from './styles';

const RulesMessage = ({ sweep, event }) => {
  const classes = useStyles();

  const defaultRulesLink = `${PATHS.sweepstakes}/${sweep.id}/${event.id}/rules`;
  const rulesLink = sweep.data?.rules?.url
    ? { pathname: sweep.data.rules.url }
    : defaultRulesLink;

  return (
    <Typography>
      I agree to the{' '}
      <Link
        color="inherit"
        component={RouterLink}
        className={classes.rulesMessageLinks}
        to={rulesLink}
        target="_blank"
      >
        Official Rules
      </Link>{' '}
      and understand that all sweepstakes entries are final.
    </Typography>
  );
};

const NewsMessage = ({ info }) => {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.newsCheckboxLabel}>
      {parse(info)}
    </Typography>
  );
};

const Checkboxes = ({
  sweepstake,
  event,
  rulesChecked,
  setRulesChecked,
  newsChecked,
  setNewsChecked,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.checkboxContainer}>
      <div className={classes.rulesContainer}>
        <Checkbox
          data-cy="rules-checkbox"
          className={classes.checkbox}
          checked={rulesChecked}
          onChange={() => setRulesChecked(!rulesChecked)}
          name="Rules"
          icon={<CheckBoxOutlineBlankIcon className={classes.checkboxIcon} />}
          checkedIcon={<CheckBoxIcon className={classes.checkboxIcon} />}
        />
        <RulesMessage sweep={sweepstake} event={event} />
      </div>
      {sweepstake.newsletter_enrollment_html && (
        <div className={classes.newsContainer}>
          <PartnerSpecificContent hidden="true" partnerName="wmg">
            <Checkbox
              className={classes.checkbox}
              checked={newsChecked}
              onChange={() => setNewsChecked(!newsChecked)}
              name="News"
              icon={
                <CheckBoxOutlineBlankIcon className={classes.checkboxIcon} />
              }
              checkedIcon={<CheckBoxIcon className={classes.checkboxIcon} />}
            />
          </PartnerSpecificContent>
          <NewsMessage info={sweepstake.newsletter_enrollment_html} />
        </div>
      )}
    </div>
  );
};

export default Checkboxes;
