import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    display: 'flex',
    flexShrink: '0',
    flexBasis: 'initial',
    maxWidth: 'initial',
    width: '100%',
    margin: theme.spacing(-1, 0),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(504)]: {
      justifyContent: 'center',
    },
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    /* Hide scrollbar for IE, Edge and Firefox */
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
  },
  button: {
    '&.active': {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.accent.main,
    },
    marginLeft: theme.spacing(1.5),
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.black.main,
    textTransform: 'capitalize',
    flexShrink: '0',
  },
}));
