import React, { useCallback, useState } from 'react';

import ReactFlagsSelect from 'react-flags-select';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useGiftCards } from 'hooks/giftCards';
import { useUserCountry } from 'hooks/user';

import { selectConfigProperty } from 'redux/config';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import HorizontalFilter from 'components/HorizontalFilter';
import RoundedOptionItem from 'components/RoundedOptionItem';
import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { PATHS } from 'constants/navigation';
import { storage } from 'services/storage';
import { useEventTracker } from 'hooks/analytics';

import { getFaceplatesUrl } from 'utils/giftCards';

import SearchBar from '../components/SearchBar';
import DisclaimerPopup from '../components/DisclaimerPopup';

import useStyles from './styles';

const GiftCardsListPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showUSOnlyDisclaimer, changeUSOnlyDisclaimer] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [search, setSearch] = useState(
    () => storage.local.get('savedSearchValue') || ''
  );

  const isGiftCardEnabled = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.giftCardsEnabled)
  );

  const {
    isLoading,
    shouldFetchGiftCards,
    activeCategory,
    error,
    giftCardsCategories,
    filteredGiftCards,
    handleActiveCatagory,
  } = useGiftCards(selectedCountry);

  const showDisclaimerPopup = () => {
    if (!storage.local.get('USOnlyDisclaimerShowed')) {
      changeUSOnlyDisclaimer(true);
    }
  };

  const handleGetCountryError = () => {
    setSelectedCountry('US');
    showDisclaimerPopup();
  };

  const handleGetCountry = (countryCode) => {
    if (countryCode) {
      setSelectedCountry(countryCode);
    }
  };

  useUserCountry(handleGetCountry, handleGetCountryError);

  const handleGAEvent = useEventTracker();

  const handleClick = useCallback(
    (id) => {
      dispatch(push(`${PATHS.giftCards}/${selectedCountry}/${id}`));
      handleGAEvent('gift-card', 'open-gift-card', id);
      if (search.length) {
        storage.local.set('savedSearchValue', search);
      }
    },
    [dispatch, search, selectedCountry, handleGAEvent]
  );

  const handleSearchChange = useCallback(({ target: { value } }) => {
    setSearch(value);
  }, []);

  const handleCloseIconClick = useCallback(() => {
    setSearch('');
    storage.local.removeItem('savedSearchValue');
  }, []);

  const closeUSOnlyDisclaimer = useCallback(() => {
    storage.local.set('USOnlyDisclaimerShowed', true);
    changeUSOnlyDisclaimer(false);
  }, []);

  const renderItems = useCallback(() => {
    if (!shouldFetchGiftCards && !filteredGiftCards.length) {
      return (
        <Typography className={classes.emptyMessage}>
          There are no active gift cards.
        </Typography>
      );
    }
    const items = search.length
      ? filteredGiftCards.filter(({ name }) =>
          name.toLowerCase().includes(search.toLowerCase().trim())
        )
      : filteredGiftCards;

    if (!items.length && search.length) {
      return (
        <Typography className={classes.emptyMessage}>
          Sorry, please search again.
        </Typography>
      );
    }

    return items.map((item) => (
      <RoundedOptionItem
        key={item.id}
        title={item.name}
        imgSrc={getFaceplatesUrl(item)}
        onClick={() => handleClick(item.id)}
      />
    ));
  }, [filteredGiftCards, shouldFetchGiftCards, handleClick, search, classes]);

  if (!isGiftCardEnabled) {
    return null;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.searchContainer}>
        <Grid item className={classes.search}>
          <SearchBar
            value={search}
            onChange={handleSearchChange}
            onCloseIconClick={handleCloseIconClick}
          />
        </Grid>
        <Grid item>
          <ReactFlagsSelect
            className={classes.countrySelector}
            onSelect={(code) => setSelectedCountry(code)}
            selected={selectedCountry}
            selectedSize={14}
            optionsSize={14}
            placeholder="Country"
            alignOptionsToRight
            showSelectedLabel={false}
            fullWidth={false}
            searchable
          />
        </Grid>
      </Grid>
      <HorizontalFilter
        filterOptions={giftCardsCategories}
        activeFilter={activeCategory}
        setActiveFilter={handleActiveCatagory}
      />
      {isLoading ? <Loader /> : renderItems()}
      <DisclaimerPopup
        isOpened={showUSOnlyDisclaimer}
        closeModal={closeUSOnlyDisclaimer}
      />
    </Grid>
  );
};

export default GiftCardsListPage;
