import { createSelector } from '@reduxjs/toolkit';
import { selectCmsItem } from 'redux/cms';
import get from 'lodash/get';

const selectGiftCards = (state) => state.giftCards;

export const selectFilteredGiftCards = (activeCategory) =>
  createSelector([selectGiftCards], ({ data }) => {
    if (activeCategory === 'all') {
      return data.slice().sort((a, b) => a.name.localeCompare(b.name));
    }
    return data
      .filter((item) => item.category?.includes(activeCategory))
      .sort((a, b) => a.name.localeCompare(b.name));
  });

export const selectGiftCardsCategories = createSelector(
  [(state) => selectCmsItem(state, { key: 'giftCardCategories' })],
  (categories) => {
    const items = get(categories, 'data.items', []);
    return [
      ...items.slice().sort((a, b) => (a.priority > b.priority ? 1 : -1)),
      { displayName: 'All', key: 'all' },
    ];
  }
);

export const selectDefaultActiveCategory = createSelector(
  [(state) => selectCmsItem(state, { key: 'giftCardCategories' })],
  (categories) => {
    const items = get(categories, 'data.items', []);
    const activeCategory = items.find((item) => item.default === 'true');
    return activeCategory?.key || 'all';
  }
);

export const selectShouldFetchGiftCards = createSelector(
  [selectGiftCards],
  ({ isLoading, isLoaded }) => Boolean(!isLoaded && !isLoading)
);

export const selectGiftCardsData = createSelector(
  [selectGiftCards],
  ({ isLoading, data, error }) => ({ isLoading, data, error })
);

export const selectGiftCardById = (id) =>
  createSelector([selectGiftCards], ({ isLoading, data, error }) => ({
    isLoading,
    giftCard: data.find((item) => item.id === id),
    error,
  }));

export const selectOrder = createSelector(
  [selectGiftCards],
  ({ order }) => order
);
