import React, { Suspense, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import useHotjar from 'react-use-hotjar';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { selectIsLiveActive } from 'redux/liveBids';
import { selectConfigProperty } from 'redux/config';

import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Metadata from 'components/Metadata';
import ScrollToTop from 'components/ScrollToTop';
import PartnerSpecificContent from 'components/PartnerSpecificContent';

import { selectIsAccountVerified } from 'redux/wallet';
import { useIsLoggedIn, useUserInfo } from 'hooks/user';
import { useGTM } from 'hooks/analytics';
import { useCms } from 'hooks/cms';
import { useConfig } from 'hooks/config';
import { getGAArgs } from 'utils/config';

import { useStyles } from './styles';
import GenericPopups from '../GenericPopups';
import WinnerPopUp from '../LiveBids/WinnerPopUp';

ReactGA.initialize(...getGAArgs());

const BaseFallback = <div>Loading...</div>;

const App = ({ children, history }) => {
  const classes = useStyles();
  const isLiveActive = useSelector(selectIsLiveActive);
  const isAccountVerified = useSelector(selectIsAccountVerified);
  const containerRef = useRef(null);

  const { isLoading, isLoaded } = useConfig();
  const { user, isValidUser } = useUserInfo();
  useCms();
  useGTM();
  const isLoggedIn = useIsLoggedIn();

  const { initHotjar, identifyHotjar } = useHotjar();
  const hotjarId = useSelector(selectConfigProperty(CONFIG_KEYS_MAP.hotjarId));
  const upholdVerificationLink = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdVerificationLink)
  );

  useEffect(() => {
    if (hotjarId) {
      initHotjar(+hotjarId, 6);
    }
  }, [initHotjar, hotjarId]);

  useEffect(() => {
    if (isValidUser && hotjarId) {
      identifyHotjar(user.id, user);
    }
  }, [identifyHotjar, user, isValidUser, hotjarId]);

  if (isLoading || !isLoaded) {
    return <Loader />;
  }

  return (
    <HelmetProvider>
      <Metadata />
      <div className={classes.root}>
        <ErrorBoundary>
          <Suspense fallback={BaseFallback}>
            <BrowserRouter>
              <ConnectedRouter history={history}>
                <PartnerSpecificContent partnerName="uber">
                  {isLiveActive && <WinnerPopUp />}
                </PartnerSpecificContent>
                {isLoggedIn && <GenericPopups />}
                <Header />
                <div
                  className={classes.contentWrapper}
                  ref={containerRef}
                  id="scrollableTarget"
                >
                  <ScrollToTop ref={containerRef} />
                  <div className={classes.contentHeight}>
                    <PartnerSpecificContent partnerNames={['brave', 'uphold']}>
                      {!isAccountVerified && (
                        <div className={classes.banner}>
                          Uphold account{' '}
                          <a href={upholdVerificationLink}>
                            verification required.
                          </a>
                        </div>
                      )}
                    </PartnerSpecificContent>
                    {children}
                  </div>
                  <Footer />
                </div>
              </ConnectedRouter>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </div>
    </HelmetProvider>
  );
};

export default App;
