/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const logError = (error) => {
  console.log('====================================');
  console.log('Error caught by Error boundary component.');
  console.log('====================================');
};

const ERROR_DISPLAY_MESSAGE =
  "Oops! We've encountered an error. Try refreshing the page, or contact support for further assistance.";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: null,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // TODO - Log to honeybadger
    logError({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div>
          <ErrorOutlineIcon />
          <p>{ERROR_DISPLAY_MESSAGE}</p>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
