import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  wrapper: {
    maxWidth: 300,
  },
  title: {
    margin: theme.spacing(2, 0, 3),
    wordBreak: 'break-word',
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },

  imgWrapper: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    borderRadius: 10,
    width: '100%',
    maxWidth: 300,
    maxHeight: 300,
  },
  image: {
    width: '100%',
  },
  pickOptionTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  btn: {
    width: '100%',
    maxWidth: '300px',
    margin: theme.spacing(3, 0, 1),
    fontSize: '0.9rem',
  },
  optionBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  optionBtnWrapper: {
    position: 'relative',
    padding: theme.spacing(2, 0),
  },
  optionBtn: {
    backgroundColor: theme.palette.transparent.main,
    borderRadius: '0.625rem',
    transitionProperty: 'background-color, box-shadow, border,color',
    margin: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: theme.palette.transparent.main,
    },
  },
  activeOptionBtn: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    borderRadius: '0.625rem',

    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
  optionBtnText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    fontSize: '0.65rem',
    color: theme.palette.greyDark.main,
  },
  textAbove: {
    top: '0',
  },
  textBelow: {
    bottom: '0',
  },
  skipLink: {
    display: 'inline-block',
    fontSize: '0.85rem',
    color: theme.palette.accent.main,
    padding: theme.spacing(1, 1.4),
    margin: theme.spacing(1, 0),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
