import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { storage } from 'services/storage';

export const selectUser = (state) => _get(state, 'user', {});

export const selectUserAcceptedTerms = (state) => {
  return storage.local.get('user_accepted_terms') || state.user.acceptedTerms;
};

export const selectUserInfo = createSelector([selectUser], (user) => user);

export const selectIsSpotify = () =>
  storage.local.get('isLoggedIn') && storage.local.get('isSpotifyLogin');

export const selectSpotifyConnectError = createSelector(
  [selectUser],
  ({ spotifyConnectError }) => spotifyConnectError
);
