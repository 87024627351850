import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1, 0, 2),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  winnerIcon: {
    width: 35,
    height: 25,
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
    margin: theme.spacing(4, 0, 2),
  },
}));
