import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Loader from 'components/Loader';
import ErrorMessage from 'components/ErrorMessage';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useCms, useCmsHtml } from 'hooks/cms';
import { useUserNavigation } from 'hooks/navigation';

import {
  selectCharitiesData,
  selectPaymentIntent,
  selectPaymentIntentSecret,
  sendCharityDonation,
  getCharities,
} from 'redux/charities';
import { selectIsLoading } from 'redux/cms';

import { storage } from 'services/storage';

import CharityDonationInfo from '../components/CharityDonationInfo';
import CheckoutForm from '../components/CheckoutForm';

import useStyles from './styles';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CharityPaymentPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const charityTokensAmount = Number(storage.local.get('charityTokensAmount'));
  const paymentIntentSecret = useSelector(selectPaymentIntentSecret);
  const paymentIntent = useSelector(selectPaymentIntent);
  const DonationNotice = useCmsHtml('donationNotice');
  const [charityInfo, setCharityInfo] = useState({});

  const charityUpsell = useCms({ key: 'charityUpsell', path: 'exchangeRates' });
  const {
    isLoading,
    data,
    error,
    isDonationProcessing,
    processingError,
  } = useSelector(selectCharitiesData);
  const isCmsLoading = useSelector(selectIsLoading);

  useUserNavigation();

  useEffect(() => {
    dispatch(getCharities());
  }, [dispatch]);

  useEffect(() => {
    if (data && charityUpsell) {
      const selectedCharity = data.find((charity) => charity.id === Number(id));
      const selectedOption = charityUpsell.find(
        ({ value }) => value === charityTokensAmount
      );
      setCharityInfo({
        tokens: charityTokensAmount,
        donation: selectedOption?.rate,
        name: selectedCharity?.name,
        id: selectedCharity?.id,
      });
    }
  }, [data, charityTokensAmount, charityUpsell, id]);

  const onSubmit = useCallback(
    ({ paymentMethod, intent }) => {
      dispatch(
        sendCharityDonation({
          paymentMethod,
          intent,
          ...charityInfo,
        })
      );
    },
    [charityInfo, dispatch]
  );

  if (isLoading || isCmsLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
    >
      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.content}
      >
        <Typography variant="h4" className={classes.title}>
          Add a Card
        </Typography>

        <Elements stripe={stripePromise}>
          <CheckoutForm
            onSubmit={onSubmit}
            isDonationProcessing={isDonationProcessing}
            processingError={processingError}
            paymentIntentSecret={paymentIntentSecret}
            paymentIntent={paymentIntent}
            donationNotice={<DonationNotice />}
          >
            <CharityDonationInfo
              name={charityInfo.name}
              donation={charityInfo.donation}
              tokens={charityInfo.tokens}
            />
          </CheckoutForm>
        </Elements>
      </Grid>
    </Grid>
  );
};

export default CharityPaymentPage;
