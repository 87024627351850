import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Link from '@material-ui/core/Link';

import { PATHS } from 'constants/navigation';

import { useStyles } from './styles';

const EventsCard = ({ location_name, event_date, sweepId, id: eventId }) => {
  const classes = useStyles();

  const locationData = location_name.split('-');
  const locationVenue = locationData[0];
  const locationCity = locationData[1]?.split(',')[0];

  const getDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  return (
    <Card className={classes.root}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <Grid container alignItems="center" spacing={2}>
            <Grid
              container
              item
              direction="column"
              justify="space-between"
              className={classes.eventInfo}
            >
              <Typography
                variant="body1"
                className={`${classes.text} ${classes.textLocation}`}
              >
                {locationVenue}
              </Typography>
              <div className={classes.secondRowContainer}>
                {locationCity && (
                  <Typography
                    variant="body1"
                    className={`${classes.text} ${classes.city}`}
                  >
                    {locationCity},
                  </Typography>
                )}
                <Typography variant="body1" className={classes.text}>
                  {getDate(event_date)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <CardActions>
          <Typography variant="body1">
            <Link
              color="inherit"
              component={RouterLink}
              className={classes.eventLink}
              to={`${PATHS.sweepstakes}/${sweepId}/${eventId}`}
              data-cy="event-link"
            >
              Select
            </Link>
          </Typography>
        </CardActions>
      </Grid>
    </Card>
  );
};

export default EventsCard;
