import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchSweeps,
  selectSweeps,
  selectDefaultActiveCategory,
  selectFilteredSweeps,
  selectSweepstakesCategories,
  selectOrderDetails,
} from 'redux/sweeps';

import { storage } from 'services/storage';

export const useSweepstakes = () => {
  const [activeCategory, setActiveCategory] = useState('');
  const dispatch = useDispatch();
  const { isLoading, sweepstakes, isLoaded } = useSelector(selectSweeps);
  const sweepstakesCategories = useSelector(selectSweepstakesCategories);
  const defaultActiveCategory = useSelector(selectDefaultActiveCategory);
  const filteredSweeps = useSelector(selectFilteredSweeps(activeCategory));

  const savedActiveCategory = storage.local.get('activeCategory');

  const handleActiveCatagory = useCallback((key) => {
    storage.local.set('activeCategory', key);
    setActiveCategory(key);
  }, []);

  useEffect(() => {
    if (!isLoading && !sweepstakes.length && !isLoaded) {
      dispatch(fetchSweeps());
    }
  }, [dispatch, isLoading, sweepstakes, isLoaded]);

  useEffect(() => {
    const activeCategoryToUse = savedActiveCategory || defaultActiveCategory;
    setActiveCategory(activeCategoryToUse);
  }, [setActiveCategory, defaultActiveCategory, savedActiveCategory]);

  return {
    activeCategory,
    isLoading,
    sweepstakesCategories,
    filteredSweeps,
    handleActiveCatagory,
  };
};

export const useSweepOrderInfo = () => {
  const { orderDetails } = useSelector(selectOrderDetails);

  return {
    announcementDate: orderDetails?.event?.winner_announcement_date,
    amount: orderDetails?.number_of_entries,
  };
};
