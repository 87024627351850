import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import merge from 'lodash/merge';
import base from './base.theme';

const wmgTheme = createMuiTheme(
  merge({}, base, {
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: '#015BB0',
          color: 'white',
        },
        root: {
          textTransform: 'capitalize',
        },
        contained: {
          textTransform: 'none',
          backgroundColor: '#F9F9F9',
          '&:hover': {
            backgroundColor: 'rgba(224,224,224, .8)',
            '@media (hover: none)': {
              backgroundColor: '#F9F9F9',
            },
          },
        },
        containedSizeLarge: {
          fontSize: '1rem',
          lineHeight: '20px',
          paddingTop: 16,
          paddingBottom: 16,
        },
      },
      MuiDivider: {
        light: {
          backgroundColor: 'rgb(132, 142, 151)',
        },
        vertical: {
          marginTop: '8px',
          marginBottom: '8px',
        },
      },
    },
    palette: {
      primary: {
        main: '#015BB0',
      },
      secondary: {
        main: '#015BB0',
      },
      accent: {
        main: '#015BB0',
        secondary: '#E2E2E2',
      },
      accentLight: {
        main: 'rgb(1, 91, 176, 0.3)',
      },
    },
  })
);

export default responsiveFontSizes(wmgTheme);
