import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  accordion: {
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  accordionSummary: {
    minHeight: 16,
    '&.Mui-expanded': {
      minHeight: 16,
    },
  },
  accordionSummaryContent: {
    justifyContent: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    wordBreak: 'break-word',
    textAlign: 'left',
    padding: theme.spacing(0, 2, 2, 2),
  },
}));
