import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import merge from 'lodash/merge';

import UberMoveRegular from 'assets/fonts/UberMoveMedium.otf';
// import UberMoveBold from 'assets/fonts/UberMoveBold.otf';

import base from './base.theme';

// TODO variant body1 should be 14px as defined in figma in responsive units

// TODO - configure bold font
const UberMove = {
  fontFamily: 'UberMove',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('UberMove'),
    local('UberMove-Regular'),
    url(${UberMoveRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const uberTheme = createMuiTheme(
  merge({}, base, {
    typography: {
      fontFamily: 'UberMove, Arial',
      fontSize: 10.5,
      color: '#FFFFFF',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [UberMove],
        },
      },
    },
    palette: {
      primary: {
        main: 'rgb(0,0,0)', // #000000
      },
      secondary: {
        main: 'rgb(5,163,87)', // #05A357
      },
      accent: {
        main: '#05A357',
        secondary: '#E2E2E2',
      },
      accentLight: {
        main: 'rgba(5, 163, 87, 0.3)',
      },
      grey: {
        main: 'rgb(224,224,224)', // #E0E0E0
        withOpacity: 'rgba(224,224,224, .8)',
        secondary: '#D0CCD0',
        third: '#F9F9F9',
      },
      greyDark: {
        main: '#757575',
        secondary: '#545454',
      },
      border: {
        main: 'rgba(16,19,20,0.1)', // #101314 + opacity 0.12
      },
      dark: {
        main: '#000000',
        secondary: '#101314',
        withOpacity: 'rgba(0, 0, 0, 0.6)',
      },
      selection: {
        main: 'rgba(255,255,255,0.3)',
      },
    },
  })
);

export default responsiveFontSizes(uberTheme);
