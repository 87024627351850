import { PATHS } from '../navigation';

const sweepsRoute = {
  name: 'Sweepstakes',
  path: PATHS.sweepstakes,
  'data-cy': 'sweepstakes-link',
};

const winnerRoute = {
  name: 'Winners',
  path: PATHS.sweepstakes,
};

const newMusicRoute = {
  name: 'New Music',
  link:
    'https://music.wmgrewards.com/?utm_source=mtr_wmg&utm_medium=web&utm_content=menu_link',
};

export const warnerStoreRoute = {
  name: 'Shop the Warner Store',
  link:
    'https://store.warnermusic.com//?utm_source=mtr_wmg&utm_medium=web&utm_content=menu_link',
};

const supportRoute = { name: 'Support', path: PATHS.support };

export const getRoutesList = ({ isGamesPageEnabled }) => {
  const gamesRoute = isGamesPageEnabled && { name: 'Games', path: PATHS.games };

  const loggedOutRoutesList = [
    sweepsRoute,
    gamesRoute,
    winnerRoute,
    newMusicRoute,
    warnerStoreRoute,
    supportRoute,
  ].filter(Boolean);

  const loggedInRoutesList = [
    {
      name: 'Get More Tokens',
      path: PATHS.moreTokens,
      'data-cy': 'get-more-tokens-link',
    },
    sweepsRoute,
    gamesRoute,
    winnerRoute,
    { name: 'Activity', path: PATHS.activity },
    newMusicRoute,
    warnerStoreRoute,
    supportRoute,
  ].filter(Boolean);

  return {
    loggedOutRoutesList,
    loggedInRoutesList,
  };
};
