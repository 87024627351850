import React from 'react';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { Link as MLink } from '@material-ui/core';

import useStyle from './styles';

export const Title = ({ children, centered, variant = 'h6', className }) => {
  const classes = useStyle();

  return (
    <Typography
      variant={variant}
      className={clsx(classes.title, className, {
        [classes.centered]: centered,
      })}
    >
      {children}
    </Typography>
  );
};

export const Text = ({ children, bold, className, centered }) => {
  const classes = useStyle();

  return (
    <Typography
      className={clsx(classes.text, className, {
        [classes.bold]: bold,
        [classes.centered]: centered,
      })}
    >
      {children}
    </Typography>
  );
};

export const StyledText = ({ children, bold, underline, className }) => {
  const classes = useStyle();

  return (
    <span
      className={clsx(classes.text, className, {
        [classes.bold]: bold,
        [classes.underline]: underline,
      })}
    >
      {children}
    </span>
  );
};

export const Link = ({ children, href, className }) => {
  const classes = useStyle();

  return (
    <MLink
      color="inherit"
      className={clsx(classes.underline, className)}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </MLink>
  );
};
