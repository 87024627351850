import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link as MLink } from '@material-ui/core';

import { PATHS } from 'constants/navigation';
import { storage } from 'services/storage';
import { ReactComponent as Checkmark } from 'assets/images/icons/checkmark-icon.svg';

import Modal from '../Modal';
import useStyles from './styles';

const rules = [
  'Your account gets deducted 1 token each time you hit “BID NOW”',
  'Placing a bid adds $.01 to the price + time to the Live Sweeps clock, giving other bidders the chance to decide if they want to out-bid you.',
  'You win a Live Sweep by being the last person to bid when the clock expires.',
  'Winning means you can purchase the item for the winning bid price, typically a fraction of the retail cost!',
];

const LiveBidsIntroModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [showIntroModal, changeShowIntroModal] = useState(open);

  useEffect(() => {
    changeShowIntroModal(open);
  }, [open]);

  useEffect(() => {
    if (!storage.local.get('liveBidsIntoShowed')) {
      setTimeout(() => {
        changeShowIntroModal(true);
      }, 3000);
    }
  }, []);

  const closeModal = () => {
    changeShowIntroModal(false);
    onClose();
    storage.local.set('liveBidsIntoShowed', true);
  };

  const handleMoreTokens = () => {
    closeModal();
    dispatch(push(PATHS.moreTokens));
  };

  return (
    <Modal
      open={showIntroModal}
      toggleModal={closeModal}
      disableBackdropClick
      showClose={open}
    >
      <Typography variant="h5" className={classes.title}>
        How it works
      </Typography>
      <Typography component="div" className={classes.sutTitle}>
        Welcome to Live Sweepstakes! Please read the following tips carefully
        before you begin.
      </Typography>
      {rules.map((rule) => (
        <div key={rule} className={classes.rule}>
          <Checkmark className={classes.ruleIcon} />
          <Typography component="div">{rule}</Typography>
        </div>
      ))}
      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        size="large"
        onClick={handleMoreTokens}
      >
        Get Tokens Now
      </Button>
      {!open && (
        <MLink className={classes.skipLink} to="#" onClick={closeModal}>
          No thanks, I’m ready to bid
        </MLink>
      )}
    </Modal>
  );
};

export default LiveBidsIntroModal;
