import React from 'react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { selectCmsItem } from 'redux/cms';
import Modal from 'components/Modal';
import { useStyles } from './styles';

const DisclaimerPopup = ({ isOpened, closeModal }) => {
  const classes = useStyles();
  const popupData = useSelector((state) =>
    selectCmsItem(state, { key: 'giftcardsDisclaimer' })
  );

  return (
    <Modal open={isOpened}>
      <div className={classes.wrapper}>
        <Typography variant="h4" className={classes.title}>
          {popupData?.data?.heading}
        </Typography>
        <Typography variant="h5" className={classes.subTitle}>
          {popupData?.html?.length > 0 && parse(popupData.html)}
        </Typography>
        <Button
          size="large"
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={closeModal}
        >
          {popupData?.data?.button}
        </Button>
      </div>
    </Modal>
  );
};

export default DisclaimerPopup;
