import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  scratchRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: '30px',
  },

  scratchCard: {
    width: '375px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  scratchCardDescription: {
    width: '80%',
  },

  scratchTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '16px',
  },

  scratchRules: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '20px',
  },

  scratchContainerGame: {
    width: '350px',
    marginTop: '20px',
  },

  scratchInfoGameFooter: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '20px',
    color: '#848E97',
    margin: '20px 0',
  },

  rulesLink: {
    textDecoration: 'underline',
  },
}));
