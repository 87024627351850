import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  takeLeading,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { storage } from 'services/storage';
import { setFBPixelEvent } from 'services/analytics';
import {
  loginUserRequest,
  logoutUserRequest,
  signupUserRequest,
  updateUserRequest,
  getAccountRequest,
  forgotPasswordRequest,
  resetPasswordConfirmRequest,
  completeOnboardingRequest,
  facebookLoginRequest,
  spotifyLoginRequest,
  upholdLoginRequest,
  verifyPhoneNumberRequest,
  resendCodeRequest,
  spotifyConnectRequest,
} from 'services/api/user';
import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from 'constants/config';
import { getPartner } from 'utils/config';

import { selectConfigProperty } from 'redux/config';
import { getWallet } from 'redux/wallet';
import { clearCouponData } from 'redux/coupon';

import {
  setUserData,
  setLoadingStart,
  setLoadingEnd,
  signupUser,
  signupWithOnboarding,
  logoutUser,
  getUserAccount,
  facebookLogin,
  spotifyLogin,
  upholdLogin,
  loginUser,
  updateUser,
  setError,
  forgotPassword,
  forgotPasswordSuccess,
  updateUserProfile,
  resetPasswordConfirm,
  resetPasswordConfirmSuccess,
  verifyPhone,
  resendCode,
  resendCodeSuccess,
  resendCodeFailure,
  spotifyConnect,
  spotifyConnectError,
} from './slice';
import { handleRedirect, handleReferrer, clearCoupon } from '../utils';

function* getUserAccountSaga() {
  yield put(setLoadingStart());
  try {
    const { data } = yield call(getAccountRequest);
    yield call(storeUserInfo, data.user);
  } catch (error) {
    yield put(setError(error.data.message));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _loginUser({ payload }) {
  yield put(setLoadingStart());
  const { email, password } = payload;
  try {
    const { data } = yield call(loginUserRequest, { email, password });

    yield call(clearCoupon);
    yield call(handleLogin, {
      ...data,
      redirectUri: payload.redirectUri,
      couponCode: payload.coupon_code,
    });
  } catch (error) {
    const { errors } = error.data;
    yield put(setError(errors[0]));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* handleFacebookLogin({
  payload: { accessToken, type, source, ...rest },
}) {
  yield put(setLoadingStart());
  const facebookRedirectUri = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.facebookRedirectUri)
  );
  const facebookApiVersion = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.facebookApiVersion)
  );
  try {
    const { data } = yield call(facebookLoginRequest, {
      fb_access_token: accessToken,
      redirect_uri: facebookRedirectUri,
      version: facebookApiVersion,
      code: type,
      source,
      ...rest,
    });
    const { user } = data;

    yield call(_completedOnboardingEvents, user);
    yield call(handleLogin, data);
  } catch (error) {
    yield put(setError(error?.data?.message));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* handleSpotifyLogin({ payload }) {
  yield put(setLoadingStart());

  try {
    const { data } = yield call(spotifyLoginRequest, {
      authorization_code: payload.code,
      redirect_uri: payload.redirectUri,
    });
    yield call(handleLogin, data);
    localStorage.setItem('isSpotifyLogin', 'true');
  } catch (error) {
    yield put(setError(error?.data?.message));
    yield put(push(PATHS.login));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* handleSpotifyConnect({ payload }) {
  yield put(setLoadingStart());
  const successStatuses = [200, 201, 202, 203, 204, 205, 206];
  try {
    const response = yield call(spotifyConnectRequest, {
      authorization_code: payload.code,
      redirect_uri: payload.redirectUri,
    });
    if (response.ok && successStatuses.includes(response.status)) {
      localStorage.setItem('isSpotifyLogin', 'true');
    } else {
      const data = {
        title: response.data?.message,
        description: response.data?.errors,
      };
      yield put(spotifyConnectError(data));
    }
    if (localStorage.getItem('follow') === 'artist') {
      yield put(push(PATHS.followArtists));
    } else {
      yield put(push(PATHS.followTracks));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setLoadingEnd());
  }
}

function* handleUpholdLogin({ payload }) {
  yield put(setLoadingStart());

  try {
    const { data } = yield call(upholdLoginRequest, {
      code: payload.code,
      sign_up_experience: 'brave',
    });
    yield call(handleLogin, data);
  } catch (error) {
    yield put(setError(error?.data?.message));
    yield put(push(PATHS.login));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _signupUser({ payload }) {
  yield put(setLoadingStart());
  try {
    const { data } = yield call(signupUserRequest, {
      ...{
        first_name: 'Uber',
        last_name: 'Member',
      },
      ...payload,
    });

    yield call(storeUserInfo, data.user);
    yield call(completeOnboardingRequest);
    yield call(handleNavigation);
    yield call(clearCoupon);
    yield put(clearCouponData());
    localStorage.removeItem('formattedPhoneNumber');
  } catch (error) {
    yield put(setError(error.data.message));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _signupUserWithOnboarding({ payload }) {
  yield put(setLoadingStart());
  try {
    const { data } = yield call(signupUserRequest, {
      ...{
        first_name: 'Warner',
        last_name: 'Member',
      },
      ...payload,
    });

    yield call(storeUserInfo, data.user);
    localStorage.removeItem('formattedPhoneNumber');
  } catch (error) {
    yield put(setError(error?.data?.message));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _updateUserProfile({ payload }) {
  const { firstName, lastName, email } = payload;
  yield put(setLoadingStart());
  try {
    const { data } = yield call(updateUserRequest, {
      first_name: firstName,
      last_name: lastName,
      email,
    });
    yield call(storeUserInfo, data.user);
    yield call(_completedOnboardingEvents, data.user);
  } catch (error) {
    const errors = error?.data?.errors;
    yield put(setError(errors && errors.length ? errors[0] : 'System error.'));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _updateUser({ payload }) {
  yield put(setLoadingStart());
  try {
    const { data } = yield call(updateUserRequest, {
      ...payload,
    });
    yield call(storeUserInfo, data.user);
    yield call(_completedOnboardingEvents, data.user);
    if (data.user.completed_onboarding) {
      yield put(getWallet());
    }
  } catch (error) {
    yield put(setError(error?.data?.errors[0]));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _verifyPhone({ payload }) {
  yield put(setLoadingStart());
  try {
    yield call(verifyPhoneNumberRequest, payload);
    yield call(getUserAccountSaga);
    localStorage.removeItem('formattedPhoneNumber');
  } catch (error) {
    yield put(setError('Invalid code, please try again.'));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* _resendCode() {
  try {
    const { data } = yield call(resendCodeRequest);
    yield put(setUserData({ ...data.user }));
    yield put(resendCodeSuccess());
  } catch (error) {
    yield put(resendCodeFailure(error?.data?.message));
  }
}

function* handleLogin({ user, redirectUri, couponCode }) {
  yield call(storeUserInfo, user);

  if (!user.completed_onboarding) {
    if (getPartner().name === 'wmg') {
      yield put(push({ pathname: PATHS.onboarding }));
      return;
    }
    yield call(completeOnboardingRequest);
  }

  const location = yield select((state) => state.router.location);
  if (couponCode) {
    yield put(push({ pathname: PATHS.exchange, search: location.search }));
  } else {
    yield call(
      handleNavigation,
      redirectUri,
      PATHS.sweepstakes,
      location.search
    );
  }
}

function* storeUserInfo(user) {
  yield put(setUserData({ ...user }));
  yield call(setLoggedIn, Boolean(user));
}

function* setLoggedIn(isLoggedIn) {
  yield call(storage.local.set, 'isLoggedIn', isLoggedIn);
}

function* handleLogout() {
  const logoutRedirectUrl = yield select(
    selectConfigProperty(CONFIG_KEYS_MAP.logoutRedirectUrl)
  );
  try {
    yield call(logoutUserRequest);
    localStorage.setItem('isSpotifyLogin', 'false');
    localStorage.removeItem('loginPage');
  } catch (e) {
    console.log(e);
  }
  // jackpotInfoDate and cachedConfig should be saved after logout
  const jackpotInfoDate = storage.local.get('jackpotInfoDate');
  const cachedConfig = storage.local.get('cachedConfig');
  yield call(storage.local.clear);
  yield call(storage.local.set, 'jackpotInfoDate', jackpotInfoDate);
  yield call(storage.local.set, 'cachedConfig', cachedConfig);

  window.location.href = logoutRedirectUrl || window.location.origin;
}

function* handleForgotPassword({ payload }) {
  yield put(setLoadingStart());
  try {
    yield call(forgotPasswordRequest, payload);
    yield put(forgotPasswordSuccess());
  } catch (error) {
    yield put(
      setError(
        error.status === 422
          ? 'Invalid email, please try again.'
          : error.data.message
      )
    );
  } finally {
    yield put(setLoadingEnd());
  }
}

function* handleResetPasswordConfirm({ payload }) {
  yield put(setLoadingStart());
  try {
    yield call(resetPasswordConfirmRequest, payload);
    yield put(resetPasswordConfirmSuccess());
  } catch (error) {
    yield put(setError(error.data.message));
  } finally {
    yield put(setLoadingEnd());
  }
}

function* handleNavigation(redirectUri, localUrl, search) {
  const referrer = yield call(storage.local.get, 'referrer');
  const experience = yield call(storage.local.get, 'experience');
  if (redirectUri) {
    yield call(handleRedirect, redirectUri);
  } else if (referrer) {
    yield call(handleReferrer, referrer);
  } else if (localUrl) {
    yield put(push({ pathname: `${localUrl}`, search }));
  } else if (experience) {
    yield put(push({ pathname: `/${experience.toLowerCase()}`, search }));
  } else {
    yield put(push({ pathname: PATHS.sweepstakes, search }));
  }
}

function* _completedOnboardingEvents(user) {
  const { completed_onboarding, email_opt_in } = user;

  if (completed_onboarding) {
    if (email_opt_in) {
      yield call(setFBPixelEvent, 'email_opt_in');
    }
    yield call(setFBPixelEvent, 'complete_registration');
  }
}

export function* userSaga() {
  yield takeLatest(signupUser, _signupUser);
  yield takeLatest(loginUser, _loginUser);
  yield takeLatest(facebookLogin, handleFacebookLogin);
  yield takeLeading(spotifyLogin, handleSpotifyLogin);
  yield takeLeading(spotifyConnect, handleSpotifyConnect);
  yield takeLeading(upholdLogin, handleUpholdLogin);
  yield takeLatest(updateUserProfile, _updateUserProfile);
  yield takeLatest(updateUser, _updateUser);
  yield takeLatest(getUserAccount, getUserAccountSaga);
  yield takeLeading(logoutUser, handleLogout);
  yield takeEvery(forgotPassword, handleForgotPassword);
  yield takeEvery(resetPasswordConfirm, handleResetPasswordConfirm);
  yield takeLatest(signupWithOnboarding, _signupUserWithOnboarding);
  yield takeLeading(verifyPhone, _verifyPhone);
  yield takeLeading(resendCode, _resendCode);
}
