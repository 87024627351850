import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4, 1, 1),
  },
  input: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: theme.spacing(3),
  },
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  poweredBy: {
    maxHeight: '30px',
    marginTop: theme.spacing(1),
  },
  list: {
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  listHeader: {
    maxWidth: '300px',
    margin: '0 auto',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5),
  },
  listIcon: {
    minWidth: 'initial',
    marginRight: theme.spacing(1),
    color: theme.palette.accent.main,
  },
  listText: {
    fontSize: '1rem',
  },
  link: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
  submitBtn: {
    width: '100%',
    maxWidth: '300px',
    textTransform: 'initial',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  hintContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 18,
  },
  hint: {
    color: theme.palette.accent.main,
    fontWeight: 'bold',
  },
}));
