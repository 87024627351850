import React, { useCallback } from 'react';
import parse from 'html-react-parser';

import Typography from '@material-ui/core/Typography';
import { Link as MLink } from '@material-ui/core';

import { useStyles } from './styles';

const ErrorMessage = ({ error, ...rest }) => {
  const styles = useStyles();
  const handleSupportButtonClick = useCallback(() => {
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open');
    });
  }, []);

  if (!error) return null;

  return (
    <Typography variant="h6" color="error" {...rest}>
      <span className={styles.text}>{parse(error)}</span>
      {!rest?.noContactUs && (
        <span>
          {` Please `}
          <MLink
            href="#"
            color="error"
            underline="always"
            onClick={handleSupportButtonClick}
          >
            contact Support
          </MLink>{' '}
          if you feel this message is in error.
        </span>
      )}
    </Typography>
  );
};

export default ErrorMessage;
