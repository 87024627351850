import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  postAuctionUsername,
  resetAuthError,
  selectAuctionAuthStatus,
} from 'redux/liveBids';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { pennyAuctionUserNameShema } from 'utils/validations';

import Modal from 'components/Modal';

import useStyles from './styles';

const GetStartedPopUp = ({ isOpened, closeModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(pennyAuctionUserNameShema),
  });

  const { authError, isAuthProcessing } = useSelector(selectAuctionAuthStatus);

  const onSubmit = useCallback(
    (data) => {
      dispatch(postAuctionUsername(data));
    },
    [dispatch]
  );

  const resetErrors = useCallback(() => {
    if (authError) {
      dispatch(resetAuthError());
    }
  }, [dispatch, authError]);

  const handleChange = useCallback(
    (value, callback) => {
      resetErrors();
      callback(value);
    },
    [resetErrors]
  );

  const toggleModal = useCallback(() => {
    resetErrors();
    closeModal();
  }, [resetErrors, closeModal]);

  const displayedError = errors?.name?.message || authError;

  return (
    <Modal open={isOpened} toggleModal={toggleModal} showClose>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.wrapper}
      >
        <Typography variant="h5" className={classes.title}>
          Get Started
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          You’re almost ready to start bidding! First, what should we call you?
          This name will be displayed so other users will know when you’re the
          highest bidder.
        </Typography>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ onChange, ref }) => (
            <TextField
              name="name"
              variant="outlined"
              fullWidth
              placeholder="Your Name"
              size="small"
              error={!!displayedError}
              helperText={displayedError}
              inputRef={ref}
              onChange={({ target: { value } }) => {
                handleChange(value, onChange);
              }}
            />
          )}
        />
        <Button
          type="submit"
          size="large"
          color="secondary"
          variant="contained"
          className={classes.button}
          disabled={!!displayedError || isAuthProcessing}
        >
          {isAuthProcessing ? <CircularProgress size={20} /> : "Let's go"}
        </Button>
      </form>
    </Modal>
  );
};

export default GetStartedPopUp;
