import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import clsx from 'clsx';

import { selectDefaultEntryOption, selectEntryOptionsItems } from 'redux/cms';

import { clearEnterSweepsErrors } from 'redux/sweeps';

import { selectConfigProperty } from 'redux/config';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { PATHS } from 'constants/navigation';
import {
  POINTS_PER_SWEEP_ENTRY,
  conversionPointsAsTokens,
} from 'utils/currencyConversions';

import Checkboxes from '../Checkboxes';
import OtherAmountInput from '../OtherAmountInput';

import useStyles from './styles';

const defaultAllOption = { value: 'ALL' };

const LoggedInContent = ({
  sweepstake,
  sendingEntriesError,
  tokenAmount,
  pointsAmount,
  event,
  onEnterHandleClick,
  isEntriesSending,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultEntryOption = useSelector(selectDefaultEntryOption);
  const entryOptionsItems = useSelector(selectEntryOptionsItems);
  const isShowOtherAmount = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.showOtherAmount)
  );

  const [activeOption, setActiveOption] = useState(defaultAllOption);
  const [otherAmountValue, setOtherAmountValue] = useState('');
  const [rulesChecked, setRulesChecked] = useState(true);
  const [newsChecked, setNewsChecked] = useState(false);

  const totalAmount = pointsAmount
    ? tokenAmount + conversionPointsAsTokens(pointsAmount)
    : tokenAmount;
  const isNoTokens = totalAmount < 1;

  useEffect(() => {
    if (defaultEntryOption && defaultEntryOption?.value <= totalAmount) {
      setActiveOption(defaultEntryOption);
    } else {
      setActiveOption(defaultAllOption);
    }
  }, [defaultEntryOption, totalAmount]);

  const handleOptionBtnClick = useCallback(
    (item) => {
      setActiveOption(item);
      setOtherAmountValue('');
      if (sendingEntriesError) {
        dispatch(clearEnterSweepsErrors());
      }
    },
    [sendingEntriesError, dispatch]
  );

  const handleInputFocus = useCallback((e) => {
    e.preventDefault();
    setActiveOption('');
  }, []);

  const handleInputBlur = useCallback(
    (e) => {
      e.preventDefault();
      if (otherAmountValue) {
        setActiveOption({ value: Number(otherAmountValue) });
      }
    },
    [otherAmountValue]
  );

  const handleOtherAmountValueChange = useCallback(
    ({ target: { value } }) => {
      if (value !== '' && (Number(value) < 1 || Number.isNaN(+value))) {
        return;
      }
      setOtherAmountValue(value);
    },
    [setOtherAmountValue]
  );

  const handleMoreTokens = () => {
    dispatch(push(PATHS.moreTokens));
  };

  return (
    <>
      <Typography variant="h6" className={classes.pickOptionTitle}>
        How many chances to win would you like?
      </Typography>
      <Typography className={classes.pickOptionDesc} data-cy="options-decs">
        {pointsAmount
          ? `1 token or ${POINTS_PER_SWEEP_ENTRY} points = 1 chance to win. You have ${pointsAmount} points available.`
          : '1 token = 1 chance to win.'}
      </Typography>
      <div className={classes.optionBtnContainer}>
        {[...entryOptionsItems, defaultAllOption].map((item) => (
          <Button
            key={item.value}
            className={clsx(classes.optionBtn, {
              [classes.activeOptionBtn]: activeOption.value === item.value,
            })}
            variant="contained"
            onClick={() => handleOptionBtnClick(item)}
            size="large"
            disabled={item.value > totalAmount}
            data-cy="option-button"
          >
            {item.value}
          </Button>
        ))}
      </div>
      {isShowOtherAmount && (
        <OtherAmountInput
          isNoTokens={isNoTokens}
          otherAmountValue={otherAmountValue}
          handleInputBlur={handleInputBlur}
          handleInputFocus={handleInputFocus}
          handleOtherAmountValueChange={handleOtherAmountValueChange}
        />
      )}
      <div className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          className={classes.codeTokensBtn}
          disableElevation
          onClick={handleMoreTokens}
        >
          Get More Tokens
        </Button>
        <Button
          className={classes.enterNowBtn}
          variant="contained"
          disableElevation
          disabled={!rulesChecked || isEntriesSending || isNoTokens}
          onClick={() =>
            onEnterHandleClick({ value: activeOption.value, newsChecked })
          }
          size="large"
          color="primary"
          data-cy="sweep-enter-button"
        >
          {isEntriesSending ? <CircularProgress size={20} /> : 'Enter Now'}
        </Button>
        <Typography className={classes.errorMessage} data-cy="sweep-error">
          {!rulesChecked
            ? 'You must agree to the Official Rules to proceed. \n'
            : sendingEntriesError || ''}
        </Typography>
        <Checkboxes
          sweepstake={sweepstake}
          event={event}
          rulesChecked={rulesChecked}
          setRulesChecked={setRulesChecked}
          newsChecked={newsChecked}
          setNewsChecked={setNewsChecked}
        />
      </div>
    </>
  );
};

export default LoggedInContent;
