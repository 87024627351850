import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    maxWidth: 300,
    wordBreak: 'break-word',
  },
  description: {
    marginBottom: theme.spacing(4),
    maxWidth: '18.75rem',
    whiteSpace: 'pre-line',
  },
  rulesContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
  },
  rulesMessageLinks: {
    textDecoration: 'underline',
    display: 'inline-block',
  },
  presentedByImage: {
    maxHeight: '2.7rem',
    marginBottom: theme.spacing(4),
  },
  imgWrapper: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    borderRadius: 10,
    width: '100%',
    maxWidth: 300,
    maxHeight: 300,
  },
  image: {
    width: '100%',
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    maxWidth: '18.75rem',
  },
  eventDate: {
    fontWeight: 'bold',
  },
  rulesLink: {
    marginBottom: theme.spacing(4),
  },
  readMore: {
    border: '0',
    margin: '0 auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
    },
  },
  entryPeriod: {
    marginBottom: theme.spacing(3),
    '& > span': {
      fontWeight: 'bold',
    },
  },
}));
