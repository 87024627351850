import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  circle: {
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    width: 6,
    height: 6,
    marginLeft: theme.spacing(0.5),
  },
}));
