import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';

export const selectWallet = createSelector(
  [(state) => _get(state, 'wallet', { isLoading: false, isLoaded: false })],
  (wallet) => wallet
);

export const selectShouldFetchWallet = createSelector(
  [selectWallet],
  ({ isLoading, isLoaded }) => Boolean(!isLoading && !isLoaded)
);

export const selectActivity = (sweepId, eventId) =>
  createSelector(
    [selectWallet],
    ({ activities }) =>
      activities.find((item) => {
        return (
          item.productType.toLowerCase().includes('sweepstake') &&
          item.sweepstakeId.toString() === sweepId &&
          item.eventId.toString() === eventId
        );
      }) || {}
  );

export const selectWalletInfo = (name) =>
  createSelector([selectWallet], (wallet) => wallet.data[name] || {});

export const selectTokensAmount = (name) =>
  createSelector([selectWalletInfo(name)], (data) => +(data?.amount || 0));

export const selectActivities = createSelector(
  [selectWallet],
  ({ activities, isLoadingActivities, isActivityLoaded, activitiesMeta }) => ({
    activities,
    meta: activitiesMeta,
    isLoadingActivities,
    isActivityLoaded,
  })
);

export const selectNumberOfAvailableSpins = createSelector(
  [selectTokensAmount('spn'), selectTokensAmount('dspn')],
  (spins, dailySpins) => spins + dailySpins
);

export const selectTapCurrencyAvailability = createSelector(
  [selectWallet],
  (wallet) => _reduce(wallet.data, (a, c) => a + +c.amount, 0) > 0
);

export const selectAvailableCards = createSelector([selectWallet], (wallet) =>
  wallet.cards.slice().sort((a, b) => +b.balance - +a.balance)
);

export const selectIsAccountVerified = createSelector(
  [selectWallet],
  (wallet) => wallet.isAccountVerified
);
