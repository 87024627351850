// import Cookies from 'universal-cookie';
// import { cookieOptions } from 'utils/config';

// const cookies = new Cookies();
// console.log(cookies);

export const storage = {
  local: {
    get: (key) => localStorage.getItem(key),
    set: (key, value = '') => localStorage.setItem(key, value),
    removeItem: (key) => localStorage.removeItem(key),
    clear: () => localStorage.clear(),
  },
  // cookies: {
  //   get: (key = '') => cookies.get(key),
  //   set: (key = '', data = '') => cookies.set(key, data, cookieOptions),
  //   getAll: () => cookies.getAll(),
  //   remove: (key = '') => cookies.remove(key, cookieOptions),
  // },
};

export const csrfToken = (cookies) => {
  console.log(cookies);
  const splitCookies = cookies.split('; ');
  const temp = splitCookies
    .find((cookie) => cookie.startsWith('CSRF-TOKEN='))
    .split('=')[1];
  console.log(temp);
  return temp;
};
