import { fork, all } from 'redux-saga/effects';

import { configSaga } from './config';
import { adgemSaga } from './adgem';
import { analyticsSaga } from './analytics';
import { charitiesSaga } from './charities';
import { cmsSaga } from './cms';
import { winnersSaga } from './winners';
import { walletSaga } from './wallet';
import { spinnerSaga } from './spinner';
import { giftCardsSaga } from './giftCards';
import { sweepsSaga } from './sweeps';
import { followArtistsSaga } from './follow/artists';
import { followTracksSaga } from './follow/tracks';
import { liveBidsSaga } from './liveBids';
import { couponSaga } from './coupon';
import { userSaga } from './user';
import { scratchGameSaga } from './scratchGame';

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(cmsSaga),
    fork(sweepsSaga),
    fork(followArtistsSaga),
    fork(followTracksSaga),
    fork(winnersSaga),
    fork(couponSaga),
    fork(walletSaga),
    fork(analyticsSaga),
    fork(spinnerSaga),
    fork(configSaga),
    fork(adgemSaga),
    fork(charitiesSaga),
    fork(giftCardsSaga),
    fork(liveBidsSaga),
    fork(scratchGameSaga),
  ]);
}
