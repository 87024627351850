import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontWeight: 'bold',
    color: theme.palette.accent.main,
    marginBottom: theme.spacing(2),
  },
  couponError: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    margin: theme.spacing(8, 0),
  },
  modalButton: {
    margin: theme.spacing(1, 0),
  },
  img: {
    width: '100%',
    height: '100%',
    maxHeight: 240,
    maxWidth: 240,
  },
  imgContainer: {
    margin: theme.spacing(4, 0),
  },
}));
