import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import format from 'date-fns/format';
import KeyValueText from '../KeyValueText';

import { useStyles } from './styles';

const WinnerCard = ({
  name,
  profile_image,
  winner_announcement_date,
  winners,
  entry_period_end,
  event_location,
}) => {
  const classes = useStyles();

  const getWinnerData = useCallback(() => {
    if (winners.length) {
      return winners.map((winner) => (
        <KeyValueText
          boldKey
          keyProp="Winner: "
          value={winner}
          key={winner}
          className={classes.winner}
        />
      ));
    }
    if (winner_announcement_date) {
      return (
        <KeyValueText
          boldKey
          keyProp="Winner(s): "
          value={format(new Date(winner_announcement_date), 'MM/d/yy')}
        />
      );
    }
    return <span className={classes.text}>Winner coming soon!</span>;
  }, [winners, winner_announcement_date, classes.winner, classes.text]);

  return (
    <Card className={classes.root}>
      <Grid container justify="space-between">
        {profile_image && (
          <Grid item>
            <Avatar className={classes.avatar} alt={name} src={profile_image} />
          </Grid>
        )}
        <Grid
          item
          container
          direction="column"
          justify="center"
          className={classes.userInfo}
        >
          <Typography variant="subtitle1" className={classes.title}>
            {name}
          </Typography>
          <Grid
            item
            container
            justify="space-between"
            className={classes.event}
          >
            {event_location && (
              <Typography variant="subtitle1">{event_location}</Typography>
            )}
            {entry_period_end && (
              <Typography variant="subtitle1">
                <KeyValueText
                  boldKey
                  keyProp="Entry End: "
                  value={format(new Date(entry_period_end), 'MM/d/yy')}
                />
              </Typography>
            )}
          </Grid>
          <Typography variant="subtitle1" className={classes.winners}>
            {getWinnerData()}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

WinnerCard.defaultProps = {
  name: '',
  profile_image: '',
  winner_announcement_date: '',
  winners: [],
  entry_period_end: '',
  event_location: '',
};

WinnerCard.propTypes = {
  name: PropTypes.string,
  profile_image: PropTypes.string,
  winners: PropTypes.arrayOf(PropTypes.string),
  winner_announcement_date: PropTypes.string,
  entry_period_end: PropTypes.string,
  event_location: PropTypes.string,
};

export default WinnerCard;
