import { getPartner } from 'utils/config';

import { getRoutesList as braveRoutesList } from './brave-menu';
import { getRoutesList as swyRoutesList } from './syw-menu';
import { getRoutesList as uberRoutesList } from './uber-menu';
import { getRoutesList as upholdRoutesList } from './uphold-menu';
import { getRoutesList as wmgRoutesList } from './wmg-menu';

const mapping = {
  brave: braveRoutesList,
  syw: swyRoutesList,
  uber: uberRoutesList,
  uphold: upholdRoutesList,
  wmg: wmgRoutesList,
};

export const getSidebarItems = (
  isChooseExperienceEnabled,
  isGamesPageEnabled
) => {
  return mapping[getPartner().name]({
    isChooseExperienceEnabled,
    isGamesPageEnabled,
  });
};
