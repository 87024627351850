import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    margin: theme.spacing(1.5, 0),
    color: theme.palette.primary.main,
  },

  rule: {
    textAlign: 'center',
    lineHeight: '28px',
    fontSize: '16px',
    margin: '20px 20px 30px 20px',
    display: 'flex',
  },
  button: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.accent.main,
    },
  },
}));
