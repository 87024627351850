import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import config from './config';
import adgem from './adgem';
import charities from './charities';
import cms from './cms';
import winners from './winners';
import wallet from './wallet';
import navigation from './navigation';
import spinner from './spinner';
import giftCards from './giftCards';
import sweeps from './sweeps';
import followArtists from './follow/artists';
import followTracks from './follow/tracks';
import liveBids from './liveBids';
import coupon from './coupon';
import user from './user';
import scratch from './scratchGame';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    cms,
    sweeps,
    wallet,
    followArtists,
    followTracks,
    winners,
    coupon,
    spinner,
    navigation,
    adgem,
    charities,
    giftCards,
    liveBids,
    config,
    scratch,
  });
