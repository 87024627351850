import React from 'react';

import MUIModal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const Modal = ({
  open,
  toggleModal,
  showClose,
  children,
  showBlurredBackground,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MUIModal
      open={open}
      onClose={toggleModal}
      hideBackdrop={!showBlurredBackground}
      BackdropProps={{
        style: {
          top: '56px', // height of the Header
          backdropFilter: 'blur(3px)',
        },
      }}
      style={{
        top: 56, // height of the Header
      }}
      {...rest}
    >
      <div className={classes.modalWrapper}>
        <div className={classes.modal}>
          {showClose && (
            <CloseIcon
              data-cy="modal-close-icon"
              className={classes.closeModal}
              onClick={toggleModal}
            />
          )}
          {children}
        </div>
      </div>
    </MUIModal>
  );
};

Modal.defaultProps = {
  showClose: false,
  showBlurredBackground: true,
};

Modal.propsTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  children: PropTypes.node.isRequired,
  showBlurredBackground: PropTypes.bool,
};

export default Modal;
