import React, { useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import format from 'date-fns/format';
import parse from 'html-react-parser';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { PATHS } from 'constants/navigation';

import { formatFinishSweepDate } from 'utils/formatting';

import useStyles from './styles';

const maxDescriptionLength = 140;

const SweepInfo = ({ sweepstake, event, eventLocationData }) => {
  const classes = useStyles();

  const [readMore, changeReadMore] = useState(false);

  const defaultRulesLink = `${PATHS.sweepstakes}/${sweepstake.id}/${event.id}/rules`;
  const rulesLink = sweepstake.data?.rules?.url
    ? { pathname: sweepstake.data.rules.url }
    : defaultRulesLink;

  const handleReadMore = useCallback(() => changeReadMore(true), []);
  return (
    <>
      <div className={classes.imgWrapper}>
        <img
          src={sweepstake.profile_image.replace(
            'image/upload/v',
            'image/upload/f_jpg,c_limit,h_300,q_auto:good,w_300/v'
          )}
          alt={sweepstake.name}
          className={classes.image}
        />
      </div>
      {sweepstake.presented_by_image && (
        <div>
          <Typography variant="subtitle1">Presented by:</Typography>
          <img
            alt="Presented by"
            src={sweepstake.presented_by_image}
            className={classes.presentedByImage}
          />
        </div>
      )}
      <Typography
        variant="h4"
        className={classes.title}
        data-cy="sweepstake-name"
      >
        {sweepstake.name}
      </Typography>
      <Typography variant="h6" className={classes.entryPeriod}>
        <span>Enter period: </span>
        {format(new Date(event.entry_period_start), 'M/d/yy')} -{' '}
        {formatFinishSweepDate(new Date(event.entry_period_end), {
          timeZone: 'UTC',
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
        })}
      </Typography>
      {sweepstake.sweep_type === 'artist' && (
        <div className={classes.eventContainer}>
          <Typography variant="h6" className={classes.eventDate}>
            {format(new Date(event.event_date), 'MMMM d, y')}
          </Typography>
          <Typography variant="h6">{eventLocationData[0]}</Typography>
          <Typography variant="h6">{eventLocationData[1]}</Typography>
        </div>
      )}
      <Typography variant="h6" className={classes.description}>
        {readMore ? (
          parse(sweepstake.description)
        ) : (
          <>
            {parse(sweepstake.description.substring(0, maxDescriptionLength))}
            ...
            <button
              className={classes.readMore}
              type="button"
              onClick={handleReadMore}
            >
              Read More
            </button>
          </>
        )}
      </Typography>
      <Typography variant="h6" className={classes.rulesLink}>
        <Link
          color="inherit"
          component={RouterLink}
          className={classes.rulesMessageLinks}
          to={rulesLink}
          target="_blank"
        >
          See Official Rules
        </Link>
      </Typography>
    </>
  );
};

export default SweepInfo;
