import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(2.5),
  },
  categoryButton: {
    '&:first-child': {
      marginLeft: theme.spacing(0),
    },
    '&.active': {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.accent.main,
    },
    marginLeft: theme.spacing(1.5),
    borderRadius: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.black.main,
    textTransform: 'capitalize',
    flexShrink: '0',
  },
  fabContainer: {
    position: 'sticky',
    width: '100%',
    flex: 1,
    bottom: 0,
    minHeight: '2rem',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1.5),
    right: '5%',
    '& .MuiFab-primary': {
      backgroundColor: 'unset',
    },
  },
  svg: {
    width: '100%',
    height: '100%',
    '& circle': {
      fill: theme.palette.accent.main,
    },
  },
}));
