import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  subTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  list: {
    marginBottom: theme.spacing(4),
    maxWidth: '300px',
    margin: '0 auto',
  },
  input: {
    width: '100%',
    maxWidth: '300px',
    margin: theme.spacing(1, 0, 2, 0),
  },
  couponImage: {
    width: '100%',
    height: '100%',
    maxHeight: 240,
    maxWidth: 240,
  },
  howGetACode: {
    display: 'block',
    border: '0',
    margin: '0 auto',
    backgroundColor: 'transparent',
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: theme.spacing(2),
    '&:focus': {
      outline: 'none',
    },
  },
  link: {
    display: 'block',
    color: theme.palette.accent.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  submitBtn: {
    width: '100%',
    maxWidth: '300px',
    textTransform: 'initial',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  modalTitle: {
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  howItWorks: {
    padding: theme.spacing(0, 2.5),
    '& ol': {
      padding: 0,
    },
    '& a': {
      color: 'inherit',
    },
  },
  modalButton: {
    margin: theme.spacing(1, 0),
  },
  poweredBy: {
    maxHeight: '30px',
    marginTop: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  errorLink: {
    color: theme.palette.error.main,
    textDecorationLine: 'underline',
  },
  captcha: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
}));
