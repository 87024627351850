import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import mailcheck from 'mailcheck';
import isEmpty from 'lodash/isEmpty';

import {
  clearUserError,
  signupWithOnboarding,
  updateUser,
  selectUser,
  facebookLogin,
} from 'redux/user';

import { onboardingAuthShema, topLevelDomains } from 'utils/validations';

import SpotifyButton from 'components/SpotifyButton';
import FacebookButton from 'components/FacebookButton';
import SignUpPopup from 'components/SignUpPopup';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { PATHS } from 'constants/navigation';
import { getQryParams } from 'utils/url';
import { useCms } from 'hooks/cms';

import useStyles from './styles';

const SignUp = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = getQryParams(location);
  const dispatch = useDispatch();
  const { isLoading, token, email, error, first_name, last_name } = useSelector(
    selectUser
  );

  const [suggestion, setSuggestion] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(onboardingAuthShema),
  });

  const popups = useCms({ key: 'signUpPopups' });

  const toggleModal = useCallback(() => {
    setIsModalOpened((opened) => !opened);
  }, []);

  useEffect(() => {
    if (params.popup && popups && popups.data && params.popup in popups.data) {
      toggleModal();
    }
  }, [params.popup, toggleModal, popups]);

  const onSubmit = (data) => {
    if (token) {
      dispatch(updateUser({ ...data }));
    } else {
      dispatch(
        signupWithOnboarding({
          ...data,
          utm_source: params.utm_source,
          utm_medium: params.utm_medium,
          utm_campaign: params.utm_campaign,
          utm_term: params.utm_term,
          utm_content: params.utm_content,
          source: params.ref_code,
        })
      );
    }
  };

  const clearError = useCallback(() => dispatch(clearUserError()), [dispatch]);

  const checkForTypos = useCallback(
    (value) => {
      const message = mailcheck.run({
        email: value,
        topLevelDomains,
        suggested: (val) => val,
        empty: () => null,
      });
      setSuggestion(message?.full);
    },
    [setSuggestion]
  );

  const getHelperText = useCallback(() => {
    return (
      <span className={classes.hintContainer}>
        <span>{errors.email?.message}</span>
        {suggestion && (
          <span className={classes.hint}>{`Did you mean: ${suggestion}?`}</span>
        )}
      </span>
    );
  }, [suggestion, errors.email, classes]);

  const handleChange = useCallback(
    (name, value, callback) => {
      if (error) {
        clearError();
      }
      callback(value);
      if (name === 'email') {
        checkForTypos(value);
      }
    },
    [checkForTypos, clearError, error]
  );

  const onFacebookLogin = useCallback(
    (response) => {
      const { accessToken } = response;
      if (accessToken) {
        dispatch(
          facebookLogin({
            accessToken,
            type: 'auth',
          })
        );
      }
    },
    [dispatch]
  );

  return (
    <>
      <Typography
        variant="h4"
        className={classes.title}
        data-cy="sign-up-title"
      >
        Let’s get started.
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        You’re just steps away from Rewards!
      </Typography>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="first_name"
                defaultValue={first_name || ''}
                render={({ onChange, ref }) => (
                  <TextField
                    className={classes.input}
                    label="First Name"
                    name="first_name"
                    variant="outlined"
                    inputRef={ref}
                    defaultValue=""
                    helperText={errors.first_name?.message}
                    error={!!errors.first_name?.message}
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value, onChange)
                    }
                    size="small"
                    data-cy="signup_first_name"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="last_name"
                defaultValue={last_name || ''}
                render={({ onChange, ref }) => (
                  <TextField
                    className={classes.input}
                    label="Last Name"
                    name="last_name"
                    variant="outlined"
                    inputRef={ref}
                    defaultValue=""
                    helperText={errors.last_name?.message}
                    error={!!errors.last_name?.message}
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value, onChange)
                    }
                    size="small"
                    data-cy="signup_last_name"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="email"
              defaultValue={email || ''}
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.input}
                  label="Email Address"
                  type="email"
                  name="email"
                  variant="outlined"
                  inputRef={ref}
                  defaultValue=""
                  helperText={getHelperText()}
                  error={!!errors.email?.message}
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value, onChange)
                  }
                  size="small"
                  data-cy="signup_email"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.input}
                  label="Password"
                  variant="outlined"
                  defaultValue=""
                  type="password"
                  name="password"
                  helperText={errors.password?.message}
                  error={!!errors.password?.message}
                  inputRef={ref}
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value, onChange)
                  }
                  size="small"
                  data-cy="signup_password"
                />
              )}
            />
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              disabled={isLoading || !isEmpty(errors)}
              size="large"
              data-cy="signup_submit"
            >
              {isLoading ? <CircularProgress size={20} /> : 'Continue'}
            </Button>
          </Grid>
          <Typography variant="h6" color="error" data-cy="signup_error">
            {error}
          </Typography>
          <div className={classes.divider}>
            <hr />
            <span>Or</span>
            <hr />
          </div>
          <Grid item>
            <FacebookButton
              onBtnClick={onFacebookLogin}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item>
            <SpotifyButton isLoading={isLoading} />
          </Grid>
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name="email_opt_in"
            defaultValue
            render={({ onChange, ref, field }) => (
              <div className={classes.checkboxContainer}>
                <Checkbox
                  {...field}
                  defaultChecked
                  className={classes.checkbox}
                  name="email_opt_in"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkboxIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon className={classes.checkboxIcon} />
                  }
                  inputRef={ref}
                  onChange={({ target: { name, value } }) => {
                    handleChange(name, value, onChange);
                  }}
                />
                <div className={classes.info}>
                  <Typography component="span">
                    Be the first to hear about concerts, album drops, ticket
                    giveaways, special offers and more via email or text
                    message.{' '}
                  </Typography>
                  <Typography className={classes.legal}>
                    By continuing you agree to the{' '}
                    <Link to={PATHS.terms}>Terms</Link> and{' '}
                    <Link to={PATHS.privacy}>Privacy Policy</Link> and confirm
                    that you are at least 18 years old.
                  </Typography>
                </div>
              </div>
            )}
          />
        </Grid>
      </form>
      {isModalOpened && (
        <SignUpPopup
          isModalOpened={isModalOpened}
          toggleModal={toggleModal}
          popupType={params.popup}
        />
      )}
    </>
  );
};

export default SignUp;
