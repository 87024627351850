import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import mailcheck from 'mailcheck';
import isEmpty from 'lodash/isEmpty';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import { Link as MLink } from '@material-ui/core';

import FacebookButton from 'components/FacebookButton';
import { useCoupon } from 'hooks/coupon';
import {
  selectUser,
  signupUser,
  clearUserError,
  facebookLogin,
} from 'redux/user';
import { selectLoginPath } from 'redux/config';
import { authShema, topLevelDomains } from 'utils/validations';
import { getQryParams } from 'utils/url';

import {
  CouponError,
  CouponSuccess,
  ReferralCode,
} from './components/SignupHandlers';

import useStyles from './styles';

const SignupPage = () => {
  const [suggestion, setSuggestion] = useState(null);
  const location = useLocation();
  const params = getQryParams(location);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(authShema),
  });

  const [coupon, couponError] = useCoupon(params.code);
  const { isLoading, error } = useSelector(selectUser);

  const loginPath = useSelector(selectLoginPath);

  const clearError = useCallback(() => dispatch(clearUserError()), [dispatch]);

  const onSubmit = ({ email, password }) => {
    dispatch(
      signupUser({
        email,
        password,
        coupon_code: coupon?.code,
        utm_source: params.utm_source,
        utm_medium: params.utm_medium,
        utm_campaign: params.utm_campaign,
        utm_term: params.utm_term,
        utm_content: params.utm_content,
        source: params.ref_code,
        email_opt_in: true,
      })
    );
  };

  const onFacebookLogin = useCallback(
    (response) => {
      const { accessToken, email } = response;
      if (accessToken) {
        dispatch(
          facebookLogin({
            accessToken,
            type: 'auth',
            email_opt_in: true,
            utm_source: params.utm_source,
            utm_medium: params.utm_medium,
            utm_campaign: params.utm_campaign,
            utm_term: params.utm_term,
            utm_content: params.utm_content,
            source: params.ref_code,
            email,
          })
        );
      }
    },
    [dispatch, params]
  );

  const checkForTypos = useCallback(
    (value) => {
      const message = mailcheck.run({
        email: value,
        topLevelDomains,
        suggested: (val) => val,
        empty: () => null,
      });
      setSuggestion(message?.full);
    },
    [setSuggestion]
  );

  const handleChange = useCallback(
    (name, value, callback) => {
      if (error) {
        clearError();
      }
      callback(value);
      if (name === 'email') {
        checkForTypos(value);
      }
    },
    [checkForTypos, clearError, error]
  );

  const getHelperText = useCallback(() => {
    return (
      <span className={classes.hintContainer}>
        <span>{errors.email?.message}</span>
        {suggestion && (
          <span className={classes.hint}>{`Did you mean: ${suggestion}?`}</span>
        )}
      </span>
    );
  }, [suggestion, errors.email, classes]);

  const handleSupportButtonClick = (e) => {
    e.preventDefault();
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open');
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        {coupon && <CouponSuccess coupon={coupon} />}
        {couponError && <CouponError error={couponError} />}
        {params.ref_code && <ReferralCode />}
        <Typography variant="h6" align="left" className={classes.listHeader}>
          Before we get started, you’ll need to set up a TAP Network account so
          you can:
        </Typography>
        <List className={classes.list}>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: 'h6' }}
              primary="Keep track of your tokens"
            />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: 'h6' }}
              primary="Review your activity"
            />
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: 'h6' }}
              primary="Hear if you’ve won a prize!"
            />
          </ListItem>
        </List>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Controller
                control={control}
                name="email"
                render={({ onChange, ref }) => (
                  <TextField
                    className={classes.input}
                    label="Email Address"
                    type="email"
                    name="email"
                    variant="outlined"
                    inputRef={ref}
                    defaultValue={params.email || ''}
                    disabled={!!params.email}
                    helperText={getHelperText()}
                    error={!!errors.email?.message}
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value, onChange)
                    }
                    data-cy="signup_email"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="password"
                render={({ onChange, ref }) => (
                  <TextField
                    className={classes.input}
                    label="Password"
                    variant="outlined"
                    defaultValue=""
                    type="password"
                    name="password"
                    helperText={errors.password?.message}
                    error={!!errors.password?.message}
                    inputRef={ref}
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value, onChange)
                    }
                    data-cy="signup_password"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FacebookButton
                onBtnClick={onFacebookLogin}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitBtn}
                disabled={isLoading || !isEmpty(errors)}
                size="large"
                data-cy="signup_submit"
              >
                {isLoading && (
                  <div className={classes.spinnerWrapper}>
                    <CircularProgress size={20} />
                  </div>
                )}
                Continue
              </Button>
            </Grid>
            {error.length > 0 && (
              <Typography
                className={classes.error}
                color="error"
                data-cy="signup_error"
              >
                {`${error}. Please `}
                <MLink
                  href="#"
                  className={classes.errorLink}
                  onClick={handleSupportButtonClick}
                >
                  contact Support
                </MLink>
                if you feel this message is in error.
              </Typography>
            )}
            <Typography variant="h6">
              Already a member?{' '}
              <Link
                to={{ ...location, pathname: loginPath }}
                className={classes.link}
                onClick={clearError}
              >
                Log in
              </Link>
            </Typography>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
