import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from '../config';

export default {
  [CONFIG_KEYS_MAP.useVeryDumbCc]: false,
  [CONFIG_KEYS_MAP.showCategoriesFilter]: true,
  [CONFIG_KEYS_MAP.chooseExperienceEnabled]: false,
  [CONFIG_KEYS_MAP.useProfile]: false,
  [CONFIG_KEYS_MAP.spinPageEnabled]: true,
  [CONFIG_KEYS_MAP.showOtherAmount]: false,
  [CONFIG_KEYS_MAP.appStoreLink]:
    'https://apps.apple.com/si/app/uber/id368677368',
  [CONFIG_KEYS_MAP.playMarketLink]:
    'https://play.google.com/store/apps/details?id=com.ubercab',
  [CONFIG_KEYS_MAP.htmlMetaTags]: {
    color: '#000000',
    description: 'SYW Rewards by Tap Network',
    title: 'SYW | My TAP Rewards',
  },
  [CONFIG_KEYS_MAP.customerService]: {
    zoho_id: '441484000006378001',
    zoho_org_id: '698264665',
  },
  [CONFIG_KEYS_MAP.externalPartnerLoginEnabled]: false,
  [CONFIG_KEYS_MAP.externalPartnerLoginUrl]: null,
  [CONFIG_KEYS_MAP.externalPartnerSignUpUrl]: null,
  [CONFIG_KEYS_MAP.logoutRedirectUrl]: null,
  [CONFIG_KEYS_MAP.logoRedirectUrl]: '/',
  [CONFIG_KEYS_MAP.adGemEnabled]: true,
  [CONFIG_KEYS_MAP.useAdgemIframe]: false,
  [CONFIG_KEYS_MAP.adGemAppId]: 3472,
  [CONFIG_KEYS_MAP.facebookEnabled]: false,
  [CONFIG_KEYS_MAP.facebookId]: null,
  [CONFIG_KEYS_MAP.facebookApiVersion]: null,
  [CONFIG_KEYS_MAP.facebookRedirectUri]: null,
  [CONFIG_KEYS_MAP.useLandingPage]: false,
  [CONFIG_KEYS_MAP.charityEnabled]: false,
  [CONFIG_KEYS_MAP.giftCardsEnabled]: false,
  [CONFIG_KEYS_MAP.spotifyEnabled]: false,
  [CONFIG_KEYS_MAP.spotifyClientId]: null,
  [CONFIG_KEYS_MAP.pennyAuctionBaseURL]: 'https://penny.auctionsoftware.com',
  [CONFIG_KEYS_MAP.pennyAuctionAppId]: 'Calling_Mazzady_backend_APIs',
  [CONFIG_KEYS_MAP.pennyAuctionAppSecret]:
    '1d30ea1fc1e6d655987ad7ba67a225827c23b2e98755ecfe051405938710c89c8bffaa5bc6ceafadb6781d355e',
  [CONFIG_KEYS_MAP.pennyAuctionSearchLimit]: '10',
  [CONFIG_KEYS_MAP.signupUrl]: PATHS.signUp,
  [CONFIG_KEYS_MAP.gamesPageEnabled]: true,
  [CONFIG_KEYS_MAP.scratchPageEnabled]: true,
};
