import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import CircleIcon from 'components/CircleIcon';

import { Typography } from '@material-ui/core';

import useStyles from './styles';

const DotTab = ({ text, showDot, className }) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(className, classes.root)} data-cy="dot_tab">
      {text}
      {showDot && <CircleIcon className={classes.dot} data-cy="dot_tab-dot" />}
    </Typography>
  );
};

DotTab.defaultProps = {
  text: 'Daily Spin',
  className: '',
};

DotTab.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  showDot: PropTypes.bool.isRequired,
};

export default DotTab;
