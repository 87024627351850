import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import clsx from 'clsx';

import useStyles from './styles';

const SearchBar = ({ value, onChange, onCloseIconClick, className }) => {
  const classes = useStyles();
  return (
    <TextField
      className={clsx(classes.search, className)}
      label="Search"
      type="text"
      variant="outlined"
      value={value}
      size="small"
      fullWidth
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            {value.length ? (
              <IconButton
                onClick={onCloseIconClick}
                onMouseDown={(event) => event.preventDefault()}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
