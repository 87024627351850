import React, { useState, useEffect, memo } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import usePennyAuctionSockets from 'hooks/usePennyAuctionSockets';

import Modal from 'components/Modal';

import { ReactComponent as WinnerIcon } from 'assets/images/icons/winner-icon.svg';

import useStyles from './styles';

const WinnerPopUp = memo(() => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { wonAuctions, resetWonAuction } = usePennyAuctionSockets();

  const closeModal = () => {
    setIsOpen(false);
    resetWonAuction();
  };

  useEffect(() => {
    if (wonAuctions.length) {
      setIsOpen(true);
    }
  }, [wonAuctions]);

  const current = wonAuctions[0];

  if (!current) {
    return null;
  }

  return (
    <Modal open={isOpen} toggleModal={closeModal}>
      <Typography variant="h5" className={classes.title}>
        You&apos;ve Won! <WinnerIcon className={classes.winnerIcon} />
      </Typography>
      <Typography variant="h6" className={classes.text}>
        {`Congrats, you’ve won the right to purchase the ${current.title} for only $${current.price}!`}
      </Typography>
      <Typography variant="h6" className={classes.text}>
        A member of our support team will be in touch via your account email for
        fulfillment.
      </Typography>
      <Button
        type="submit"
        size="large"
        color="secondary"
        variant="contained"
        className={classes.button}
        onClick={closeModal}
      >
        OK
      </Button>
    </Modal>
  );
});

export default WinnerPopUp;
