import React from 'react';

import Modal from 'components/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useCmsHtml, useCms } from 'hooks/cms';
import useStyles from './styles';

const CmsModal = ({ cmsKey, isModalOpened, toggleModal, showClose }) => {
  const classes = useStyles();
  const CmsContent = useCmsHtml(cmsKey);
  const textUnderButton = useCms({ key: cmsKey, path: 'textUnderButton' });
  const buttonLink = useCms({ key: cmsKey, path: 'buttonLink' });

  return (
    <Modal open={isModalOpened} toggleModal={toggleModal} showClose={showClose}>
      <Typography variant="h5" className={classes.modalTitle}>
        How it works
      </Typography>
      <Typography component="div" variant="h6" className={classes.howItWorks}>
        <CmsContent />
      </Typography>
      <Button
        variant="contained"
        className={classes.modalButton}
        color="primary"
        size="large"
        href={buttonLink}
        target="_blank"
      >
        Let&apos;s go!
      </Button>
      <Typography variant="h7" className={classes.textUnderButton}>
        {textUnderButton}
      </Typography>
    </Modal>
  );
};

export default CmsModal;
