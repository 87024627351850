import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { storage } from 'services/storage';
import { useIsLoggedIn } from 'hooks/user';
import { selectLoginPath } from 'redux/config';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  const loginPath = useSelector(selectLoginPath);

  if (!isLoggedIn) {
    storage.local.set('referrer', location.pathname);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={loginPath} />
      }
    />
  );
};

export default ProtectedRoute;
