import React from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import format from 'date-fns/format';

import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link as MLink } from '@material-ui/core';

import useStyles from './styles';

const WalletActivityCard = ({
  displayedAmount,
  createdAt,
  displayName,
  link,
  referralEmail,
  pending,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} data-cy="activity-card">
      <Grid
        container
        alignItems="stretch"
        justify="space-between"
        className={classes.content}
      >
        <Grid
          item
          container
          direction="column"
          justify="space-between"
          className={classes.itemWapper}
        >
          <Typography
            variant="body1"
            className={clsx(classes.text, classes.name)}
            data-cy="displayed-name"
          >
            {displayName}
          </Typography>
          <Typography
            variant="body1"
            className={classes.tiny}
            data-cy="activity-date"
          >
            {format(new Date(createdAt), 'MMMM d, y')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify={link ? 'space-between' : 'center'}
          className={classes.amountWrapper}
        >
          {displayedAmount && (
            <Typography
              variant="body1"
              className={classes.amount}
              data-cy="displayed-amount"
            >
              {displayedAmount}
            </Typography>
          )}
          {pending && <Typography className={classes.tiny}>Pending</Typography>}
          {link && (
            <MLink
              component={link.isExternal ? 'a' : Link}
              target={link.isExternal ? '_blank' : '_self'}
              href={link.url}
              to={link.url}
              className={classes.link}
              data-cy="activity-link"
            >
              <Typography className={classes.text}>{link.label}</Typography>
            </MLink>
          )}
          {referralEmail && (
            <Typography variant="body1" className={classes.tiny}>
              {referralEmail}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

WalletActivityCard.defaultProps = {
  link: null,
  displayedAmount: null,
};

WalletActivityCard.propTypes = {
  displayedAmount: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
};

export default WalletActivityCard;
