export const PATHS = {
  activity: '/activity',
  chooseExperience: '/choose-experience',
  exchange: '/exchange',
  faq: '/faq',
  forgotPassword: '/forgot-password',
  login: '/login',
  loginSpotify: '/login/oauth/spotify',
  loginUphold: '/login/oauth/uphold',
  externalLogin: '/external-login',
  followArtists: '/follow/artists',
  followTracks: '/follow/tracks',
  followed: '/follow',
  privacy: '/privacy',
  profile: '/profile',
  resetPassword: '/reset-password',
  referral: '/referral',
  signIn: '/sign-up',
  signUp: '/sign-up',
  offers: '/offers',
  spin: '/spin',
  scratch: '/scratch',
  scratchRules: '/scratch/rules',
  support: '/support',
  sweepstakes: '/sweepstakes',
  terms: '/terms',
  spinnerRules: '/spinner-rules',
  sweepstakesActivity: '/activity/sweepstakes',
  root: '/',
  moreTokens: '/get-more-tokens',
  charities: '/charities',
  charityUpsell: '/charity-upsell',
  giftCards: '/gift-cards',
  donations: '/donations',
  liveBids: '/live-bids',
  onboarding: '/onboarding',
  games: '/games',
};

export const ALTNAMING = {
  auctions: '/auctions',
  affiliates: '/affiliates',
  awards: '/awards',
};

export const supportItems = ['FAQ', 'Support'];

const staticInfoRoutes = [
  { name: 'FAQ', path: PATHS.faq },
  { name: 'Terms', path: PATHS.terms },
  { name: 'Privacy', path: PATHS.privacy },
  { name: 'Support', path: PATHS.support },
];

const winnerRoute = {
  name: 'Winners',
  path: PATHS.sweepstakes,
};

export const getSidebarItems = (
  isChooseExperienceEnabled,
  isGamesPageEnabled
) => {
  const gamesRoute = isGamesPageEnabled && {
    name: 'Games',
    path: PATHS.games,
  };

  const sweepsRoute = {
    name: isChooseExperienceEnabled ? 'Choose experience' : 'Sweepstakes',
    path: isChooseExperienceEnabled
      ? PATHS.chooseExperience
      : PATHS.sweepstakes,
  };

  const defaultRoutes = [sweepsRoute, gamesRoute, winnerRoute];

  const loggedOutRoutesList = [...defaultRoutes, ...staticInfoRoutes];

  const loggedInRoutesList = [
    ...defaultRoutes,
    { name: 'Activity', path: PATHS.activity },
    ...staticInfoRoutes,
  ];

  return { loggedInRoutesList, loggedOutRoutesList };
};
