import {
  takeLeading,
  call,
  put,
  select,
  race,
  delay,
} from 'redux-saga/effects';
import { fetchPartnerConfig } from 'services/api/config';
import { updateAuctionApiConfig } from 'services/api/liveBids';
import { storage } from 'services/storage';
import { MAX_REQUEST_TIMEOUT } from 'constants/config';
import partnerConfig from 'constants/partnerConfig';
import { fetchConfig, fetchConfigSuccess, fetchConfigFailure } from './slice';

const isDevMode = process.env.NODE_ENV === 'development';

function* fetchConfigSaga() {
  const defaultConfig = yield select((state) => state.config);
  const cachedConfig = JSON.parse(storage.local.get('cachedConfig'));
  let config = {
    ...defaultConfig,
    ...cachedConfig,
    ...(isDevMode ? partnerConfig : {}),
  };
  try {
    const { response } = yield race({
      response: call(fetchPartnerConfig),
      timeout: delay(MAX_REQUEST_TIMEOUT),
    });

    if (response) {
      const { data } = response;
      const {
        meta_color,
        meta_description,
        meta_title,
        zoho_id,
        zoho_org_id,
        ...rest
      } = data;
      const fetchedConfig = {
        html_meta_tags: {
          color: meta_color,
          description: meta_description,
          title: meta_title,
        },
        customer_service: {
          zoho_id: zoho_id ?? config.customer_service.zoho_id,
          zoho_org_id: zoho_org_id ?? config.customer_service.zoho_org_id,
        },
        ...rest,
      };
      if (isDevMode) {
        // eslint-disable-next-line no-console
        console.warn(
          `These env keys are missed - ${Object.keys(fetchedConfig)
            .filter((key) => !config[key])
            .join(', ')}\n\nDo you want to add them?`
        );
      } else {
        config = { ...config, ...fetchedConfig };
      }
    }
    yield call(updateAuctionApiConfig, config);
    storage.local.set('cachedConfig', JSON.stringify(config));
    yield put(fetchConfigSuccess(config));
  } catch (errorMsg) {
    const error = errorMsg?.data?.errors[0] || errorMsg.message;
    yield put(fetchConfigFailure(error));
    storage.local.set('cachedConfig', JSON.stringify(config));
  }
}

export function* configSaga() {
  yield takeLeading(fetchConfig, fetchConfigSaga);
}
