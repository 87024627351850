import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  selectActivities,
  getMoreActivities,
  setActivityCategory,
} from 'redux/wallet';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PATHS } from 'constants/navigation';
import HorizontalFilter from 'components/HorizontalFilter';
import Loader from 'components/Loader';
import { useCms } from 'hooks/cms';

import WalletActivityCard from './components/WalletActivityCard';

import useStyles from './styles';

const WalletPage = ({ match, history }) => {
  const tabs = useCms({ key: 'activityCategories', path: 'items' });

  const classes = useStyles();

  const dispatch = useDispatch();

  const { isLoadingActivities, activities, meta } = useSelector(
    selectActivities
  );

  useEffect(() => {
    dispatch(setActivityCategory(match.params?.activityType));
  }, [dispatch, match.params]);

  useEffect(() => {
    if (match.params?.activityType !== meta.category) {
      dispatch(setActivityCategory(match.params?.activityType));
    }
  }, [dispatch, match.params, meta.category]);

  const setCategory = useCallback(
    (key) => {
      if (meta.category !== key) {
        history.push(`${PATHS.activity}/${key}`);
      }
    },
    [history, meta.category]
  );

  useEffect(() => {
    if (!meta.category && tabs?.length) {
      if (history.location.state?.from !== '/gift-cards/:id/success') {
        setCategory(tabs[0]?.key);
      }
    }
  }, [tabs, setCategory, meta.category, history]);

  const fetchData = () => dispatch(getMoreActivities());

  return (
    <div className={classes.container}>
      {meta.category && (
        <HorizontalFilter
          filterOptions={tabs}
          activeFilter={meta.category}
          setActiveFilter={setCategory}
        />
      )}
      {isLoadingActivities && <Loader />}
      {!isLoadingActivities &&
        (activities.length ? (
          <InfiniteScroll
            dataLength={activities.length}
            next={fetchData}
            hasMore={meta.page < meta.totalPages}
            loader={<CircularProgress size={20} />}
            scrollableTarget="scrollableTarget"
          >
            {activities.map((item) => (
              <WalletActivityCard key={item.id} {...item} />
            ))}
          </InfiniteScroll>
        ) : (
          <Typography className={classes.empty} data-cy="no-activity-message">
            You don’t have any activity yet.
          </Typography>
        ))}
    </div>
  );
};

export default WalletPage;
