import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import merge from 'lodash/merge';

import base from './base.theme';

const sywTheme = createMuiTheme(
  merge({}, base, {
    palette: {
      primary: {
        main: 'rgb(78, 41, 153)', // #4E2999
      },
      secondary: {
        main: 'rgb(78, 41, 153)', // #4E2999
      },
      accent: {
        main: 'rgb(78, 41, 153)', // #4E2999
        secondary: '#E2E2E2',
      },
      accentLight: {
        main: 'rgba(78, 41, 153, 0.3)',
      },
      grey: {
        main: 'rgb(224,224,224)', // #E0E0E0
        withOpacity: 'rgba(224,224,224, .8)',
        secondary: '#D0CCD0',
        third: '#F9F9F9',
      },
      greyDark: {
        main: '#757575',
        secondary: '#545454',
      },
      border: {
        main: 'rgba(16,19,20,0.1)', // #101314 + opacity 0.12
      },
      dark: {
        main: '#000000',
        secondary: '#101314',
        withOpacity: 'rgba(0, 0, 0, 0.6)',
      },
      selection: {
        main: 'rgba(255,255,255,0.3)',
      },
    },
  })
);

export default responsiveFontSizes(sywTheme);
