import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const GamesItem = ({ title, imgSrc, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} onClick={onClick} {...rest}>
      <div className={classes.cardImageWrapper}>
        <img src={imgSrc} alt={title} className={classes.cardImage} />
      </div>
      <Typography className={classes.title}>{title}</Typography>
    </Grid>
  );
};

export default GamesItem;
