import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectDefaultEntryOption, selectEntryOptionsItems } from 'redux/cms';

import { selectConfigProperty } from 'redux/config';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link as MLink } from '@material-ui/core';

import { CONFIG_KEYS_MAP } from 'constants/config';
import { useCurrencyConversionRate, useCardSelector } from 'hooks/wallet';

import { ReactComponent as BatIcon } from 'assets/images/brave/bat-logo.svg';

import Checkboxes from '../Checkboxes';
import OtherAmountInput from '../OtherAmountInput';

import useStyles from './styles';

const defaultAllOption = 'ALL';

const BraveLoggedInContent = ({ sweepstake, event, onEnterHandleClick }) => {
  const classes = useStyles();

  const defaultEntryOption = useSelector(selectDefaultEntryOption);
  const entryOptionsItems = useSelector(selectEntryOptionsItems);
  const isShowOtherAmount = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.showOtherAmount)
  );
  const upholdAddFundsLink = useSelector(
    selectConfigProperty(CONFIG_KEYS_MAP.upholdAddFundsLink)
  );

  const [activeOption, setActiveOption] = useState(defaultAllOption);
  const [otherAmountValue, setOtherAmountValue] = useState('');
  const [rulesChecked, setRulesChecked] = useState(true);
  const [newsChecked, setNewsChecked] = useState(false);

  const [card, CardSelector] = useCardSelector();
  const [convertUsdToCurrency, currencyName] = useCurrencyConversionRate(
    card.currency,
    true
  );

  useEffect(() => {
    setActiveOption(
      defaultEntryOption?.value ??
        entryOptionsItems[entryOptionsItems.length - 1].value
    );
  }, [defaultEntryOption, entryOptionsItems]);

  const handleOptionBtnClick = useCallback((item) => {
    setActiveOption(item.value);
    setOtherAmountValue('');
  }, []);

  const handleOtherAmountValueChange = useCallback(
    ({ target: { value } }) => {
      if (value !== '' && (Number(value) < 1 || Number.isNaN(+value))) {
        return;
      }
      setOtherAmountValue(value);
      setActiveOption(null);
    },
    [setOtherAmountValue]
  );

  const amount = convertUsdToCurrency(
    (otherAmountValue || activeOption) * 0.25
  );

  return (
    <>
      <Typography variant="h6" className={classes.pickOptionTitle}>
        How many chances to win would you like?
      </Typography>
      <div className={classes.optionBtnContainer}>
        {entryOptionsItems.map((item) => (
          <Button
            key={item.value}
            className={clsx(classes.optionBtn, {
              [classes.activeOptionBtn]: activeOption === item.value,
            })}
            variant="contained"
            onClick={() => handleOptionBtnClick(item)}
            size="large"
            data-cy="option-button"
          >
            {item.value}
          </Button>
        ))}
      </div>
      {isShowOtherAmount && (
        <OtherAmountInput
          otherAmountValue={otherAmountValue}
          handleOtherAmountValueChange={handleOtherAmountValueChange}
        />
      )}
      <div className={classes.inputContainer}>
        <Typography className={classes.inputLabel}>Choose a card</Typography>
        <div className={classes.input}>{CardSelector}</div>
      </div>
      {currencyName && (
        <>
          <Typography className={classes.exchangeAmount}>
            {currencyName === 'BAT' && (
              <BatIcon className={classes.currencyIcon} />
            )}
            {currencyName} amount:&nbsp;
            {amount}
            <MLink
              className={classes.addFundsLink}
              href={upholdAddFundsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Add funds
            </MLink>
          </Typography>
          <div className={classes.note}>
            * {currencyName} value adjusts every 15 seconds
          </div>
        </>
      )}
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.enterNowBtn}
          variant="contained"
          disableElevation
          disabled={!rulesChecked || !card.id || !(+card.balance > amount)}
          onClick={() =>
            onEnterHandleClick({
              value: otherAmountValue || activeOption,
              newsChecked,
              cardId: card.id,
            })
          }
          size="large"
          color="primary"
          data-cy="sweep-enter-button"
        >
          {card.id && Number(card.balance) > Number(amount)
            ? 'Enter Now'
            : 'Choose a different card'}
        </Button>
        <Typography className={classes.errorMessage} data-cy="sweep-error">
          {!rulesChecked
            ? 'You must agree to the Official Rules to proceed. \n'
            : ''}
        </Typography>
        <Checkboxes
          sweepstake={sweepstake}
          event={event}
          rulesChecked={rulesChecked}
          setRulesChecked={setRulesChecked}
          newsChecked={newsChecked}
          setNewsChecked={setNewsChecked}
        />
      </div>
    </>
  );
};

export default BraveLoggedInContent;
