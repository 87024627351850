import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';

import { useUserNavigation } from 'hooks/navigation';

import { useIsLoggedIn } from 'hooks/user';

import {
  fetchSweeps,
  enterSweeps,
  clearEnterSweepsErrors,
  selectSweeps,
  selectSweepstakeById,
  selectEventByEventId,
} from 'redux/sweeps';
import { selectTokensAmount } from 'redux/wallet';

import PartnerSpecificContent from 'components/PartnerSpecificContent';

import BraveLoggedInContent from '../components/BraveLoggedInContent';
import LoggedInContent from '../components/LoggedInContent';
import LoggedOutContent from '../components/LoggedOutContent';

import SweepInfo from '../components/SweepInfo';

import useStyle from './styles';

const SweepstakeDetailsPage = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const isLoggedIn = useIsLoggedIn();
  const sweepstake = useSelector(selectSweepstakeById(match.params.sweepId));
  const { isLoading, isEntriesSending, sendingEntriesError } = useSelector(
    selectSweeps
  );
  const event = useSelector(
    selectEventByEventId({
      sweepId: match.params.sweepId,
      eventId: match.params.eventId,
    })
  );

  const eventLocationData = event?.location_name.split('-');

  const tokenAmount = useSelector(selectTokensAmount('ust'));
  const pointsAmount = useSelector(selectTokensAmount('wmp'));

  const onEnterHandleClick = useCallback(
    ({ value, newsChecked, cardId }) => {
      const { eventId, sweepId } = match.params;
      dispatch(
        enterSweeps({
          value,
          event_id: eventId,
          sweep_newsletter_opt_in: newsChecked,
          wallet_id: cardId,
          sweepId,
        })
      );
    },
    [match.params, dispatch]
  );
  useEffect(() => {
    dispatch(fetchSweeps());
    return () => {
      dispatch(clearEnterSweepsErrors());
    };
  }, [dispatch]);

  useUserNavigation();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.wrapper}>
        {sweepstake && (
          <div className={classes.contentWrapper}>
            <SweepInfo
              sweepstake={sweepstake}
              event={event}
              eventLocationData={eventLocationData}
            />
            <div className={classes.optionsWrapper}>
              {isLoggedIn ? (
                <>
                  <PartnerSpecificContent partnerNames={['brave', 'uphold']}>
                    <BraveLoggedInContent
                      sweepstake={sweepstake}
                      event={event}
                      onEnterHandleClick={onEnterHandleClick}
                    />
                  </PartnerSpecificContent>
                  <PartnerSpecificContent
                    hidden="true"
                    partnerNames={['brave', 'uphold']}
                  >
                    <LoggedInContent
                      sweepstake={sweepstake}
                      sendingEntriesError={sendingEntriesError}
                      tokenAmount={tokenAmount}
                      pointsAmount={pointsAmount}
                      event={event}
                      onEnterHandleClick={onEnterHandleClick}
                      isEntriesSending={isEntriesSending}
                    />
                  </PartnerSpecificContent>
                </>
              ) : (
                <LoggedOutContent />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SweepstakeDetailsPage;
