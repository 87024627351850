import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchFollowTracks,
  selectTracks,
  followTrack,
} from 'redux/follow/tracks';

export const useTracks = () => {
  const dispatch = useDispatch();
  const { isLoading, tracks, isLoaded } = useSelector(selectTracks);

  useEffect(() => {
    if (!isLoading && !tracks.length && !isLoaded) {
      dispatch(fetchFollowTracks());
    }
  }, [dispatch, isLoading, tracks, isLoaded]);

  return {
    isLoading,
    tracks,
    isLoaded,
  };
};

export const useFollowTrack = () => {
  const [id, setId] = useState(0);

  const subscribeTrack = (artistId) => {
    setId(artistId);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== 0) {
      dispatch(followTrack({ product_id: id }));
    }
  }, [id, dispatch]);

  return { subscribeTrack };
};
