import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    flex: 1,
  },
  contentWrapper: {
    maxWidth: 300,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    color: theme.palette.accent.main,
    marginBottom: theme.spacing(2),
    fontSize: '0.9rem',
  },
  submitBtn: {
    width: '100%',
    textTransform: 'initial',
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
    color: theme.palette.greyDark.main,
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    '& hr': {
      flex: 1,
      border: 'none',
      borderBottom: '1px solid',
    },
    '& span': {
      margin: theme.spacing(0, 2),
    },
  },
  upholdIcon: {
    width: 24,
    height: 24,
  },
  flex: {
    flex: 1,
  },
}));
