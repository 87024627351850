import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';

import { Typography } from '@material-ui/core';

import { PATHS } from 'constants/navigation';
import { ADJUSTS_PAUSE } from 'constants/scratchGame';
import PartnerSpecificContent from 'components/PartnerSpecificContent';

import {
  fetchScratchGame,
  selectScratchGame,
  fetchScratchGameReward,
} from 'redux/scratchGame';
import { getWallet, selectTokensAmount } from 'redux/wallet';

import { useConfig } from 'hooks/config';
import { useCardSelector } from 'hooks/wallet';

import { getPartner } from 'utils/config';

import { WmgButton } from '../WmgButton';
import { WinLoseModal } from '../WinLoseModal';
import { UpholdCardSelection } from '../UpholdCardSelection';

import { useStyles } from './styles';

export const ScratchGame = () => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [shuffledIcons, setShuffledIcons] = useState([]);
  const [isUserStartDrawing, setIsUserStartDrawing] = useState(false);
  const [isLessThanFourPercent, setIsLessThanFourPercent] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { config } = useConfig();
  const currentPartner = getPartner().name;
  const [card, CardSelector] = useCardSelector();
  const isBraveOrUpholdPartner =
    currentPartner === 'brave' || currentPartner === 'uphold';

  const { iconsList, orderId, errors, isModalOpen, isLoading } = useSelector(
    selectScratchGame
  );

  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const softTokens = useSelector(selectTokensAmount('soft'));
  const userTokens = useSelector(selectTokensAmount('wme'));
  const hasUserFreeScratchGame = softTokens > 0;
  const hasUserTokens = userTokens > 0;

  const context = contextRef.current;

  const widthGame = 350;
  const heightGame = 420;
  const area = widthGame * heightGame;
  const scratchGameImage = config.scratch_off_cover_image;

  const handleOnloadImage = (ctx, image, canvas) => {
    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
  };

  useEffect(() => {
    setShuffledIcons(
      _.shuffle(iconsList.map((icon) => `url(${icon.image_url})`))
    );
  }, [iconsList]);

  useEffect(() => {
    if (isBraveOrUpholdPartner && !hasUserFreeScratchGame) {
      const interval = setInterval(() => {
        dispatch(getWallet());
      }, ADJUSTS_PAUSE);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [isBraveOrUpholdPartner, hasUserFreeScratchGame, dispatch]);

  useEffect(() => {
    if (hasUserFreeScratchGame && isUserStartDrawing) {
      dispatch(fetchScratchGame({ wallet_id: card.id }));
    }
  }, [dispatch, hasUserFreeScratchGame, isUserStartDrawing, card.id]);

  useEffect(() => {
    if (isLessThanFourPercent) {
      dispatch(fetchScratchGameReward({ order_id: orderId }));
    }
  }, [isLessThanFourPercent, dispatch, orderId]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = scratchGameImage;

    image.onload = () => {
      handleOnloadImage(ctx, image, canvas);
    };
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(100, 0);
    ctx.lineTo(100, 0);
    ctx.lineTo(0, 150);
    ctx.closePath();
    ctx.fill();
    ctx.save();
    contextRef.current = ctx;
  }, [scratchGameImage]);

  const handleMouseDown = (e) => {
    if (!isLessThanFourPercent && !isLoading) {
      const { offsetX, offsetY } = e;
      contextRef.current.beginPath();
      contextRef.current.moveTo(offsetX, offsetY);

      setIsDrawing(true);
      setIsUserStartDrawing(true);
    }
  };

  const handleMouseUp = () => {
    contextRef.current.closePath();

    setIsDrawing(false);
  };

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;

    getRegionPercentAndMove(offsetX, offsetY);
  };

  const handleMouseOut = () => {
    setIsDrawing(false);
  };

  const calculateRegionPercent = () => {
    const { data } = contextRef.current.getImageData(
      0,
      0,
      widthGame,
      heightGame
    );
    let ct;
    let i;
    let len;
    for (ct = 0, i = 3, len = data.length; i < len; i += 4) {
      if (data[i] > 50) {
        // eslint-disable-next-line no-plusplus
        ct++;
      }
    }
    return ((100 * ct) / area).toFixed(2);
  };

  const getRegionPercentAndMove = (offsetX, offsetY) => {
    if (isDrawing && iconsList.length !== 0 && !isLessThanFourPercent) {
      calculateRegionPercent();

      if (calculateRegionPercent() <= 4) {
        setIsLessThanFourPercent(true);
        setIsUserStartDrawing(false);
      }
      context.beginPath();
      context.globalCompositeOperation = 'destination-out';
      context.arc(offsetX, offsetY, 20, 0, Math.PI * 2, false);
      context.fill();
    }
  };

  const handleTouchStart = (e) => {
    if (!isLessThanFourPercent && !isLoading) {
      const rect = e.target.getBoundingClientRect();
      const x = e.targetTouches[0].pageX - rect.left;
      const y = e.targetTouches[0].pageY - rect.top;

      contextRef.current.beginPath();
      contextRef.current.moveTo(x, y);
      setIsDrawing(true);
      setIsUserStartDrawing(true);
    }
  };

  const handleTouchmove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.targetTouches[0].pageX - rect.left;
    const y = e.targetTouches[0].pageY - rect.top;

    getRegionPercentAndMove(x, y);
  };

  const ScratchImageRecovery = () => {
    const canvas = canvasRef.current;
    context.restore(true);
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = scratchGameImage;
    image.onload = () => {
      handleOnloadImage(context, image, canvas);
    };
    context.save();
  };

  const handleOnclick = (isUserHasBalance) => {
    if (isUserHasBalance) {
      ScratchImageRecovery();
      dispatch(fetchScratchGame({ wallet_id: card.id }));
      setIsLessThanFourPercent(false);
    } else {
      dispatch(push(PATHS.moreTokens));
    }
  };

  return (
    <>
      <canvas
        width={widthGame}
        height={heightGame}
        style={{
          backgroundImage: shuffledIcons.toString(),
          backgroundSize: '22%',
          backgroundPosition:
            'top 10px left 10px, top 10px left 50%,' +
            'top 10px right 10px, top 50% left 10px,' +
            'top 50% left 50%, top 50% right 10px,' +
            'bottom 10px left 10px, bottom 10px left 50%,' +
            'bottom 10px right 10px',
          backgroundRepeat: 'no-repeat',
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseOut={handleMouseOut}
        onBlur={handleMouseOut}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleMouseUp}
        onTouchMove={handleTouchmove}
        ref={canvasRef}
      />
      <PartnerSpecificContent partnerNames={['brave', 'uphold']}>
        {!hasUserFreeScratchGame && (
          <UpholdCardSelection
            card={card}
            cardSelector={CardSelector}
            handleOnclick={handleOnclick}
          />
        )}
      </PartnerSpecificContent>
      <PartnerSpecificContent partnerName="wmg">
        {!hasUserFreeScratchGame && (
          <WmgButton
            handleOnclick={handleOnclick}
            hasUserTokens={hasUserTokens}
          />
        )}
      </PartnerSpecificContent>
      {errors.length !== 0 &&
        errors.map((error) => (
          <Typography color="error" className={classes.errorText}>
            {error}
          </Typography>
        ))}
      {isModalOpen ? (
        <WinLoseModal
          cardBalance={card.balance}
          handleOnclick={handleOnclick}
          hasUserTokens={hasUserTokens}
          currentPartner={currentPartner}
          setIsLessThanFourPercent={setIsLessThanFourPercent}
        />
      ) : null}
    </>
  );
};
