import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './styles';

const SupportPage = () => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open');
    });
  }, [location.pathname]);

  return (
    <div>
      <div className={classes.logoContainer}>
        <Grid container justify="center">
          <Grid container direction="column" />
        </Grid>
      </div>
    </div>
  );
};

export default SupportPage;
