import TagManager from 'react-gtm-module';

export const setFBPixelEvent = (eventName) => {
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      eventAction: eventName,
    },
    dataLayerName: 'onboarding',
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const sendGAEvent = (event) => {
  if (window.ga) {
    window.ga('send', { hitType: 'event', ...event });
  }
};

export const setGAEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventFields = {}
) => {
  sendGAEvent({
    eventCategory,
    eventAction,
    eventLabel,
    ...eventFields,
  });
};

export const sendPageView = (location) => {
  if (window.ga) {
    window.ga('send', 'pageview', location.pathname);
  }
};
