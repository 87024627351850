import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import clsx from 'clsx';
import format from 'date-fns/format';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { PATHS } from 'constants/navigation';

import { fetchLiveBids, selectLiveBidsList } from 'redux/liveBids';
import { useIsLoggedIn } from 'hooks/user';
import usePennyAuctionSockets from 'hooks/usePennyAuctionSockets';
import { selectLoginPath, selectPennyAuctionBaseUrl } from 'redux/config';

import Loader from 'components/Loader';
import { toHHMMSS } from 'utils/formatting';

import LiveBidsIntroModal from 'components/LiveBidsIntroModal';
import useStyles from './styles';

const LiveBidsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const loginPath = useSelector(selectLoginPath);

  const [showIntroModal, changeShowIntroModal] = useState(false);

  const { shouldFlash, calculateDiff } = usePennyAuctionSockets();

  const closeIntroModal = () => {
    changeShowIntroModal(false);
  };

  const { isLoading, items } = useSelector(selectLiveBidsList);
  const pennyAuctionBaseURL = useSelector(selectPennyAuctionBaseUrl);

  useEffect(() => {
    dispatch(fetchLiveBids());
  }, [dispatch]);

  const goToLogin = useCallback(
    (id) => {
      dispatch(push(`${loginPath}?redirect_uri=${PATHS.liveBids}/${id}`));
    },
    [dispatch, loginPath]
  );

  const handleBtnPress = (id) => {
    if (!isLoggedIn) {
      goToLogin(id);
    } else {
      dispatch(push(`${PATHS.liveBids}/${id}`));
    }
  };

  const getBidderInfo = (isFuture, isSold, dateAdded, bidder) => {
    if (isFuture) {
      return `@ ${format(new Date(dateAdded), 'M/dd, h:mm a')}`;
    }
    if (isSold) {
      return `Winner: ${bidder}`;
    }
    return bidder;
  };

  const getPriceInfo = (price, isSold) => {
    if (isSold) {
      return `Winning bid: $${price}`;
    }
    return `$${price}`;
  };

  const getVisibleTime = (diff) => {
    // number of seconds in 24 hours
    if (diff > 86400) {
      return '';
    }
    return toHHMMSS(diff);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {items.map((item) => {
        const {
          id,
          avatar,
          bidder,
          dateAdded,
          isEnded,
          isFuture,
          isSold,
          price,
          title,
        } = item;

        const diff = calculateDiff(item);

        let btnText = 'BID NOW';

        if (!isLoggedIn && !isFuture) {
          btnText = 'LOG IN';
        }
        if (isSold) {
          btnText = 'SOLD';
        }

        return (
          <Grid key={id} item xs={6} sm={4} md={3}>
            <div className={classes.item}>
              <Typography className={classes.title}>{title}</Typography>
              <div className={classes.imgContainer}>
                <img
                  src={`${pennyAuctionBaseURL}/uploads/auction/${avatar}`}
                  className={clsx(classes.image, {
                    [classes.hiddenImg]: isSold,
                  })}
                  alt={title}
                />
                {isSold && (
                  <Typography color="error" className={classes.sold}>
                    SOLD
                  </Typography>
                )}
              </div>
              <Typography
                className={clsx(classes.time, {
                  [classes.redTime]: diff > 0 && diff <= 15,
                })}
              >
                {isEnded ? 'Ended' : getVisibleTime(diff)}
              </Typography>
              <Typography
                className={clsx(classes.price, {
                  [classes.flashAnimation]: shouldFlash(item),
                })}
              >
                {getPriceInfo(price, isSold)}
              </Typography>
              <Typography className={classes.bidderName}>
                {getBidderInfo(isFuture, isSold, dateAdded, bidder)}
              </Typography>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => handleBtnPress(id)}
                disabled={isEnded || isFuture}
              >
                {btnText}
              </Button>
            </div>
          </Grid>
        );
      })}
      <LiveBidsIntroModal open={showIntroModal} onClose={closeIntroModal} />
    </>
  );
};

export default LiveBidsList;
