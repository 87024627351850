import { PATHS } from 'constants/navigation';
import { CONFIG_KEYS_MAP } from '../config';

export default {
  [CONFIG_KEYS_MAP.useVeryDumbCc]: false,
  [CONFIG_KEYS_MAP.showCategoriesFilter]: true,
  [CONFIG_KEYS_MAP.chooseExperienceEnabled]: false,
  [CONFIG_KEYS_MAP.useProfile]: false,
  [CONFIG_KEYS_MAP.spinPageEnabled]: true,
  [CONFIG_KEYS_MAP.showOtherAmount]: true,
  [CONFIG_KEYS_MAP.appStoreLink]: null,
  [CONFIG_KEYS_MAP.playMarketLink]: null,
  [CONFIG_KEYS_MAP.htmlMetaTags]: {
    color: '#000000',
    description: 'Uphold Rewards by Tap Network',
    title: 'Uphold | My TAP Rewards',
  },
  [CONFIG_KEYS_MAP.customerService]: {
    zoho_id: '441484000006378001',
    zoho_org_id: '698264665',
  },
  [CONFIG_KEYS_MAP.externalPartnerLoginEnabled]: false,
  [CONFIG_KEYS_MAP.externalPartnerLoginUrl]: null,
  [CONFIG_KEYS_MAP.externalPartnerSignUpUrl]: null,
  [CONFIG_KEYS_MAP.logoutRedirectUrl]: null,
  [CONFIG_KEYS_MAP.logoRedirectUrl]: '/',
  [CONFIG_KEYS_MAP.adGemEnabled]: true,
  [CONFIG_KEYS_MAP.useAdgemIframe]: false,
  [CONFIG_KEYS_MAP.adGemAppId]: 3472,
  [CONFIG_KEYS_MAP.facebookEnabled]: false,
  [CONFIG_KEYS_MAP.facebookId]: null,
  [CONFIG_KEYS_MAP.facebookApiVersion]: null,
  [CONFIG_KEYS_MAP.facebookRedirectUri]: null,
  [CONFIG_KEYS_MAP.useLandingPage]: false,
  [CONFIG_KEYS_MAP.charityEnabled]: false,
  [CONFIG_KEYS_MAP.giftCardsEnabled]: true,
  [CONFIG_KEYS_MAP.spotifyEnabled]: false,
  [CONFIG_KEYS_MAP.spotifyClientId]: null,
  [CONFIG_KEYS_MAP.pennyAuctionBaseURL]: 'https://penny.auctionsoftware.com',
  [CONFIG_KEYS_MAP.pennyAuctionAppId]: 'Calling_Mazzady_backend_APIs',
  [CONFIG_KEYS_MAP.pennyAuctionAppSecret]:
    '1d30ea1fc1e6d655987ad7ba67a225827c23b2e98755ecfe051405938710c89c8bffaa5bc6ceafadb6781d355e',
  [CONFIG_KEYS_MAP.pennyAuctionSearchLimit]: '10',
  [CONFIG_KEYS_MAP.signupUrl]: PATHS.signUp,
  [CONFIG_KEYS_MAP.upholdAuthLink]:
    'https://sandbox.uphold.com/authorize/f4a5ade43f814f8e6cb6efe8aa3e7cd0c90772f0?scope=cards:read%20cards:write%20phones:read%20transactions:read%20transactions:transfer:application%20user:read',
  [CONFIG_KEYS_MAP.upholdAppState]: 'p172y44ftv82fb7v84b1c',
  [CONFIG_KEYS_MAP.upholdAddFundsLink]:
    'https://sandbox.uphold.com/dashboard/cards/',
  [CONFIG_KEYS_MAP.upholdVerificationLink]:
    'https://sandbox.uphold.com/dashboard/membership/personal-information',
  [CONFIG_KEYS_MAP.useExternalWallets]: true,
  [CONFIG_KEYS_MAP.filteredExternalCurrencies]: 'BAT',
  [CONFIG_KEYS_MAP.gamesPageEnabled]: true,
  [CONFIG_KEYS_MAP.scratchPageEnabled]: true,
};
