import React from 'react';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { Upsell } from 'models/sweeps';

import SweepstakeItem from '../SweepstakeItem';

import useStyle from './styles';

const params = (numOfSlides) => ({
  plugins: [
    'infinite',
    'arrows',
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides: numOfSlides,
      },
    },
  ],
});

const Upsells = ({ upsells }) => {
  const classes = useStyle();

  return (
    !isEmpty(upsells) && (
      <>
        <Typography variant="h5" className={classes.upsellTitle}>
          You may be also interested in:
        </Typography>

        <Grid item xs={12} sm={12} md={12}>
          <Carousel
            {...params(4)}
            breakpoints={{
              720: params(1),
              1100: params(2),
              1300: params(3),
            }}
            className={classes.carousel}
          >
            {upsells.map((item) => (
              <SweepstakeItem key={item.id} item={item} />
            ))}
          </Carousel>
        </Grid>
      </>
    )
  );
};

Upsells.propTypes = {
  upsells: PropTypes.arrayOf(Upsell).isRequired,
};

export default Upsells;
