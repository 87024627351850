import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: 'max-content',
  },
  text: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.7),
  },
  svg: {
    fontSize: '1.3rem',
  },
}));
